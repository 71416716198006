import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManangeLayoutService {
  private classState = new BehaviorSubject<boolean>(true); // true significa que a classe está ativa

  classState$ = this.classState.asObservable();

  constructor() { }

  toggleClass() {
    this.classState.next(!this.classState.value);
  }

  addClass() {
    this.classState.next(true);
  }

  removeClass() {
    this.classState.next(false);
  }
}
