import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SVirtualIndexCalculationService {

  constructor() { }

  novoVirtualIndex: number = 0;
  getVirtualActiveIndex(loaded_slides:number, virtualIndex:number, realActiveIndex:number, lastRealIndex:number){
    let slickN = loaded_slides;

    let swipeFactor = this.getSwipeFactor(realActiveIndex, lastRealIndex);
    this.novoVirtualIndex = this.calculateIndexSum(virtualIndex, swipeFactor, slickN);

    return this.novoVirtualIndex;
  }
  getSwipeFactor(active: any, last: any) {
    return (active - last + 3) % 3 === 1 ? 1 : -1;
  }
  calculateIndexSum(virtualIndex: any, swipeFactor: any, slickN: any) {
    let indexSum = (virtualIndex + swipeFactor) % slickN;

    // Ajusta se o resultado for negativo
    if (indexSum < 0) {
      indexSum += slickN;
    }

    return indexSum;
  }
}
