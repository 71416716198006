import { NgModule } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { PhonePipe } from './phone.pipe';
import { DataBackButtonDirective } from './directives/data-back-button.directive';
import { CpfCnpjMaskDirective } from './directives/cpf-cnpj-mask.directive';
import { FooterSpComponent } from './components/footer-sp/footer-sp.component';
import { HeaderSpComponent } from './components/header-sp/header-sp.component';
//import { WaitSpinnerComponent } from './components/wait-spinner/wait-spinner.component';
// import { CurrencyBRLPipe } from './pipes/currency-brl.pipe';
// import { DateDDMMYYYYPipe } from './pipes/date-ddmm-yyyy.pipe';
// import { WhatsappPipe } from './pipes/whatsapp.pipe';
import { FormatDurationPipe } from './format-duration.pipe';
@NgModule({
  declarations: [
    PhonePipe,
    FormatDurationPipe,
    DataBackButtonDirective,
    CpfCnpjMaskDirective,
    FooterSpComponent,
    HeaderSpComponent,
    //WaitSpinnerComponent,
    //CurrencyBRLPipe,
    //DateDDMMYYYYPipe,
    //WhatsappPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [Location],
  exports: [
    PhonePipe,
    //CurrencyBRLPipe,
    //DateDDMMYYYYPipe,
    //WhatsappPipe,
    DataBackButtonDirective,
    FooterSpComponent,
    HeaderSpComponent,
    FormatDurationPipe,
    //WaitSpinnerComponent
  ]
})
export class SharedModule { }
