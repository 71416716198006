<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="sidebar-header">
        <a [routerLink]="['/']" class="app-logo">
            <img [src]="logoSrc" alt="Logo" class="app-logo-normal" style="height: 40px;">
            <img [src]="logoSrc" alt="Logo" class="app-logo-small" style="height: 40px;">
        </a>
        <button class="layout-sidebar-anchor p-link z-2 mb-2" type="button" (click)="anchor()"></button>
    </div>


    <!-- Barra entre os elementos -->
    <div class="divider-bar"></div>
    <!-- <button *ngIf="!createWidgetBtn" pButton pRipple label="Salvar" [loading]="isLoadingSave"
                (click)="editWidget(1)" class="ml-2 mr-2"></button> -->

    <div class="sidebar-header" style="padding: 1.5rem 1rem;">
        <button pButton pRipple icon="pi pi-plus-circle" class="btn-adicionar w-full p-button-label"
            (click)="goToLives()">
            <ng-container *ngIf="layoutService.state.sidebarActive || isMobile()">
                <div class="ml-2 ss-l-btn-plus">add slick</div>

            </ng-container>
        </button>
    </div>
    <div class="sidebar-header"
        style="padding: 0rem 2rem !important; margin-bottom: 1rem !important; margin-top: 0.0rem !important;justify-content: left;">
        <button class="player-button p-button-label" (click)="goToMyPlayer()">
            🏠 <ng-container *ngIf="layoutService.state.sidebarActive || isMobile()">
                <div class="p-button-label text-left ml-2">Ir para meu Player</div>
            </ng-container>
        </button>
    </div>
    <div #menuContainer class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>