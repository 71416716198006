import { Injectable } from '@angular/core';
import { ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(private appRef: ApplicationRef, private swUpdate: SwUpdate) {  // Corrigido aqui

  }

  update() {
    if (this.swUpdate.isEnabled) {
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(1 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await this.swUpdate.checkForUpdate();
          if (updateFound) {
            this.promptUser();
          }
        } catch (err) {
          console.error('Error when checking for update', err);
        }
      });
    }
  }

  promptUser() {
    if (confirm("New version available. Load New Version?")) {
      window.location.reload();
    }
  }
}
