<div class=" ss-l-selectButtonWidth rotate-90" *ngIf="!isFullScreen">
    <div>
        <p-selectButton class="ss-g-selectButton-50" [options]="landscapeOptions3" (onChange)="changeViewMode()"
            [(ngModel)]="valSelectviewMode" optionLabel="name" [disabled]="recording">
            <ng-template let-option pTemplate="item">
                <div class="flex align-items-center justify-content-center">
                    <i [ngClass]="option.icon"></i>
                    <span class="font-bold">{{ option.name }}</span>
                </div>
            </ng-template>
        </p-selectButton>
    </div>
</div>
<div class="flex align-items-center justify-content-center" (mouseup)="onDragEnd($event)">
    <div id="app">

    </div>
    <div [ngClass]="[columnSize, noPadding ? 'no-padding' : '']" style="text-align: center;">
        <div class="mt-2 mb-2  ss-l-selectButton-bottom " *ngIf="!noPadding">
            <div class="w-full">
                <p-selectButton class=" mt-5 w-full ss-g-selectButton-50" [options]="landscapeOptions2"
                    (onChange)="changeLandscape()" [(ngModel)]="valSelect1Video" optionLabel="name"
                    [disabled]="recording">
                    <ng-template let-option pTemplate="item">
                        <div class="flex align-items-center justify-content-center w-full">
                            <i [ngClass]="option.icon" class="mr-2"></i>
                            <span class="font-bold">{{ option.name }}</span>
                        </div>
                    </ng-template>
                </p-selectButton>
            </div>
        </div>

        <div #videoUploadContainer class="relative video-container " (pointerenter)="showMenu()" [ngClass]="{
            'ss-l-video-container': valSelectviewMode.value === 1,
          }" (pointermove)="onMouseMove()" (pointerdown)="onTouch()">



            <div #videoUploadContainerInside class="videoUploadContainer flex" (mouseenter)="onVideoMouseEnter()"
                (mouseleave)="onVideoMouseLeave()" (mousemove)="onVideoMouseMove()" [ngClass]="{
                    'video-upload-container-full': fullScreen,
                    'ss-l-view-mode-mobile': valSelectviewMode.value === 1,
                    'ss-l-view-mode-desktop': valSelectviewMode.value === 2,
                    'ss-l-view-mode-desktop-vertical': valSelect1Video.value === 2 && valSelectviewMode.value === 1
                  }" [ngStyle]="dynamicStyle">
                <div #progressContainerWrapper [ngStyle]="combinedStylesBar()"
                    class="ml-3 col-12 mb-2 p-0 ss-l-progress-container-wrapper ss-g-progreesBarStudio "
                    style="width: 100%;" (mousedown)="onDragStart($event); $event.stopPropagation();"
                    (mousemove)="onDragging($event); $event.stopPropagation();" *ngIf="!typeLive"
                    (touchstart)="onDragStart($event); $event.stopPropagation();"
                    (mouseup)="onDragEnd($event); $event.stopPropagation();"
                    (touchend)="onDragEnd($event); $event.stopPropagation();">

                    <div #progressContainer class="ss-l-progress-container" (click)="$event.stopPropagation();"
                        [ngStyle]="{'width': progressContainerWidth}" [ngClass]="{
                  'ss-l-progress-container-fullscreen': fullScreen,'ss-g-progress-container-preview': !fullScreen}">

                        <div #originalProgress class="ss-l-original-progress" [hidden]="fakeBar"
                            [ngStyle]="{'--progress-bar-color': progressBarColor}">
                        </div>

                        <div #parallelProgress class="ss-l-parallel-progress" [hidden]="!fakeBar"
                            [ngStyle]="{'--progress-bar-color': progressBarColor}">
                        </div>

                        <!-- Div para exibir a miniatura -->
                        <div #thumbnailPreview class="ss-l-thumbnail-preview" [style.display]="'block'"
                            [style.position]="'absolute'" [style.width.px]="160" [style.height.px]="90"
                            [style.backgroundSize]="'cover'"></div>

                        <!-- <div #marker class="ss-l-marker" (click)="$event.stopPropagation();"></div> -->
                    </div>

                </div>
                <div #progressBarTime class="progress-times ml-2 ss-l-progressBarTime2 ss-g-custom-bg" *ngIf="!typeLive"
                    [ngStyle]="{'width.px': timeWidth}" style="align-items: center;">
                    <ng-container *ngIf="combinedTime$ | async as times; else loading">
                        <ng-container *ngIf="times.current && times.total; else loading">
                            <span class="text-white text-5xl mr-1 font-bold">{{ displayedTime }}</span>
                            <span class="text-xl text-white font-light">/</span>
                            <span class="text-white text-5xl ml-1">{{ times.total | formatDuration }}</span>
                        </ng-container>
                    </ng-container>
                    <ng-template #loading>
                        <div class="skeleton-loader" style="width: 25px; height: 15px; margin-right: 4px;">
                        </div> /
                        <div class="skeleton-loader" style="width: 25px; height: 15px; margin-left: 4px;">
                        </div>
                    </ng-template>
                </div>


                <div #videoInner class="text-center flex" [ngClass]="{
                    'video-upload-container-full-vertical-desktop': fullScreen || valSelect1Video.value === 1 && valSelectviewMode.value === 2,
                    'ss-l-view-mode-mobile-inner': valSelectviewMode.value === 1 && valSelect1Video.value === 1,
                    'ss-g-video-container-inner-landscape': fullScreen && valSelect1Video.value === 2 || !fullScreen && valSelect1Video.value === 2
                  }" [ngStyle]="theaterMode">
                    <!--    -->
                    <video *ngIf="videoReadyToLoad" #videoElement id="my-video" class="video-js video-preview" controls
                        preload="auto" [muted]="true" muted loop autoplay playsinline (error)="onVideoError($event)"
                        (loadeddata)="onVideoLoaded($event)">
                    </video>



                </div>
                <div *ngIf="showCountdown" [ngClass]="{
                    'ss-l-countdown-fullscreen': fullScreen,'ss-l-countdown': !fullScreen}" id="countdown"
                    [ngStyle]="countdownStyle">{{ countdownMessage }}
                </div>
                <p-sidebar [(visible)]="screenChat"
                    class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom z-9 p-0" position="bottom"
                    [style]="{position: 'absolute', width: isMobile ? '100%' : (fullScreen ? '33%' : '100%'), height: '65%'}">
                    <ng-template pTemplate="header">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span class="text-xl font-bold">Comentários</span>
                        </div>
                    </ng-template>
                    <div *ngIf="screenChat" class="ss-l-div-chat" [@slideInUpChat]>
                        <app-chat-app #chatBoxComponent [admin]="true" [showSidebar]="true" [chatId]="uuid ?? ''"
                            [showTopChat]="false"></app-chat-app>
                    </div>
                </p-sidebar>

                <p-sidebar [(visible)]="showAllShortucutLinks"
                    class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom z-9 p-0" position="bottom"
                    [style]="{position: 'absolute', width: isMobile ? '100%' : (fullScreen ? '33%' : '100%'), height: '65%'}">
                    <ng-template pTemplate="header">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span class="text-xl font-bold">Links</span>
                        </div>
                    </ng-template>
                    <!-- Container Flex para alinhar os links lado a lado -->
                    <div class="ss-g-custom-container cursor-pointer">
                        <div *ngFor="let link of additionalLinks$ | async" class="ss-g-links2"
                            (click)="handleLinkClick(link); $event.stopPropagation();">
                            <img [src]="link.click_url" class="border-circle ss-g-maxheight-links-adds" />
                            <div class="ss-g-maxWidthName-links-adds">{{ link.name }}</div>
                        </div>
                    </div>
                </p-sidebar>


                <p-sidebar [(visible)]="displaySidebarBottom" class="ss-g-custom-sidebar-width ss-l-sideBarBottom z-9"
                    position="bottom" [style]="{position: 'absolute', width: '100%', height: '35%'}">
                    <ng-template pTemplate="header">
                        <div style="display: flex; flex-direction: column;">
                            <div><b>Opções</b></div>
                        </div>
                    </ng-template>
                    <p-menu [model]="menuInferior" styleClass="ss-l-megamenu"></p-menu>
                </p-sidebar>

                <p-sidebar [(visible)]="displayCameraSidebar" position="bottom" class="z-9"
                    [style]="{position: 'absolute', width: isMobile ? '100%' : (fullScreen ? '33%' : '100%'), height: '65%'}">

                    <h3>Selecione a Câmera</h3>
                    <ul>
                        <li *ngFor="let camera of cameras; let i = index" (click)="switchCamera(camera)">
                            {{ camera.label || 'Câmera ' + (i + 1) }}
                        </li>
                    </ul>

                </p-sidebar>
                <div class="grid mx-0 video-upload-container " (click)="$event.stopPropagation();"
                    style="position: absolute; left: 0rem; right: 0rem; background-color: transparent !important;">
                    <div class="ss-g-controls" *ngIf="isPaused">
                        <div class="text-white" *ngIf="showPlayPauseButton">
                            <button (click)="togglePlayPause(); $event.stopPropagation();" #playPauseButton
                                id="playPauseButton"
                                class="ss-l-control-button text-2xl zoomin animation-duration-100 cursor-pointer"
                                style="z-index: 1002;" pButton pRipple [ngStyle]="{'background-color': playPauseColor}">
                                <i class="bi bi-play-fill " *ngIf="isPaused" [ngStyle]="{'color': iconColor}"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <!-- div de elementos posicionais start -->

            <div #gridContainer class="grid mx-0 position-grid" [style.grid-template-rows]="gridTemplateRows"
                (click)="togglePlayPause()" [ngClass]="{
                'video-upload-container-full': fullScreen,
                'ss-l-view-mode-mobile': valSelectviewMode.value === 1,
                'ss-l-view-mode-desktop': valSelectviewMode.value === 2,
                'ss-l-view-mode-desktop-vertical': valSelect1Video.value === 2 && valSelectviewMode.value === 1
              }" [ngStyle]="dynamicStyle">
                <p-dock [model]="itemsDock" position="left">
                    <ng-template pTemplate="item" let-item>
                        <div (click)="item.command($event); $event.stopPropagation();">
                            <img [src]="item.icon" class="ss-l-menu-dock cursor-pointer" [alt]="item.label"
                                width="100%" />
                        </div>
                    </ng-template>
                </p-dock>
                <div class="top-1 pt-3" [class.pr-3]="!isMobile" [class.pl-3]="!isMobile" [class.pr-2]="isMobile"
                    [class.pl-2]="isMobile">
                    <div (click)="$event.stopPropagation();" class="ss-l-logo-container">
                        <img class="ss-l-logoStore" src="{{logoStore}}" alt="Logo" />
                    </div>
                    <div class="ss-l-numUserChat-ex text-center" (click)="$event.stopPropagation();">
                        <div class="ss-l-numUserChat-in text-white"><i class="bi bi-person-fill"></i> {{ currentNumUsers
                            }}</div>
                    </div>
                </div>


                <div class="top-2" [class.pr-0]="!isMobile" [class.pl-3]="!isMobile" [class.pr-0]="isMobile"
                    *ngIf="hasAlerts">
                    <div *ngIf="screenAlertsBtn && showCTAs" class="mt-1" style="    text-align: left;">
                        <!-- Swiper, mostrado somente se showSwiper for true -->
                        <swiper *ngIf="showSwiper" [config]="configSwiper" class="mySwiper"
                            click="$event.stopPropagation();">
                            <ng-template swiperSlide *ngFor="let alert of filteredAlerts$ | async">
                                <div class="border-round alert-child cursor-pointer link-card"
                                    (click)="$event.stopPropagation();"
                                    [style.backgroundColor]="convertToRgba(alert.background ?? 'rgb(0, 0, 0)', 0.5)"
                                    [style.color]="alert.font_color"
                                    style="max-height: 5vh; display: flex; align-items: center; min-height: 3rem; padding-right: 1rem;">
                                    <i class="bi bi-bell-fill mr-2 ss-g-bellAlertsFix" style="margin-left: 1rem;"></i>
                                    <span class="ml-2">{{alert.text}}</span>
                                    <i class="pi pi-times" style="margin-left: auto;"
                                        (click)="toggleSwiperVisibility(); $event.stopPropagation();"></i>
                                </div>
                            </ng-template>
                        </swiper>
                        <!-- Badge, mostrado somente se showSwiper for false -->
                        <button *ngIf="!showSwiper" (click)="showAlertsSwiper(); $event.stopPropagation();"
                            class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right ss-g-bellAlerts"
                            pButton pRipple style="position: absolute; left: 1rem;">
                            <i pBadge="" [value]="numberOfAlerts.toString()" style="left: 0.5rem;"
                                class="p-element pi bi bi-bell-fill mr-4 p-overlay-badge ss-g-sino ">
                            </i>
                        </button>
                    </div>
                </div>




                <div class="middle-left text-center">
                    <div class="middle-left-top text-center"></div>
                    <div class="middle-left-bottom text-center">

                    </div>
                </div>
                <div class="middle-center"></div>
                <div class="middle-right" #btnsRightSup *ngIf="displayButtons" [class.pr-3]="!isMobile"
                    [class.pr-1]="isMobile">

                    <div class="ss-l-btns-right-sup ss-g-alignItensEnd">
                        <div class="positionLinks" *ngIf="showCTAs">
                            <div class="flex-container-links2">
                                <div *ngFor="let link of filteredLinksShortucut$ | async; trackBy: trackByLinkId"
                                    class="text-center">
                                    <div class="card p-1 shadow-none text-center my-0 ss-l-links ss-g-links-shorts ss-l-links-shorts-style"
                                        (click)="handleLinkClick(link); $event.stopPropagation();"
                                        [ngStyle]="{'background-color': link.bg_color}"><img [src]="link.click_url" />
                                    </div>
                                    <div class="text-white text-sm ss-l-text-sm">{{ link.name }}</div>
                                </div>
                                <button *ngIf="hasLinks$ | async" pButton pRipple
                                    (click)="openSidebarCTALinks(); $event.stopPropagation();" icon="pi pi-plus"
                                    class="ss-g-btn-plus-links border-none"></button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div *ngIf="(visibleButtonWhatsApp$ | async) as visibleButtonWhats"
                            class="ss-l-custom-btn-whats ">
                            <div *ngIf="showCTAs">
                                <button *ngIf="visibleButtonWhats.show_item"
                                    class="ss-g-whatsapp-btn p-0  m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right"
                                    pButton pRipple
                                    (click)="openWhatsApp(visibleButtonWhats); $event.stopPropagation();">
                                    <i class="pi pi-whatsapp"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="text-center ss-l-btns-right-inf mb-4 ss-g-alignItensEnd">

                        <div>
                            <button *ngIf="!screenChat" (click)="openSreenChat(); $event.stopPropagation();" pButton
                                pRipple class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right">
                                <i class="bi bi-chat-fill"></i>
                            </button>
                            <button *ngIf="screenChat" (click)="openSreenChat(); $event.stopPropagation();" pButton
                                pRipple class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right">
                                <i class="bi bi-chat-fill"></i>
                            </button>
                        </div>
                        <div>
                            <button *ngIf="!liked" (click)="sendLike(); $event.stopPropagation()" pButton pRipple
                                class="m-0 border-none ss-g-custom-bg  ss-g-btns-menu-right">
                                <i class="bi bi-heart-fill" style="position: relative;top: 3px;"></i>
                            </button>
                            <button *ngIf="liked" (click)="sendLike(); $event.stopPropagation()" pButton pRipple
                                class="m-0 border-none ss-g-custom-bg  ss-g-btns-menu-right">
                                <i class="bi bi-heart-fill" style="color: red;"></i>
                            </button>
                        </div>
                        <div>
                            <button pButton pRipple class="m-0 border-none ss-g-custom-bg  ss-g-btns-menu-right"
                                (click)="compartilhar();$event.stopPropagation();">
                                <i class="bi bi-share-fill"></i>
                            </button>
                        </div>
                        <div>
                            <button pButton pRipple type="button"
                                (click)="menu.toggle($event);$event.stopPropagation();"
                                class="m-0 border-none ss-g-custom-bg  ss-g-btns-menu-right">
                                <i class="bi bi-three-dots-vertical"></i>
                            </button>
                            <p-menu #menu class="s-g-menuLoja" [model]="getActionMenu2()" [popup]="true"
                                appendTo="body"></p-menu>
                        </div>
                        <div>
                            <button pButton pRipple (mousemove)="showVolumeControl()"
                                (click)="toggleMute(); $event.stopPropagation();" (touchstart)="showVolumeControl()"
                                (mouseleave)="hideVolumeControl()"
                                class="m-0 border-none ss-g-custom-bg  ss-g-btns-menu-right">
                                <i [ngClass]="isVideoMuted() ? 'bi bi-volume-mute-fill' : 'bi bi-volume-up'"></i>
                            </button>
                        </div>

                    </div>
                </div>
                <div class="bottom-0 pl-3">

                </div>
                <div class="bottom-1 pl-3">

                </div>
                <div class="bottom-2 pl-3"></div>
                <div class="bottom-3 pl-3" #bottomDiv [class.pl-1]="isMobile" *ngIf="showCTAs">
                    <div class="text-white text-left w-full font-bold select-none">{{slickTitle}}</div>
                    <div class="text-white w-full mb-2 cursor-pointer select-none" (click)="handleDivClick($event)"
                        style="text-align: left; max-height: auto; overflow-y: auto;"
                        [ngStyle]="{'max-height': dynamicHeight, 'overflow-y': 'auto'}">
                        {{truncatedDesc}}
                        <a (click)="goVerMais(); $event.stopPropagation();" class="text-white cursor-pointer"
                            *ngIf="showBtnVerMais && shouMuteImg"><b>ver mais</b></a>

                    </div>
                    <div *ngIf="visibleButton$ | async as visibleButton" class="ss-l-custom-btn-screen mb-2"
                        style="display: flex; justify-content: flex-start;">
                        <div *ngIf="showCTAs" class="w-full md:max-w-25rem">
                            <a *ngIf="visibleButton.show_item" pButton pRipple
                                class="font-bold py-1 ss-g-btn-screen-custom border-none w-full md:max-w-25rem"
                                [ngStyle]="{'color': visibleButton.font_color_custom, 'background-color': visibleButton.bg_color_custom}"
                                (click)="handleLinkClick(visibleButton); $event.stopPropagation();">
                                {{ visibleButton.button_text }} >
                            </a>
                        </div>
                    </div>
                    <div class="w-full mb-3">
                        <swiper [config]="configSwiperThird" class="mySwiperThird">
                            <ng-template swiperSlide *ngFor="let link of filteredLinks$ | async">
                                <div class="link-container cursor-pointer"
                                    (click)="handleLinkClick(link); $event.stopPropagation();">
                                    <div class="link-card ">
                                        <!-- Área da Imagem -->
                                        <div class="link-image" [ngStyle]="{'background-color': link.bg_color}">
                                            <img src="{{link.click_url}}" [alt]="link.name" class="image-style">
                                        </div>
                                        <!-- Conteúdo do Texto -->
                                        <div class="link-name text-sm text-white"
                                            [ngStyle]="{'background-color': convertToRgba(link.bg_color ?? 'rgb(0, 0, 0)', 0.5)}">
                                            <div class="text-white">{{link.name}}</div>
                                        </div>
                                        <!-- Ícone da Direita -->
                                        <div class="link-icon"
                                            [ngStyle]="{'background-color': convertToRgba(link.bg_color ?? 'rgb(0, 0, 0)', 0.5)}">
                                            <i class="pi pi-chevron-right" style="position: relative;top: -3px;"></i>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </swiper>

                    </div>


                    <div (click)="$event.stopPropagation();" class="grid grid-nogutter w-full mb-4">

                        <div class="col-12 ss-l-div-inf-volume-power flex justify-content-center align-items-center">
                            <div click="$event.stopPropagation();" class="text-right text-xs">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 879 152"
                                    style="height: 20px; max-width: 80px;">
                                    <style>
                                        .a {
                                            fill: #fff
                                        }
                                    </style>
                                    <path class="a"
                                        d="m6.7 103v-18.9q3.3-0.6 6.5-0.6 4.7 0 7 1.7 2.3 1.8 2.3 5.4 0 3.5-2.2 5.3-2.2 1.8-6.6 1.8-0.4 0-1.2 0h-0.6v5.3zm7-9q2 0 2.9-0.8 0.9-0.8 0.9-2.6 0-1.7-0.9-2.5-0.9-0.8-2.9-0.8-1 0-1.8 0.1v6.4q1 0.2 1.8 0.2zm21 9.3q-3.1 0-5.4-1.2-2.2-1.1-3.5-3.3-1.2-2.2-1.2-5.4 0-3.2 1.2-5.4 1.3-2.2 3.5-3.4 2.3-1.1 5.4-1.1 3.1 0 5.4 1.1 2.3 1.2 3.5 3.4 1.2 2.2 1.2 5.4 0 3.1-1.2 5.3-1.2 2.3-3.5 3.4-2.3 1.2-5.4 1.2zm0-4.2q2.3 0 3.6-1.4 1.2-1.4 1.2-4.2 0-3-1.2-4.4-1.3-1.5-3.6-1.5-2.3 0-3.5 1.4-1.3 1.4-1.3 4.3 0 3 1.2 4.4 1.3 1.4 3.6 1.4zm33.9-0.6l4-14.7h4.9l-5.4 19.2h-6.6l-3.9-13.8-4 13.8h-6.6l-5.4-19.2h5.3l3.8 14.5 4.1-14.5h5.8zm16.1-3.4v3.8h10v4h-15.2v-19.2h15v4h-9.8v3.4h8.5v4zm30.1 7.8h-5.6l-4.8-6.7q-0.3 0-0.7 0-0.4 0-0.6 0h-0.6v6.7h-5.1v-18.7q1.5-0.3 3.3-0.5 1.8-0.3 3.4-0.3 4.5 0 6.8 1.6 2.2 1.5 2.2 4.8 0 2-0.9 3.4-1 1.4-2.8 2.2zm-12.3-15.6v5q1.2 0.1 2.2 0.1 1.8 0 2.7-0.6 0.8-0.6 0.8-2 0-1.4-0.8-2.1-0.9-0.6-2.7-0.6-1.1 0-2.2 0.2zm19.3 7.8v3.8h10v4h-15.2v-19.3h15v4h-9.8v3.4h8.5v4.1zm18.7 8q-1.4 0-3-0.1-1.6-0.1-3-0.3l0.1-18.5q1.4-0.4 3.3-0.6 1.9-0.2 3.7-0.2 5.7 0 8.7 2.4 2.9 2.5 2.9 7.5 0 3.4-1.4 5.6-1.4 2.1-4.2 3.2-2.8 1-7.1 1zm1.4-4.2q2.1 0 3.4-0.5 1.3-0.6 1.9-1.9 0.6-1.2 0.6-3.3 0-2.9-1.4-4.3-1.5-1.3-4.5-1.3-0.5 0-1.1 0-0.6 0.1-1 0.1v11.1q0.8 0.1 2.1 0.1zm33.8-5.9q1.6 0.3 2.6 1.4 0.9 1.1 0.9 3 0 1.9-1 3.1-1 1.3-3.2 2-2.3 0.6-6 0.6-1.3 0-3-0.1-1.7-0.1-3-0.3v-18.5q1.5-0.4 3.4-0.6 1.8-0.2 3.5-0.2 4.6 0 6.7 1.4 2.1 1.3 2.1 4.1 0 1.5-0.8 2.6-0.8 1.1-2.2 1.5zm-5.2-6q-1.2 0-2.4 0.3v3.9h2.6q1.7 0 2.4-0.5 0.7-0.5 0.7-1.6 0-0.7-0.3-1.2-0.3-0.4-1-0.6-0.7-0.3-2-0.3zm-0.1 12.5q1.6 0 2.4-0.3 0.9-0.2 1.3-0.7 0.4-0.5 0.4-1.3 0-0.8-0.4-1.3-0.3-0.5-1-0.7-0.7-0.3-1.9-0.3h-3.1v4.5q1 0.1 2.3 0.1zm22.5-15.9h5.2l-7.1 11.3v8h-5.2v-8l-7.3-11.3h5.5l4.5 7.1z">
                                    </path>
                                    <path class="a"
                                        d="m288.9 65.7h22.9c-0.3-20.1-16.9-34-41.9-34-24.8 0-42.9 13.7-42.8 34.2 0 16.6 11.7 26.1 30.8 30.7l12.2 3.1c12.3 2.9 19.1 6.4 19.2 14-0.1 8.3-7.9 13.9-20 13.9-12.4 0-21.3-5.7-22.1-17h-23c0.6 24.4 18 37 45.4 37 27.5 0 43.7-13.2 43.7-33.8 0-18.8-14.2-28.7-33.8-33.1l-10.1-2.5c-9.8-2.2-18-5.8-17.8-13.9 0-7.3 6.4-12.6 18.1-12.6 11.4 0 18.4 5.1 19.2 14zm63.4-32.4h-23.4v112.7h23.4zm18.7 112.7h23.5v-84.5h-23.5zm11.8-95.5c7 0 12.7-5.3 12.7-11.9 0-6.4-5.7-11.8-12.7-11.8-6.9 0-12.7 5.4-12.7 11.8 0 6.6 5.8 11.9 12.7 11.9zm68.6 97.1c22.5 0 36.6-13.2 37.7-32.6h-22.1c-1.4 9-7.3 14.1-15.3 14.1-11 0-18.1-9.2-18.1-25.4 0-16 7.2-25.1 18.1-25.1 8.5 0 14 5.7 15.3 14.1h22.1c-1-19.5-15.8-32.4-37.8-32.4-25.7 0-41.5 17.8-41.5 43.7 0 25.7 15.5 43.6 41.6 43.6zm52.8-1.6h23.5v-26.9l6.3-7.2 23.1 34.1h27.4l-32.9-48 31.3-36.6h-26.9l-27.1 32.1h-1.2v-60.2h-23.5zm156.1-84.6h13.2c-0.6-17-16.3-29.7-38.1-29.7-21.5 0-38.5 12.5-38.5 31.5 0 15.2 11 24.2 28.6 29.3l13.9 3.9c11.9 3.3 22.5 7.5 22.5 18.7 0 12.4-11.9 20.5-27.6 20.5-13.4 0-25.3-5.9-26.4-18.7h-14.1c1.4 18.5 16.3 31 40.5 31 26 0 40.8-14.3 40.8-32.5 0-21.2-20.1-28-31.7-31.1l-11.5-3.1c-8.3-2.2-21.8-6.6-21.8-18.7 0-10.8 9.9-18.7 24.9-18.7 13.7 0 24 6.5 25.3 17.6zm70.9 86.3c17.2 0 29.7-8.6 33.7-21.3l-12.5-3.6c-3.3 8.8-11 13.2-21.2 13.2-15.2 0-25.7-9.8-26.3-27.9h61.3v-5.5c0-31.5-18.7-42.3-36.3-42.3-22.9 0-38.1 18.1-38.1 44 0 26 15 43.4 39.4 43.4zm-26.3-50.8c0.8-13.2 10.1-24.9 25-24.9 14.1 0 23.1 10.5 23.1 24.9zm94.2-63.7h-13v112.8h13zm36.8 0h-13v112.7h13z">
                                    </path>
                                    <path class="a"
                                        d="m848.5 8.1h3.8v9.9h2.9v-9.9h3.8v-2.5h-10.5zm11.9-2.4v12.4h3v-7.7h0.1l2.9 7.6h1.9l3-7.5h0.1v7.6h2.9v-12.4h-3.7l-3.2 7.7h-0.1l-3.2-7.7z">
                                    </path>
                                </svg>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- fim grid -->



            <div *ngIf="principal" class="grid mx-0 video-upload-container"
                (click)="togglePlayPause(); $event.stopPropagation();"
                style="position: absolute;top:0;left: 0;background-color: transparent !important;"
                (mouseenter)="onVideoMouseEnter(); $event.stopPropagation();"
                (mouseleave)="onVideoMouseLeave(); $event.stopPropagation();"
                (mousemove)="onVideoMouseMove(); $event.stopPropagation();">

                <!-- Para os primeiros 3 links -->
                <div class="positionLinks" *ngIf="showCTAs" style="position: absolute; top:130px; right: 0;">
                    <div class="flex-container-links">
                        <div *ngFor="let link of filteredLinksShortucut$ | async" class="text-center">
                            <div class="card p-1 shadow-none text-center my-0 ss-l-links ss-g-links-shorts"
                                style="border-radius: 50%;" (click)="handleLinkClick(link); $event.stopPropagation();"
                                [ngStyle]="{'background-color': link.bg_color}"><img class="border-circle"
                                    [src]="link.click_url" style="max-width: 30px;" /></div>
                            <div class="text-white">{{ link.name }}</div>
                        </div>
                        <button *ngIf="hasLinks$ | async" pButton pRipple
                            (click)="openSidebarCTALinks(); $event.stopPropagation();" icon="pi pi-plus"></button>
                    </div>
                </div>










                <div @fadeInOut class="absolute p-0 w-full gap-2 " style="z-index: 11;"
                    [ngClass]="{'ss-g-menu-inf': typeLive, 'ss-g-menu-inf2': !typeLive}" *ngIf="showControls">
                    <div class="flex w-full justify-content-center">

                        <!-- btn 1 -->


                        <!-- btn 2 -->
                        <div *ngIf="typeLive">
                            <button *ngIf="micShow" pButton pRipple icon="bi bi-mic-fill text-white-alpha-90"
                                (click)="openCloseMic(); $event.stopPropagation();" [disabled]="disableRecorder"
                                class="ss-g-custom-bg border-none text-lg border-circle-right border-circle-left"></button>
                            <button *ngIf="!micShow" pButton pRipple icon="bi bi-mic-mute text-white-alpha-90"
                                (click)="openCloseMic(); $event.stopPropagation();" [disabled]="disableRecorder"
                                class="ss-g-custom-bg border-none text-lg border-circle-right border-circle-left"></button>

                        </div>

                        <!-- btn 3 -->
                        <div *ngIf="typeLive">
                            <button *ngIf="!recording" pButton pRipple
                                (click)="startRecording(0); $event.stopPropagation();" [disabled]="disableRecorder"
                                class="ss-l-custom-bg-rec border-none text-xl ss-l-circular-button"><i
                                    class="bi bi-record-fill ss-l-custom-icon text-red-600"></i></button>
                            <div class="ss-l-custom-bg-pause-stop flex" *ngIf="recording">
                                <button pButton pRipple (click)="stopTransmission(); $event.stopPropagation();"
                                    class="border-none text-sm ss-l-circular-button-pause-stop"><i
                                        class="bi bi-stop-fill ss-l-custom-icon-pause-stop text-red-600 mr-2"></i></button>
                                <button pButton pRipple (click)="pause();" icon="bi bi-pause-fill" *ngIf="!pauseSlick"
                                    class="border-none text-sm ss-l-circular-button-pause-stop"></button>
                                <button *ngIf="pauseSlick" pButton pRipple (click)="pause(); $event.stopPropagation();"
                                    icon="bi bi-play-fill"
                                    class="border-none text-sm ss-l-circular-button-pause-stop"></button>
                            </div>
                        </div>

                        <!-- btn 4 -->
                        <div *ngIf="typeLive">
                            <button pButton pRipple icon="pi pi-video text-white-alpha-90"
                                (click)="openCameraSelector(); $event.stopPropagation();" [disabled]="disableRecorder"
                                class="ss-g-custom-bg border-none text-lg border-circle-right border-circle-left"></button>
                        </div>

                        <!-- btn 5 -->


                    </div>
                </div>
                <div class="grid ss-l-swiper-products " *ngIf="showCTAs">
                    <div class="w-full mb-1">
                        <swiper [config]="configSwiperSecond" class="mySwiperSecond">
                            <ng-template swiperSlide *ngFor="let product of filteredProducts$ | async">

                                <div class="border-round"
                                    style="border: 1px solid var(--surface-d); background-color: white;">
                                    <div *ngIf="product.highlight" class="icon-circular-red"></div>
                                    <div class="grid m-0 p-3">
                                        <div class="col-3 p-0 align-items-center justify-content-center">
                                            <img src="{{product.thumbnail}}" [alt]="product.name"
                                                style="width: 4.5rem;height: 4.5rem;" class="shadow-4" />
                                        </div>
                                        <div class="col-9 p-0">
                                            <div class="row mb-0" style="max-width: 90%;">
                                                <span class="col-12 text-sm ss-l-single-line-text"
                                                    style="font-weight: bold;padding: 0;">{{product.name}}</span>
                                            </div>
                                            <div class="row mb-2">
                                                <span class="col-12 text-xs text-bluegray-500"
                                                    style="padding: 0;">${{product.price}}</span>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 flex" style="padding: 0;">
                                                    <button pButton pRipple type="button"
                                                        class="p-button-success mr-2 p-2 border-none" label="Adicionar"
                                                        *ngIf="stateManagementService.getProductColors(product.id) | async as productColors"
                                                        [ngStyle]="{'color': productColors.adicionarFontColor, 'background-color': productColors.adicionarBgColor}">
                                                    </button>
                                                    <button pButton pRipple type="button"
                                                        class="p-button-secondary p-2 border-none" label="Comprar"
                                                        *ngIf="stateManagementService.getProductColors(product.id) | async as productColors"
                                                        [ngStyle]="{'color': productColors.comprarFontColor, 'background-color': productColors.comprarBgColor}">
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </swiper>
                    </div>
                    <div class="buttons-container"
                        style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                        <!-- btns custom e whats ctas -->
                        <div *ngIf="visibleButton$ | async as visibleButton" class="mt-2 ss-l-custom-btn-screen mr-2"
                            style="display: flex; justify-content: flex-start;">
                            <div *ngIf="showCTAs">
                                <a *ngIf="visibleButton.show_item" pButton pRipple
                                    class="font-bold py-1 ss-g-btn-screen-custom border-none"
                                    [ngStyle]="{'color': visibleButton.font_color_custom, 'background-color': visibleButton.bg_color_custom}"
                                    (click)="checkPiP(visibleButton); $event.stopPropagation();">
                                    {{ visibleButton.button_text }} >
                                </a>
                            </div>
                        </div>
                        <div *ngIf="!(visibleButton$ | async) as visibleButton" class="mt-2 ss-l-custom-btn-screen mr-2"
                            style="display: flex; justify-content: flex-start;">

                        </div>

                        <div *ngIf="(visibleButtonWhatsApp$ | async) as visibleButtonWhats"
                            class="ss-l-custom-btn-whats" style="justify-content: flex-end;">
                            <div *ngIf="showCTAs">
                                <button *ngIf="visibleButtonWhats.show_item"
                                    class="ss-g-whatsapp-btn p-0 m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right"
                                    pButton pRipple
                                    (click)="openWhatsApp(visibleButtonWhats); $event.stopPropagation();">
                                    <i class="bi bi-whatsapp"></i>
                                </button>
                            </div>
                        </div>
                    </div>


                    <div class="w-full mb-1">
                        <swiper [config]="configSwiperThird" class="mySwiperThird">
                            <ng-template swiperSlide *ngFor="let link of filteredLinks$ | async">
                                <div class="col-12 mx-0 p-0 ss-l-links"
                                    (click)="handleLinkClick(link); $event.stopPropagation();">
                                    <div class="grid mx-0 p-3 mb-3 mt-4 align-items-center card shadow-none"
                                        [ngStyle]="{'background-color': link.bg_color}" style="margin: 0;">

                                        <div class="link-image">
                                            <img src="{{link.click_url}}" [alt]="link.name"
                                                class="shadow-none border-circle image-style" />
                                        </div>
                                        <!-- Conteúdo do centro com título e texto -->
                                        <div class="col-8 mx-0 p-0 align-items-center">
                                            <div style="text-align: left;
                                            margin-left: 2rem;">
                                                <div class="text-xl font-bold">{{link.name}}</div>
                                            </div>
                                        </div>

                                        <div class="col-2 text-right mx-0 p-0">
                                            <i class="pi pi-chevron-right" style="font-size: 2rem; cursor: pointer;"
                                                (click)="openIntegrations(); $event.stopPropagation();"></i>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </swiper>
                    </div>
                </div>



                <ng-container *ngIf="(filteredPromotions$ | async) as filteredPromotions">
                    <div class="ss-l-custom-btn ml-2 ss-l-gem-promos"
                        style="position: absolute; top: 12rem; right: 1rem;" *ngIf="filteredPromotions.length > 0">
                        <div *ngIf="showCTAs">
                            <i class="bi bi-gem" (click)="toggleSidebarPromos($event); $event.stopPropagation();"
                                style="cursor: pointer;"></i>
                        </div>
                    </div>
                </ng-container>


                <div class="ss-g-positionBarControl w-full p-0 shadow-none" style="margin-bottom:0%; padding: 0%;">

                    <div @fadeInOut #controlsContainer id="controls-container"
                        class="video-js ng-star-inserted vjs-controls-enabled vjs-workinghover vjs-v8 vjs-has-started vjs-paused vjs-user-inactive">
                    </div>

                </div>
                <div *ngIf="typeLive" class="absolute" style="bottom: 1.5rem;left: 53%;">
                    <div *ngIf="recording && !pauseSlick" class="ss-l-recording-timer">
                        {{ formatTime(recordingTimeSeconds) }}
                    </div>
                    <div *ngIf="pauseSlick" class="ss-l-recording-timer">
                        {{ formatTime(countdownSeconds) }}
                    </div>
                </div>

                <div class="ss-l-alert-btn">
                    <div *ngIf="screenAlertsBtn && showCTAs">
                        <swiper [config]="configSwiper" class="mySwiper ">
                            <ng-template swiperSlide *ngFor="let alert of filteredAlerts$ | async">
                                <div class="border-round  alert-child" [ngClass]="{'mb-2': alert.show_item}"
                                    [style.border]="'1px solid var(--surface-d)'"
                                    [style.backgroundColor]="alert.background" [style.color]="alert.font_color"
                                    style="max-height: 5vh; display: flex; align-items: center; min-height: 3rem;">
                                    <span class="mb-1 ml-4">{{alert.text}}</span>
                                </div>
                            </ng-template>
                        </swiper>
                    </div>
                    <div class="ss-g-postionProductsDestaque" style="z-index: 30;" *ngIf="showCTAs"
                        [class.first-child-hidden]="!screenAlertsBtn">
                        <div class="card shadow-none p-0 " *ngFor="let product of highlightedProductsLimited$ | async"
                            style="background:transparent;border: none;margin-bottom:0;">
                            <div *ngIf="product.highlight && product.show_item " class="ss-l-produtos-destaque"
                                style="max-height: 6vmin;background-color: transparent !important;">
                                <div class="ss-l-produtos-destaque-inner mb-2">
                                    <img src="{{product.thumbnail}}" [alt]="product.name"
                                        class="shadow-4 ss-l-produtos-destaque-preview mb-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="grid m-0 ss-l-tagsPlayer" *ngIf="showBtns">
                    <div (click)="$event.stopPropagation();">
                        <img class="ss-l-logoStore" src="{{logoStore}}" alt="Logo" />
                    </div>
                    <div class="ss-l-numUserChat-ex text-center" (click)="$event.stopPropagation();">
                        <div class="ss-l-numUserChat-in"><i class="bi bi-person-fill"></i> {{ currentNumUsers }}</div>
                    </div>

                    <div *ngIf="recording && typeLive">
                        <p-tag [value]="convertToHHMMSS(tert)" class="ss-l-custom-text-size"></p-tag>
                    </div>

                    <div class="ss-l-custom-tag" *ngIf="typeLive">
                        <span class="ss-l-tag-label" *ngIf="recording">Live</span>
                        <span class="ss-l-tag-icon"
                            [ngClass]="{'ss-l-tag-icon': !recording, 'ss-l-tag-icon-recording': recording}">
                            <i class="pi pi-user"></i>
                            <span class="ss-l-tag-count">1.2k</span>
                        </span>
                    </div>

                    <div>
                        <button pButton pRipple (click)="toggleShowCTAs(); $event.stopPropagation();"
                            [icon]="showCTAs ? 'pi pi-eye' : 'pi pi-eye-slash'"
                            class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup text-white">
                        </button>
                    </div>

                    <div>
                        <button pButton pRipple icon="bi bi-magic" (click)="openSidebar(); $event.stopPropagation();"
                            class="m-0 ss-g-custom-bg border-none text-lg border-none ss-g-btns-menu-sup text-white"></button>
                    </div>

                    <div>
                        <button *ngIf="!screenChat" icon="bi bi-chat-fill"
                            (click)="openSreenChat(); $event.stopPropagation();" pButton pRipple
                            class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup text-white">
                        </button>
                        <button *ngIf="screenChat" icon="bi bi-chat-fill"
                            (click)="openSreenChat(); $event.stopPropagation();" pButton pRipple
                            class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup text-white">
                        </button>
                    </div>


                    <div>
                        <button *ngIf="!isFullScreen" icon="pi pi-window-maximize" pButton pRipple
                            (click)="goFullscreen(); $event.stopPropagation();"
                            class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup text-white">
                        </button>
                        <button *ngIf="isFullScreen" icon="pi pi-window-minimize" pButton pRipple
                            (click)="goMinscreen(); $event.stopPropagation();"
                            class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup text-white">
                        </button>
                    </div>

                    <div>
                        <button pButton pRipple [disabled]="!typeLive"
                            class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup"
                            (click)="togglePiP(); $event.stopPropagation();">
                            <i [ngClass]="isPiPActive ? 'bi bi-pip-fill' : 'bi bi-pip'"
                                style="cursor: pointer;position: relative;top: 1.5px;right: 1px;"></i>
                        </button>
                    </div>

                    <div class="volume-wrapper">
                        <button pButton pRipple (mousemove)="showVolumeControl()"
                            (click)="toggleMute(); $event.stopPropagation();" (touchstart)="showVolumeControl()"
                            (mouseleave)="hideVolumeControl()"
                            style=" border-radius: 6px; width: 2rem; padding: 0.25rem 0.5rem; height: 2rem;"
                            class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup">
                            <i [ngClass]="isVideoMuted() ? 'bi bi-volume-mute-fill' : 'bi bi-volume-up'"
                                style="cursor: pointer; position: relative; top: 1.5px; right: 1px;"></i>
                        </button>
                    </div>



                    <div>
                        <button pButton pRipple (click)="openSidebarBottom(); $event.stopPropagation();"
                            icon="bi bi-three-dots-vertical text-white-alpha-90"
                            class="m-0 ss-g-custom-bg border-none text-lg ss-g-btns-menu-sup"></button>
                    </div>
                </div>



            </div>
            <!-- div de elementos posicionais -->

















            <p-sidebar [(visible)]="displaySidebarSaldo" class="ss-g-custom-sidebar-width z-1" position="right">
                <ng-template pTemplate="header">
                    <div style="display: flex; flex-direction: column;">
                        <h3 style="margin-bottom: 0;">Recarregar Saldo</h3>
                    </div>
                </ng-template>
                <div class="sidebar-content">
                    <p>Recarga instantânea de sua carteira.</p>
                    <div class="col-12 md:col-12">
                        <div class="card shadow-none">
                            <div class="ss-g-container-qr-code mb-2" style="text-align: center;">
                                <i class="bi bi-lightning-fill text-xl"></i>
                                <div class="text-2xl mb-2">QR Code PIX</div>
                                <p class="mb-4" style="max-width: 50%;">Scaneie o QR Code ou copie o código PIX em seu
                                    app</p>
                                <div *ngIf="qrCodeUrl" class="mb-5">
                                    <!-- <qr-code [size]="250" [value]="qrCodeUrl" class="qr-code mb-0 mt-0"></qr-code> -->
                                    <!-- <qrcode [qrdata]="qrCodeUrl" [width]="256" [errorCorrectionLevel]="'M'"></qrcode> -->

                                    <img [src]="qrCodeUrl" alt="QR Code" style="width: 200px; height: 200px;">
                                </div>
                                <div class="p-inputgroup ss-g-custom-inputgroup">
                                    <input type="text" pInputText [(ngModel)]="url_share" readonly class="custom-input">
                                    <button pButton pRipple type="button" icon="pi pi-copy"
                                        (click)="copiarLink(); $event.stopPropagation();"
                                        class="ss-l-custom-button"></button>
                                </div>


                                <div class="ss-g-button-container-qr-code mt-2">
                                    <button pButton pRipple type="button" style="padding: 0;"
                                        (click)="compartilharLink(); $event.stopPropagation();" icon="pi pi-share-alt"
                                        class="p-button-link left-button" label="Compartilhar"></button>
                                    <button pButton pRipple label="Simular preço de live" icon="bi bi-table"
                                        (click)="simulateLive()" class="right-button text-xs p-1"> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <ng-template pTemplate="footer">
                    <div style="text-align: right;">
                        <button pButton pRipple (click)="closeSidebarSaldo(); $event.stopPropagation();" label="Fechar"
                            class="p-button-outlined"></button>
                    </div>
                </ng-template>
            </p-sidebar>









            <p-sidebar [(visible)]="sidebarVisible" class="ss-g-custom-sidebar-width ss-l-sideBarBottom z-1"
                position="bottom" [style]="{position: 'absolute', width: '100%', height: '80%'}">
                <ng-template pTemplate="header">
                    <div style="display: flex; flex-direction: column;">
                        <div><b>Promoções</b></div>
                    </div>
                </ng-template>

                <div class="ss-l-sidebar-bottom mt-2 mb-0 shadow-none col-12 p-0"
                    *ngFor="let promotion of filteredPromotions$ | async">
                    <div class="card shadow-none" *ngIf="promotion.show_item">
                        {{promotion.name}}
                    </div>
                </div>

            </p-sidebar>








            <p-sidebar [(visible)]="openDialogDragPromotion" position="right">
                <div class="flex-grow-1 flex flex-column">
                    <!-- Switches lado a lado -->
                    <p class="font-semibold text-xl">{{ selectedProductForEdit.name }}</p>
                    <div class="flex align-items-center gap-2 mb-2">
                        <p-inputSwitch [(ngModel)]="selectedProductForEdit.show_item" binary="true"
                            class="ss-l-switch-position3"></p-inputSwitch>
                        <label>Exibir</label>
                    </div>
                    <div class="mb-2">
                        <p-inputSwitch [(ngModel)]="selectedProductForEdit.define_interval" *ngIf="!typeLive"
                            class="ss-l-switch-position2 mr-2"
                            (ngModelChange)="toggleDefineIntervalPromotions($event)"></p-inputSwitch>
                        <label *ngIf="!typeLive">Definir Intervalo</label>
                    </div>
                    <!-- Slider abaixo dos switches -->
                    <div *ngIf="selectedProductForEdit.show_slider && !typeLive"
                        class="ss-l-inputs-container mt-2 w-full">
                        <div class="ss-l-inputs-row mb-2">
                            <div class="ss-l-input-group">
                                <label class="ss-g-globalClassLabel">Início</label>
                                <input type="text" pInputText [(ngModel)]="selectedProductForEdit.interval_time!.start"
                                    class="ss-l-half-width-input" readonly />
                            </div>
                            <div class="ss-l-input-group">
                                <label class="ss-g-globalClassLabel">Fim</label>
                                <input type="text" pInputText [(ngModel)]="selectedProductForEdit.interval_time!.end"
                                    class="ss-l-half-width-input" readonly />
                            </div>
                        </div>
                        <p-slider [(ngModel)]="sliderValuePromotion" [range]="true"
                            (ngModelChange)="updateIntervalTime($event)" [max]="sliderMaxValue"
                            class="w-full"></p-slider>

                    </div>
                </div>

                <ng-template pTemplate="footer">
                    <div style="text-align: right;">
                        <button pButton pRipple (click)="closeSidebarDetailPromos()" label="Fechar" class=""></button>



                    </div>
                </ng-template>
            </p-sidebar>


            <div class="ss-g-muteImg flex flex-column" (click)="toggleMute()"
                *ngIf="isVideoMuted() && shouMuteImg && showMute">
                <i class="'bi bi-volume-mute-fill text-white"></i>
                <div class="font-bold pl-4 pr-4 text-center text-white">Toque na tela para tirar o mute</div>
            </div>


        </div>
        <!-- abaixo video-upload-container -->

        <!-- abaixo video-upload-container -->
    </div>
</div>

<p-sidebar [(visible)]="displaySidebar" class="ss-g-custom-sidebar-width" position="right" id="sideBarCtas"
    (onHide)="onDialogCloseMain()">
    <ng-template pTemplate="header">
        <div style="display: flex; flex-direction: column;">
            <div style="margin-bottom: 0;" *ngIf="sidebarGerenciar || mananger > 0" class="text-lg">
                <div *ngIf="mananger > 0" class="ss-l-back-studio" (click)="backStudio()">
                    <div class="ss-l-back-studio">
                        <i class="pi pi-chevron-left text-lg ss-l-back-studio-icon"></i>
                    </div>
                </div>
                <span [ngStyle]="{'position': 'absolute', 'top': '19px', 'left': mananger > 0 ? '40px' : '15px'}">
                    <span (click)="closeSidebarGeral()" class="cursor-pointer"
                        [ngStyle]="{'font-weight': pageStudio === '' && pageStudioNow === '' ? 'bold' : 'normal'}">
                        Gerenciar Slick
                    </span>
                    >
                    <span class="cursor-pointer" (click)="backStudio()"
                        [ngStyle]="{'font-weight': pageStudio !== '' && pageStudioNow === '' ? 'bold' : 'normal'}">
                        {{ pageStudio }}
                    </span>
                    <span [ngStyle]="{'font-weight': pageStudioNow !== '' ? 'bold' : 'normal'}">
                        {{ pageStudioNow }}
                    </span>
                </span>
            </div>
        </div>
    </ng-template>

    <div class="sidebar-content" *ngIf="mananger == 1">
        <p>Gerencie os elementos de ações exibidos em seu Slick.</p>
        <div class="card p-1 mb-1">
            <div class="mb-2 w-full">
                <div class="mt-2 w-full">
                    <p-tabMenu [model]="ctaOptions" [(activeItem)]="activeItem" (change)="changeCta()"
                        [scrollable]="true">
                    </p-tabMenu>
                </div>
            </div>

        </div>
        <div *ngIf="activeOption === 1">
            <p>Exiba seus produtos e permita que seus clientes comprem direto de seus vídeos e trasmissões.
                <a href="#">Saiba mais.</a>
            </p>

            <div class="p-0">
                <p-pickList [source]="sourceProducts" [target]="targetProducts"
                    sourceHeader="Disponíveis ({{ sourceProducts.length }})" [responsive]="true" class="z-2"
                    [dragdrop]="true" targetHeader="Selecionados ({{ targetProducts.length }})" filterBy="name"
                    sourceFilterPlaceholder="Buscar por nome" (onMoveToSource)="onMoveToSource($event)"
                    targetFilterPlaceholder="Buscar por nome" (onMoveToTarget)="onMoveToTarget($event)">
                    <ng-template let-product pTemplate="item" let-i="index">
                        <!-- Contêiner principal para o item, com direção da coluna para empilhar conteúdo verticalmente -->
                        <div class="flex flex-column p-2 gap-2 z-2" [class.clickable]="product.isSelected"
                            (click)="product.isSelected">


                            <i *ngIf="product.isSelected" class="bi bi-three-dots-vertical p-3"
                                style="position: absolute; top: 0.5rem; right: 0.5rem;"
                                (click)="product.isSelected && openSidebarEditConfigs(product, i)"></i>

                            <!-- Contêiner para a linha da imagem e detalhes do produto -->
                            <div class="flex align-items-center gap-2">
                                <!-- Imagem à esquerda -->
                                <img class="w-4rem shadow-2 border-round" src="{{ product.thumbnail }}" />
                                <!-- Nome e preço ao lado da imagem -->
                                <div class="flex-grow-1 flex flex-column">
                                    <div class="font-bold">{{ product.name }}</div>
                                    <div class="text-xs text-bluegray-500">{{
                                        convertPriceProduct(product.price) | currency:'BRL':'symbol' }}
                                    </div>
                                </div>
                            </div>

                            <!-- Contêiner para os switches e o slider, apenas visível quando selecionado -->
                            <div *ngIf="product.isSelected" class="flex-grow-1 flex flex-column">
                                <!-- Switches lado a lado -->
                                <div class="flex align-items-center gap-1 col-12 p-0">
                                    <div class="flex p-0 col-4">
                                        <p-inputSwitch [(ngModel)]="product.show_item" class="ss-l-switch-position mr-2"
                                            (ngModelChange)="debounceFunction(); updateShowItem(product); onHighlightChange(product);"
                                            binary="true"></p-inputSwitch>
                                        <label>Exibir</label>
                                    </div>
                                    <div class="flex p-0 col-4">
                                        <p-inputSwitch [(ngModel)]="product.highlight" class="ss-l-switch-position mr-2"
                                            (ngModelChange)="debounceFunction();"
                                            (onChange)="onHighlightChange(product);" binary="true"></p-inputSwitch>
                                        <label>Destacar</label>
                                    </div>
                                    <div class="flex p-0 col-4">
                                        <button pButton pRipple icon="pi pi-trash" (click)="removeProduct(product);"
                                            class="p-button-danger p-button-sm ss-l-custom-button"></button>
                                    </div>
                                </div>
                                <!-- Slider abaixo dos switches -->

                            </div>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
        </div>
        <div *ngIf="activeOption === 2">
            <!-- Conteúdo para Custom -->

            <p-accordion *ngIf="accordionTabs.length > 0; else noCustomMessage" [activeIndex]="0"
                class="ss-l-accordion mb-2">
                <p-accordionTab *ngFor="let tab of accordionTabs; let i = index" [header]="'Botão ' + (i + 1)">
                    <div class="card shadow-none">
                        <div class="p-fluid">
                            <div class="p-field mb-2">
                                <label for="buttonText" class="ss-g-globalClassLabel">Texto do botão</label>
                                <input id="buttonText" class="mt-2" type="text" pInputText maxlength="20"
                                    [(ngModel)]="tab.button_text" (click)="forceFocus($event)"
                                    placeholder="ex: texto do botão"
                                    (ngModelChange)="debounceFunction(); setupVisibleButton();" />
                            </div>

                            <div class="p-field mb-2">
                                <label for="redirectUrl" class="ss-g-globalClassLabel">URL de
                                    redirecionamento</label>
                                <div class="p-inputgroup mt-2">
                                    <span class="p-inputgroup-addon">https://</span>
                                    <input id="redirectUrl" type="text" pInputText placeholder="ex: www.example.com"
                                        (click)="forceFocus($event)" [(ngModel)]="tab.redirect_url"
                                        (ngModelChange)="debounceFunction(); setupVisibleButton();" />
                                </div>
                            </div>


                            <div class="p-formgrid p-grid">
                                <div class="p-field p-col mb-2">
                                    <label for="fontColor" class="ss-g-globalClassLabel">Cor da
                                        fonte</label>
                                    <div class="mt-2"></div>
                                    <p-colorPicker class="mt-2 mb-2" id="fontColor" [(ngModel)]="tab.font_color_custom"
                                        format="hex" (ngModelChange)="setCurrentTabAndDebounce(tab)"
                                        [style]="{'width':'100%'}"></p-colorPicker>
                                </div>
                                <div class="p-field p-col mb-2">
                                    <label for="bgColor" class="ss-g-globalClassLabel">Cor do fundo</label>
                                    <div class="mt-2"></div>
                                    <p-colorPicker class="mt-2 mb-2" id="bgColor" [(ngModel)]="tab.bg_color_custom"
                                        format="hex" (ngModelChange)="setCurrentTabAndDebounce(tab)"
                                        [style]="{'width':'100%'}"></p-colorPicker>
                                </div>
                            </div>

                            <div class="flex center between mb-0 mt-4 col-12 p-0">
                                <div class="flex left col-4 p-0">
                                    <p-inputSwitch [(ngModel)]="tab.show_item" class="ss-l-switch-position mr-2"
                                        (ngModelChange)="debounceFunction(); setupVisibleButton();"
                                        class="mr-2"></p-inputSwitch>

                                    <label class="p-mr-5">Exibir</label>
                                </div>
                                <div class="flex left col-6 p-0" *ngIf="!typeLive">
                                    <p-inputSwitch [(ngModel)]="tab.define_interval" [disabled]="!tab.show_item"
                                        class="ss-l-switch-position mr-2"
                                        (ngModelChange)="handleDefineIntervalChange(tab); debounceFunction()"
                                        class="mr-2"></p-inputSwitch>

                                    <label class="p-mr-5">Definir Intervalo</label>
                                </div>
                                <div class="col-2 p-0 text-center">
                                    <i class="pi pi-trash cursor-pointer" (click)="removeComponentCustom(i)"></i>
                                </div>

                            </div>

                            <div class="p-field ss-l-inputs-container"
                                *ngIf="!typeLive && tab.define_interval && tab.show_item">
                                <div class="ss-l-inputs-row mb-2">
                                    <div class="ss-l-input-group">
                                        <label class="ss-g-globalClassLabel">Início</label>
                                        <input type="text" pInputText [(ngModel)]="tab.interval_time!.start"
                                            (ngModelChange)="updateSliderFromInput(tab, 'start', $event)"
                                            class="ss-l-half-width-input" readonly />
                                    </div>
                                    <div class="ss-l-input-group">
                                        <label class="ss-g-globalClassLabel">Fim</label>
                                        <input type="text" pInputText [(ngModel)]="tab.interval_time!.end"
                                            (ngModelChange)="updateSliderFromInput(tab, 'end', $event)"
                                            class="ss-l-half-width-input" readonly />
                                    </div>
                                </div>
                                <p-slider [(ngModel)]="tab.slider_time_range" (click)="forceFocus($event)"
                                    [max]="sliderMaxValue" [range]="true"
                                    (onChange)="updateInputsFromSlider(tab, $event)" class="w-full"></p-slider>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
            <ng-template #noCustomMessage>
                <div class="empty-message">
                    Você ainda não adicionou mensagens customizadas ao seu Slick.
                </div>
            </ng-template>
            <div class="flex justify-content-center">
                <button icon="pi pi-plus" label="Adicionar" (click)="addComponentCustom()" pButton pRipple class="mt-2"
                    [disabled]="isAddButtonDisabled"></button>
            </div>

        </div>

        <div *ngIf="activeOption === 3">
            <!-- Conteúdo para Whatsapp -->

            <p-accordion *ngIf="whatsappTabs.length > 0; else noWhatsappMessage" [activeIndex]="0"
                class="ss-l-accordion mb-2">
                <p-accordionTab *ngFor="let tab of whatsappTabs; let i = index" [header]="'Whatsapp ' + (i + 1)">
                    <div class="card shadow-none">
                        <div class="p-fluid">
                            <div class="grid mx-0 gap-2">
                                <div class="col-2 mb-2 p-0">
                                    <label for="ddd-{{i}}" class="ss-g-globalClassLabel">DDD</label>
                                    <div class="mt-2"></div>
                                    <input [id]="'ddd-' + i" class="mt-2" inputmode="numeric" pInputText mask="00"
                                        (input)="limitInput($event)" (keydown)="preventE($event)"
                                        (ngModelChange)="debounceFunction()" [(ngModel)]="tab.ddd" placeholder="ex: 99"
                                        type="text" />


                                </div>

                                <div class="col-9 mb-4  p-0">
                                    <label for="fone-{{i}}" class="ss-g-globalClassLabel">Fone</label>
                                    <div class="mt-2"></div>
                                    <input [id]="'fone-' + i" class="mt-2" type="text" pInputText inputmode="numeric"
                                        (ngModelChange)="debounceFunction()" mask="00000-0000" [(ngModel)]="tab.fone"
                                        placeholder="ex: 99999-9999" />
                                </div>
                            </div>
                            <div class="flex center between mb-2 mt-2">
                                <div class="flex left col-4 p-0">
                                    <p-inputSwitch [(ngModel)]="tab.show_item" class="ss-l-switch-position mr-2"
                                        (onChange)="debounceFunction(); setupVisibleButtonWhatsApp();"
                                        class="mr-2"></p-inputSwitch>
                                    <label class="p-mr-5">Exibir</label>
                                </div>
                                <div class="flex left col-6 p-0" *ngIf="!typeLive">
                                    <p-inputSwitch [(ngModel)]="tab.define_interval" [disabled]="!tab.show_item"
                                        class="ss-l-switch-position mr-2"
                                        (onChange)="handleDefineIntervalChangeWhats(tab); debounceFunction()"
                                        class="mr-2"></p-inputSwitch>
                                    <label class="p-mr-5">Definir Intervalo</label>
                                </div>
                                <div class="col-2 p-0 text-center">
                                    <i class="pi pi-trash cursor-pointer" (click)="removeComponentWhatsapp(i)"></i>
                                </div>
                            </div>

                            <div class="p-field ss-l-inputs-container"
                                *ngIf="!typeLive && tab.define_interval && tab.show_item">
                                <div class="ss-l-inputs-row mb-2">
                                    <div class="ss-l-input-group">
                                        <label class="ss-g-globalClassLabel">Início</label>
                                        <input type="text" pInputText [(ngModel)]="tab.interval_time!.start"
                                            (ngModelChange)="debounceFunction()" class="ss-l-half-width-input"
                                            readonly />
                                    </div>
                                    <div class="ss-l-input-group">
                                        <label class="ss-g-globalClassLabel">Fim</label>
                                        <input type="text" pInputText [(ngModel)]="tab.interval_time!.end"
                                            (ngModelChange)="debounceFunction()" class="ss-l-half-width-input"
                                            readonly />
                                    </div>
                                </div>
                                <p-slider [(ngModel)]="tab.slider_time_range" [range]="true" [max]="sliderMaxValue"
                                    (ngModelChange)="debounceFunction()" class="w-full"
                                    (onChange)="updateInputsFromSlider(tab, $event)"></p-slider>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
            <ng-template #noWhatsappMessage>
                <div class="empty-message">
                    Você ainda não adicionou whatsapp ao seu Slick.
                </div>
            </ng-template>
            <div class="flex justify-content-center">
                <button (click)="addComponentWhatsApp()" label="Adicionar" pButton pRipple icon="pi pi-plus"
                    class="mt-2" pButton pRipple [disabled]="isAddButtonDisabledWhats"></button>
            </div>

        </div>

        <div *ngIf="activeOption === 4">
            <!-- Conteúdo para Promoções -->

            <div class="p-0">
                <p-pickList [source]="sourcePromotions" [target]="targetPromotions" sourceHeader="Disponíveis"
                    [responsive]="true" class="z-2" [dragdrop]="true" targetHeader="Selecionados" filterBy="name"
                    sourceFilterPlaceholder="Buscar por nome" targetFilterPlaceholder="Buscar por nome"
                    (onMoveToTarget)="onMoveToTargetPromos($event)" (onMoveToSource)="onMoveToSourcePromos($event)">
                    <ng-template let-promotion pTemplate="item" let-i="index">
                        <!-- Contêiner principal para o item, com direção da coluna para empilhar conteúdo verticalmente -->
                        <div class="flex flex-column p-2 gap-2 z-2" [class.clickable]="promotion.isSelected"
                            (click)="promotion.isSelected">

                            <i *ngIf="promotion.isSelected" class="bi bi-three-dots-vertical p-3"
                                style="position: absolute; top: 0.5rem; right: 0.5rem;"
                                (click)="promotion.isSelected && openSidebarEditConfigsPromotion(promotion, i)"></i>

                            <!-- Contêiner para a linha da imagem e detalhes do produto -->
                            <div class="flex align-items-center gap-2">
                                <!-- Imagem à esquerda -->
                                <!-- Nome e preço ao lado da imagem -->
                                <div class="flex-grow-1 flex flex-column">
                                    <div class="font-bold">{{ promotion.name }}</div>
                                    <div class="text-xs text-bluegray-500">{{ promotion.value |
                                        currency:'BRL':'symbol' }}</div>
                                </div>
                            </div>

                            <!-- Contêiner para os switches e o slider, apenas visível quando selecionado -->
                            <div *ngIf="promotion.isSelected" class="flex-grow-1 flex flex-column">
                                <!-- Switches lado a lado -->
                                <div class="flex align-items-center gap-2 col-12 p-0">
                                    <div class="col-6 p-0">
                                        <p-inputSwitch [(ngModel)]="promotion.show_item"
                                            (onChange)="debounceFunction();" class="ss-l-switch-position2 mr-2"
                                            binary="true"></p-inputSwitch>
                                        <label>Exibir</label>
                                    </div>
                                    <div class="flex p-0 col-6 text-center">
                                        <button pButton pRipple icon="pi pi-trash" (click)="removePromotion(promotion)"
                                            class="p-button-danger p-button-sm ss-l-custom-button"></button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
        </div>

        <div *ngIf="activeOption === 5">
            <!-- Conteúdo para Alertas -->
            <p-accordion *ngIf="alertsTabs.length > 0; else noAlertsMessage" [activeIndex]="0"
                class="ss-l-accordion mb-2">
                <p-accordionTab *ngFor="let tab of alertsTabs; let i = index" [header]="'Alerta ' + (i + 1)">
                    <div class="card shadow-none">
                        <div class="p-fluid">
                            <div class="p-field mb-2">
                                <label for="alertText-{{i}}" class="ss-g-globalClassLabel">Insira seu
                                    alerta</label>
                                <input pInputText class="mt-2" id="alertText-{{i}}" [(ngModel)]="tab.text"
                                    placeholder="ex: Texto do alerta" type="text" (click)="forceFocus($event)"
                                    (ngModelChange)="handleChanges(i, 'text', $event)" maxlength="30" />
                            </div>

                            <div class="p-formgrid p-grid mb-3">
                                <div class="p-field p-col mb-2">
                                    <label for="fontColor-{{i}}" class="ss-g-globalClassLabel">Cor da
                                        fonte</label>
                                    <div class="mt-2"></div>
                                    <p-colorPicker id="fontColor-{{i}}" [(ngModel)]="tab.font_color" format="hex"
                                        (ngModelChange)="handleChanges(i, 'fontColor', $event)"
                                        [style]="{'width':'100%'}"></p-colorPicker>
                                </div>
                                <div class="p-field p-col mb-2">
                                    <label for="bgColor-{{i}}" class="ss-g-globalClassLabel">Cor do
                                        fundo</label>
                                    <div class="mt-2"></div>
                                    <p-colorPicker id="bgColor-{{i}}" [(ngModel)]="tab.background" format="hex"
                                        (ngModelChange)="handleChanges(i, 'backgroundColor', $event)"
                                        [style]="{'width':'100%'}"></p-colorPicker>
                                </div>
                            </div>

                            <div class="flex center between mb-2 mt-2 col-12 p-0">
                                <div class="flex left col-4 p-0">
                                    <p-inputSwitch [(ngModel)]="tab.show_item" (ngModelChange)="changeShowAlert()"
                                        class="mr-2"></p-inputSwitch>
                                    <label class="p-mr-5">Exibir</label>
                                </div>
                                <div class="flex left col-6 p-0" *ngIf="!typeLive">
                                    <p-inputSwitch [(ngModel)]="tab.define_interval" [disabled]="!tab.show_item"
                                        (ngModelChange)="changeIntervalAlert()" class="mr-2"></p-inputSwitch>
                                    <label class="p-mr-5">Definir Intervalo</label>
                                </div>
                                <div class="col-2 p-0 text-center">
                                    <i class="pi pi-trash cursor-pointer" (click)="removeComponentAlerts(i)"></i>
                                </div>
                            </div>

                            <div *ngIf="tab.define_interval && tab.show_item" class="p-field ss-l-inputs-container">
                                <div class="ss-l-inputs-row mb-2">
                                    <div class="ss-l-input-group">
                                        <label class="ss-g-globalClassLabel">Início</label>
                                        <input type="text" pInputText [(ngModel)]="tab.interval_time!.start"
                                            (ngModelChange)="debounceFunction()" class="ss-l-half-width-input"
                                            readonly />
                                    </div>
                                    <div class="ss-l-input-group">
                                        <label class="ss-g-globalClassLabel">Fim</label>
                                        <input type="text" pInputText [(ngModel)]="tab.interval_time!.end"
                                            (ngModelChange)="debounceFunction()" class="ss-l-half-width-input"
                                            readonly />
                                    </div>
                                </div>
                                <p-slider [(ngModel)]="tab.slider_time_range" (click)="forceFocus($event)"
                                    [max]="sliderMaxValue" [range]="true"
                                    (onChange)="updateInputsFromSlider(tab, $event)" class="w-full"></p-slider>
                                <!-- Ajuste conforme necessário para vincular ao slider, se aplicável -->
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
            <ng-template #noAlertsMessage>
                <div class="empty-message">
                    Você ainda não adicionou alertas ao seu Slick.
                </div>
            </ng-template>

            <div class="flex justify-content-center">
                <button icon="pi pi-plus" label="Adicionar" (click)="addComponentAlerts()" pButton pRipple
                    class="mt-2"></button>
            </div>
        </div>
        <div *ngIf="activeOption === 6">
            <!-- Links -->
            <div class="scroll-container">
                <p-orderList #orderList header="Links ({{ linksSelecteds.length }})" *ngIf="!isMobile"
                    [value]="linksSelecteds" class="ss-l-order-list" (onReorder)="onReorder($event)" [dragdrop]="true"
                    [listStyle]="{'height':'350px', 'overflow-y': 'auto'}">
                    <ng-template let-link let-i="index" pTemplate="item">
                        <div class="flex-container-links">
                            <div class="link-name">
                                <img [src]="link.click_url" style="width: 15px; height: auto;">
                                <span class="link-name-text">{{ link.name }}</span>
                            </div>
                            <div class="right-aligned-items">
                                <div class="switch-container">
                                    <span class="mr-1 font-light" *ngIf="!isMobile">Exibir</span>
                                    <p-inputSwitch [(ngModel)]="link.show_item"
                                        (onChange)="updateShowLink(link, link.show_item)" binary="true"></p-inputSwitch>
                                </div>
                                <i class="pi pi-trash cursor-pointer" (click)="removeLink(i)"></i>
                                <i class="bi bi-three-dots-vertical icon-item cursor-pointer"
                                    (click)="selectedLink(link, i)"></i>
                                <span class="link-name">
                                    <img [src]="getLinkIcon(link.link_type)" style="width: 20px; height: auto;">
                                </span>
                            </div>

                        </div>
                    </ng-template>
                    <ng-template pTemplate="empty">
                        <div class="empty-message">
                            Você ainda não adicionou links ao seu Slick.
                        </div>
                    </ng-template>
                </p-orderList>
                <p-orderList *ngIf="isMobile" #orderList [value]="linksSelecteds" class="ss-l-order-list"
                    (onReorder)="onReorder($event)" [dragdrop]="isDragDrop"
                    [listStyle]="{'height':'350px', 'overflow-y': 'auto'}">
                    <ng-template pTemplate="header">
                        <div>
                            Links ({{ linksSelecteds.length }}) <i *ngIf="isDragDrop" class="pi pi-arrows-alt p-2"></i>
                        </div>
                    </ng-template>
                    <ng-template let-link let-i="index" pTemplate="item">
                        <div class="flex-container-links" (click)="dragDrop(1)">
                            <div (mousedown)="dragDrop(0, $event)" (touchstart)="onTouchStart($event)"
                                (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd($event)">
                                <i class="pi pi-arrows-alt p-2"></i>
                            </div>
                            <div class="link-name">
                                <img [src]="link.click_url" style="width: 15px; height: auto;">
                                <span class="link-name-text">{{ link.name }}</span>
                            </div>
                            <div class="right-aligned-items" (click)="dragDrop(1)">
                                <div class="switch-container">
                                    <span class="mr-1 font-light" *ngIf="!isMobile">Exibir</span>
                                    <p-inputSwitch [(ngModel)]="link.show_item"
                                        (onChange)="updateShowLink(link, link.show_item)" binary="true"></p-inputSwitch>
                                </div>
                                <i class="pi pi-trash cursor-pointer" (click)="removeLink(i)"></i>
                                <i class="bi bi-three-dots-vertical icon-item cursor-pointer"
                                    (click)="selectedLink(link, i)"></i>
                                <span class="link-name">
                                    <img [src]="getLinkIcon(link.link_type)" style="width: 20px; height: auto;">
                                </span>
                            </div>

                        </div>
                    </ng-template>
                    <ng-template pTemplate="empty">
                        <div class="empty-message">
                            Você ainda não adicionou links ao seu Slick.
                        </div>
                    </ng-template>
                </p-orderList>
            </div>

















            <div class="flex justify-content-center">
                <button icon="pi pi-plus" label="Adicionar" (click)="addLinks()" pButton pRipple class="mt-2"></button>
            </div>
        </div>


    </div>
    <div class="sidebar-content" *ngIf="mananger == 2">
        <p>Customize seu player, barra de progresso e muito mais</p>

        <div class="card">
            <div class="grid mx-0 align-center p-justify-between ss-l-custom-slicks-sidebar p-2 ">
                <div class="col-12 mx-0 p-0 surface-border border-bottom-1 ">
                    <div class="grid mx-0 p-0 mb-3 align-items-center ml-3" style="padding: 0;margin: 0;">
                        <!-- Ícone à esquerda -->
                        <div class="col-2 mx-0 p-0">
                            <i class="bi bi-play-circle-fill mr-2" style="font-size: 2rem;"></i>
                        </div>
                        <!-- Conteúdo do centro com título e texto -->
                        <div class="col-8 mx-0 p-0 align-items-center mb-2">
                            <div>
                                <div class="text-xl font-bold mb-1" [ngClass]="{'toMobToolTip': isMobileDevice()}">
                                    Smart Autoplay
                                    <i class="bi bi-info-circle" *ngIf="!isMobileDevice(); else mobileTooltip"
                                        pTooltip="O vídeo começa a tocar assim que o usuário abre"
                                        tooltipPosition="right"></i>
                                    <ng-template #mobileTooltip>
                                        <app-mobile-tooltip text="O vídeo começa a tocar assim que o usuário abre"
                                            position="right">
                                            <i class="bi bi-info-circle ml-2"></i>
                                        </app-mobile-tooltip>
                                    </ng-template>
                                </div>
                                <p>O vídeo começa a tocar assim que o usuário abre</p>
                            </div>
                        </div>


                        <!-- Seta à direita -->
                        <p-inputSwitch [(ngModel)]="smartAutoPlay" binary="true" [disabled]="typeLive"
                            (ngModelChange)="debounceFunction()" class="col-2 text-right mx-0 p-0"></p-inputSwitch>
                    </div>
                </div>

                <!-- -->

                <div class="col-12 mx-0 p-0 surface-border border-bottom-1">
                    <div class="grid mx-0 p-0 mb-3 mt-4 align-items-center ml-3" style="padding: 0;margin: 0;">
                        <!-- Ícone à esquerda -->
                        <div class="col-2 mx-<0 p-0">
                            <i class="bi bi-rocket-takeoff mr-2" style="font-size: 2rem;"></i>
                        </div>
                        <!-- Conteúdo do centro com título e texto -->
                        <div class="col-8 mx-0 p-0 align-items-center mb-2">
                            <div>
                                <div class="text-xl font-bold mb-1" [ngClass]="{'toMobToolTip': isMobileDevice()}">
                                    Fake Bar
                                    <i class="bi bi-info-circle" *ngIf="!isMobileDevice(); else mobileTooltipFakeBar"
                                        pTooltip="Engaje mais usuários a assistirem seus vídeos"
                                        tooltipPosition="right"></i>
                                    <ng-template #mobileTooltipFakeBar>
                                        <app-mobile-tooltip text="Engaje mais usuários a assistirem seus vídeos"
                                            position="left">
                                            <i class="bi bi-info-circle ml-2"></i>
                                        </app-mobile-tooltip>
                                    </ng-template>
                                </div>
                                <p>Engaje mais usuários a assistirem seus vídeos</p>
                            </div>
                        </div>


                        <!-- Seta à direita -->
                        <p-inputSwitch [ngModel]="fakeBar$ | async"
                            (ngModelChange)="debounceFunction(); setFakeBar($event);" [disabled]="typeLive"
                            binary="true" class="col-2 text-right mx-0 p-0"></p-inputSwitch>
                    </div>
                </div>

                <!-- -->

                <div class="col-12 mx-0 p-0 surface-border border-bottom-1">
                    <div class="grid mx-0 p-0 mb-3 mt-4 align-items-center ml-3" style="padding: 0;margin: 0;">
                        <!-- Ícone à esquerda -->
                        <div class="col-2 mx-0 p-0">
                            <i class="bi bi-hourglass-bottom mr-2" style="font-size: 2rem;"></i>
                        </div>
                        <!-- Conteúdo do centro com título e texto -->
                        <div class="col-8 mx-0 p-0 align-items-center mb-2">
                            <div>
                                <div class="text-xl font-bold mb-1" [ngClass]="{'toMobToolTip': isMobileDevice()}">
                                    Tempo até o Pitch
                                    <i class="bi bi-info-circle" *ngIf="!isMobileDevice(); else mobileTooltipTempoPitch"
                                        pTooltip="É a parte do vídeo que apresenta a oferta"
                                        tooltipPosition="right"></i>
                                    <ng-template #mobileTooltipTempoPitch>
                                        <app-mobile-tooltip text="É a parte do vídeo que apresenta a oferta"
                                            position="left">
                                            <i class="bi bi-info-circle ml-2"></i>
                                        </app-mobile-tooltip>
                                    </ng-template>
                                </div>
                                <p>É a parte do vídeo que apresenta a oferta</p>
                            </div>
                        </div>






                        <!-- Seta à direita -->
                        <p-inputSwitch [(ngModel)]="timeToPitch" (ngModelChange)="debounceFunction()"
                            (click)="forceFocus($event)" [disabled]="typeLive" binary="true"
                            class="col-2 text-right mx-0 p-0"></p-inputSwitch>
                    </div>
                    <div class="p-field mb-5" *ngIf="timeToPitch">
                        <label for="tconfig" class="w-full ss-g-globalClassLabel">Tempo do vídeo</label>
                        <input id="tconfig" [(ngModel)]="timePitch" [disabled]="typeLive" class="w-full mt-2 mb-2"
                            type="text" pInputText placeholder="ex: 00h03m05s" readonly />

                        <p-slider [(ngModel)]="sliderValuePitch" [min]="0" [max]="sliderMaxValue"
                            [style]="{'width':'100%'}" (onChange)="updateTimeValue()"></p-slider>

                    </div>
                </div>


                <div class="col-12 mx-0 p-0">
                    <div class="grid mx-0 p-0 mb-3 mt-4 align-items-center ml-3" style="padding: 0;margin: 0;">
                        <!-- Ícone à esquerda -->
                        <div class="col-2 mx-0 p-0">
                            <i class="bi bi-brush-fill mr-2" style="font-size: 2rem;"></i>
                        </div>
                        <!-- Conteúdo do centro com título e texto -->
                        <div class="col-8 mx-0 p-0 align-items-center mb-2">
                            <div>
                                <span class="text-xl font-bold mb-2" [ngClass]="{'toMobToolTip': isMobileDevice()}">
                                    Cores
                                    <i class="bi bi-info-circle" *ngIf="!isMobileDevice(); else mobileTooltipCores"
                                        pTooltip="Defina as cores do seu player" tooltipPosition="right"></i>
                                    <ng-template #mobileTooltipCores>
                                        <app-mobile-tooltip text="Defina as cores do seu player" position="right">
                                            <i class="bi bi-info-circle ml-2"></i>
                                        </app-mobile-tooltip>
                                    </ng-template>
                                </span>
                                <p>Defina as cores do seu player</p>
                            </div>
                        </div>

                    </div>
                    <div class="grid mt-2">
                        <div class="col-12">
                            <label class="ss-g-globalClassLabel">Cor do Play/Pause</label>
                            <div class="mt-2"></div>
                            <p-colorPicker [(ngModel)]="playPauseColor" format="hex" [disabled]="typeLive"
                                (onChange)="updatePlayPauseColor($event.value)" class="w-full"
                                (ngModelChange)="debounceFunction()"></p-colorPicker>
                        </div>
                        <div class="col-12">
                            <label class="ss-g-globalClassLabel mt-2">Cor da barra de progresso</label>
                            <div class="mt-2"></div>
                            <p-colorPicker [(ngModel)]="progressBarColor" format="hex" [disabled]="typeLive"
                                (onChange)="updateProgressBarColor($event.value)" (ngModelChange)="debounceFunction()"
                                class="w-full"></p-colorPicker>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="sidebar-content" *ngIf="mananger == 3">
        <p>Teste A/B.</p>
    </div>
    <div class="sidebar-content" *ngIf="mananger == 4">
        <p>Veja em tempo real a atualização dos números de sua apresentação</p>
        <!-- -->
        <div class="grid">
            <!-- <div class="col-12 md:col-6 xl:col-12 mb-2">
                            <div class="card h-full">
                                <ng-container>
                                    <span class="font-semibold text-lg"><i class="pi pi-eye mr-2"></i> Espectadores
                                        agora</span>
                                    <div class="flex justify-content-between align-items-start mt-3">
                                        <div class="w-12">
                                            <span class="text-4xl font-bold text-900" style="margin: 0;">
                                                {{numViewers}}</span><span
                                                class="ml-4 text-sm text-bluegray-500 ss-l-analytics-txt">Quantidade de
                                                espectadores ao
                                                vivo</span>
                                            <div class="text-green-500">
                                            
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #loadingTemplate>
                                    <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                                    <p-skeleton width="100%" height="1rem"></p-skeleton>
                                    <p-skeleton width="60%" height="1rem"></p-skeleton>
                                </ng-template>
                            </div>
                        </div> -->

            <div class="col-12 md:col-12 xl:col-12  mb-2">
                <div class="card h-full">
                    <ng-container>
                        <div class="flex align-items-center p-3 lg:p-6" style="padding: 0 !important;">
                            <div class="relative flex align-items-left mr-3 font-semibold text-lg">
                                <i class="bi bi-people-fill mr-2"></i>Espectadores total
                            </div>
                            <div class="mr-2">

                            </div>
                            <div class="flex align-items-right ml-auto">
                                <i class="bi bi-info-circle" *ngIf="!isMobileDevice(); else mobileTooltipEspectadores"
                                    pTooltip="Quantidade de espectadores total" tooltipPosition="right"></i>
                                <ng-template #mobileTooltipEspectadores>
                                    <app-mobile-tooltip text="Quantidade de espectadores total" position="right">
                                        <i class="bi bi-info-circle ml-2"></i>
                                    </app-mobile-tooltip>
                                </ng-template>
                            </div>

                        </div>
                        <div class="flex justify-content-between align-items-start mt-3">
                            <div class="w-12">
                                <span class="text-4xl font-bold text-900" style="margin: 0;">
                                    {{shopping_carts?shopping_carts:0}}</span>
                                <div class="text-green-500">
                                    <!-- <span class="font-medium">
                                                    -13%
                                                </span>
                                                <i class="pi pi-arrow-up text-xs ml-2"></i> -->
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #loadingTemplate>
                        <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                        <p-skeleton width="100%" height="1rem"></p-skeleton>
                        <p-skeleton width="60%" height="1rem"></p-skeleton>
                    </ng-template>
                </div>
            </div>

            <div class="col-12 md:col-12 xl:col-12  mb-2">
                <div class="card h-full">
                    <ng-container>
                        <div class="flex align-items-center p-3 lg:p-6" style="padding: 0 !important;">
                            <div class="relative flex align-items-left mr-3 font-semibold text-lg">
                                <i class="bi bi-easel mr-2"></i> Sessões
                            </div>
                            <div class="mr-2">

                            </div>
                            <div class="flex align-items-right ml-auto">
                                <i class="bi bi-info-circle" *ngIf="!isMobileDevice(); else mobileTooltipEntradas"
                                    pTooltip="Quantidade de vezes que entraram na transmissão"
                                    tooltipPosition="right"></i>
                                <ng-template #mobileTooltipEntradas>
                                    <app-mobile-tooltip text="Quantidade de vezes que entraram na transmissão"
                                        position="right">
                                        <i class="bi bi-info-circle ml-2"></i>
                                    </app-mobile-tooltip>
                                </ng-template>
                            </div>

                        </div>
                        <div class="flex justify-content-between align-items-start mt-3">
                            <div class="w-12">
                                <span class="text-4xl font-bold text-900" style="margin: 0;">
                                    {{sessions}}</span><span
                                    class="ml-4 text-sm text-bluegray-500 ss-l-analytics-txt"></span>
                                <div class="text-green-500">
                                    <!-- <span class="font-medium">
                                                    -13%
                                                </span>
                                                <i class="pi pi-arrow-up text-xs ml-2"></i> -->
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #loadingTemplate>
                        <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                        <p-skeleton width="100%" height="1rem"></p-skeleton>
                        <p-skeleton width="60%" height="1rem"></p-skeleton>
                    </ng-template>
                </div>
            </div>

            <div class="col-12 md:col-12 xl:col-12  mb-2">
                <div class="card h-full">
                    <ng-container>
                        <div class="flex align-items-center p-3 lg:p-6" style="padding: 0 !important;">
                            <div class="relative flex align-items-left mr-3 font-semibold text-lg">
                                <i class="bi bi-clock mr-2"></i> Tempo de permanência
                            </div>
                            <div class="mr-2">

                            </div>
                            <div class="flex align-items-right ml-auto">
                                <i class="bi bi-info-circle" *ngIf="!isMobileDevice(); else mobileTooltipPermanencia"
                                    pTooltip="Tempo médio de permanência na apresentação" tooltipPosition="right"></i>
                                <ng-template #mobileTooltipPermanencia>
                                    <app-mobile-tooltip text="Tempo médio de permanência na apresentação"
                                        position="right">
                                        <i class="bi bi-info-circle ml-2"></i>
                                    </app-mobile-tooltip>
                                </ng-template>
                            </div>

                        </div>

                        <div class="flex justify-content-between align-items-start mt-3">
                            <div class="w-12">
                                <span class="text-4xl font-bold text-900" style="margin: 0;">
                                    {{ formatTime2(+tmp!) }} ({{timeM}}%)</span>
                                <div class="text-green-500">
                                    <!-- <span class="font-medium">
                                                    -13%
                                                </span>
                                                <i class="pi pi-arrow-up text-xs ml-2"></i> -->
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #loadingTemplate>
                        <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                        <p-skeleton width="100%" height="1rem"></p-skeleton>
                        <p-skeleton width="60%" height="1rem"></p-skeleton>
                    </ng-template>
                </div>
            </div>

            <!-- <div class="col-12 md:col-6 xl:col-12  mb-2">
                            <div class="card h-full">
                                <ng-container>
                                    <span class="font-semibold text-lg"><i class="bi bi-easel-fill mr-2"></i> Tempo
                                        total de live</span>
                                    <div class="flex justify-content-between align-items-start mt-3">
                                        <div class="w-12">
                                            <span class="text-4xl font-bold text-900" style="margin: 0;">
                                                {{ (isNumeric(currentTime) ? formatTime2(+currentTime / 60) :
                                                '00:00:00') }}</span><span
                                                class="ml-4 text-sm text-bluegray-500 ss-l-analytics-txt">Tempo
                                                apresentado</span>
                                            <div class="text-green-500">
                                                <span class="font-medium">
                                                    -13%
                                                </span>
                                                <i class="pi pi-arrow-up text-xs ml-2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #loadingTemplate>
                                    <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                                    <p-skeleton width="100%" height="1rem"></p-skeleton>
                                    <p-skeleton width="60%" height="1rem"></p-skeleton>
                                </ng-template>
                            </div>
                        </div> -->


            <!-- <div class="col-12 md:col-6 xl:col-12  mb-2">
                            <div class="card h-full">
                                <ng-container>
                                    <span class="font-semibold text-lg"><i class="bi bi-cash-coin mr-2"></i> Valor
                                        investido</span>
                                    <div class="flex justify-content-between align-items-start mt-3">
                                        <div class="w-12">
                                            <span class="text-4xl font-bold text-900" style="margin: 0;">
                                                {{ (paid ? paid : 0) | currency:'BRL':'symbol':'1.2-2' }}</span><span
                                                class="ml-4 text-sm text-bluegray-500 ss-l-analytics-txt">Valor
                                                investido na apresentação</span>
                                            <div class="text-green-500">
                                                <span class="font-medium">
                                                    -13%
                                                </span>
                                                <i class="pi pi-arrow-up text-xs ml-2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #loadingTemplate>
                                    <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                                    <p-skeleton width="100%" height="1rem"></p-skeleton>
                                    <p-skeleton width="60%" height="1rem"></p-skeleton>
                                </ng-template>
                            </div>
                        </div> -->

            <div class="col-12 md:col-12 xl:col-12  mb-2">
                <div class="card h-full">
                    <ng-container>
                        <div class="flex align-items-center p-3 lg:p-6" style="padding: 0 !important;">
                            <div class="relative flex align-items-left mr-3 font-semibold text-lg">
                                <i class="bi bi-hand-thumbs-up-fill mr-2"></i>Curtidas
                            </div>
                            <div class="mr-2">

                            </div>
                            <div class="flex align-items-right ml-auto">
                                <i class="bi bi-info-circle" *ngIf="!isMobileDevice(); else mobileTooltipCurtidas"
                                    pTooltip="Quantidade de curtidas" tooltipPosition="right"></i>
                                <ng-template #mobileTooltipCurtidas>
                                    <app-mobile-tooltip text="Quantidade de curtidas" position="right">
                                        <i class="bi bi-info-circle ml-2"></i>
                                    </app-mobile-tooltip>
                                </ng-template>
                            </div>

                        </div>

                        <div class="flex justify-content-between align-items-start mt-3">
                            <div class="w-12">
                                <span class="text-4xl font-bold text-900" style="margin: 0;">
                                    {{ like_reaction ? like_reaction : 0}}</span>
                                <div class="text-green-500">
                                    <!-- <span class="font-medium">
                                                    -13%
                                                </span>
                                                <i class="pi pi-arrow-up text-xs ml-2"></i> -->
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #loadingTemplate>
                        <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                        <p-skeleton width="100%" height="1rem"></p-skeleton>
                        <p-skeleton width="60%" height="1rem"></p-skeleton>
                    </ng-template>
                </div>
            </div>

        </div>
        <!-- -->


    </div>
    <div class="sidebar-content" *ngIf="mananger == 5">
        <p>Teste seus vídeos e descubra qual converte mais.</p>
        <div class="card">
            <div class="grid mx-0 align-center p-justify-between ss-l-custom-slicks-sidebar p-2">


                <div class="col-12 mx-0 p-0 hover:surface-hover" style="border-radius: 15px;">
                    <div class="grid mx-0 p-0 mb-3 mt-4 align-items-center cursor-pointer" style="margin: 0;"
                        (click)="openFacebookPixel()">

                        <div class="col-2 mx-0 p-2">
                            <i class="bi bi-facebook mr-2" style="font-size: 2rem;"></i>
                        </div>
                        <!-- Conteúdo do centro com título e texto -->
                        <div class="col-8 mx-0 p-0 align-items-center mb-2">
                            <div>
                                <div class="text-xl font-bold mb-1">Facebook Pixel</div>
                                <p>Potencialize saus campanhas no Facebook e Instagram</p>
                            </div>
                        </div>

                        <div class="col-2 text-right mx-0 p-2">
                            <i class="pi pi-chevron-right" style="font-size: 1rem; cursor: pointer;"></i>
                        </div>
                    </div>
                </div>



                <!-- <div class="col-12 mx-0 p-0">
                                <div class="grid mx-0 p-0 mb-3 mt-4 align-items-center" style="margin: 0;">

                                    <div class="col-2 mx-0 p-0">
                                        <i class="bi bi-graph-up-arrow mr-2" style="font-size: 2rem;"></i>
                                    </div>
                                    <div class="col-8 mx-0 p-0 align-items-center mb-2">
                                        <div>
                                            <div class="text-xl font-bold mb-1">Analytics</div>
                                            <p>Conheça suas métricas em tempo real</p>
                                        </div>
                                    </div>

                                    <div class="col-2 text-right mx-0 p-0">
                                        <i class="pi pi-chevron-right" style="font-size: 2rem; cursor: pointer;"
                                            (click)="openAnalytics()"></i>
                                    </div>
                                </div>
                            </div> -->




            </div>
        </div>
    </div>
    <div class="sidebar-content" *ngIf="mananger == 6">
        <p>Edite detalhes de seu <b>Slick®</b>.</p>

        <!-- Detalhes -->
        <div class="">
            <div class="card shadow-none">
                <div class="flex flex-column mb-2">
                    <label class="w-full ss-g-globalClassLabel">
                        Título do Slick
                        <div class="p-inputgroup mt-2">
                            <input type="text" id="title" pInputText class="w-full" (ngModelChange)="onInputChange()"
                                [(ngModel)]="slickTitle" [ngClass]="{'ng-invalid': hasError1, 'ng-dirty': hasError1}">
                        </div>
                    </label>
                    <div class="flex align-items-center justify-content-between">
                        <small *ngIf="hasError1" class="p-error">
                            Obrigatório
                        </small>

                    </div>
                </div>
                <div class="flex flex-column mb-2">
                    <label class="w-full ss-g-globalClassLabel">
                        Descrição
                    </label>
                    <div class="mb-2">
                        <textarea rows="3" cols="30" class="w-full p-inputtextarea p-inputtext p-component p-element"
                            placeholder="ex: Descrição do Slick" [(ngModel)]="slickDesc" pInputTextarea></textarea>
                    </div>

                </div>



                <!-- area de upload -->
                <div class="mt-3 mb-2 flex flex-column" *ngIf="!typeLive">
                    <span class="ss-g-globalClassLabel flex-start" style="align-self:flex-start">Carregue
                        seu vídeo aqui</span>
                    <p-fileUpload #fileUpload name="demo[]" accept="video/*" [multiple]="false" *ngIf="!videoPreviewUrl"
                        [ngClass]="{'ng-invalid': hasError3, 'ng-dirty': hasError3, 'file-upload-error': hasError3}"
                        (onSelect)="onFileSelect($event)" [customUpload]="true" [showCancelButton]="false"
                        (uploadHandler)="myUploadMethod($event)"
                        styleClass="border-1 surface-border surface-card border-round">
                        <ng-template pTemplate="content">
                            <div [ngClass]="{'video-upload-container2': valSelect1Video.value === 1, 'video-upload-container-landscape2': valSelect1Video.value === 2}"
                                (click)="fileUpload.choose()">
                                <div class="content">
                                    <!-- Exibir apenas se nenhum arquivo for selecionado -->
                                    <div *ngIf="!invoiceSelected"
                                        class="flex flex-column justify-content-center align-items-center"
                                        style="position: absolute;top:40%;left: 10%;">
                                        <i class="pi pi-file text-4xl text-primary"></i>
                                        <span class="block font-semibold text-900 text-lg mt-3">Arraste um
                                            arquivo ou
                                            selecione</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-fileUpload>

                    <div *ngIf="videoPreviewUrl"
                        [ngClass]="{'video-upload-container': valSelect1Video.value === 1, 'video-upload-container-landscape': valSelect1Video.value === 2}">
                        <div
                            [ngClass]="{'ss-l-video-container-inner': valSelect1Video.value === 1, 'ss-g-video-container-inner-landscape': valSelect1Video.value === 2}">

                            <video #videoPlayer [src]="videoPreviewUrl" controls [controls]="true" muted [muted]="true"
                                autoplay [autoplay]="true" loop [loop]="true"
                                [ngClass]="{'video-preview': valSelect1Video.value === 1, 'video-preview-landscape': valSelect1Video.value === 2}"
                                (ended)="videoPlayer.play()" (canplaythrough)="handleCanPlayThrough()"
                                playsinline></video>

                            <!-- Botão de fechar -->
                            <button *ngIf="videoPreviewUrl" pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center cursor-pointer w-2rem h-2rem"
                                style="top: -10px; right: -10px; cursor: pointer;"
                                (click)="removeVideo($event)"></button>
                        </div>
                    </div>

                    <div class="flex align-items-center justify-content-between mt-2" *ngIf="uploadProgressVideo > 0">
                        <p-progressBar [value]="uploadProgressVideo " class="w-full"></p-progressBar>
                        <!-- <div class="progress-value"
                            style="position: absolute; width: 100%; top: 0; text-align: center; color: #FFFFFF;">
                            {{ uploadProgressVideo.toFixed(0) }}%
                        </div> -->
                    </div>
                    <div class="flex align-items-center justify-content-between mt-2">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="hasError3" class="p-error">
                            Obrigatório
                        </small>

                    </div>
                    <div class="flex align-items-center justify-content-between mt-2">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="hasSizeVideoError" class="p-error">
                            O vídeo deve ter no máximo 400 MB.
                        </small>
                    </div>
                </div>
                <!-- area de upload imageSelected -->

                <div class="col-12 mt-3 mb-2 flex flex-column" style="padding: 0;">
                    <span class="ss-g-globalClassLabe flex-start" style="align-self:flex-start">Escolha uma
                        imagem de capa</span>
                    <div class="mb-2"></div>
                    <p-fileUpload #imageUpload name="image[]" accept="image/*" [multiple]="false" *ngIf="!imageSelected"
                        (onSelect)="onImageSelect($event)" [customUpload]="true" [showCancelButton]="false"
                        (uploadHandler)="myImageUploadMethod($event)"
                        styleClass="border-1 surface-border surface-card border-round">
                        <ng-template pTemplate="content">
                            <div [ngClass]="{'custom-upload-area': valSelect1Video.value === 1, 'custom-upload-area-landscape': valSelect1Video.value === 2}"
                                (click)="imageUpload.choose()">
                                <!-- Exibir apenas se nenhum arquivo de imagem for selecionado image-preview -->
                                <div *ngIf="!imageSelected"
                                    class="flex flex-column justify-content-center align-items-center">
                                    <i class="pi pi-file text-4xl text-primary"></i>
                                    <span class="block font-semibold text-900 text-center text-lg mt-3">Arraste
                                        uma
                                        imagem ou
                                        selecione</span>
                                </div>


                            </div>
                        </ng-template>
                    </p-fileUpload>
                    <!-- Exibir após selecionar um arquivo de imagem -->
                    <div *ngIf="imageSelected" class="flex flex-wrap gap-3 border-round" #videoContainer
                        [ngClass]="{'video-upload-container': valSelect1Video.value === 1, 'video-upload-container-landscape': valSelect1Video.value === 2}">
                        <div class="border-3 border-transparent border-round position-relative"
                            (mouseenter)="showImageDeleteButton = true"
                            [ngClass]="{'ss-l-video-container-inner': valSelect1Video.value === 1, 'ss-g-video-container-inner-landscape': valSelect1Video.value === 2}"
                            (mouseleave)="showImageDeleteButton = false">
                            <img [src]="imagePreviewUrl"
                                [ngClass]="{'video-preview': valSelect1Video.value === 1, 'video-preview-landscape': valSelect1Video.value === 2}"
                                class="image-preview shadow-2 border-round">
                            <!-- Botão de excluir para imagem -->
                            <button *ngIf="showImageDeleteButton" pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center cursor-pointer w-2rem h-2rem"
                                style="top: -10px; right: -10px;" (click)="removeImage($event)"></button>
                        </div>
                    </div>
                    <div class="flex align-items-center justify-content-between mt-2" *ngIf="uploadProgress > 0">
                        <p-progressBar [value]="uploadProgress" class="w-full"></p-progressBar>
                        <!-- <div class="progress-value"
                            style="position: absolute; width: 100%; top: 0; text-align: center; color: #FFFFFF;">
                            {{ uploadProgress.toFixed(0) }}%
                        </div> -->
                    </div>
                    <div class="flex align-items-center justify-content-between">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="erroTamanhoImagem" class="p-error">
                            Tamanho da imagem deve ser de no máximo 1 mega
                        </small>
                    </div>
                    <div class="flex align-items-center justify-content-between">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="erroTipoImagem" class="p-error">
                            Tipo da imagem deve ser .png, .webp, .jpg ou jpeg
                        </small>
                    </div>
                </div>



                <div class="flex flex-column mb-4 mt-5 w-full">
                    <label class="w-full ss-g-globalClassLabel">Tags</label>
                    <p-chips [(ngModel)]="selectedTags" class="w-full" (ngModelChange)="updateTagsString()"
                        separator="," placeholder="Digite tags" styleClass="w-full"></p-chips>

                </div>
                <!-- Contêiner principal para alinhar tudo verticalmente -->
                <div class="flex flex-column">
                    <!-- Primeira label sozinha -->
                    <div class="align-items-center mb-2">
                        <label class="ss-g-globalClassLabel">mais opções</label>
                    </div>

                    <!-- Segundo contêiner para os switches e labels -->
                    <div class="flex align-items-center mb-2">

                        <p-inputSwitch [(ngModel)]="publicarAgora" class="mr-2" (onChange)="simular()"></p-inputSwitch>
                        <label>Publicar Agora</label>
                    </div>

                    <!-- Terceiro contêiner para o último switch e label -->
                    <div class="flex align-items-center" *ngIf="typeLive">

                        <p-inputSwitch [(ngModel)]="armazenarEvento" class="mr-2"
                            (onChange)="simular()"></p-inputSwitch>
                        <label>Armazenar evento e re-play</label>
                    </div>
                </div>


                <div class="flex align-items-center mt-5 mb-2 w-full" *ngIf="!publicarAgora">
                    <label class="ss-g-globalClassLabel">Data e hora</label>
                </div>
                <div class="flex align-items-center w-full" *ngIf="!publicarAgora">
                    <p-calendar [(ngModel)]="dataSelecionada" (ngModelChange)="onDateChange($event)" [showTime]="true"
                        class="mb-2 calendar-grid " [showIcon]="true" [disabledDates]="disabledDates"
                        [readonlyInput]="true"></p-calendar>
                </div>
            </div>
        </div>
        <!-- Detalhes -->

    </div>
    <div class="sidebar-content" *ngIf="mananger == 7">
        <p>Insira seu <b>Short</b> direto em sua loja, landingpage, blog ou aplicação. <a href="#">Saiba
                mais.</a></p>

        <div class="mb-2"> <b>Passo 1 - Selecione o tipo de display</b></div>
        <div class="flex flex-column gap-3 surface-border border-bottom-1 mb-5">
            <div *ngFor="let integration of integrations" class="field-checkbox surface-border border-1 mb-0 card p-0"
                [ngClass]="{'selected-integration': integration === selectedIntegration}">
                <p-radioButton [inputId]="integration.key" class="ml-2" name="integration" [value]="integration"
                    [(ngModel)]="selectedIntegration"></p-radioButton>
                <div class="p-col-fixed col-10 flex ss-l-integration-opc">
                    <i class="pi pi-bolt mr-2 text-4xl"></i>

                    <div>
                        <label [for]="integration.key" class="ml-2">{{ integration.name }}</label>
                        <p *ngIf="integration.key == 'I'">O tamanho e posição de vídeo na página é
                            customizável
                        </p>
                        <p *ngIf="integration.key == 'P'">O vídeo é inserido no canto inferior direito da
                            página
                            e pode ser movido</p>
                    </div>
                </div>

            </div>
            <div class="mb-2"></div>
        </div>
        <div class="mb-2"> <b>Passo 2 - Adicione em sua página</b></div>
        <div class="card bg-blue-200">
            <p class="mb-0"><b>1. Adicione o script da Slicksell ao header de seu site.</b></p>
            <p>Você só precisa adicionar esse script apenas uma vez ao seu site. Se o script já foi
                inserido, pule esta etapa.</p>

            <div class="p-inputgroup ss-g-custom-inputgroup mb-2">
                <input type="text" pInputText [(ngModel)]="script_share" readonly class="custom-input">
                <button pButton pRipple type="button" icon="pi pi-copy" (click)="copiarScript()"
                    class="ss-l-custom-button"></button>
            </div>

            <p class="mt-4 mb-0"><b>2. Adicione seu widget às páginas em que deseja exibir.</b></p>
            <p>Você pode adicionar este script manualmente à sua página ou incluir o endereço usando o
                Slickmap.</p>

            <div class="p-inputgroup ss-g-custom-inputgroup">
                <input type="text" pInputText [(ngModel)]="widget_share" readonly class="custom-input">
                <button pButton pRipple type="button" icon="pi pi-copy" (click)="copiarWidget()"
                    class="ss-l-custom-button"></button>
            </div>

            <p class="mt-4"><b>Slickmap</b></p>

            <div class="p-fluid bg-blue-200 shadow-none border-black-alpha-30">
                <p-chips [(ngModel)]="valuesMaps" separator="," placeholder="ex: a, b, c"
                    (ngModelChange)="showSlickClearFunction()"></p-chips>
                <div class="flex-container" *ngIf="showClearSlickMaps">
                    <a class="ss-g-remove-coupon" (click)="clearSlickMaps()">
                        <i class="pi pi-times"></i> Limpar Slick Maps
                    </a>
                </div>
            </div>

        </div>

    </div>


    <div class="sidebar-content" *ngIf="sidebarGerenciar && mananger == 0">
        <p>Personalize seus vídeos, suas cores e elementos de interação de seus <b>Slicks®</b></p>
        <div class="card">
            <div
                class="grid mx-0 align-center p-justify-between ss-l-custom-slicks-sidebar p-2 surface-border border-bottom-1">

                <div class="col-12 mx-0 p-0 hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                    style="border-radius: 15px;padding-top: 1.5rem !important;" (click)="openCTA()">
                    <div class="grid mx-0 p-0 mb-3 align-items-center" style="margin: 0;">

                        <div class="col-2 mx-0 p-2">
                            <i class="pi pi-bolt mr-2" style="font-size: 2rem;"></i>
                        </div>

                        <div class="col-8 mx-0 p-0 align-items-center mb-2">
                            <div>
                                <div class="text-xl font-bold mb-1">Elementos Interativos</div>
                                <p>Adicione produtos, botões e mais em seus Slicks</p>
                            </div>
                        </div>

                        <div class="col-2 text-right mx-0 p-2">
                            <i class="pi pi-chevron-right" style="font-size: 1rem; cursor: pointer;"></i>
                        </div>
                    </div>
                </div>

                <!-- -->

                <div class="col-12 mx-0 p-0 hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                    style="border-radius: 15px;" (click)="openConfigs()">
                    <div class="grid mx-0 p-0 mb-3 mt-4 align-items-center" style="margin: 0;">

                        <div class="col-2 mx-0 p-2">
                            <i class="bi bi-gear mr-2" style="font-size: 2rem;"></i>
                        </div>

                        <div class="col-8 mx-0 p-0 align-items-center mb-2">
                            <div>
                                <div class="text-xl font-bold mb-1">Configurações e Estilos</div>
                                <p>Customize seu player, thumbnails e muito mais</p>
                            </div>
                        </div>

                        <div class="col-2 text-right mx-0 p-2">
                            <i class="pi pi-chevron-right" style="font-size: 1rem; cursor: pointer;"
                                (click)="openConfigs()"></i>
                        </div>
                    </div>
                </div>

                <!-- -->

                <!-- <div class="col-12 mx-0 p-0">
                                <div class="grid mx-0 p-0 mb-3 mt-4 align-items-center" style="margin: 0;">

                                    <div class="col-2 mx-0 p-0">
                                        <i class="bi bi-buildings-fill mr-2" style="font-size: 2rem; opacity: 0.5;"></i>
                                    </div>
                                    <div class="col-8 mx-0 p-0 align-items-center mb-2" style="position: relative;">
                                        <div style="opacity: 0.5;">
                                            <div class="text-xl font-bold mb-1">
                                                Teste A/B
                                            </div>
                                            <p>Teste seus vídeos e descubra qual converte mais</p>
                                        </div>
                                        <div class="text-xl font-bold"
                                            style="position: absolute; left: calc(100% - 8em); top: 0;">
                                            <span style="white-space: nowrap;">em breve</span>
                                        </div>
                                    </div>

                                    <div class="col-2 text-right mx-0 p-0">
                                        <i class="pi pi-chevron-right" style="font-size: 2rem; opacity: 0.5;"
                                            (click)="openAB()"></i>
                                    </div>
                                </div>
                            </div> -->

                <!-- -->

                <!-- <div class="col-12 mx-0 p-0 hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                    style="border-radius: 15px;" (click)="openAnalytics()">
                    <div class="grid mx-0 p-0 mb-3 mt-4 align-items-center" style="margin: 0;">

                        <div class="col-2 mx-0 p-2">
                            <i class="bi bi-graph-up-arrow mr-2" style="font-size: 2rem;"></i>
                        </div>
                        <div class="col-8 mx-0 p-0 align-items-center mb-2">
                            <div>
                                <div class="text-xl font-bold mb-1">Analytics</div>
                                <p>Conheça suas métricas em tempo real</p>
                            </div>
                        </div>

                        <div class="col-2 text-right mx-0 p-2">
                            <i class="pi pi-chevron-right" style="font-size: 1rem; cursor: pointer;"
                                (click)="openAnalytics()"></i>
                        </div>
                    </div>
                </div> -->

                <!-- -->

                <div class="col-12 mx-0 p-0 hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                    style="border-radius: 15px;" (click)="openPixels()">
                    <div class="grid mx-0 p-0 mb-3 mt-4 align-items-center" style="margin: 0;">

                        <div class="col-2 mx-0 p-2">
                            <i class="bi bi-puzzle mr-2" style="font-size: 2rem;"></i>
                        </div>
                        <!-- Conteúdo do centro com título e texto -->
                        <div class="col-8 mx-0 p-0 align-items-center mb-2">
                            <div>
                                <div class="text-xl font-bold mb-1">Pixels</div>
                                <p>Rastreie seus usuários e crie campanhas poderosas</p>
                            </div>
                        </div>

                        <div class="col-2 text-right mx-0 p-2">
                            <i class="pi pi-chevron-right" style="font-size: 1rem; cursor: pointer;"
                                (click)="openPixels()"></i>
                        </div>
                    </div>
                </div>
            </div>

            <!-- -->

            <div class="grid mx-0 align-center p-justify-between ss-l-custom-slicks-sidebar p-2 mt-1">
                <div class="col-12 mx-0 p-0 hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                    style="border-radius: 15px;" (click)="openDetails()">
                    <div class="grid mx-0 p-0 mb-3 mt-4 align-items-center" style="margin: 0;">

                        <div class="col-2 mx-0 p-2">
                            <i class="bi bi-pencil mr-2" style="font-size: 2rem;"></i>
                        </div>
                        <!-- Conteúdo do centro com título e texto -->
                        <div class="col-8 mx-0 p-0 align-items-center mb-2">
                            <div>
                                <div class="text-xl font-bold mb-1">Detalhes</div>
                                <p>Edite título, descrição e orientações de seu Slick</p>
                            </div>
                        </div>

                        <div class="col-2 text-right mx-0 p-2">
                            <i class="pi pi-chevron-right" style="font-size: 1rem; cursor: pointer;"
                                (click)="openDetails()"></i>
                        </div>
                    </div>
                </div>

                <!-- -->

                <!-- <div class="col-12 mx-0 p-0">
                                <div class="grid mx-0 p-0 mb-3 mt-4 align-items-center" style="margin: 0;">

                                    <div class="col-2 mx-0 p-0">
                                        <i class="pi pi-globe mr-2" style="font-size: 2rem;"></i>
                                    </div>
                                    <div class="col-8 mx-0 p-0 align-items-center mb-2">
                                        <div>
                                            <div class="text-xl font-bold mb-1">Integrações</div>
                                            <p>Integre seu Slick onde quiser -loja e landing pages</p>
                                        </div>
                                    </div>

                                    <div class="col-2 text-right mx-0 p-0">
                                        <i class="pi pi-chevron-right" style="font-size: 2rem; cursor: pointer;"
                                            (click)="openIntegrations()"></i>
                                    </div>
                                </div>
                            </div> -->

            </div>
        </div>
    </div>



    <ng-template pTemplate="footer">
        <div style="text-align: right;">
            <button pButton pRipple (click)="backStudio()" *ngIf="mananger > 0" label="Voltar"
                [ngClass]="{'p-button-outlined': mananger === 6 || mananger === 7}" class="mr-2"></button>

            <button pButton pRipple (click)="closeSidebarGeral()" *ngIf="mananger !== 7 && mananger !== 6"
                label="Fechar" class="p-button-outlined"></button>
            <button pButton pRipple (click)="saveEditSlick()" [loading]="loadingBtn" label="Salvar"
                *ngIf="mananger == 6" class="ml-2"></button>
            <button pButton pRipple (click)="saveSlickMaps()" label="Salvar" [loading]="loadingBtnSlicks"
                *ngIf="mananger == 7" class="ml-2"></button>
        </div>
    </ng-template>
</p-sidebar>
<p-sidebar [(visible)]="sideBarLinks" position="right">
    <ng-template pTemplate="header">
        <div style="display: flex; flex-direction: column;">
            <div class="text-xl font-bold mb-1">Editar Links</div>
        </div>
    </ng-template>
    <div class="flex-grow-1 flex flex-column">
        <div class="mb-4">
            <label for="pixelId" class="w-full ss-g-globalClassLabel">Nome</label>
            <input type="text" pInputText [(ngModel)]="LinkSelectedName" placeholder="ex.: Nome"
                (ngModelChange)="updateLinkName($event)" class="ss-l-half-width-input w-full" />
        </div>

        <div class="mb-4">
            <label class="ss-g-globalClassLabel">Cor do fundo</label>
            <div class="mt-2"></div>
            <div class="flex align-items-center">
                <p-colorPicker [(ngModel)]="selectedLinkColor" (ngModelChange)="updateLinksColors($event);" format="hex"
                    [ngStyle]="{'width': '30px', 'flex-grow': 0}" [disabled]="isTransparent"></p-colorPicker>
                <p-inputSwitch [(ngModel)]="isTransparent" (onChange)="handleTransparency()"
                    [ngStyle]="{'margin-left': '8px', 'margin-right': '4px', 'position': 'relative', 'top': '3px'}"></p-inputSwitch>
                <span>Transparente</span>
            </div>
            <div class="mb-2"></div>
        </div>



        <div class="mb-4">
            <label for="pixelId" class="w-full ss-g-globalClassLabel">URL</label>
            <div class="p-inputgroup mt-2">
                <span class="p-inputgroup-addon">https://</span>
                <input type="text" pInputText [(ngModel)]="LinkSelectedURL" placeholder="ex.: www.google.com"
                    (ngModelChange)="updateLinkURL($event)" class="ss-l-half-width-input w-full" />
            </div>
        </div>


        <div>
            <label for="pixelId" class="w-full ss-g-globalClassLabel">Icon/Gif</label>
            <div class="mt-2"></div>
            <div (click)="displayDialogIconsGifs = true" class="add-icon-container">
                <img [src]="iconGifSelected" style="width: 40px;height: 40px;" *ngIf="hasIconGif">
                <i class="bi bi-plus-circle" *ngIf="!hasIconGif"></i>
                <p *ngIf="!hasIconGif">Click and add your icon, GIF or image</p>
            </div>
        </div>

        <div class="mt-4 mb-4 w-full">
            <label for="pixelId" class="w-full ss-g-globalClassLabel">Tipo do Link</label>
            <div class="mt-2"></div>
            <div class="w-full full-width-selectbutton">
                <p-selectButton [options]="linkTypes" [(ngModel)]="linkSelecionado.link_type"
                    (onChange)="updateLinkType()" id="typeSelect">
                    <ng-template let-option pTemplate="item">
                        <div class="flex align-items-center justify-content-center w-full">
                            <img [src]="option.iconUrl" class="mr-1" style="width: 24px; height: 24px;">
                            <span class="font-bold">{{ option.label }}</span>
                        </div>
                    </ng-template>
                </p-selectButton>
            </div>
        </div>


        <div class="mb-4">
            <p-inputSwitch [(ngModel)]="defineIntervalLinkSelected" *ngIf="!typeLive" class="ss-l-switch-position2 mr-2"
                (ngModelChange)="toggleDefineIntervalLinks($event)"></p-inputSwitch>
            <label *ngIf="!typeLive">Definir Intervalo</label>
        </div>
        <div *ngIf="defineIntervalLinkSelected && !typeLive" class="ss-l-inputs-container mt-2 mb-4 w-full">
            <div class="ss-l-inputs-row mb-4">
                <div class="ss-l-input-group">
                    <label class="ss-g-globalClassLabel">Início</label>
                    <input type="text" pInputText [(ngModel)]="intervalTimeStartLinkSelected"
                        class="ss-l-half-width-input" readonly />
                </div>
                <div class="ss-l-input-group">
                    <label class="ss-g-globalClassLabel">Fim</label>
                    <input type="text" pInputText [(ngModel)]="intervalTimeEndLinkSelected"
                        class="ss-l-half-width-input" readonly />
                </div>
            </div>
            <p-slider [(ngModel)]="sliderValue" [range]="true" (ngModelChange)="updateIntervalTimeLinks($event)"
                [max]="sliderMaxValue" class="w-full"></p-slider>

        </div>

    </div>

    <ng-template pTemplate="footer">
        <div style="text-align: right;">
            <button pButton pRipple (click)="closeSidebarDetailCtasLinks()" label="Fechar"
                class="p-button-outlined"></button>
        </div>
    </ng-template>
</p-sidebar>



<p-sidebar [(visible)]="openDialogDrag" position="right">
    <div class="flex-grow-1 flex flex-column">
        <!-- Switches lado a lado -->
        <p class="font-semibold text-xl">{{ selectedProductForEdit.name }}</p>
        <div class="flex align-items-center gap-2 mb-2">
            <p-inputSwitch [(ngModel)]="selectedProductForEdit.highlight" class="ss-l-switch-position3"
                (onChange)="onHighlightChange(selectedProductForEdit)" binary="true"></p-inputSwitch>
            <label>Destacar</label>
        </div>
        <div class="mb-2">
            <p-inputSwitch [(ngModel)]="selectedProductForEdit.show_item" class="ss-l-switch-position2 mr-2"
                (onChange)="onHighlightChange(selectedProductForEdit)" binary="true"></p-inputSwitch>
            <label>Exibir</label>
        </div>
        <div class="mb-2">
            <p-inputSwitch [(ngModel)]="selectedProductForEdit.define_interval" *ngIf="!typeLive"
                class="ss-l-switch-position2 mr-2" (ngModelChange)="toggleDefineInterval($event)"></p-inputSwitch>
            <label *ngIf="!typeLive">Definir Intervalo</label>
        </div>
        <!-- Slider abaixo dos switches onSliderChange rrrrrrrrrrrrr-->
        <div *ngIf="selectedProductForEdit.define_interval && !typeLive" class="ss-l-inputs-container mt-2 mb-4 w-full">
            <div class="ss-l-inputs-row mb-4">
                <div class="ss-l-input-group">
                    <label class="ss-g-globalClassLabel">Início</label>
                    <input type="text" pInputText [(ngModel)]="selectedProductForEdit.interval_time!.start"
                        class="ss-l-half-width-input" readonly />
                </div>
                <div class="ss-l-input-group">
                    <label class="ss-g-globalClassLabel">Fim</label>
                    <input type="text" pInputText [(ngModel)]="selectedProductForEdit.interval_time!.end"
                        class="ss-l-half-width-input" readonly />
                </div>
            </div>
            <p-slider [(ngModel)]="sliderValue" [range]="true" (ngModelChange)="updateIntervalTime($event)"
                [max]="sliderMaxValue" class="w-full"></p-slider>

        </div>
        <div class=" mt-4">
            <div class="p-0 mb-2">Botão de Comprar</div>
        </div>
        <div>
            <label class="ss-g-globalClassLabel">Cor da fonte</label>
            <div class="mt-2"></div>
            <p-colorPicker [(ngModel)]="selectedProductForEdit.comprar_font_color" (ngModelChange)="updateColors();"
                format="hex" class="w-full mb-2" [style]="{'width':'100%'}"></p-colorPicker>
            <div class="mb-2"></div>
        </div>
        <div class="surface-border border-0 border-bottom-1 ">
            <label class="ss-g-globalClassLabel">Cor de fundo</label>
            <div class="mt-2"></div>
            <p-colorPicker [(ngModel)]="selectedProductForEdit.comprar_bg_color" (ngModelChange)="updateColors();"
                format="hex" class="w-full mb-2" [style]="{'width':'100%'}"></p-colorPicker>
            <div class="mb-5"></div>
        </div>
        <div>
            <div class="p-0 mb-2 mt-4">Botão de Adicionar</div>
        </div>
        <div>
            <label class="ss-g-globalClassLabel">Cor da fonte</label>
            <div class="mt-2"></div>
            <p-colorPicker [(ngModel)]="selectedProductForEdit.adicionar_font_color" (ngModelChange)="updateColors();"
                format="hex" class="w-full mb-2" [style]="{'width':'100%'}"></p-colorPicker>
            <div class="mb-2"></div>

        </div>
        <div>

            <label class="ss-g-globalClassLabel">Cor de fundo</label>
            <div class="mt-2"></div>
            <p-colorPicker [(ngModel)]="selectedProductForEdit.adicionar_bg_color" (ngModelChange)="updateColors();"
                format="hex" class="w-full mb-2" [style]="{'width':'100%'}"></p-colorPicker>
            <div class="mb-4"></div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div style="text-align: right;">
            <button pButton pRipple (click)="closeSidebarDetail()" label="Fechar" class=""></button>



        </div>
    </ng-template>
</p-sidebar>
<p-toast></p-toast>
<p-dialog header="Confirmação" [(visible)]="displayDialog" [modal]="true" [closable]="false" [resizable]="false">
    <p style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">Você será redirecionado para uma
        página externa - {{ tempUrl }}. Deseja continuar?</p>
    <p-footer>
        <button type="button" pButton pRipple label="Sim"
            (click)="confirmRedirect(); $event.stopPropagation()"></button>
        <button type="button" pButton pRipple label="Não" (click)="cancelRedirect(); $event.stopPropagation()"></button>
    </p-footer>
</p-dialog>

<p-dialog header="Confirmação" [(visible)]="displayDialogWhats" [modal]="true" [closable]="false" [resizable]="false">
    <p style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">Você será redirecionado para uma
        página externa - {{ tmpWhasPhone }}. Deseja continuar?</p>
    <p-footer>
        <button type="button" pButton pRipple label="Sim"
            (click)="confirmRedirectWhats(); $event.stopPropagation()"></button>
        <button type="button" pButton pRipple label="Não"
            (click)="cancelRedirectWhats(); $event.stopPropagation()"></button>
    </p-footer>
</p-dialog>
<p-dialog header="Confirmação" [(visible)]="openDialogDuration" [modal]="true" [closable]="false" id="dialogUpload"
    [resizable]="false">
    <p>O tempo do vídeo selecionado é menor que o tempo do vídeo removido e pode causar erros nos intervalos já
        definidos dos elementos interativos. Deseja continuar?</p>

    <p-footer>
        <button type="button" pButton pRipple label="Sim"
            (click)="zeraDialogDuration(); $event.stopPropagation()"></button>
        <button type="button" pButton pRipple label="Não"
            (click)="closeDialogDuration(); $event.stopPropagation()"></button>
    </p-footer>
</p-dialog>
<p-dialog [baseZIndex]="101" (onHide)="onDialogPixelClose()" class="ss-l-sizeDialogs"
    header="{{isEditing ? 'Editar' : 'Adicionar'}} Facebook Pixel" [(visible)]="addPixelDialog" [modal]="true"
    [dismissableMask]="true" id="dialog2">

    <div class="card shadow-none widSimulador" style="max-width: 55vh !important;">
        <div class="mb-4 surface-border border-0 border-bottom-1">
            <div class="mb-2">
                <label for="pixelName" class="w-full ss-g-globalClassLabel">Nome do Pixel</label>
                <input id="pixelName" type="text" pInputText class="w-full mt-2" placeholder="ex: Nome"
                    (click)="forceFocus($event)" [(ngModel)]="formData.key" [ngModelOptions]="{standalone: true}"
                    [ngClass]="{'ng-invalid': hasErrorNamePixel, 'ng-dirty': hasErrorNamePixel}" maxlength="50">
            </div>
            <div class="flex align-items-center justify-content-between">
                <!-- Mensagem de Erro à esquerda -->
                <small *ngIf="hasErrorNamePixel" class="p-error">
                    Obrigatório
                </small>
            </div>
            <div class="mb-2">
                <label for="pixelId" class="w-full ss-g-globalClassLabel">Pixel ID</label>
                <input id="pixelId" type="text" pInputText class="w-full mt-2" placeholder="ex: ID" maxlength="30"
                    [ngClass]="{'ng-invalid': hasErrorIdPixel, 'ng-dirty': hasErrorIdPixel}"
                    (click)="forceFocus($event)" [(ngModel)]="formData.pixel_id" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="flex align-items-center justify-content-between">
                <!-- Mensagem de Erro à esquerda -->
                <small *ngIf="hasErrorIdPixel" class="p-error">
                    Obrigatório
                </small>
            </div>
            <div class="mb-2"></div>
        </div>
        <!-- <div class="mb-4 surface-border border-0 border-bottom-1"> -->
        <div class="surface-border border-0">
            <p class="mb-2">Eventos de visualização</p>
            <div class="flex align-items-center gap-2">
                <p-inputSwitch binary="true" class="mr-2 ss-l-switch-position3"
                    [(ngModel)]="formData.view_events.fb_pixel_view.view_events.view_percentage"
                    [ngModelOptions]="{standalone: true}"></p-inputSwitch>
                <label>A cada 5% de vídeo assistido</label>
            </div>
            <div class="flex align-items-center gap-2 mb-0">
                <p-inputSwitch binary="true" class="mr-2 ss-l-switch-position3"
                    [(ngModel)]="formData.view_events.fb_pixel_view.view_events.pitch_hit"
                    [ngModelOptions]="{standalone: true}"></p-inputSwitch>
                <label>Ao chegar ao <b>Pitch</b></label>
            </div>
        </div>
        <!-- <div class="mb-2 surface-border border-0 border-bottom-0">
                        <p class="mb-2">Eventos de conversão</p>
                        <div class="mb-4">
                            <label for="multiselect-itens" class="w-full ss-g-globalClassLabel">Selecione os itens para
                                os disparos de
                                conversão
                                <p-multiSelect [options]="formattedProducts" placeholder="Selecione os itens"
                                    optionLabel="label" class="w-full ss-g-sidebarVendas multiselect-custom mt-2"
                                    [(ngModel)]="formData.conversion_events.fb_pixel_conversion.conversion_events.skus"
                                    [ngModelOptions]="{standalone: true}" optionGroupLabel="label" scrollHeight="250px"
                                    display="chip" optionGroupChildren="items">
                                    <ng-template let-item pTemplate="item">
                                        <div class="custom-chip">
                                            {{ item.label }}
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </label>
                        </div>
                        <div class="flex align-items-center gap-2 mb-2">
                            <p-inputSwitch binary="true" class="mr-2 ss-l-switch-position3"
                                [(ngModel)]="formData.conversion_events.fb_pixel_conversion.conversion_events.product_view"
                                [ngModelOptions]="{standalone: true}"></p-inputSwitch>
                            <label>Visualização de produto</label>
                        </div>
                        <div class="flex align-items-center gap-2 mb-2">
                            <p-inputSwitch binary="true" class="mr-2 ss-l-switch-position3"
                                [(ngModel)]="formData.conversion_events.fb_pixel_conversion.conversion_events.credit_card_purchase"
                                [ngModelOptions]="{standalone: true}"></p-inputSwitch>
                            <label>Comprar com <b>Cartão de Crédito</b></label>
                        </div>
                        <div class="flex align-items-center gap-2 mb-2">
                            <p-inputSwitch binary="true" class="mr-2 ss-l-switch-position3"
                                [(ngModel)]="formData.conversion_events.fb_pixel_conversion.conversion_events.pixel_purchase.trigger"
                                [ngModelOptions]="{standalone: true}"></p-inputSwitch>
                            <label>Comprar com <b>PIX</b></label>
                        </div>
                        <div class="mb-2"
                            *ngIf="formData.conversion_events.fb_pixel_conversion.conversion_events.pixel_purchase.trigger">
                            <label for="conversionValuePIX" class="w-full ss-g-globalClassLabel">Valor de conversão para
                                PIX (%)</label>
                            <p-inputNumber #conversionValuePIX id="conversionValuePIX"
                                (keypress)="validateNumber($event, 2)" type="text" pInputText class="w-full mt-2"
                                placeholder="ex: 50%" (click)="forceFocus($event)" suffix=" %" class="p-0 w-full"
                                [(ngModel)]="formData.conversion_events.fb_pixel_conversion.conversion_events.pixel_purchase.conversion_value"
                                [ngModelOptions]="{standalone: true}" [max]="100"></p-inputNumber>
                        </div>
                        <div class="flex align-items-center gap-2 mb-2">
                            <p-inputSwitch binary="true" class="mr-2 ss-l-switch-position3"
                                [(ngModel)]="formData.conversion_events.fb_pixel_conversion.conversion_events.slip_purchase.trigger"
                                [ngModelOptions]="{standalone: true}"></p-inputSwitch>
                            <label>Comprar com <b>Boleto</b></label>
                        </div>
                        <div class="mb-2"
                            *ngIf="formData.conversion_events.fb_pixel_conversion.conversion_events.slip_purchase.trigger">
                            <label for="conversionValueBoleto" class="w-full ss-g-globalClassLabel">Valor de conversão
                                para Boleto (%)</label>
                            <p-inputNumber id="conversionValueBoleto" (keypress)="validateNumber($event, 2)" type="text"
                                pInputText class="w-full mt-2" placeholder="ex: 50%" (click)="forceFocus($event)"
                                suffix=" %" class="p-0 w-full"
                                [(ngModel)]="formData.conversion_events.fb_pixel_conversion.conversion_events.slip_purchase.conversion_value"
                                [ngModelOptions]="{standalone: true}" [max]="100"></p-inputNumber>
                        </div>
                        <div class="flex align-items-center gap-2 mb-2">
                            <p-inputSwitch binary="true" class="mr-2 ss-l-switch-position3"
                                [(ngModel)]="formData.conversion_events.fb_pixel_conversion.conversion_events.cart_abandonment"
                                [ngModelOptions]="{standalone: true}"></p-inputSwitch>
                            <label>Abandono de carrinho</label>
                        </div>
                        <div class="flex align-items-center gap-2 mb-2">
                            <p-inputSwitch binary="true" class="mr-2 ss-l-switch-position3"
                                [(ngModel)]="formData.conversion_events.fb_pixel_conversion.conversion_events.credit_card_purchase_denied"
                                [ngModelOptions]="{standalone: true}"></p-inputSwitch>
                            <label>Transação com cartão de crédito negada</label>
                        </div>
                    </div> -->
    </div>


    <ng-template pTemplate="footer">
        <button pButton pRipple (click)="closeAddFacebookPixel()" label="Fechar" class="p-button-outlined"></button>
        <button pButton pRipple label="Salvar" *ngIf="!isEditing" class="ml-2 mr-2" (click)="savePixel()"
            [loading]="savePixelLoading"></button>
        <button pButton pRipple label="Salvar" *ngIf="isEditing" class="ml-2 mr-2" (click)="updatePixel()"
            [loading]="savePixelLoading"></button>
    </ng-template>
</p-dialog>
<p-dialog header="Simular Preço de live" id="dialogSimulator" class="ss-l-sumulator ss-l-sizeDialogs"
    [(visible)]="simuladorDialog" [modal]="true" [dismissableMask]="true">
    <p>Aprenda a estimar o valor estimado de sua apresentação <b>aqui.</b></p>

    <div class="card shadow-none mb-2" style="z-index: 1102;">
        <div class="mb-2 w-full">
            <label for="tempoDuracao" class="w-full">Tempo de duração <i
                    class="bi bi-info-circle-fill ml-1"></i></label>

            <input id="tempoDuracao" class="grid m-0 w-full ss-l-timeDurationSimulator" inputmode="numeric" mask="00:00"
                [(ngModel)]="liveTime" (ngModelChange)="correctMinutes($event)" placeholder="ex: 00:15" type="text"
                pInputText [ngClass]="{'ng-invalid': hasErrorTime, 'ng-dirty': hasErrorTime}" />

        </div>
        <div class="flex align-items-center justify-content-between">
            <!-- Mensagem de Erro à esquerda -->
            <small *ngIf="hasErrorTime" class="p-error">
                Obrigatório
            </small>
        </div>
        <div class="mb-2">
            <label for="autocomplete-cliente" class="w-full">Espectadores <i
                    class="bi bi-info-circle-fill ml-1"></i></label>
            <input type="text" pInputText class="w-full" [(ngModel)]="viewers" inputmode="numeric"
                (input)="onlyNumber($event)" placeholder="ex: 20"
                [ngClass]="{'ng-invalid': hasErrorViewers, 'ng-dirty': hasErrorViewers}">
        </div>
        <div class="flex align-items-center justify-content-between">
            <!-- Mensagem de Erro à esquerda -->
            <small *ngIf="hasErrorViewers" class="p-error">
                Obrigatório
            </small>
        </div>
        <div class="mb-2">
            <label for="autocomplete-cliente" class="w-full">Tempo médio de permanência <i
                    class="bi bi-info-circle-fill ml-1"></i>

                <p-dropdown [options]="targetTime" id="dropdown-time" placeholder="Selecione o tempo"
                    (click)="forceFocus($event)"
                    [ngClass]="{'ng-invalid': hasErrorSelectedTime, 'ng-dirty': hasErrorSelectedTime}"
                    class="w-full ss-g-sidebarVendas" [(ngModel)]="selectedTime" optionLabel="label">
                </p-dropdown>
            </label>
        </div>
        <div class="flex align-items-center justify-content-between">
            <!-- Mensagem de Erro à esquerda -->
            <small *ngIf="hasErrorSelectedTime" class="p-error">
                Obrigatório
            </small>
        </div>
        <button pButton pRipple label="Simular" class="w-full mt-2" (click)="simular()"
            [loading]="loadingSimulate"></button>
        <div class="flex flex-column align-items-start mt-2 mb-2 ">
            <div class="text-xs font-light mb-2">
                <div>
                    <i class="bi bi-question-circle-fill mr-1"></i> <b>Investimento</b>
                </div>
                <p>O custo estimado é <b>R$ {{ resultSimulatePrice }}</b></p>
            </div>
            <p>O custo final de sua transmissão pode variar conforme seu evento como <b>tempo de duração,
                    espectadores</b> e <b>tempo de permanência.</b>
                Conheça nossas taxas <a href="#">aqui.</a></p>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div style="text-align: right;">
            <button pButton pRipple (click)="closeLive()" label="Fechar" class="p-button-outlined"></button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="RTMP infos" [(visible)]="displayRTMPInofos" [modal]="true" style="z-index: 1102;"
    [dismissableMask]="true">
    Conteúdo
</p-dialog>

<p-dialog header="Erro durante a conexão" [(visible)]="dialogVisible" [modal]="true" [closable]="true"
    [showHeader]="true">
    <p>{{ errorMessage }}</p>
    <p-footer>
        <button type="button" pButton label="Fechar" pButton pRipple class="p-button-outlined"
            (click)="closeDialog()"></button>
    </p-footer>
</p-dialog>
<p-dialog header="Erro durante a conexão" [(visible)]="dialogVisibleError" [modal]="true" [closable]="true"
    [showHeader]="true">
    <p>{{ errorMessage }}</p>
    <p-footer>
        <button type="button" pButton label="Fechar" pButton pRipple class="p-button-outlined"
            (click)="closeDialogError()"></button>
        <button type="button" label="Desconectar" pButton pRipple (click)="disconectSocket()"></button>
    </p-footer>
</p-dialog>

<p-dialog header="Confirmação" [(visible)]="showConfirmDialog" [modal]="true" [closable]="false" [showHeader]="true">
    <p>Tem certeza de que deseja interromper a transmissão?</p>
    <p-footer>
        <button pButton pRipple label="Sim" (click)="stopMediaTracks(stream); showConfirmDialog = false;"></button>
        <button pButton pRipple label="Não" (click)="closeDialogConfirmation()"></button>
    </p-footer>
</p-dialog>
<p-confirmDialog></p-confirmDialog>
<p-dialog [baseZIndex]="100" class="ss-l-sizeDialogs" header="Selecione um ícone, gif ou adicione"
    [(visible)]="displayDialogIconsGifs" id="iconsGifs" [modal]="true" [dismissableMask]="true">

    <p-tabMenu [model]="linksOptions" [ngStyle]="{'max-width': '90vw !important'}" [(activeItem)]="activeLinkOption"
        (change)="changeCtaLink()" [scrollable]="false">
    </p-tabMenu>

    <div *ngIf="activeLinkOption?.label === 'Ícones'" style="max-width: 90vw !important;">
        <div class="mt-2 mb-2 search-container">
            <span class="p-float-label p-input-icon-right w-full mb-2">
                <input type="text" class="w-full" pInputText [(ngModel)]="nameIconSearch" (input)="searchIcons()"
                    placeholder="Buscar ícones...">
                <i *ngIf="!loadingLinks; else loading" class="pi pi-search" style="top: 28px !important;"></i>
                <ng-template #loading>
                    <i class="pi pi-spin pi-spinner"></i>
                </ng-template>
            </span>
            <a *ngIf="totalRecordsIcons > 0" class="ss-g-remove-coupon mt-2" style="cursor: pointer;"
                (click)="clearSearchIcons()">
                <i class="pi pi-times"></i> Limpar Busca
            </a>
        </div>

        <div class="mb-2">
            <label class="ss-g-globalClassLabel">Cor</label>
            <div class="button-row">
                <div class="button-row2">
                    <p-selectButton [options]="linkOptions" [(ngModel)]="valSelect1" optionLabel="label"
                        (ngModelChange)="setIconGifColor($event)">
                    </p-selectButton>
                </div>
            </div>
        </div>
        <div>
            <label class="ss-g-globalClassLabel">Shapes</label>
            <div class="button-row">
                <p-selectButton [options]="linkOptionsGif" [(ngModel)]="valSelect2" optionLabel="label"
                    (ngModelChange)="setIconGifShape($event)">
                </p-selectButton>
            </div>
        </div>
        <div class="dataView-container">
            <p-dataView [value]="icons" [lazy]="true" layout="grid" [paginator]="true" [rows]="96"
                [totalRecords]="totalRecordsIcons" (onLazyLoad)="loadData($event)">
                <ng-template pTemplate="gridItem" let-icon>
                    <div class="p-col-12 p-md-6 p-lg-2_4 icon-container1 card p-4" style="max-height: 60px;"
                        (click)="getIcon(icon.thumbnails[0]?.url)">
                        <img [src]="icon.thumbnails[0]?.url" [alt]="icon.name" style="width: 40px;height: 40px;"
                            *ngIf="icon.thumbnails.length > 0" loading="lazy">
                    </div>
                </ng-template>
                <ng-template pTemplate="loadingItem">
                    <div class="p-col-12 p-md-6 p-lg-2_4 icon-container1">
                        <div class="skeleton-image"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
    </div>
    <div *ngIf="activeLinkOption?.label === 'Custom'">
        <!-- Conteúdo para Custom -->

        <div class="mt-4 mb-4">Colar url do icon ou gif desejado (Formato PNG/GiF)</div>
        <div class="w-full text-center mb-4">
            <input type="text" pInputText [(ngModel)]="tempIconGifUrl" (input)="onInputChange2()" class="w-full">
            <div class="flex align-items-center justify-content-between">
                <small *ngIf="errorGif" class="p-error">
                    Verificar formato de imagem
                </small>

            </div>
        </div>
        <div class="w-full text-right">
            <button pButton pRipple (click)="selectIconGif(tempIconGifUrl)" label="OK"></button>
        </div>

    </div>
    <div *ngIf="activeLinkOption?.label === 'GIFS'">
        <!-- Conteúdo para GIFS -->
        <div class="mt-2 mb-2 search-container">
            <span class="p-float-label p-input-icon-right w-full mb-2">
                <input type="text" class="w-full" pInputText [(ngModel)]="nameGifSearch" (input)="searchGifs()"
                    placeholder="Buscar gifs...">
                <!-- <label for="righticon">Buscar Ícones</label> -->
                <i *ngIf="!loadingLinks; else loading" class="pi pi-search" style="top: 28px !important;"></i>
                <ng-template #loading>
                    <i class="pi pi-spin pi-spinner"></i>
                </ng-template>
            </span>
            <a *ngIf="totalRecordsGifs > 0" class="ss-g-remove-coupon mt-2" style="cursor: pointer;"
                (click)="clearSearchGifs()">
                <i class="pi pi-times"></i> Limpar Busca
            </a>
        </div>
        <div class="dataView-container">
            <p-dataView [value]="gifs" layout="grid" [lazy]="true" (onLazyLoad)="loadDataGifs($event)"
                [totalRecords]="totalRecordsGifs" [paginator]="true" [rows]="25">
                <ng-template pTemplate="gridItem" let-gif>
                    <!-- Certifique-se de que a variável 'gif' é usada dentro de ng-template dataView-container-->
                    <div class="p-col-12 p-md-6 p-lg-2_4 icon-container card p-4 gap-5 not-last"
                        (click)="getIcon(gif.images.downsized.url)">
                        <img [src]="gif.images.downsized.url" [alt]="gif.title" style="width: 40px;height: 40px;"
                            loading="lazy">
                    </div>
                </ng-template>
                <ng-template pTemplate="loadingItem">
                    <div class="p-col-12 p-md-6 p-lg-2_4 icon-container">
                        <div class="skeleton-image"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple (click)="closeIconsModal()" label="Fechar" class="p-button-outlined"></button>
    </ng-template>
</p-dialog>

<p-dialog [baseZIndex]="100" class="ss-l-sizeDialogs" header="Facebook Pixel" [(visible)]="facebookPixel"
    class="ss-l-sizeDialogs" [modal]="true" id="dialog1" [dismissableMask]="true">

    <p-table [value]="filteredPixels" [lazy]="true" (onLazyLoad)="loadPixelsLazy($event)" [paginator]="true"
        [rowsPerPageOptions]="[10, 20, 100]" [loading]="loadingPixelsTable" [rows]="10" [totalRecords]="totalRecords"
        class="w-full" [scrollable]="true" scrollHeight="flex">

        <ng-template pTemplate="caption">
            <div class="caption-container">

                <button pButton pRipple label="Adicionar" (click)="openAddPixel()"
                    class="p-button-primary-500 text-white b1 mb-2 mx-0"></button>

            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th>Nome</th>
                <th>Pixel ID</th>
                <th>Ações</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
            <tr *ngIf="!loadingPixels; else loadingContent">
                <td>{{ rowData.key }}</td>
                <td>{{ rowData.pixel_id }}</td>
                <td class="centered-content-sales">
                    <div class="flex">
                        <button pButton pRipple type="button" icon="pi pi-pencil" class="mr-2 mt-3"
                            (click)="editPixel(rowData.id)"></button>
                        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-danger mt-3"
                            (click)="confirmDeletionPixel(rowData.id)"></button>
                    </div>

                </td>
            </tr>
            <ng-template #loadingContent>
                <tr>
                    <td><p-skeleton></p-skeleton></td>
                    <td><p-skeleton></p-skeleton></td>
                    <td><p-skeleton></p-skeleton></td>
                </tr>
            </ng-template>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3" class="text-center">Nenhum pixel adicionado.</td>
            </tr>
        </ng-template>

        <!-- Este é o ng-template que será exibido quando loadingPixels for true -->

    </p-table>



    <ng-template pTemplate="footer">
        <button pButton pRipple (click)="closeFacebookPixel()" label="Fechar" class="p-button-outlined"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Conexão Perdida" class="ss-l-sizeDialogs" [(visible)]="displayReconnectDialog" class="dialogReconnect"
    [modal]="true" [closable]="false">

    <div class="p-d-flex p-ai-center p-jc-center p-flex-column">
        <p-progressSpinner></p-progressSpinner>
        <p>{{ reconnectMessage }}</p>
        <p>Um erro ocorreu. Verifique sua conexão, saldo e plano contratdo ou entre em contato com o
            suporte.</p>
    </div>
    <p-footer>
        <button type="button" pButton label="Desconectar" icon="pi pi-times" (click)="onDisconnectClick()"></button>
    </p-footer>
</p-dialog>

<p-dialog header="Trocando a câmera" id="switchCam" [(visible)]="displayChangeCameraDialog" [modal]="true"
    class="dialogReconnect ss-l-sizeDialogs" [closable]="false">
    <div class="p-d-flex p-ai-center p-jc-center p-flex-column">
        <p-progressSpinner></p-progressSpinner>
        <p>{{ changeCameraMessage }}</p>
    </div>
</p-dialog>