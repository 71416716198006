<div class="grid">
    <div class="mb-4 w-1/2 mx-auto text-center">
        <p-selectButton class="w-full" [options]="pageOptions" (ngModelChange)="selecionarOpcao($event.value)"
            [(ngModel)]="pageConfigs" optionLabel="name">
            <ng-template let-option pTemplate="item">
                <div class="flex align-items-center justify-content-center w-full">
                    <i [ngClass]="option.icon" class="mr-2"></i>
                    <span class="font-bold">{{ option.name }}</span>
                </div>
            </ng-template>
        </p-selectButton>
    </div>
</div>
<div class="grid ss-l-marginProfileConfig" *ngIf="activeOption == 1">
    <div class="col-12 xl:col-6">
        <div class="card">
            <div class="flex align-items-start justify-content-between mb-2">
                <span class="text-900 text-xl font-semibold">Informações da loja</span>
            </div>
            <div>
                <div>Essas informações serão exibidas para seus clientes e em mecanismos de busca (ex.: Google).
                </div>
            </div>
            <div class="col-12 mb-4 mt-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    Nome da loja
                    <div class="p-inputgroup mt-2">
                        <input type="text" id="title" (keypress)="filterSpecialChars($event)"
                            (input)="formToSave1(); checkInput();" pInputText class="w-full" [(ngModel)]="storeName">
                    </div>
                </label>
                <div class="flex align-items-center justify-content-between mt-2">
                    <!-- Mensagem de Erro à esquerda -->
                    <span *ngIf="errorName" class="p-error">
                        O nome não pode estar vazio
                    </span>
                </div>
            </div>
            <div class="col-12 mt-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    Descrição da loja
                    <div class="mb-2 mt-2">
                        <textarea rows="3" (ngModelChange)="formToSave1()" cols="30"
                            class="w-full p-inputtextarea p-inputtext p-component p-element"
                            placeholder="Descrição do Slick" [(ngModel)]="storeDescription" pInputTextarea></textarea>
                    </div>
                </label>
            </div>
        </div>
        <div class="card">
            <div class="flex align-items-start justify-content-between mb-2">
                <span class="text-900 text-xl font-semibold">URL da loja</span>
            </div>
            <div>
                <div>Esse é o endereço URL usado para acessar a loja</div>
            </div>
            <div class="col-12 mb-4 mt-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    URL
                    <div class="p-inputgroup mt-2">
                        <span class="p-inputgroup-addon">https://slicksell.com.br/</span>
                        <input id="urlStore" (keypress)="filterSpecialCharsUrl($event)" (ngModelChange)="formToSave3()"
                            type="text" pInputText placeholder="sua-loja" [(ngModel)]="urlStore" />
                        <!-- <span class="p-inputgroup-addon">.slicksell.com.br</span> -->
                    </div>
                </label>
                <div class="flex align-items-center justify-content-between mt-2">
                    <!-- Mensagem de Erro à esquerda -->
                    <span *ngIf="errorURL" class="p-error">
                        {{message}}
                    </span>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="flex align-items-start justify-content-between mb-2">
                <span class="text-900 text-xl font-semibold">Status da loja</span>
            </div>
            <div>Para desabilitar o acesso a sua loja habilite o <b>modo de manutenção</b>.</div>
            <div class="col-12 mb-4 mt-4 p-0">
                <div class="flex align-items-center gap-2">
                    <p-inputSwitch binary="true" (ngModelChange)="formToSave1()" class="mr-2 ss-l-switch-position3"
                        [(ngModel)]="manutantionMode" [ngModelOptions]="{standalone: true}"></p-inputSwitch>
                    <label>Modo de manutenção</label>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 xl:col-6">
        <div class="card mt-0  ">
            <div class="text-900 text-xl font-semibold mb-2">Identidade visual</div>
            <div class="col-12 p-0 mb-2">
                <div>Carregue as logos de sua loja</div>
            </div>


            <!---->
            <div class="col-12 p-0 grid mx-0 w-full surface-border border-bottom-1">
                <div class="col-6 mt-3 mb-2 pl-0">
                    <span class="ss-g-globalClassLabel flex-start" style="align-self:flex-start">Logotipo da loja</span>
                    <div class="mt-2"></div>
                    <p-fileUpload #fileUpload name="demo[]" class="cursor-pointer" accept="image/*" [multiple]="false"
                        *ngIf="!logoPreviewUrl" (onSelect)="onLogoSelect($event)" [customUpload]="true"
                        [showCancelButton]="false" styleClass="border-1 surface-border surface-card border-round">
                        <ng-template pTemplate="content">
                            <div (click)="fileUpload.choose()">
                                <div class="content">
                                    <!-- Exibir apenas se nenhum arquivo for selecionado -->
                                    <div *ngIf="!logoSelected"
                                        class="flex flex-column justify-content-center align-items-center">
                                        <i class="pi pi-file text-4xl text-primary"></i>
                                    </div>

                                </div>
                            </div>
                        </ng-template>
                    </p-fileUpload>

                    <div *ngIf="logoPreviewUrl" class="image-container">
                        <img [src]="logoPreviewUrl" class="image-preview">
                        <button *ngIf="logoPreviewUrl" pButton pRipple type="button" icon="pi pi-times"
                            class="close-button" (click)="removelogo($event)"></button>
                    </div>
                    <div class="flex align-items-center justify-content-between">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="erroTamanhoImagem" class="p-error">
                            Tamanho da imagem deve ser de no máximo 1 mega
                        </small>
                    </div>
                    <div class="flex align-items-center justify-content-between">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="erroTipoImagem" class="p-error">
                            Tipo da imagem deve ser .png, .webp, .jpg ou jpeg
                        </small>
                    </div>
                </div>
                <div class="col-6 mt-3 mb-2 pr-0">
                    <span class="ss-g-globalClassLabel flex-start" style="align-self:flex-start">Favicon da loja</span>
                    <div class="mt-2"></div>
                    <p-fileUpload #fileUpload class="cursor-pointer" name="demo[]" accept="image/*" [multiple]="false"
                        *ngIf="!faviconPreviewUrl" (onSelect)="onFaviconSelect($event)" [customUpload]="true"
                        [showCancelButton]="false" styleClass="border-1 surface-border surface-card border-round">
                        <ng-template pTemplate="content">
                            <div (click)="fileUpload.choose()">
                                <div class="content">
                                    <!-- Exibir apenas se nenhum arquivo for selecionado -->
                                    <div *ngIf="!logoSelected"
                                        class="flex flex-column justify-content-center align-items-center">
                                        <i class="pi pi-file text-4xl text-primary"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-fileUpload>

                    <div *ngIf="faviconPreviewUrl" class="image-container">
                        <img [src]="faviconPreviewUrl" class="image-preview">
                        <button *ngIf="faviconPreviewUrl" pButton pRipple type="button" icon="pi pi-times"
                            class="close-button" (click)="removelogoFavicon($event)"></button>
                    </div>
                    <div class="flex align-items-center justify-content-between">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="erroTamanhoImagem2" class="p-error">
                            Tamanho da imagem deve ser de no máximo 1 mega
                        </small>
                    </div>
                    <div class="flex align-items-center justify-content-between">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="erroTipoImagem2" class="p-error">
                            Tipo da imagem deve ser .png, .webp, .jpg ou jpeg
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-12 p-0 grid mx-0 mt-4 w-full">
                <div class="flex align-items-start justify-content-between mb-2">
                    <span class="text-900 text-xl font-semibold">Cores</span>
                </div>
                <div class="col-12 p-0 mb-2">
                    <div>Defina as cores da loja</div>
                </div>
                <div class="col-12 mb-4 mt-2 p-0">
                    <div class="col-12 p-0 mb-2">
                        <label class="ss-g-globalClassLabel">Primária</label>
                        <div class="mt-2"></div>
                        <p-colorPicker [(ngModel)]="primaryColor" format="hex" (ngModelChange)="formToSave2()"
                            (onChange)="updatePrimaryColor($event.value)" class="w-full"></p-colorPicker>
                    </div>
                    <div class="col-12 p-0">
                        <label class="ss-g-globalClassLabel mt-2">Secundária</label>
                        <div class="mt-2"></div>
                        <p-colorPicker [(ngModel)]="secondaryColor" format="hex" (ngModelChange)="formToSave2()"
                            (onChange)="updateSecondaryColor($event.value)" class="w-full"></p-colorPicker>
                    </div>
                </div>
            </div>
            <!---->

        </div>

    </div>
</div>
<div class="grid h-full" *ngIf="activeOption == 2">
    <div class="col-12 xl:col-6">
        <div class="card">
            <div class="flex align-items-start justify-content-between mb-2">
                <span class="text-900 text-xl font-semibold">Endereço Fiscal da Loja</span>
            </div>
            <div>
                <div>Essas informações serão exibidas para seus clientes apenas em caso de vendas e pagamentos
                    realizados junto à plataforma.
                </div>
            </div>
            <div class="col-12 mb-4 mt-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    País
                    <div class="p-inputgroup mt-2">

                    </div>
                </label>
                <p-dropdown [options]="countries" [(ngModel)]="country" (onChange)="formToSave1()"
                    placeholder="Select a Country" optionLabel="name" class="w-full">
                    <!-- Template para os itens da lista -->
                    <ng-template let-country pTemplate="item">
                        <div class="flex align-items-center">
                            <img src="assets/demo/images/flag/flag_placeholder.png"
                                [class]="'flag flag-' + country.code.toLowerCase()" alt="Flag" style="width:21px" />
                            <span class="ml-2">{{country.name}}</span>
                        </div>
                    </ng-template>
                    <!-- Template para o item selecionado -->
                    <ng-template let-country pTemplate="selectedItem">
                        <div class="flex align-items-center">
                            <img src="assets/demo/images/flag/flag_placeholder.png"
                                [class]="'flag flag-' + country.code.toLowerCase()" alt="Flag" style="width:21px" />
                            <span class="ml-2">{{country.name}}</span>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="col-12 mt-4 mb-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    CEP
                    <div class="p-inputgroup mt-2">
                        <input (ngModelChange)="formToSave1(); checkCEP();" type="text" id="cep" pInputText
                            class="w-full" (keypress)="allowOnlyNumbers($event)" [(ngModel)]="zipcode"
                            (input)="updateZipCode($event)" (keypress)="filterNonNumeric($event)">
                    </div><!---->
                </label>
            </div>
            <div class="col-12 mt-6 p-0">
                <div class="grid mx-0">
                    <div class="col-8 mb-2 p-0">
                        <label for="address" class="ss-g-globalClassLabel">Logradouro</label>
                        <input (ngModelChange)="formToSave1()" [id]="address" class="mt-2 w-full" pInputText
                            [(ngModel)]="address" placeholder="ex: Rua dos Guararapes" type="text" />
                    </div>
                    <div class="col-1 mb-2 p-0"></div>
                    <div class="col-3 mb-4  p-0">
                        <label for="nro" class="ss-g-globalClassLabel">Nro</label>
                        <input (ngModelChange)="formToSave1()" [id]="nro" class="mt-2 w-full" type="text" pInputText
                            [(ngModel)]="nro" placeholder="ex: 15" />
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4 p-0">
                <div class="grid mx-0">
                    <div class="col-8 mb-2 p-0">
                        <label for="neighborhood" class="ss-g-globalClassLabel">Bairro</label>
                        <input (ngModelChange)="formToSave1()" [id]="neighborhood" class="mt-2 w-full" pInputText
                            [(ngModel)]="neighborhood" placeholder="ex: Vila dos Pássaros" type="text" />


                    </div>
                    <div class="col-1 mb-2 p-0"></div>
                    <div class="col-3 mb-4  p-0">
                        <label for="complement" class="ss-g-globalClassLabel">Compl.</label>
                        <input (ngModelChange)="formToSave1()" [id]="complement" class="mt-2 w-full" type="text"
                            pInputText [(ngModel)]="complement" placeholder="ex: AP 15" />
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4 p-0">
                <div class="grid mx-0">
                    <div class="col-8 mb-2 p-0">
                        <label for="city" class="ss-g-globalClassLabel">Cidade</label>
                        <input (ngModelChange)="formToSave1()" [id]="city" class="mt-2 w-full" pInputText
                            [(ngModel)]="city" placeholder="ex: São Paulo" type="text" />


                    </div>
                    <div class="col-1 mb-2 p-0"></div>
                    <div class="col-3 mb-4  p-0">
                        <label for="state" class="ss-g-globalClassLabel">Estado</label>
                        <div class="mt-2"></div>
                        <p-dropdown (ngModelChange)="formToSave1()" [options]="states" [(ngModel)]="selectedState"
                            styleClass="w-full" optionLabel="label" (onChange)="onStateChange()"></p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 xl:col-6">
        <div class="card mt-0  ">
            <div class="text-900 text-xl font-semibold mb-2">Contato</div>
            <div class="col-12 p-0 mb-2">
                <div>Essas informações serão exibidas para seus clientes apenas em caso de vendas e pagamentos
                    realizados juntos à plataforma</div>
            </div>
            <div class="grid mx-0 mt-2">
                <div class="col-3 col-md-2 mb-2 p-0">
                    <label for="phoneDDD" class="ss-g-globalClassLabel">DDD</label>
                    <input [id]="phoneDDD" class="mt-2 w-full" inputmode="numeric" pInputText
                           (input)="limitInput($event)" (keydown)="preventE($event)" [(ngModel)]="phoneDDD"
                           (keypress)="allowOnlyNumbers($event)" placeholder="ex: 99" type="text"
                           (ngModelChange)="formToSave1()" />
                </div>
                <!-- Adicionando margem direita para espaçamento -->
                <div class="col-1 p-0"></div> 
                <div class="col-8 col-md-9 mb-4 p-0">
                    <label for="phone" class="ss-g-globalClassLabel">Telefone</label>
                    <input [id]="phone" class="mt-2 w-full" type="text" pInputText inputmode="numeric"
                           (keypress)="allowOnlyNumbers($event)" [(ngModel)]="phone" placeholder="ex: 99999-9999"
                           (ngModelChange)="formToSave1()" />
                </div>
            </div>
            
            <div class="col-12 mt-2 p-0">
                <div class="grid mx-0 mt-2">
                    <div class="col-3 col-md-2 mb-2 p-0">  <!-- Ajuste para proporção menor para DDD -->
                        <label for="whatsDDD" class="ss-g-globalClassLabel">DDD</label>
                        <input [id]="whatsDDD" class="mt-2 w-full" inputmode="numeric" pInputText
                            (input)="limitInput($event)" (keydown)="preventE($event)" [(ngModel)]="whatsDDD"
                            (keypress)="allowOnlyNumbers($event)" placeholder="ex: 99" type="text"
                            (ngModelChange)="formToSave1()" />
                    </div>
                    <div class="col-1 p-0"></div>  <!-- Espaçamento entre os campos -->
                    <div class="col-8 col-md-9 mb-4 p-0">  <!-- Ajuste para maior largura para WhatsApp -->
                        <label for="whatsapp" class="ss-g-globalClassLabel">Whatsapp</label>
                        <input [id]="whatsapp" class="mt-2 w-full" type="text" pInputText inputmode="numeric"
                            [(ngModel)]="whatsapp" (keypress)="allowOnlyNumbers($event)" placeholder="ex: 99999-9999"
                            (ngModelChange)="formToSave1()" />
                    </div>
                </div>
            </div>
            
            <div class="col-12 mb-2 mt-2 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    Email
                    <div class="p-inputgroup mt-2">
                        <input type="email" id="email" pInputText class="w-full" [(ngModel)]="email"
                            (ngModelChange)="formToSave1()">
                    </div>
                </label>
            </div>

            <!---->


            <!---->

        </div>

    </div>
</div>
<div class="grid h-full" *ngIf="activeOption == 3">
    <div class="col-12 xl:col-6">
        <div class="card">
            <div class="flex align-items-start justify-content-between mb-2">
                <span class="text-900 text-xl font-semibold">Domínios permitidos </span>
            </div>
            <div>
                <div>Adicione domínios permitidos a executar suas APIs e Players. Você pode usar wildcards (*) para
                    subdomínios, e.g.:<b>*.dominio.com</b>. IPs não são permitidos.
                </div>
            </div>
            <div class="col-12 mb-4 mt-4 p-0">
                <div class="p-fluid bg-blue-200 p-2 shadow-none border-black-alpha-30">
                    <p-chips [(ngModel)]="valuesDoms" (ngModelChange)="formToSave2()" separator=","
                        placeholder="ex: a, b, c" (ngModelChange)="showSlickClearFunction()"></p-chips>
                    <div class="flex-container" *ngIf="showClearDoms">
                        <a class="ss-g-remove-coupon mt-2" (click)="clearSlickMaps()">
                            <i class="pi pi-times"></i> Limpar domínios
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 xl:col-6">
        <div class="card mt-0  ">
            <div class="text-900 text-xl font-semibold mb-2">Localhost</div>
            <div class="col-12 p-0 mb-2">
                <div>Permitir conexões de localhost ou qualquer porta para fins de testes e integração</div>
            </div>
            <!---->
            <div class="col-12 p-0 grid mx-0 w-full">
                <div class="col-12 mb-2 mt-4 p-0">
                    <div class="flex align-items-center gap-2">
                        <p-inputSwitch binary="true" class="mr-2 ss-l-switch-position3" [(ngModel)]="localhost"
                            [ngModelOptions]="{standalone: true}" (ngModelChange)="formToSave2()"></p-inputSwitch>
                        <label>Localhost[Qualquer porta]</label>
                    </div>
                </div>
            </div>
            <!---->
        </div>

    </div>
</div>
<p-dialog header="Aguarde" [(visible)]="displayAguarde" [modal]="true" [closable]="true" [showHeader]="true"
    [dismissableMask]="true">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</p-dialog>
<p-confirmDialog header="Confirmação" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast></p-toast>
<p-sidebar [(visible)]="displaySidebar" #sidebar id="ss-l-sideBarBottom" class="ss-g-custom-sidebar-width"
    position="bottom" [modal]="false">
    <ng-template pTemplate="footer">
        <div class="w-full  text-right p-0">
            <button pButton pRipple label="Cancelar" (click)="cancelButton()" class="p-button-outlined mr-2"></button>
            <button pButton pRipple label="Salvar" [disabled]="btnSave" [loading]="saveBtnForm" (click)="saveBtn()"
                class="primary"></button>
        </div>
    </ng-template>
</p-sidebar>