import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { IStoreData } from '../@types/Store';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private REST_API_SERVER = '';

  path: string = '';
  pathVariantes: string = '';
  path2: string = '';

  constructor(private httpClient: HttpClient) {
    const id_loja = this.getIdLoja();
    this.REST_API_SERVER = `https://app.krabo.io/api/admin/store/${id_loja}/promotions`;
    this.path = `https://app.krabo.io/api/admin/store/${id_loja}/promotions`;
    this.pathVariantes = 'admin/variantes';
    this.path2 = `admin/store/${id_loja}/promotions`;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
  }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessToken()}`
    }),
  };

  detailStoreInfoByStoreIdX(storeId: number): Observable<IStoreData> {
    if (environment.production) {
      return this.httpClient.get<IStoreData>(`${environment.serverUrl}/admin/store/${storeId}`);
    } else {
      return this.httpClient.get<IStoreData>(`${environment.paymentGenetatorLinkURL}/admin/store/${storeId}`);
    }
  }

  detailStoreInfos(store_name: string | undefined, slick?: string, widget?: string): Observable<IStoreData> {
    const requestBody = {
      store_id: null,
      domain: null,
      subdomain: `${store_name}.slicksell.com.br`
    };

    if (environment.production) {
      return this.httpClient.post<IStoreData>(`${environment.serverUrl}/admin/store`, requestBody, this.httpOptions);
    } else {
      return this.httpClient.post<IStoreData>(`${environment.paymentGenetatorLinkURL}/admin/store`, requestBody, this.httpOptions);
    }
  }
  detailStoreInfosByID(store_id: number | undefined, slick?: string, widget?: string): Observable<IStoreData> {
    const requestBody = {
      store_id: store_id,
      domain: null,
      subdomain: null
    };

    if (environment.production) {
      return this.httpClient.post<IStoreData>(`${environment.serverUrl}/admin/store`, requestBody, this.httpOptions);
    } else {
      return this.httpClient.post<IStoreData>(`${environment.paymentGenetatorLinkURL}/admin/store`, requestBody, this.httpOptions);
    }
  }


  listarAssetsWidget(widget_id: number | undefined, page: number = 1, limit: number | null = 10): Observable<IStoreData> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`,
        'X-Full-Referer': window.location.href
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || 163;
    if(limit == null) limit = 10;
    
    if (environment.production) {
      return this.httpClient.get<IStoreData>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/widget/${widget_id}/assets?page=${page}`, this.httpOptions);
    } else {
      return this.httpClient.get<IStoreData>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/widget/${widget_id}/assets?page=${page}`, this.httpOptions);
    }
  }
  listarAssetsWidget2(widget_id: number | undefined, page: number = 1, limit: number | null = 10): Observable<IStoreData> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`,
        'X-Full-Referer': window.location.href
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || 163;
    if(limit == null) limit = 9;
    
    if (environment.production) {
      return this.httpClient.get<IStoreData>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/widget/${widget_id}/assets?page=${page}&limit=${limit}`, this.httpOptions);
    } else {
      return this.httpClient.get<IStoreData>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/widget/${widget_id}/assets?page=${page}&limit=${limit}`, this.httpOptions);
    }
  }

  getIdLoja(): any {
    try {
      const idLojaString = localStorage.getItem('SelectedStoreId');
      if (idLojaString) {
        return JSON.parse(idLojaString);
      }
      return null;
    } catch (error) {
      console.error('Erro ao fazer parse do id_loja:', error);
      return null;
    }
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  // Error handling
  errorHandl(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  getAccessToken(): string {
    return localStorage.getItem('access_token') || '';
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem('user') || '');
  }
}
