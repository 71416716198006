import { Component } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { IUserData } from 'src/app/@types/UserData';
import { from } from 'rxjs';
import { ConfGeraisService } from 'src/app/services/conf-gerais.service';
import { ConfirmationService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { LocalstorageService } from 'src/app/services/localstorage.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { BillingService } from 'src/app/services/billing.service';
@Component({
	templateUrl: './register.component.html'
})
export class RegisterComponent {

	confirmed: boolean = false;
	errorPassword: boolean = false;
	password: string = "";
	store_subdoman: string = "";
	issueForm?: FormGroup;
	new_email: string = "";
	errorEmail: boolean = false;
	errorDisponibilidadeEmal: boolean = false;
	loading: boolean = false;
	isLogged: boolean = false;
	displayStyle: string = 'none';
	name: string = "";
	message: string = "";
	store_name: string = "";
	full_name: string = "";
	errorName: boolean = false;
	errorURL: boolean = false;
	errorStoreName: boolean = false;
	criarLoagind: boolean = false;
	userData: IUserData | null = null;
	constructor(
		private layoutService: LayoutService,
		private configService: ConfGeraisService,
		private authService: AuthService,
		private messageService: MessageService,
		private router: Router,
		private httpClient: HttpClient,
		private localstorageService: LocalstorageService,
		private billingService: BillingService,
		public fb: FormBuilder,
		private confirmationService: ConfirmationService
	) {
		this.issueForm = this.fb.group({
			// defina os controles do formulário aqui
			// exemplo: 'name': ['']
		});
	}

	get dark(): boolean {
		return this.layoutService.config.colorScheme !== 'light';
	}


	// ngOnInit(): void {
	// 	console.log(this.generateUniqueId());
	// }

	checkPassword(): void {
		this.errorPassword = !this.password || this.password.length < 6;
	}



	logar() {
		// Se o formulário é válido, continua com o processo de login
		const email = this.new_email;
		const pass = this.password;

		this.loading = true;

		// Chamada de serviço de autenticação
		const loginAuth$ = from(this.authService.login(email, pass));
		const loginBilling$ = this.billingService.login(email, pass);

		loginAuth$
			.pipe(
				switchMap((res: any) => {
					this.authService.setAccessToken(res.access_token, false);
					this.getUserLogged(res.access_token);

					this.authService.me(res.access_token).subscribe({
						next: (resp: any) => {
							localStorage.setItem('user_name', resp.name);
						}
					});


					return loginBilling$;
				})
			)
			.subscribe(
				(res: any) => {
					// Lógica em caso de sucesso
					this.billingService.setAccessTokenEbanking(res.access_token, false);
					// Redirecionar para outra página se necessário 
					// this.router.navigate(['/minha-loja']);
					this.getWallets();
				},
				(err: any) => {
					// Lógica em caso de erro
					console.error('Falha ao Autenticar o billing', err);

					this.loading = false;
					if (err.status === 401 || err.status === 400) {
						this.messageService.add({
							severity: 'error',
							summary: 'Erro',
							detail: 'Email não encontrado'
						});
					} else {
						// Tratamento para outros tipos de erros
						this.messageService.add({
							severity: 'error',
							summary: 'Erro',
							detail: 'Ocorreu um erro inesperado'
						});
					}
					// Aqui você pode definir uma mensagem de erro genérica, se necessário
				},
				() => {
					// Completado
					this.loading = false;
				}
			);
	}


	getUserLogged(accessToken?: string): void {
		console.log('logged')
		this.authService.me(accessToken).subscribe(
			(res: IUserData) => {
				if (accessToken) {
					console.log('dentro1')
					console.log('dentro di ne chamando certo')
					this.setUserToLocalStorageAndRedirect(accessToken, res);
					this.isLogged = true;
					this.router.navigate(['/']);
					// this.router.navigate(['/minha-loja']).then(success => {
					// 	if (!success) {
					// 		console.error('Falha na navegação para /minha-loja 2');
					// 	}
					// }).catch(error => console.error(error));
				}

				this.userData = res;
				//
			},
			(err) => {
				console.log('dentro3')
				if (err.error.message === 'Unauthenticated 234.') {
					this.displayStyle = 'block';
					localStorage.removeItem('access_token');
					localStorage.removeItem('access_token_ebanking');
					localStorage.removeItem('domain_store');
					localStorage.removeItem('SelectedStoreId');
				} else {
					//this.utilService.Logg(err);
				}
			}
		);
	}
	wallets: any[] = [];
	getWallets() {
		console.log('Wallets');
		this.authService.wallet().then(wallets => {
			this.wallets = wallets;
			// Agora, você tem um array de carteiras e pode obter os IDs.
			const ids = wallets.map(wallet => wallet.id);
			console.log(ids); // Aqui estão todos os IDs das carteiras
			this.billingService.setWalletId(ids[0]);

		}).catch(error => {
			console.error('Erro ao obter as carteiras:', error);
		});
	}
	setUserToLocalStorageAndRedirect(
		access_token: string,
		user: IUserData
	): void {
		localStorage.removeItem("access_token");

		this.authService.setAccessToken(access_token, false);
		// this.authService.setSaleorToken(tokenSaleor);
		this.authService.setUser(user.id);
		this.authService.setUserName(user.name);
		this.authService.setUserID(user.id);
		if (user.trial_date) {
			this.authService.setUserTrial(user.trial_date);
		}
		if (user.url_image) {
			this.authService.setUserAvatar(user.url_image);
		}

		if (user.stores && typeof user.stores[0] !== 'number') {
			console.log(user.stores[0].store_id);
			let store_id = user.stores[0].store_id;
			this.localstorageService.setSelectedStoreId(user.stores[0].store_id);
			let httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${this.getAccessToken()}`
				}),
			};

			let data;
			if (environment.production) {
				data = this.httpClient.get<any[]>(`${environment.serverUrl}/admin/show_stores?store_id=${store_id}`, httpOptions);
			} else {
				data = this.httpClient.get<any[]>(`${environment.paymentGenetatorLinkURL}/admin/show_stores?store_id=${store_id}`, httpOptions);
			}

			//////////////////////////
			data.subscribe(response => {
				if (response && response.length > 0) {
					const storeLinkSubdomain = response[0].store_link_subdomain;
					this.localstorageService.setDomain(storeLinkSubdomain);
					console.log(storeLinkSubdomain); // Aqui você terá o valor de store_link_subdomain
				} else {
					this.localstorageService.setDomain(null);
					// Lidar com a situação em que a resposta está vazia ou não é o que você espera
				}
			}, error => {
				// Lidar com erros de requisição aqui
			});
			////////////////////////////
		}


	}
	getAccessToken(): string {
		return localStorage.getItem("access_token") || '';
	}
	generateUniqueId(): string {
		let uniqueString = '';
		while (uniqueString.length < 6) {
			uniqueString += Math.random().toString(36).substring(2, 8);
		}
		return uniqueString.substring(0, 6);
	}
	criarConta() {
		if (this.name.trim().length === 0) {
			this.errorName = true;
		}
		if (this.store_name.trim().length === 0) {
			this.errorStoreName = true;
		}
		if (this.password.length < 6) {
			this.errorPassword = true;
		}
		if (!this.isValidEmail(this.new_email)) {
			this.errorEmail = true;
		}


		if (this.errorName || this.errorStoreName || this.errorPassword || this.errorEmail || this.errorDisponibilidadeEmal) {
			return;
		}
		this.store_subdoman = this.store_name.replace(/\s/g, '');
		this.store_subdoman = this.store_subdoman + this.generateUniqueId();
		this.criarLoagind = true;
		this.issueForm = this.fb.group({
			"user": {
				"name": this.name,
				"surname": this.full_name,
				"email": this.new_email,
				"password": this.password,
				"status": 1,
			},
			"wallet": {
				"wallet_name": "Carteira Principal",
				"access_level": 1
			},
			"store": {
				"store_name": this.store_name,
				"store_description": "",
				"store_link_subdomain": this.store_subdoman + ".slicksell.com.br",
				"store_link_domain": null,
				"support_email": this.new_email,
				"support_phone_number": "",
				"support_whatsapp": "",
				"support_whatsapp_status": 1,
				"zipcode": "",
				"store_street": "",
				"store_number": "",
				"store_district": "",
				"store_city": "",
				"store_complement": "",
				"status": 1
			},
			"store_settings": {
				"logo": "",
				"favicon": "",
				"background_color": "#671abc",
				"background_font_color": "#671abc",
				"footer_color": "#671cbc",
				"footer_font_color": "#671cbc",
				"navigation_menu_color": "#671cbc",
				"navigation_menu_font_color": "#671cbc",
				"regular_buttons_color": "#671cbc",
				"regular_buttons_font_color": "#671cbc",
				"buy_button_color": "#671cbc",
				"buy_button_font_color": "#671cbc",
				"add_cart_button_color": "#671cbc",
				"add_cart_button_font_color": "#671cbc",
				"maximum_cashback_fixed": "100",
				"notification_status": "1",
				"notification_via_email": "1",
				"notification_via_push": "1",
				"notification_via_whatsapp": "1",
				"subs_re_payment": "0",
				"subs_cancell_days": "0",
				"subs_billing_days": "0",
				"checkout_pix": "0",
				"checkout_slip": "0",
				"checkout_card": "0"
			}
		});
		this.configService.createOnboarding(this.issueForm.value).subscribe({
			next: (res: any) => {
				console.log('res', res);
				this.criarLoagind = false;
				this.logar();
				this.messageService.add({
					severity: 'success',
					summary: 'Conta criada',
					detail: 'A Conta foi criada com sucesso!'
				});
			},
			error: (error: any) => {
				this.criarLoagind = false;
				console.log('caiu no erro: ' + error)
				let message = '';
				if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 422 || error.status === 405) {
					message = error.error.message;
				} else if (error.status === 500) {
					message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
				}

				if (message) {
					this.showErrorDialog(message);
				}
			}
		});

	}
	checkInputName() {
		this.errorName = false;
	}

	checkInputStoreName() {
		this.errorStoreName = false;
	}
	showErrorDialog(message: string) {
		this.confirmationService.confirm({
			message: message,
			header: 'Erro',
			icon: 'pi pi-times',
			accept: () => {
			}
		});
	}
	filterSpecialChars(event: KeyboardEvent) {
		// Permite apenas letras (maiúsculas e minúsculas), números e espaço
		if (!/^[a-zA-Z0-9 ]$/.test(event.key)) {
			event.preventDefault();
		}
	}

	filterSpecialCharsNames(event: KeyboardEvent) {
		// Permite letras (maiúsculas e minúsculas), números, espaço, acentos e cedilha
		if (!/^[a-zA-Z0-9 çÇáéíóúÁÉÍÓÚàÀãÃõÕâÂêÊîÎôÔûÛüÜñÑ]+$/.test(event.key)) {
			event.preventDefault();
		}
	}
	timeOut:any;
	checkEmail() {
		
		clearTimeout(this.timeOut);
		this.timeOut = setTimeout(() => {
			console.log('verificando email');
			this.errorDisponibilidadeEmal = false;
			this.configService.verificarDisponibilidadeEmail(this.new_email).subscribe({
				next: (res: any) => {
					if (res && res.message == "Não existe usuário com o email informado.") {
						this.errorDisponibilidadeEmal = false;
					}
					console.log(res);
				},
				error: (error: any) => {
					this.errorDisponibilidadeEmal = true;
					console.log(error);
				}
			});
		}, 750);

	}
	private debounceTimer: any = null;
	formToSave3() {
		this.errorURL = false;
		clearTimeout(this.debounceTimer);
		this.debounceTimer = setTimeout(() => {
			this.configService.verificarDisponibilidadeSubdominio(this.store_subdoman).subscribe({
				next: (res: any) => {
					if (res.message == "Não existe Loja com o subdomínio informado.") {
						this.errorURL = false;
					}
					console.log(res);
				},
				error: (error: any) => {
					this.errorURL = true;
					console.log(error);
					console.error('Error response', error);
					if (error.status === 400 || error.status === 403 || error.status === 401) {
						//message = error.error.message;
						this.message = error.error.message;
					} else if (error.status === 500) {
						//message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
						this.message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
					}

					// if (message) {
					//   this.showErrorDialog(message);
					// }
				}
			});
		}, 750);

	}
	checkEmailValid(): void {
		this.errorEmail = false;
		clearTimeout(this.debounceTimer);
		this.debounceTimer = setTimeout(() => {
			this.errorEmail = !this.isValidEmail(this.new_email);
		}, 1500);
	}
	isValidEmail(email: string): boolean {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}
}
