<div class="card shadow-none " ><!-- [class.pb-4]="!isMobile" -->
    <div class="header-container mb-2 "
        style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
        <!-- Título com ícone de olho -->
        <div class="title-container" style="display: flex; align-items: center;">
            <i class="pi pi-eye mr-2" style="font-size: 1.5rem;"></i>
            <span style="font-weight: bold; font-size: 1.2rem;">Prévia - {{ capitalizeFirstLetter(templateType) }}</span>
        </div>
        <!-- Botão alinhado à direita -->
        <div class="button-container">
            <button pButton pRipple type="button" icon="pi pi-pencil"
            (click)="onOpenSidebar()" class="border-none button-tag"></button>
        </div>
    </div>
    <ng-container [ngSwitch]="templateType">
        <app-carousel *ngSwitchCase="'carousel'" [templateType]="templateType" [widget_id]="widget_id" [arraySlicksFull]="arraySlicksFull"
            [videoUrls]="videoUrls" [posterUrls]="posterUrls" [height]="height" [pageToVideos]="pageToVideos" 
            (loadMoreVideos)="loadMoreVideosForCarousel()" (openSidebar)="onOpenSidebar()" [loadingComplete]="loadingComplete" [containerDiv]="containerDiv" [totalAssets]="totalAssets">
        </app-carousel>
        <app-grid *ngSwitchCase="'grid'" [templateType]="templateType" [widget_id]="widget_id" [videoUrls]="videoUrls" [isCol4]="isCol4" [arraySlicksFull]="arraySlicksFull" [totalAssets]="totalAssets"
            (loadMoreVideos)="loadMoreVideosForGrid()" [hasMoreVideos]="hasMoreVideos" [pageToVideos]="pageToVideos"
            (openSidebar)="onOpenSidebar()"></app-grid>
        <app-popup *ngSwitchCase="'popup'" [templateType]="templateType" [position]="popupPosition || 'bottomright'" [font_color]="font_color" [arraySlicksFull]="arraySlicksFull" [totalAssets]="totalAssets"
            [highlight]="highlight" (openSidebar)="onOpenSidebar()" [shape]="selectedShape" [backgroundColor]="bg_color" [pageToVideos]="pageToVideos"
            [videoUrls]="videoUrls" [height]="height" [widget_id]="widget_id">
        </app-popup>
        <app-inline [height]="height" [templateType]="templateType" [aspectRatio]="aspectRatio" *ngSwitchCase="'inline'" [isCol4]="isCol4"
            [store_id]="store_id" [widget_id]="widget_id" (openSidebar)="onOpenSidebar()"></app-inline>



            <div class="card shadow-none" style="padding: 1rem;" *ngSwitchDefault>
                <!-- Placeholder para o conteúdo principal -->
                <div class="skeleton-content" style="margin-top: 1rem;">
                    <!-- Placeholder para imagem -->
                    <p-skeleton shape="rectangle" width="30%" height="150px" [ngStyle]="{ 'margin-bottom': '1rem' }"></p-skeleton>
            
                    <!-- Placeholder para duas colunas menores à direita -->
                    <div style="display: flex; gap: 1rem;">
                        <p-skeleton shape="rectangle" width="20%" height="50px"></p-skeleton>
                        <p-skeleton shape="rectangle" width="50%" height="50px"></p-skeleton>
                    </div>
            
                    <!-- Placeholder para uma série de elementos de texto -->
                    <div style="margin-top: 1rem;">
                        <div style="margin-bottom: 0.5rem;">
                            <p-skeleton width="70%" height="20px"></p-skeleton>
                        </div>
                        <div style="margin-bottom: 0.5rem;">
                            <p-skeleton width="60%" height="20px"></p-skeleton>
                        </div>
                        <div style="margin-bottom: 0.5rem;">
                            <p-skeleton width="40%" height="20px"></p-skeleton>
                        </div>
                    </div>
            
                    <!-- Placeholder para botões ou ações, como "ver mais" -->
                    <div style="margin-top: 1rem; display: flex; gap: 0.5rem;">
                        <p-skeleton shape="circle" size="40px"></p-skeleton>
                        <p-skeleton shape="circle" size="40px"></p-skeleton>
                        <p-skeleton shape="circle" size="40px"></p-skeleton>
                    </div>
            
                    <!-- Placeholder para o rodapé -->
                    <div style="margin-top: 1.5rem;">
                        <div style="margin-bottom: 0.5rem;">
                            <p-skeleton width="90%" height="20px" [ngStyle]="{ 'margin-bottom': '0.5rem' }"></p-skeleton>
                        </div>
                        <div style="margin-bottom: 0.5rem;">
                            <p-skeleton width="80%" height="20px" [ngStyle]="{ 'margin-bottom': '0.5rem' }"></p-skeleton>
                        </div>
                        <div style="margin-bottom: 0.5rem;">
                            <p-skeleton width="40%" height="20px"></p-skeleton>
                        </div>
            
            
                    </div>
                </div>
            </div>
        <!-- <p *ngSwitchDefault>Componente não encontrado</p> -->
    </ng-container>
</div>
<p-toast></p-toast>