<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>
        <!-- <app-breadcrumb class="topbar-breadcrumb text-4xl"></app-breadcrumb> -->
        <app-breadcrumbs ></app-breadcrumbs>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu">
            <li class="topbar-profile">
                <button type="button" class="p-link" (click)="onProfileButtonClick()">
                    <!-- Utilizar o p-skeleton enquanto a imagem está carregando -->
                    <p-skeleton *ngIf="isLoading" width="45px" height="45px" borderRadius="50%"></p-skeleton>
                    <!-- Mostrar imagem quando não estiver carregando -->
                    <img *ngIf="!isLoading && avatarUrl" [src]="avatarUrl" alt="Profile" />
                </button>
            </li>
        </ul>
    </div>
</div>