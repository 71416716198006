import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MediaStreamService {
  private stream: MediaStream | null = null;

  getMediaStream(): MediaStream | null {
    return this.stream;
  }

  setMediaStream(stream: MediaStream | null): void {
    this.stream = stream;
  }
}
