import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SSetCtasService {

  constructor() { }

  async setupCtas(
    swiper: any,
    virtualIndex: number,
    slicks: any[], 
    currentVideoIndex: number,
    setComponentState: (key: string, value: any) => void, // Função callback para definir estados no componente
    handleTruncatedDescCallback: () => void, // Callback para atualizar a descrição truncada
    updatePlayPauseColorCallback: (color: string) => void, // Callback para atualizar a cor do botão de play/pause
    updateProgressBarColorCallback: (color: string) => void, // Callback para atualizar a cor da barra de progresso
    setupResizeObserverCallback: () => void // Callback para configurar o Resize Observer
  ): Promise<void> {
    const playerDataString = localStorage.getItem('player_data');
    if (playerDataString) {
      const playerData = JSON.parse(playerDataString);
      setComponentState('slideIndex', playerData.current_asset.slide_index);

      // Corrigir a comparação para usar a variável `slideIndex` do componente em vez de `setComponentState`
      if (virtualIndex === playerData.current_asset.slide_index) {
        return;
      }
    }

    const newChatId = slicks[virtualIndex].content.uid;
    const ctaComponents = slicks[virtualIndex]?.content?.cta?.components || {};
    setComponentState('lastSrc', slicks[virtualIndex].media.src);

    // Atualizar alertas
    const alertsComponents = ctaComponents.alerts;
    if (alertsComponents) {
      setComponentState('alerts', alertsComponents);
    } else {
      setComponentState('alerts', []);
    }

    // Atualizar botões personalizados
    const customComponents = ctaComponents.custom;
    if (customComponents) {
      setComponentState('customComponents', customComponents);
      setComponentState('setupVisibleButton', true); // Ativa o botão de visibilidade se existir
    } else {
      setComponentState('customComponents', []);
    }

    // Atualizar botões WhatsApp
    const whatsComponents = ctaComponents.whatsapp;
    if (whatsComponents) {
      setComponentState('whatsComponents', whatsComponents);
      setComponentState('setupVisibleButtonWhatsApp', true);
    } else {
      setComponentState('whatsComponents', []);
    }

    // Atualizar links
    const linksComponents = ctaComponents.links;
    if (linksComponents) {
      setComponentState('linksComponents', linksComponents);
    } else {
      setComponentState('linksComponents', []);
    }

    // Atualizar cores do player e barra de progresso
    const playerColor = slicks[virtualIndex].extra.player.player_color || '#ff0000';
    const progressBarColor = slicks[virtualIndex].extra.player.progress_bar_color || '#ff0000';
    updatePlayPauseColorCallback(playerColor);
    updateProgressBarColorCallback(progressBarColor);

    setComponentState('playPauseColor', playerColor);
    setComponentState('progressBarColor', progressBarColor);
    setComponentState('aspect_ratio', slicks[virtualIndex].media.aspect_ratio);
    setComponentState('slickDesc', slicks[virtualIndex].content.description);
    setComponentState('slickTitle', slicks[virtualIndex].content.title);
    setComponentState('uidSlick', slicks[virtualIndex]?.content?.uid || '');

    if (slicks[virtualIndex].content.description === null) {
      setComponentState('truncatedDesc', '');
      setComponentState('showDesc', false);
    } else {
      setComponentState('showDesc', true);
    }

    // Chama a função de truncamento de descrição
    handleTruncatedDescCallback();

    // Atualiza a transição
    const isFinishTransition = slicks.map((_: any, index: number) => index === currentVideoIndex ? false : true);
    setComponentState('isFinishTransition', isFinishTransition);

    // Configura o Resize Observer
    setupResizeObserverCallback();
  }
}
