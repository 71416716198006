<div class="overlay s-s-l-zIndexVideo" [ngClass]="{ 'hidden-player': !isPlayerVisible }">
  <div class="modal-header">
    <button class="close-btn-header m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right"
      (click)="closeModal()">X</button>
  </div>
  <div class="modal-content">
    <div [style.height.px]="height">
      <!-- Imagem de Pôster -->
      <div *ngIf="isThumbnailVisible" [ngClass]="{'full-screen': isFullScreen, 'aspect-ratio-9-16': !isFullScreen}"
        class="poster-image">
        <img [src]="selectedThumbnail" alt="Loading Thumbnail" class="thumbnail-img" />
      </div>

      
      <!-- Contêiner para o componente dinâmico -->
      <ng-template #videoPlayerContainer></ng-template>
    </div>
  </div>
</div>