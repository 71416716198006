import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class SUpdateVerificationService {
  private progressInterval: any;
  private progress: number = 0;

  constructor(private messageService: MessageService) {}

  async verificarToast(
    virtualIndex: number,
    loadedSlides: number,
    isRequestingSlides: boolean,
    openToastCallback: () => void,
    startMonitoringVirtualIndexCallback: () => void,
    updateModalDisplayCallback: (display: boolean) => void,
    updateProgressCallback: (progress: number) => void,
    updateProgressIntervalCallback: (interval: any) => void // Callback para passar `progressInterval`
  ): Promise<void> {
    console.log('verificarToast no Serviço');

    let calcSlides = 0;

    if (virtualIndex !== 1) {
      calcSlides = loadedSlides - virtualIndex - 1;
    }

    if (calcSlides === 0) {
      startMonitoringVirtualIndexCallback();
      this.startProgressLoop(updateProgressCallback, updateProgressIntervalCallback); // Atualiza `progressInterval`
    } else {
      updateModalDisplayCallback(false);
    }
  }

  openToast(): void {
    this.messageService.clear('confirm');

    this.messageService.add({
      key: 'confirm',
      severity: 'info',
      summary: 'Carregando novos vídeos...',
      detail: '',
      sticky: true,
    });
  }

  startProgressLoop(
    updateProgressCallback: (progress: number) => void,
    updateProgressIntervalCallback: (interval: any) => void // Callback para passar `progressInterval`
  ): void {
    const totalTime = 5000; 
    const incrementInterval = 100; 
    const totalIncrements = totalTime / incrementInterval; 
    const progressIncrement = 100 / totalIncrements; 

    this.progress = 0;

    this.progressInterval = setInterval(() => {
      this.progress += progressIncrement;

      updateProgressCallback(this.progress);

      if (this.progress >= 100) {
        this.progress = 100;
        clearInterval(this.progressInterval);
      }
    }, incrementInterval);

    // Passa o `progressInterval` atualizado para o componente
    updateProgressIntervalCallback(this.progressInterval);
  }

  stopProgressLoop(): void {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      this.progressInterval = undefined;
    }
  }

   // Novo método `verificarLoadSlides` para checar a necessidade de carregar mais slides
   verificarLoadSlides(
    virtualIndex: number,
    loadedSlides: number,
    directionMove: string | undefined,
    finishFindFiles: boolean,
    isRequestingSlides: boolean
  ): boolean {
    // Verifica se a condição para carregar mais slides foi atendida
    if (
      virtualIndex >= (loadedSlides - 2)  && directionMove != 'prev' &&
      !finishFindFiles && 
      !isRequestingSlides
    ) {
      return true; // Retorna `true` para indicar que `loadMoreSlides` deve ser chamado
    }
    return false; // Retorna `false` caso `loadMoreSlides` não precise ser chamado
  }
}
