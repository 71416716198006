import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
export interface AlertChange {
  index: number;
  text?: string;
  fontColor?: string;
  backgroundColor?: string;
}
@Injectable({
  providedIn: 'root'
})
export class StateManagementService {
  private timeUpdateSubject = new BehaviorSubject<number>(0);
  constructor() {

  }
  updateVideoTime(currentTime: number): void {
    this.timeUpdateSubject.next(currentTime);
  }
  timeAtual:any;
  setTimeAtual(time:any){
    this.timeAtual = time;
  }
  // Method to set the video element and initialize the time update listener
  getVideoTime(): BehaviorSubject<number> {
    return this.timeUpdateSubject;
  }

  complete() {
    this.videoTimeSubject.complete();
  }


  // observable para tempo do vídeo
  private videoTimeSubject = new Subject<number>();


















  public productColors = new BehaviorSubject<Record<number, { comprarFontColor: string; comprarBgColor: string; adicionarFontColor: string; adicionarBgColor: string; }>>({});

  private playPauseButtonColor = new BehaviorSubject<string>('#FFFFFF');
  private progressBarColor = new BehaviorSubject<string>('#FF6347');
  // Métodos para atualizar os BehaviorSubjects
  // Por exemplo, para atualizar a cor de fundo do botão Comprar
  updateProductColors(productId: number, colors: { comprarFontColor: string; comprarBgColor: string; adicionarFontColor: string; adicionarBgColor: string; }) {
    console.log(`Atualizando cores do produto ${productId}:`, colors);
    const currentColors = this.productColors.value;
    const updatedColors = {
      ...currentColors,
      [productId]: colors
    };
    this.productColors.next(updatedColors);
  }

  getProductColors(productId: number) {
    return this.productColors.asObservable().pipe(
      map(colors => colors[productId] || { comprarFontColor: '#000000', comprarBgColor: '#FFFFFF', adicionarFontColor: '#000000', adicionarBgColor: '#FFFFFF' }),
      distinctUntilChanged()
    );
  }


  // No StateManagementService ou um serviço dedicado para o acordeão
  updateAccordionColors(fontColor: string, bgColor: string) {
    this.accordionColors.next({ fontColor, bgColor });
  }

  // Observable para as cores do acordeão
  public accordionColors = new BehaviorSubject<{ fontColor: string; bgColor: string }>({ fontColor: '#000000', bgColor: '#FFFFFF' });

  getAccordionColors() {
    return this.accordionColors.asObservable(); // Aqui, as cores do acordeão são expostas como um Observable
  }



  public linkBackgroundColor = new BehaviorSubject<string>('#FFFFFF'); // Valor inicial padrão

  updateLinkBackgroundColor(color: string) {
    this.linkBackgroundColor.next(color);
  }

  getLinkBackgroundColor(): Observable<string> {
    return this.linkBackgroundColor.asObservable();
  }


  updatePlayerControlsColors(playPauseColor: string, progressBarColor: string): void {


    this.playPauseButtonColor.next(playPauseColor);
    this.progressBarColor.next(progressBarColor);
  }

  // Métodos para obter as cores dos controles do player como Observables
  getPlayPauseButtonColor(): Observable<string> {
    return this.playPauseButtonColor.asObservable();
  }

  getProgressBarColor(): Observable<string> {
    return this.progressBarColor.asObservable();
  }


  private iconColor = new BehaviorSubject<string>('#FFFFFF'); // Cor padrão

  updateIconColor(color: string): void {
    this.iconColor.next(color);
  }

  getIconColor(): Observable<string> {
    return this.iconColor.asObservable();
  }





  // Observable para alertas de erro em live
  private alertChangeSource = new BehaviorSubject<AlertChange>({ index: -1 });
  alertChange$ = this.alertChangeSource.asObservable();
  emitAlertChange(change: AlertChange) {
    this.alertChangeSource.next(change);
  }







  private typeLiveSubject = new BehaviorSubject<boolean>(false);
  public typeLive$ = this.typeLiveSubject.asObservable();
  updateTypeLive(newTypeLive: boolean) {
    this.typeLiveSubject.next(newTypeLive);
  }

}
