import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { SwUpdate } from '@angular/service-worker';
import { UpdateService } from './services/update.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {


  constructor(
    private primengConfig: PrimeNGConfig,
    public layoutService: LayoutService,
    private swUpdate: SwUpdate, // Injete o SwUpdate aqui
    private updateService: UpdateService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  async serviceWorker() {
    this.updateService.update();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(() => {
        console.log('Checando por atualizações...');
      });
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version available. Load New Version?")) {
          window.location.reload();
        }
      });
      // Verifique se o Service Worker já está registrado, se não, registre-o
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          if (registrations.length === 0) {
            navigator.serviceWorker.register('/service-worker.js').then(registration => {
              console.log('Service Worker registrado com sucesso:', registration);
            }).catch(error => {
              //console.error('Falha ao registrar o Service Worker:', error);
            });
          } else {
            console.log('Service Worker já está registrado.');
          }
        });
      }
    }
  }

  async ngOnInit(): Promise<void> {

    this.primengConfig.ripple = true;
    await this.serviceWorker();

    //this.updateService.initialize();

    console.log('em AppComponent3');

    let domain = localStorage.getItem('domain_store');
    if (domain) {
      // Quebra o domínio pelo ponto
      let parts = domain.split('.');

      // Checa se o array 'parts' possui ao menos um elemento
      if (parts.length > 0) {
        // Pega a primeira parte do domínio
        let firstPart = parts[0];

        // Define 'store_name' no localStorage usando a primeira parte do domínio
        localStorage.setItem('store_name', firstPart);
      } 
    }

  }


}
