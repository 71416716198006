import { Component, Input, ViewChild, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'app-mobile-tooltip',
  template: `
    <div [pTooltip]="text" tooltipPosition="right" #tooltip>
      <ng-content></ng-content>
    </div>
  `,
  styles: []
})
export class MobileTooltipComponent implements AfterViewInit {
  @Input() text: string = '';
  @ViewChild('tooltip', { static: false }) tooltipEl!: ElementRef;
  @ViewChild(Tooltip) tooltipDir!: Tooltip;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.renderer.listen(this.tooltipEl.nativeElement, 'click', () => this.toggleTooltip());
  }

  toggleTooltip() {
    if (this.tooltipDir && this.tooltipEl.nativeElement) {
      const tooltipElement = this.tooltipEl.nativeElement;
      if (tooltipElement.classList.contains('p-tooltip-active')) {
        this.tooltipDir.hide();
      } else {
        this.tooltipDir.show();
      }
    }
  }
}
