import { Component, ElementRef, ViewChild, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html',
    styleUrls: ['./app.sidebar.component.scss']
})
export class AppSidebarComponent implements OnInit, OnDestroy {
    timeout: any = null;
    logoSrc: string = 'assets/Logo_claro_sem.svg'; // Default logo

    @ViewChild('menuContainer') menuContainer!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        public el: ElementRef,
        private router: Router,
        private renderer: Renderer2 // Inject Renderer2
    ) { }

    ngOnInit(): void {
        this.updateLogoSrc();
        this.layoutService.configUpdate$.subscribe(() => {
            this.updateLogoSrc();
        });
        window.addEventListener('resize', this.updateLogoSrc.bind(this));
    }

    ngOnDestroy(): void {
        window.removeEventListener('resize', this.updateLogoSrc.bind(this));
    }
    isDark: boolean = false;
    updateLogoSrc(): void {
        const layoutContainer = document.querySelector('.layout-container');
        const btnAdicionar = this.el.nativeElement.querySelector('.btn-adicionar');

        if (layoutContainer) {
            this.isDark = this.layoutService.config.colorScheme === 'dark' || this.layoutService.config.colorScheme === 'dim';
            const isSidebarActive = this.layoutService.state.sidebarActive || this.isMobile();

            if (this.isDark) {
                //this.logoSrc = 'assets/logo_slicksell_for_dark_version.svg';
                this.logoSrc = 'assets/Logo_escuro_sem.svg';
            } else {
                this.logoSrc = 'assets/Logo_claro_sem.svg';
            }

            if (!isSidebarActive) {
                this.logoSrc = 'assets/favicon.svg';
            }
        }

        if (this.layoutService.state.sidebarActive || this.isMobile()) {
            this.renderer.addClass(btnAdicionar, 'w-full');
        } else {
            this.renderer.removeClass(btnAdicionar, 'w-full');
        }
    }

    onMouseEnter() {
        if (this.isDesktop() && !this.layoutService.state.anchored) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.layoutService.state.sidebarActive = true;
            this.updateLogoSrc();
        }
    }

    onMouseLeave() {
        if (this.isDesktop() && !this.layoutService.state.anchored) {
            if (!this.timeout) {
                this.timeout = setTimeout(() => {
                    this.layoutService.state.sidebarActive = false;
                    this.updateLogoSrc();
                }, 300);
            }
        }
    }

    anchor() {
        this.layoutService.state.anchored = !this.layoutService.state.anchored;
    }

    goToLives(): void {
        const timestamp = new Date().getTime();// teste
        this.router.navigate(['/lives'], { queryParams: { openSidebar: 'true', ts: timestamp } });
    }

    // goToMyPlayer() {
    //     const domainStore = localStorage.getItem('domain_store');
    //     if (domainStore) {
    //         const url = `https://${domainStore}`;
    //         window.open(url, '_blank');
    //     } else {
    //         console.error('domain_store not found in localStorage');
    //     }
    // }

    goToMyPlayer() {
        const domainStore = localStorage.getItem('domain_store');
        if (domainStore) {
            // Extrai a parte da loja do domínio
            const subdomain = domainStore.split('.')[0];
            // Constrói a nova URL
            const url = `https://slicksell.link/${subdomain}`;
            window.open(url, '_blank');
        } else {
            console.error('domain_store not found in localStorage');
        }
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }
}
