import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  breadcrumbs: MenuItem[] = [];

  constructor(private router: Router) {
    this.setupBreadcrumbs();
  }

  setupBreadcrumbs() {
    this.updateBreadcrumbs(this.router.url); // Atualiza breadcrumbs na inicialização

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd) // Filtra apenas eventos de NavigationEnd
    ).subscribe(event => {
      const navigation = event as NavigationEnd;
      this.updateBreadcrumbs(navigation.urlAfterRedirects);
    });
  }

  updateBreadcrumbs(url: string) {
    this.breadcrumbs = []; // Limpa os breadcrumbs existentes
  
    // Remove os parâmetros de consulta da URL para correta identificação da rota
    const baseUrl = url.split('?')[0];
  
    if (baseUrl.startsWith('/slick/')) {
      this.breadcrumbs.push({ label: 'Slicks', routerLink: ['/lives'] });
      this.breadcrumbs.push({ label: 'Gerenciar Slick', routerLink: [], styleClass: 'gerenciar-slick' });
    } else if (baseUrl.startsWith('/edit_widgets/')) {
      this.breadcrumbs.push({ label: 'Widget', routerLink: ['/widgets'] });
      this.breadcrumbs.push({ label: 'Gerenciar Widget', routerLink: [], styleClass: 'gerenciar-slick' });
    }else if (baseUrl.startsWith('/create_widget')) {
      this.breadcrumbs.push({ label: 'Widget', routerLink: ['/widgets'] });
      this.breadcrumbs.push({ label: 'Criar Widget', routerLink: [], styleClass: 'gerenciar-slick' });
    }else if (baseUrl === '/lives') {
      this.breadcrumbs.push({ label: 'Slicks' });
    } else if (baseUrl === '/') {
      this.breadcrumbs.push({ label: 'Analytics', routerLink: ['/'] });
      console.log('Analytics breadcrumb added');
    } else {
      let currentRoute = this.router.routerState.snapshot.root;
      let routeUrl = '';
      while (currentRoute.children.length > 0) {
        currentRoute = currentRoute.children[0];
        if (currentRoute.routeConfig?.path) {
          routeUrl += `/${currentRoute.routeConfig.path}`;
          if (currentRoute.data['breadcrumb']) {
            this.breadcrumbs.push({
              label: currentRoute.data['breadcrumb'],
              routerLink: [routeUrl]
            });
          }
        }
      }
    }
  }
  
}
