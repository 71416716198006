import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { DataView } from 'primeng/dataview';
import { LiveShopService } from 'src/app/services/liveshop.service';
import { Product } from 'src/app/api/product';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, from, defer } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { tap, catchError, finalize, switchMap } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Asset } from 'src/app/models/liveshop';
import { FileUpload } from 'primeng/fileupload';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { formatDate } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import { UnregisterSwService } from 'src/app/services/unregister-sw/unregister-sw.service';
// import { ProductService } from 'src/app/demo/service/product.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  ValidatorFn
} from '@angular/forms';
interface TransferProgressEvent {
  transferredBytes: number;
  totalBytes?: number;
}
import { uploadData, getUrl } from 'aws-amplify/storage';

interface Severities {
  [key: string]: string;
}
interface ProgressEvent {
  transferredBytes: number;
  totalBytes: number | null; // Assumindo que pode ser null, baseado no uso de ?? 1
}
interface SlickType {
  label: string;
  value: string;
}
interface AssetsResponse {
  assets: Asset[];   // Define a propriedade 'assets' como um array de 'Asset'
  totalAssets: number; // Define 'totalAssets' como um número
}
@Component({
  selector: 'app-lives',
  templateUrl: './lives.component.html',
  styleUrls: ['./lives.component.scss']
})
export class LivesComponent implements OnInit, OnDestroy {

  products: Product[] = [];
  assets: Asset[] = [];

  sortOptions: SelectItem[] = [];

  sortOrder: number = 0;

  sortField: string = '';

  sourceCities: any[] = [];

  targetCities: any[] = [];

  orderCities: any[] = [];

  rangeDates: Date[] | null = null;

  constructor(
    //private productService: ProductService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private liveShopService: LiveShopService,
    private primengConfig: PrimeNGConfig,
    private unregisterSwService: UnregisterSwService,
    public fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.issueForm = this.fb.group({
      // exemplo: 'name': ['']
    });
  }
  media_folder: string | null = null;
  isLoading: boolean = false;
  isLoadingSkeleton: boolean = true;

  openSidebarWithDelay(): void {
    setTimeout(() => {
      console.log('Tentando abrir a sidebar com delay');
      this.openSidebar();
    }, 100); // Delay aumentado para 3 segundos para garantir que outras operações assíncronas sejam concluídas
  }
  totalAssets: number = 0;
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['openSidebar'] === 'true') {
        console.log('Parâmetro openSidebar encontrado');
        this.openSidebarWithDelay();
      }
    });

    this.isLoadingSkeleton = true;
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation({
      emptyMessage: 'Nenhum resultado encontrado' // Mensagem global para quando não houver dados
    });
    this.sendUidsToService([], 0);
    this.liveShopService.getAssets().pipe(takeUntil(this.destroy$)).subscribe({
      next: (res: AssetsResponse) => {  // Utilize a nova interface aqui
        this.assets = res.assets || [];  // Agora 'res' tem 'assets' e 'totalAssets'
        this.totalAssets = res.totalAssets;
        console.log(this.assets.length);
        console.log('Total de Assets:', this.totalAssets);

        this.filteredAssets = [...this.assets];
        this.OriginalAssets = [...this.assets];

        // Calcula a somatória inicial dos durations
        let totalDurationSeconds = this.assets.reduce((acc, asset) => {
          const assetDuration = asset.items.reduce((accInner, item) => {
            return accInner + (item.media.duration || 0); // Adiciona duration, considerando 0 para null
          }, 0);
          return acc + assetDuration;
        }, 0);

        this.isLoadingSkeleton = false;
      },
      error: (err) => {
        this.isLoadingSkeleton = false;
      }
    });

    this.liveShopService.detailSettings().pipe(takeUntil(this.destroy$)).subscribe({
      next: (settings) => {

        // listagem de settings media_folder

        const mediaFolderSetting = settings.find(s => s.key === 'media_folder');
        if (mediaFolderSetting) {
          this.media_folder = mediaFolderSetting.value;
        } else {
          console.log('media_folder not found');
        }

      },
      error: (err) => {

      }
    });





    this.itemsButtons = [
      { label: 'Exportar relatório', icon: 'pi pi-eye' },
    ];

    this.landscapeOptions = [
      { icon: 'pi pi-mobile', name: 'Vertical', value: 1 },
      { icon: 'pi pi-mobile rotate-90', name: 'Horizontal', value: 2 },
    ];
    this.valSelect1Video = this.landscapeOptions[0];
  }
  valSelect1Video?: any;
  changeLandscape() {
    if (this.valSelect1Video) {
      console.log("Seleção alterada para valor:", this.valSelect1Video.value);
    }
  }
  onSortChange(event: any) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  onFilter(dv: DataView, event: Event) {
    dv.filter((event.target as HTMLInputElement).value);
  }

  loading: boolean = true;
  filterValue: string = ''; // Armazena o valor do filtro atual
  filteredAssets!: Asset[];
  OriginalAssets!: Asset[];
  selectedType: string | null = null;
  typeOptions = [
    // { label: 'Todos', value: null },
    // { label: 'Live', value: 'live' },
    { label: 'Shorts', value: 'shorts' }
  ];
  selectedStatus: string = 'todos';

  selectedStreamStatus: string | null = null; // Armazena o status do stream selecionado


  onStreamStatusSelect() {
    this.applyFilters();
  }
  onStatusSelect() {
    this.applyFilters();
  }
  onFilterSlicks(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.applyFilters(); // Atualiza os filtros.
  }

  onDateSelect() {
    const startDate = this.rangeDates && this.rangeDates[0] ? this.rangeDates[0].getTime() : null;
    const endDate = this.rangeDates && this.rangeDates[1] ? this.rangeDates[1].getTime() : null;

    if (startDate && endDate && this.rangeDates && this.rangeDates[0] && this.rangeDates[1]) {
      this.applyFilters();
    }
  }
  onTypeSelect() {
    this.applyFilters();
  }
  date_from!: string | null;
  date_to!: string | null;
  applyFilters() {
    const now = new Date();
    const startDate = this.rangeDates && this.rangeDates[0] ? this.rangeDates[0].getTime() : null;
    const endDate = this.rangeDates && this.rangeDates[1] ? this.rangeDates[1].getTime() : null;

    if (startDate && endDate && this.rangeDates && this.rangeDates[0] && this.rangeDates[1]) {
      // Faz a chamada ao serviço com as datas formatadas
      this.date_from = this.formatDateToDDMMYYYY(this.rangeDates[0]);
      this.date_to = this.formatDateToDDMMYYYY(this.rangeDates[1]);
      this.fetchFilteredData(startDate, endDate);

      this.sendUidsToService([], 0);

    } else {
      // Se o intervalo de datas não estiver completo, aplica os outros filtros localmente
      this.filterDataLocally();
    }
  }

  formatDateToDDMMYYYY(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Janeiro é 0!
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  fetchFilteredData(startDateTimestamp: number, endDateTimestamp: number) {
    // Converte timestamps para objetos Date
    const startDate = new Date(startDateTimestamp);
    const endDate = new Date(endDateTimestamp);

    // Formata as datas
    const formattedStartDate = this.formatDateToDDMMYYYY(startDate);
    const formattedEndDate = this.formatDateToDDMMYYYY(endDate);

    // Utiliza as datas formatadas na chamada ao serviço
    this.liveShopService.getFilteredAssetsByDate(formattedStartDate, formattedEndDate).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        this.assets = res; // Dados filtrados por datas
        this.filteredAssets = [...this.assets]; // Inicia com dados filtrados por datas
        this.reapplyLocalFilters(); // Nova função para reaplicar filtros locais
      },
      error: (err) => {
        console.error('Erro ao buscar dados filtrados', err);
      }
    });
  }
  reapplyLocalFilters() {
    let totalDuration = 0; // Reseta totalDuration

    // Filtra novamente os assets com base nos filtros locais
    this.filteredAssets = this.filteredAssets.filter(asset => {
      return asset.items.filter(item => {
        // Aplica filtragem por tipo
        const type = item.media.type;
        const typeFilter =
          this.selectedType === 'live' ? (type === null || type.toLowerCase().includes('live')) :
            this.selectedType === 'shorts' ? (type !== null && type.toLowerCase() === 'shorts') : true;

        // Aplica filtragem por status do stream
        const streamStatus = item.media.stream_status;
        const streamStatusFilter =
          this.selectedStreamStatus === 'idle' ? streamStatus?.toLowerCase() === 'idle' :
            this.selectedStreamStatus === 'disabled' ? (streamStatus?.toLowerCase() === 'disabled' || streamStatus === null) :
              this.selectedStreamStatus === 'active' ? streamStatus?.toLowerCase() === 'active' : true;

        // Aplica filtragem por status de publicação
        const now = new Date();
        let dateFrom = item.schedule?.date_from ? new Date(item.schedule?.date_from) : null;
        const statusFilter =
          this.selectedStatus === 'publicado' ? item.media.status === 1 :
            this.selectedStatus === 'agendado' ? (item.media.status === 0 && (!dateFrom || dateFrom > now)) :
              this.selectedStatus === 'naoPublicado' ? item.media.status === 0 && (!dateFrom || dateFrom <= now) : true;
        console.log(this.filterValue)
        // Aplica filtragem por nome
        //const nameFilter = item.content.title.toLowerCase().includes(this.filterValue);
        const nameFilter = (item.content.title || "").toLowerCase().includes(this.filterValue.toLowerCase());


        // Verifica se o item passa por todos os critérios de filtro
        const isItemFiltered = typeFilter && streamStatusFilter && statusFilter && nameFilter;

        // Acumula a duração apenas para itens que passam pelo filtro
        if (isItemFiltered && item.media.duration) {
          totalDuration += item.media.duration;
        }

        return isItemFiltered;
      }).length > 0; // Garante que o asset seja incluído se algum item passar pelo filtro
    });

    // Atualiza o tempo total assistido formatado após filtragem
    this.contentTime = this.formatDuration(totalDuration);
  }


  filterDataLocally() {
    let totalDuration = 0; // Reseta totalDuration para cada aplicação do filtro

    // Aplica os filtros localmente, exceto por datas
    this.filteredAssets = this.assets.filter(asset => {
      return asset.items.filter(item => {
        // Aplica filtragem por tipo
        const type = item.media.type;
        const typeFilter =
          this.selectedType === 'live' ? (type === null || type.toLowerCase().includes('live')) :
            this.selectedType === 'shorts' ? (type !== null && type.toLowerCase() === 'shorts') : true;

        // Aplica filtragem por status do stream
        const streamStatus = item.media.stream_status;
        const streamStatusFilter =
          this.selectedStreamStatus === 'idle' ? streamStatus?.toLowerCase() === 'idle' :
            this.selectedStreamStatus === 'disabled' ? (streamStatus?.toLowerCase() === 'disabled' || streamStatus === null) :
              this.selectedStreamStatus === 'active' ? streamStatus?.toLowerCase() === 'active' : true;

        // Aplica filtragem por status de publicação
        const now = new Date();
        let dateFrom = item.schedule?.date_from ? new Date(item.schedule?.date_from) : null;
        const statusFilter =
          this.selectedStatus === 'publicado' ? item.media.status === 1 :
            this.selectedStatus === 'agendado' ? (item.media.status === 0 && (!dateFrom || dateFrom > now)) :
              this.selectedStatus === 'naoPublicado' ? item.media.status === 0 && (!dateFrom || dateFrom <= now) : true;

        // Aplica filtragem por nome
        console.log(this.filterValue)
        console.log(this.filterValue)
        //const nameFilter = item.content.title.toLowerCase().includes(this.filterValue);
        const nameFilter = (item.content.title || "").toLowerCase().includes(this.filterValue.toLowerCase());


        // Verifica se o item passa por todos os critérios de filtro
        const isItemFiltered = typeFilter && streamStatusFilter && statusFilter && nameFilter;

        // Acumula a duração apenas para itens que passam pelo filtro
        if (isItemFiltered && item.media.duration) {
          totalDuration += item.media.duration;
        }

        return isItemFiltered;
      }).length > 0; // Garante que o asset seja incluído se algum item passar pelo filtro
    });

    // Atualiza o tempo total assistido formatado após filtragem
    this.contentTime = this.formatDuration(totalDuration);

    // Chama collectAndSendUids se necessário após a filtragem local
    this.collectAndSendUids();
  }

  calculateTotalDuration() {
    let totalDuration = 0;
    // Lógica para calcular totalDuration...
    this.contentTime = this.formatDuration(totalDuration);
  }

  formatDuration(totalSeconds: number): string {
    // Converte total de segundos para minutos
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}h${minutes.toString().padStart(2, '0')}m`;
  }


  countLikes: number = 0;
  countEspectadores: number = 0;
  timeWatched: number = 0;
  contentTime: string | null = "0h0m";


  countLikes2: number = 0;
  countEspectadores2: number = 0;
  timeWatched2: number = 0;
  contentTime2: string | null = "0h0m";

  percentageLikes: number = 0;
  percentageEspectadores: number = 0;
  percentageWatched: number = 0;
  percentageTime: number = 0;

  collectAndSendUids() {
    const uids = this.filteredAssets.flatMap(asset =>
      asset.items.map(item => item.content.uid)
    );

    // Agora, uids é um array de todos os UIDs dos items filtrados
    //console.log(uids)
    this.sendUidsToService(uids, 1);
  }

  formatDateToYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  sendUidsToService(uids: string[], n: number) {
    this.loading = true;
    const slickIdToSend = undefined; // slickId que será enviado, se houver

    if (this.date_from != null && this.date_to != null) {
      console.log('entrou na chamada com datas setadas');

      // Aqui, formate as datas corretamente
      const formattedDateFrom = this.formatDateToYYYYMMDD(this.convertStringToDate(this.date_from));
      const formattedDateTo = this.formatDateToYYYYMMDD(this.convertStringToDate(this.date_to));

      // Chamada à nova request de analytics
      this.liveShopService.getAnalytics(formattedDateFrom, formattedDateTo, slickIdToSend).pipe(takeUntil(this.destroy$)).subscribe({
        next: (res) => {
          console.log('req 1');
          const overviewData = res.player_analytics.overview_data;

          // Mapeia os campos do novo formato
          this.countLikes = overviewData.likes;
          this.countEspectadores = overviewData.viewers.mobile + overviewData.viewers.desktop;
          this.timeWatched = overviewData.avg_watched_time;
          this.contentTime = this.formatDuration(overviewData.avg_slicks_per_viewer); // ou qualquer campo apropriado
          let contentTimeTmp = overviewData.avg_slicks_per_viewer;

          if (this.date_from != null && this.date_to != null) {
            console.log('req 2');
            let dateFrom = this.convertStringToDate(this.date_from);
            let dateTo = this.convertStringToDate(this.date_to);

            // Subtrai 30 dias
            dateFrom.setDate(dateFrom.getDate() - 30);
            dateTo.setDate(dateTo.getDate() - 30);

            let newDateFrom = this.formatDateToYYYYMMDD(dateFrom);
            let newDateTo = this.formatDateToYYYYMMDD(dateTo);

            // Segunda requisição para o período anterior
            this.liveShopService.getAnalytics(newDateFrom, formattedDateFrom, slickIdToSend).pipe(takeUntil(this.destroy$)).subscribe({
              next: (res) => {
                const previousOverviewData = res.player_analytics.overview_data;

                // Mapeia os campos do novo formato para o período anterior
                this.countLikes2 = previousOverviewData.likes;
                this.countEspectadores2 = previousOverviewData.viewers.mobile + previousOverviewData.viewers.desktop;
                this.timeWatched2 = previousOverviewData.avg_watched_time;
                this.contentTime2 = this.formatDuration(previousOverviewData.avg_slicks_per_viewer);

                // Calcula as porcentagens de diferença
                this.percentageLikes = this.calculatePercentageDifference(this.countLikes, this.countLikes2);
                this.percentageEspectadores = this.calculatePercentageDifference(this.countEspectadores, this.countEspectadores2);
                this.percentageWatched = this.calculatePercentageDifference(this.timeWatched, this.timeWatched2);
                this.percentageTime = this.calculatePercentageDifference(contentTimeTmp, previousOverviewData.avg_slicks_per_viewer);

                this.loading = false;
              },
              error: (err) => {
                console.error('Erro ao buscar dados do período anterior:', err);
                this.loading = false;
              }
            });
          }
        },
        error: (err) => {
          console.error('Erro ao buscar dados:', err);
          this.loading = false;
        }
      });
    } else {
      // Caso não haja datas, faça uma chamada padrão sem data
      this.liveShopService.getAnalytics('', '', slickIdToSend).pipe(takeUntil(this.destroy$)).subscribe({
        next: (res) => {
          console.log('req 3');
          const overviewData = res.player_analytics.overview_data;

          // Mapeia os campos do novo formato
          this.countLikes = overviewData.likes;
          this.countEspectadores = overviewData.viewers.mobile + overviewData.viewers.desktop;
          this.timeWatched = overviewData.avg_watched_time;
          this.contentTime = this.formatDuration(overviewData.avg_slicks_per_viewer);

          this.loading = false;
        },
        error: (err) => {
          console.error('Erro ao buscar dados:', err);
          this.loading = false;
        }
      });
    }
  }


  calculatePercentageDifference(first: number, second: number): number {
    if (first === 0) {
      return second !== 0 ? 100 : 0; // Ajuste conforme a necessidade para casos onde o primeiro valor é 0
    }
    return ((first - second) / first) * 100;
  }
  convertStringToDate(dateStr: string): Date {
    const [day, month, year] = dateStr.split('/').map(num => parseInt(num, 10));
    return new Date(year, month - 1, day);
  }
  searchTerm: string = '';
  clearFilter() {
    this.rangeDates = null;
    this.selectedStatus = 'todos';
    this.selectedStreamStatus = null;
    this.selectedType = null;
    this.searchTerm = '';
    this.sendUidsToService([], 0);

    this.filteredAssets = this.OriginalAssets;
  }

  getActionMenu(id: any, uid: any, title: string) {
    return [
      { label: 'Editar', icon: 'pi pi-file-edit', command: () => this.editSlick(uid) },
      { label: 'Fixar em Link da Bio', icon: 'bi bi-pin-angle-fill', command: () => this.fixarVideo(uid) },
      { label: 'Compartilhar', icon: 'pi pi-share-alt', command: () => this.share(uid) },
      { label: 'Analytics', icon: 'bi bi-graph-up', command: () => this.analytics(uid, title) },
      { label: 'Usar como template', icon: 'bi bi-clipboard', command: () => this.template(uid) },
      { label: 'Deletar', icon: 'pi pi-trash', command: () => this.confirmDeletion(id) },
    ];
  }
  isSharing: boolean = false;

  share(uid: string) {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const domainStore = localStorage.getItem('domain_store');

    // Verificar e obter o subdomínio
    let subdomain = '';
    if (domainStore) {
      const parts = domainStore.split('.');
      if (parts.length >= 2) {
        subdomain = parts[0];
      }

      subdomain = domainStore.split('.')[0];
    }

    // Construir a URL de compartilhamento
    const shareUrl = `slicksell.link/${subdomain}` + '?slick_id=' + uid;

    if (navigator.share) {
      if (this.isSharing) {
        console.warn('Já existe uma operação de compartilhamento em andamento.');
        return;
      }

      this.isSharing = true;

      navigator.share({
        title: 'Compartilhar Link',
        url: 'https://' + shareUrl
      }).then(() => {
        console.log('Link compartilhado com sucesso!');
      }).catch(err => {
        console.error('Erro ao compartilhar:', err);
      }).finally(() => {
        this.isSharing = false;
      });
    } else {
      console.error('A Web Share API não é suportada neste navegador.');
    }
  }


  analytics(uid: string, title: string) {
    this.router.navigate(['/'], { queryParams: { slick_id: uid, title: title } });
  }
  displayLoading: boolean = false;
  fixarVideo(uid: string) {
    this.pendingUid = uid;
    this.displayConfirmDialog = true;
  }
  confirmFixarVideo() {
    this.displayLoading = true;
    this.displayConfirmDialog = false;
    this.issueForm = this.fb.group({
      pinned: true,
    });

    this.liveShopService.pinarSlick(this.issueForm.value, this.pendingUid).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        console.log(res);
        this.displayLoading = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Slick fixado',
          detail: 'O Slick foi fixado com sucesso!'
        });

        // Atualize os assets para refletir a mudança
        let pinnedItem;
        this.assets.forEach(asset => {
          asset.items.forEach(item => {
            if (item.content.uid === this.pendingUid) {
              item.media.pin = 1;
              pinnedItem = item;
            } else {
              item.media.pin = 0;
            }
          });
        });

        // Remover o item fixado da sua posição atual
        if (pinnedItem) {
          this.assets.forEach(asset => {
            asset.items = asset.items.filter(item => item.content.uid !== this.pendingUid);
          });

          // Inserir o item fixado na primeira posição
          this.assets[0].items.unshift(pinnedItem);
        }

        // Atualize os assets filtrados
        this.filteredAssets = [...this.assets];

      },
      error: (error) => {
        this.displayLoading = false;
        let message = '';
        if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 404) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }

        if (message) {
          this.showErrorDialog(message);
        }
      }
    });
  }

  private pendingUid: string = '';
  displayConfirmDialog: boolean = false;
  // Método para abrir o diálogo de confirmação

  uidSeletedTemplate: string = '';
  template(uid: string) {
    this.uidSeletedTemplate = uid;
    this.displayDialogTemplate = !this.displayDialogTemplate;
  }
  editSlick(uid: string) {
    this.redirectToSlickDetail(uid);
  }
  redirectToSlickDetail(uuid: string): void {
    this.router.navigate(['/slick', uuid]);
  }
  displayDialogSidebar: boolean = false;
  confirmDeletion(n: number) {

    this.confirmationService.confirm({
      message: 'Você realmente deseja remover este slick?',
      header: 'Confirmação de Exclusão',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.displayDialogSidebar = true;
        this.deletarSlick(n);
      },
      reject: () => {
      }
    });
  }
  // deletarSlick(n: number) {

  // }
  displayDialog: boolean = false;
  deletarSlick(n: number): void {
    this.displayDialog = true;
    this.liveShopService.DeleteSlick(n).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        this.filteredAssets.forEach((asset, index) => {
          this.filteredAssets[index].items = asset.items.filter(item => item.content.id !== n);
        });

        //this.filteredAssets = this.filteredAssets.filter(asset => asset.items.length > 0);

        // Atualize outras listas, se necessário
        this.assets = [...this.filteredAssets];
        this.OriginalAssets = [...this.filteredAssets];


        // Exiba uma mensagem de sucesso
        this.messageService.add({
          severity: 'success',
          summary: 'Slick Deletado',
          detail: 'O slick foi deletado com sucesso!'
        });
        this.displayDialogSidebar = false;
        this.displayDialog = false;

      },
      error: (err) => {
        // Exiba uma mensagem de erro
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Erro ao deletar slick.'
        });
        this.displayDialog = false;
      }
    });
  }

  getSeverity(label: number): string {
    const severities: Severities = {
      'Enviado': 'success',
      'Parcialmente Enviado': 'warning',
      'Não Enviado': 'info',
      'Pronto p/ Retirada': 'primary',
      'Devolvido': 'danger',
    };
    return severities[label] || 'info';
  }
  getSeverityType(type: string | null): string {
    if (type === 'shorts' || type === 'live' || type === null) {
      return 'custom-white'; // Retornamos um identificador personalizado para a cor branca
    } else {
      return ''; // Retorna um valor padrão caso não se encaixe nas condições (ajustável conforme necessário)
    }
  }

  statusOptions = [
    { label: 'Todos', value: 'todos' },
    { label: 'Publicado', value: 'publicado' },
    { label: 'Não Publicado', value: 'naoPublicado' },
    { label: 'Agendado', value: 'agendado' }
  ];
  getSeverityStatusDropdown(status: any): string {
    switch (status) {
      case 'publicado':
        return 'success';
      case 'naoPublicado':
        return 'warning';
      case 'agendado':
        return 'danger';
      default:
        return '';
    }
  }
  formatDurationPill(duration: number | null, stream_status: string | null, type: string): string {
    if (duration === null || duration == 0 || type === 'live') {

      if (stream_status === null) {
        return 'Ativo';
      }

      if (stream_status !== null || duration == 0 || type === 'live') {

        if (stream_status == 'idle') {
          return 'Ativo';
        }
        if (stream_status == 'disabled') {
          return 'Desativado';
        }
        if (stream_status == 'active') {
          return 'Ao vivo';
        }
        if (stream_status == '') {
          return 'Ativo';
        }

      }

    }

    // Se duration é null e não temos um stream_status válido, retorna "-"
    if (duration === null) {
      return "-";
    }

    if (type !== 'live') {
      let hours = Math.floor(duration / 3600);
      let minutes = Math.floor((duration % 3600) / 60);

      if (hours == 0 && minutes == 0) {
        minutes = 1;
      }
      return `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}m`;
    }
    if (stream_status == 'idle') {
      return 'Ativo';
    }
    if (stream_status == 'disabled') {
      return 'Desativado';
    }
    if (stream_status == 'active') {
      return 'Ao vivo';
    }
    return "-";
  }
  streamStatusOptions = [
    { label: 'Todos', value: null },
    { label: 'Ativo', value: 'idle' },
    { label: 'Desabilitado', value: 'disabled' },
    { label: 'Ao vivo', value: 'active' }
  ];
  getSeverityStreamStatusDropdown(status: any): string { // active desabilitado
    console.log('DROP: ' + status);
    switch (status) {
      case 'publicado':
        return 'success';
      case 'naoPublicado':
        return 'warning';
      case 'agendado':
        return 'danger';
      default:
        return '';
    }
  }
  // Exemplo de função getSeverityStatus statusOptions
  getSeverityStatus(status: number, dateFromString?: string): string {
    const now = new Date(); // modificação
    let dateFrom = dateFromString ? new Date(dateFromString) : null;

    // Ajustar para a mesma zona horária, se necessário
    // Por exemplo, converter dateFrom para a zona horária local, se estiver em UTC
    // Isso depende de como você quer lidar com as zonas horárias

    if (status === 1) {
      //console.log('Returning publicado');
      return 'success';
    } else if (status === 0 && (!dateFrom || dateFrom > now)) {
      //console.log('Returning agendado');
      return 'danger';
    } else if (status === 0) {
      //console.log('Returning não publicado');
      return 'warning';
    } else {
      //console.log('Returning default');
      return ''; // Retorna um valor padrão caso não se encaixe nas condições
    }
  }

  // Função para converter status numérico para texto
  getStatusText(status: number, dateFrom?: Date): string {
    const now = new Date();

    if (status === 1) {
      return 'Publicado';
    } else if (status === 0 && dateFrom && new Date(dateFrom) > now) {
      return 'Agendado';
    } else if (status === 0) {
      return 'Não Publicado';
    } else {
      return 'Desconhecido'; // ou qualquer valor padrão adequado
    }
  }



  getIconDuration(duration: number | null, stream_status: string | null): string {
    if (duration === null) {

      if (stream_status === null) {
        return '';
      }

      if (stream_status !== null) {

        if (stream_status == 'idle') {
          return '';
        }
        if (stream_status == 'disabled') {
          return '';
        }
        if (stream_status == 'active') {
          return 'pi pi-circle-fill custom-color';
        }

      }

    }

    // Se duration é null e não temos um stream_status válido, retorna "-"

    return 'pi pi-play';
  }
  getIconType(type: string | null): string {
    if (!type) {
      return ''; // Retorna o ícone padrão se type for null
    }

    // Normaliza o tipo para minúsculas para simplificar a comparação
    const normalizedType = type.toLowerCase();

    switch (normalizedType) {
      case 'live':
        return 'pi pi-video'; // Retorna o ícone para live
      case 'shorts':
        return 'pi pi-fw pi-chevron-circle-right'; // Retorna o ícone para shorts
      default:
        return ''; // Retorna um ícone padrão se nenhum caso corresponder
    }
  }


  timeoutId: any = null;
  shouldPlay = false;

  startVideo(videoElement: HTMLVideoElement) {
    this.shouldPlay = true;
    // Limpa um timeout existente se houver
    clearTimeout(this.timeoutId);

    // Define um novo timeout para tocar o vídeo
    this.timeoutId = setTimeout(() => {
      this.playVideo(videoElement);
    }, 100);  // atraso de 100ms
  }

  resetVideo(videoElement: HTMLVideoElement) {
    this.shouldPlay = false;
    clearTimeout(this.timeoutId); // Cancela o timeout de play se ainda estiver pendente
    videoElement.pause();
    videoElement.currentTime = 0;
    videoElement.load();
  }

  playVideo(videoElement: HTMLVideoElement) {
    if (this.shouldPlay) {
      videoElement.play().catch(e => {
        console.error("Erro ao tentar reproduzir o vídeo:", e);
      });
    }
  }

  displaySidebar: boolean = false;
  openSidebar() {
    this.displaySidebar = true;
    this.cdr.detectChanges();
    console.log('displaySidebar:', this.displaySidebar);
    this.valSelect1Video = this.landscapeOptions[0];
  }
  itemsButtons: MenuItem[] = [];
  slickTitle?: string = '';
  slickDesc?: string = '';
  slickTitleCopy?: string = '';
  slickDescCopy?: string = '';



  // slick type
  slickTypes = [
    { label: 'Shorts', value: 'shorts' },
    // { label: 'Live', value: 'live' }
  ];

  // Modelo vinculado ao dropdown para armazenar a seleção atual
  selectedSlickType?: SlickType;


  onSelectSlick(event: any) {
    console.log(event);
  }
  // slick type
  showDeleteButton: boolean = false;
  invoiceSelected: boolean = false;
  invoiceSelectedCover: boolean = false;
  typeLive: boolean = false;
  onTypeChange(event: any) {
    console.log('Evento completo:', event);
    console.log('Valor selecionado:', event.value.value);
    this.typeLive = event.value.value === 'shorts';

    if (this.selectedSlickType) {
      this.hasError2 = false;
    } else {
      this.hasError2 = true;
    }
  }
  videoPreviewUrl: string | null = null;
  duration: number = 0;
  getFileExtension(contentType: string): string {
    // Mapear o tipo MIME para a extensão do arquivo
    const extensionMap: { [key: string]: string } = {
      'video/mp4': 'mp4',
      'video/quicktime': 'mov',
      // Adicione mais mapeamentos de tipos MIME conforme necessário
    };

    // Obter a extensão correspondente ao tipo MIME
    return extensionMap[contentType] || '';
  }
  fileExtension?: string;
  hasSizeVideoError: boolean = false;
  hasTypeVideoError: boolean = false;


  onFileSelect(event: any): void {
    this.invoiceSelected = true;
    this.hasError3 = false;
    this.hasSizeVideoError = false;
    this.hasTypeVideoError = false;

    if (event.files && event.files[0]) {
      this.videoFile = event.files[0]; // Atribuir o arquivo selecionado à variável videoFile

      if (this.videoFile) {
        // Verificar o tamanho do arquivo (máximo 400 MB)
        if (this.videoFile.size > 400 * 1024 * 1024) {
          this.hasSizeVideoError = true;
          this.videoPreviewUrl = null;
          this.invoiceSelected = false;
          this.videoFile = null; // Resetar videoFile se o tamanho exceder o limite
          return;
        }

        // Verificar o tipo do arquivo
        const allowedTypes = ['video/mp4', 'video/quicktime', 'audio/mpeg', 'audio/wav', 'video/webm', 'video/vnd.avi', 'video/x-ms-wmv', 'video/x-matroska', 'video/x-flv', 'video/mpeg', 'video/3gpp', 'video/x-m4v'];
        if (!allowedTypes.includes(this.videoFile.type)) {
          this.hasTypeVideoError = true;
          this.videoPreviewUrl = null;
          this.invoiceSelected = false;
          this.videoFile = null; // Resetar videoFile se o tipo não for permitido
          return;
        }

        // Criar uma URL de objeto para o vídeo
        this.videoPreviewUrl = URL.createObjectURL(this.videoFile);

        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        const contentType: string = this.videoFile ? this.videoFile.type : '';
        this.fileExtension = this.getFileExtension(contentType);

        videoElement.onloadedmetadata = () => {
          this.duration = videoElement.duration;
          console.log(`A duração do vídeo é: ${this.duration} segundos.`);
        };

        videoElement.onerror = () => {
          console.error('Erro ao carregar o vídeo.');
        };

        videoElement.src = this.videoPreviewUrl;
        videoElement.load();
      }
    } else {
      this.videoPreviewUrl = null;
      this.invoiceSelected = false;
      this.videoFile = null; // Resetar videoFile se nenhum arquivo for selecionado
    }
  }


  removeVideo(event: MouseEvent): void {
    event.stopPropagation();
    this.videoPreviewUrl = '';
    this.invoiceSelected = false;
  }

  onFileSelectCover(event: any): void {
    this.invoiceSelectedCover = true;
    this.hasError3 = false;
    if (event.files && event.files.length > 0) {
      const file = event.files[0];
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          //this.invoiceSelected = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result); fileExtension
        };
        reader.readAsDataURL(file);
      }
    }
  }

  @ViewChild('fileUpload') fileUpload!: FileUpload;
  myUploadMethod(event: any) {
    console.log('chamou myUploadMethod');
    const formData: FormData = new FormData();

    // Vou assumir que você quer enviar o primeiro arquivo selecionado
    const file = event.files[0];
    formData.append('mime', file, file.name);

    // O campo 'folder' é fixo e deve ser enviado junto, como você mostrou no Postman
    formData.append('folder', 'thumbnail');


  }

  closeSidebar() {
    this.displaySidebar = false;
  }

  //tags = ['Água', 'Terra', 'Ar']; // Inicialize com as tags desejadas

  tags = [
    { label: 'Novo', value: 'novo' },
    { label: 'Atual', value: 'atual' },
    { label: 'Antigo', value: 'antigo' }
  ];
  selectedTags: string[] = [];
  tagsString: string = '';
  updateTagsString() {
    this.tagsString = this.selectedTags.join(',');
  }
  publicarAgora: boolean = true;
  publicarAgoraCopy: boolean = true;
  armazenarEvento: boolean = false;
  simular() {

  }
  dataSelecionada?: Date;
  dataSelecionadaCopy?: Date;
  minDate?: Date;
  disabledDates: Date[] = [];
  onDateChange(event: Date | null) {
    if (event === null) {
      console.log(null); // Data não está selecionada
      return null;
    } else {
      // Formate a data ao receber uma nova seleção
      //const formattedDate = formatDate(event, 'yyyy-MM-dd HH:mm:ss', this.locale);
      if (this.dataSelecionada) {
        const formattedDate = formatDate(this.dataSelecionada, 'yyyy-MM-dd HH:mm:ss', 'en-US');
        console.log(formattedDate); // "2023-05-20 02:26:03" ou outra data formatada
        return formattedDate;
      } else {
        console.log('Nenhuma data selecionada');
        return null;
      }



    }
  }

  onDateChangeCopy(event: Date | null) {
    if (event === null) {
      console.log(null); // Data não está selecionada
      return null;
    } else {
      // Formate a data ao receber uma nova seleção
      //const formattedDate = formatDate(event, 'yyyy-MM-dd HH:mm:ss', this.locale);
      if (this.dataSelecionadaCopy) {
        const formattedDate = formatDate(this.dataSelecionadaCopy, 'yyyy-MM-dd HH:mm:ss', 'en-US');
        console.log(formattedDate); // "2023-05-20 02:26:03" ou outra data formatada
        return formattedDate;
      } else {
        console.log('Nenhuma data selecionada');
        return null;
      }



    }
  }
  // Método para remover uma tag
  // onChipRemove(tagToRemove: string) {
  //   this.tags = this.tags.filter(tag => tag !== tagToRemove);
  // }
  hasError1: boolean = false;
  hasError1Copy: boolean = false;
  hasError2Copy: boolean = false;
  hasError2: boolean = false;
  hasError3: boolean = false;
  onInputChange() {
    this.hasError1 = false;
  }
  // onTypeChange() {
  //   this.hasError1 = false;
  //   this.erro1 = false;
  // }
  uploadProgress: number = 0;
  uploadProgressVideo: number = 0;
  videoFile: File | null = null;
  imageFile: File | null = null;
  issueForm: FormGroup;
  uploadProgressBar: boolean = false;
  loadingBtn: boolean = false;
  uploadOn: boolean = false;
  mediaThumbnail?: string;
  mediaSrc?: string;


  tmpFileName?: string;
  tmpMediaName?: string;
  // Função para remover o SW e limpar caches
  async removeServiceWorkerAndCaches(): Promise<void> {
    if ('serviceWorker' in navigator) {
      try {
        // Força a atualização do SW (se houver um novo)
        const registrations = await navigator.serviceWorker.getRegistrations();
        // for (const registration of registrations) {
        //   registration.update();
        // }

        // Desregistra o service worker
        for (const registration of registrations) {
          const success = await registration.unregister();
          if (success) {
            console.log('Service worker unregistered successfully');
          }
        }

        // Limpa os caches
        const cacheNames = await caches.keys();
        for (const name of cacheNames) {
          await caches.delete(name);
        }
        console.log('All caches deleted successfully');

        // Força o Bypass for network, removendo o controller do SW
        if (navigator.serviceWorker.controller) {
          console.log('Service worker still controlling, applying bypass...');
          navigator.serviceWorker.controller.postMessage({ action: 'skipWaiting' });
        }

      } catch (error) {
        console.error('Error while unregistering service worker or clearing caches:', error);
      }
    }
  }
  async uploadSave() {
    this.uploadProgressVideo = 0;
    this.uploadProgress = 0;
    console.log('chamou certo aqui');

    if (!this.selectedSlickType) {
      this.hasError2 = true;
    }
    if (!this.slickTitle || this.slickTitle.trim() === '') {
      this.hasError1 = true;
    }

    if (!this.hasError1 && !this.hasError2) {
      if (this.selectedSlickType?.value === 'shorts' && !this.invoiceSelected) {
        this.hasError3 = true;
        return;
      }

      // Remover Service Worker
      //await this.removeServiceWorkerAndCaches();  // Isso garante que o SW seja removido antes

      // Agora, prosseguir com o upload
      this.loadingBtn = true;
      const uploadStatus = {
        video: false, // Estado de conclusão do upload do vídeo
        image: false, // Estado de conclusão do upload da imagem
      };

      // Upload de vídeo
      if (this.selectedSlickType?.value === 'shorts' && this.videoFile) {
        console.log('tem vídeo');
        const contentType = this.getContentType(this.fileExtension!) ?? '.mp4';
        this.tmpMediaName = this.generateUID();

        try {
          const videoUrl = await this.uploadFile(this.videoFile, `media/${this.media_folder}/${this.tmpMediaName}.${this.fileExtension ?? '.mp4'}`, contentType, 1);
          if (!videoUrl.includes('https://d2434dp583bttd.cloudfront.net/public/')) {
            this.mediaSrc = `https://d2434dp583bttd.cloudfront.net/public/${videoUrl}`;
          } else {
            this.mediaSrc = videoUrl;  // Atualiza a URL do vídeo
          }
          uploadStatus.video = true; // Marca o upload do vídeo como concluído
        } catch (error: any) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Erro no upload do vídeo: ' + error.message
          });
          uploadStatus.video = false;
        }
      } else {
        uploadStatus.video = true; // Se não há vídeo, marca como concluído
      }

      // Upload de imagem
      if (this.imageFile) {
        console.log('tem capa novo');
        this.tmpFileName = this.generateUID();
        try {
          const imageUrl = await this.uploadFile(this.imageFile, `media/${this.media_folder}/${this.tmpFileName}.jpeg`, 'image/jpeg', 2);

          if (!imageUrl.includes('https://d2434dp583bttd.cloudfront.net/public/')) {
            this.mediaThumbnail = `https://d2434dp583bttd.cloudfront.net/public/${imageUrl}`;
          } else {
            this.mediaThumbnail = imageUrl; // Atualiza a URL da imagem
          }
          uploadStatus.image = true; // Marca o upload da imagem como concluído
        } catch (error: any) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Erro no upload da imagem: ' + error.message
          });
          uploadStatus.image = false;
        }
      } else {
        uploadStatus.image = true; // Se não há imagem, marca como concluído
      }

      // Verifica se ambos os uploads foram concluídos
      if (uploadStatus.video && uploadStatus.image) {
        this.saveSlicks();
        this.loadingBtn = false;
      }
    } else {
      if (this.selectedSlickType?.value === 'shorts' && !this.invoiceSelected) {
        this.hasError3 = true;
      }
      return;
    }
  }

  async uploadFile(file: File, storageKey: string, contentTypeSend: string, bar: number): Promise<string> {
    console.log('Chamou uploadFile');

    try {
      const uploadTask = uploadData({
        key: storageKey,
        data: file,
        options: {
          accessLevel: 'guest',

          contentType: contentTypeSend,
          onProgress: (progressEvent) => {
            const progress = (progressEvent.transferredBytes / (progressEvent.totalBytes ?? 1)) * 100;
            let roundedProgress = Math.round(progress);

            console.log(`Progresso atual: ${roundedProgress}%`);
            if (bar === 2) {
              this.uploadProgress = roundedProgress;
            } else if (bar === 1) {
              this.uploadProgressVideo = roundedProgress;
            }
          }
        }
      });

      // Aguarda o resultado do upload
      await uploadTask.result;

      console.log('Upload concluído com sucesso.');
      return storageKey;  // Retorna o storageKey como resultado

    } catch (error) {
      console.error('Erro no upload:', error);
      throw error;  // Lança o erro para ser tratado onde a função for chamada
    }
  }





  checkUploadsAndSave(uploadStatus: any) {
    // Verifica se ambos os uploads (vídeo e imagem) foram concluídos
    if (uploadStatus.video && uploadStatus.image) {
      console.log('Todos os uploads foram concluídos');
      // Aguarda até que this.uploadProgressVideo atinja 100%
      if (uploadStatus.video) {
        console.log('Todos os uploads foram concluídos');
        let intervalStartedAt = Date.now();
        const interval = setInterval(() => {

          const elapsedTime = Date.now() - intervalStartedAt; // Calcula quanto tempo passou desde o início
          console.log('Tempo passado: ' + elapsedTime + ' ms');

          // Define um timeout para chamar saveSlicks após 3 segundos

          // if (this.selectedSlickType?.value == 'shorts') {
          //   if (this.uploadProgressVideo >= 99) {
          //     console.log('Todos os uploads foram concluídos');
          //     clearInterval(interval); // Para o intervalo
          //     console.log('Upload de vídeo concluído');
          //     //if (!uploadStatus.video) {
          //     this.saveSlicks(); // Chama o método para salvar após a conclusão dos uploads
          //     //}
          //     this.loadingBtn = false; // Atualiza o estado do botão, se necessário
          //   } 
          // }

          // if (this.uploadProgress === 100) {
          //   console.log('Todos os uploads foram concluídos');
          //   clearInterval(interval); // Para o intervalo
          //   console.log('Upload de vídeo concluído');
          //   this.saveSlicks(); // Chama o método para salvar após a conclusão dos uploads
          //   this.loadingBtn = false; // Atualiza o estado do botão, se necessário
          // }

          console.log(this.uploadProgressVideo)
          //if(this.uploadProgressVideo)

        }, 100); // Verifica a cada segundo
      } else {
        this.saveSlicks();
        this.loadingBtn = false;
      }

    } else {
      this.saveSlicks();
    }
  }
  generateUID() {
    return uuidv4();
  }

  resetFormCreator() {
    this.slickTitle = undefined;
    this.slickDesc = undefined;
    this.selectedSlickType = undefined;
    this.selectedTags = [];
    this.updateTagsString();
    this.publicarAgora = true;
    this.armazenarEvento = false;
    this.dataSelecionada = undefined;
    this.imageSelected = false;
    this.imageFile = null;
    this.videoPreviewUrl = null;
    this.invoiceSelected = false;
    this.typeLive = false;
    this.videoFile = null;
  }

  replaceDomain(url: string | undefined): string | undefined {
    const s3Domain = 'https://krabo163508-dev.s3.sa-east-1.amazonaws.com';
    const cloudFrontDomain = 'https://d2434dp583bttd.cloudfront.net';
    if (url && url.includes(s3Domain)) {
      return url.replace(s3Domain, cloudFrontDomain);
    }
    return url; // Retorna a URL original, que pode ser undefined
  }



  saveSlicks() {
    



    this.issueForm = this.fb.group({
      "widget": this.fb.array([
        this.fb.group({
          "assets": this.fb.group({
            "items": this.fb.array([
              this.fb.group({
                "schedule": this.fb.group({
                  "date_from": formatDate(this.dataSelecionada || new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
                  "date_to": null
                }),
                "media": this.fb.group({
                  "type": this.selectedSlickType?.value,
                  "src": this.replaceDomain(this.mediaSrc),
                  "duration": this.duration,
                  "thumbnail": this.replaceDomain(this.mediaThumbnail),
                  "aspect_ratio": this.valSelect1Video.value,
                  "status": this.publicarAgora ? 1 : 0
                }),
                "content": this.fb.group({
                  "title": this.slickTitle,
                  "description": this.slickDesc,
                  "cta": this.fb.group({
                    "type": 0,
                    "style": {
                      "size": {
                        "width": null,
                        "height": null
                      },
                      "padding": {
                        "top": null,
                        "right": null,
                        "bottom": null,
                        "left": null
                      },
                      "colors": {
                        "background": null,
                        "font": null
                      },
                      "position": null
                    },
                    "components": {
                      "custom": {
                        "title": "",
                        "redirect_url": ""
                      },
                      "whatsapp": {
                        "phone": ""
                      },
                      "products": {
                        "items": []
                      }
                    }
                  })
                }),
                extra: this.fb.group({ // Início do FormGroup 'extra'
                  player: this.fb.group({ // FormGroup 'player' dentro de 'extra'
                    time_to_pitch: [0],
                    time_to_pitch_value: [],
                    player_color: [],
                    progress_bar_color: [],
                    auto_play: [0],
                    fake_bar: [0],
                  }),
                  "tags": this.tagsString
                })
              })
            ])
          })
        })
      ])
    });

    this.liveShopService.saveSlick(this.issueForm.value).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        this.loadingBtn = false;
        this.uploadOn = false;
        if (!this.uploadOn) {
          // zerar form
          this.resetFormCreator();

        }
        //this.unregisterSwService.registerServiceWorker();
        // Exiba uma mensagem de sucesso
        this.messageService.add({
          severity: 'success',
          summary: 'Slick Salvo',
          detail: 'O slick foi salvo com sucesso!'
        });
        this.displayDialogSidebar = false;
        // Router
        if (res.widget && res.widget.length > 0 && res.widget[0].assets.items.length > 0 && res.widget[0].assets.items[0].content.uid) {
          this.redirectToSlickDetail(res.widget[0].assets.items[0].content.uid);
        } else {
          // Exiba uma mensagem de erro
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Erro ao criar slick.'
          });
        }
      },
      error: (err) => {

        this.uploadOn = false;
        this.loadingBtn = false;
        if (err.status === 400) {
          this.displayLimitDialog = true;
          this.liveShopService.postToExternalEndpoint(this.media_folder ? this.media_folder : '', this.tmpMediaName ? this.tmpMediaName : '').subscribe({
            next: (res) => {
              console.log('Post to external endpoint successful', res);
            },
            error: (error) => {
              console.error('Error posting to external endpoint', error);
            }
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Erro ao criar slick.'
          });
        }
        this.displayDialog = false;
        this.displayDialogSidebar = false;
      }
    });
  }
  displayLimitDialog: boolean = false;
  upgradePlan() {
    this.displayLimitDialog = false;
    this.router.navigate(['/upgrade']);
  }
  closeLimitDialog() {
    this.displayLimitDialog = false;
  }
  landscapeOptions: any[] = [];
  getContentType(fileExtension: string): string {
    const contentTypeMap: { [key: string]: string } = {
      'mp4': 'video/mp4',
      'mov': 'video/quicktime',
      'mp3': 'audio/mpeg',
      'wav': 'audio/wav',
      'webm': 'video/webm',
      'avi': 'video/vnd.avi',
      'wmv': 'video/x-ms-wmv',
      'mkv': 'video/x-matroska',
      'flv': 'video/x-flv',
      'mpeg': 'video/mpeg',
      'mpg': 'video/mpeg',
      '3gp': 'video/3gpp',
      'm4v': 'video/x-m4v',
    };
    return contentTypeMap[fileExtension.toLowerCase()] || ''; // Ajuste para normalizar a extensão para minúsculas
  }

  uploadProgress$?: Observable<number>;







  imageSelected: boolean = false;
  imagePreviewUrl: string | null = null;
  showImageDeleteButton: boolean = false;
  // Supondo que `hasErrorImage` seja a sua variável para controle de erro de imagem
  hasErrorImage: boolean = false;
  erroTamanhoImagem: boolean = false;
  erroTipoImagem: boolean = false;
  // Método chamado quando um arquivo de imagem é selecionado
  onImageSelect(event: any): void {
    this.erroTamanhoImagem = false;
    this.erroTipoImagem = false;
    if (event.files && event.files.length > 0) {
      this.imageFile = event.files[0]; // Atribuir o arquivo selecionado à variável imageFile

      // Verificar o tamanho do arquivo (máximo 1MB)
      if (this.imageFile && this.imageFile.size > 1048576) {
        this.erroTamanhoImagem = true;
        this.imageFile = null;
        this.imageSelected = false;
        return;
      }


      // Verificar o tipo do arquivo (webp, png, jpg, jpeg)
      const fileType = this.imageFile?.type;
      if (fileType && !['image/webp', 'image/png', 'image/jpg', 'image/jpeg'].includes(fileType)) {
        this.erroTipoImagem = true;
        this.imageFile = null;
        this.imageSelected = false;
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageSelected = true;
        this.imagePreviewUrl = e.target.result; // Atribui a URL da imagem para visualização
      };
      if (this.imageFile) {
        reader.readAsDataURL(this.imageFile);
      }

    } else {
      this.imageSelected = false;
      this.imageFile = null; // Resetar imageFile se nenhum arquivo for selecionado
    }
  }


  // Método para remover a imagem selecionada
  removeImage(event: MouseEvent): void {
    event.stopPropagation();
    this.imageSelected = false;
    this.imagePreviewUrl = null;
    this.showImageDeleteButton = false;
  }

  // Método para o upload da imagem
  myImageUploadMethod(event: any): void {
    // Implemente a lógica para o upload da imagem
  }

  getSeverityFilterTypeLive(value: string): string {
    if (value === 'live') {
      return 'info';
    } else if (value === 'shorts') {
      return 'success';
    } else {
      return 'neutral'; // ou qualquer outro valor padrão de severidade
    }
  }
  handleCanPlayThrough() {
    console.log('Video can play through without interruption.');
  }
  displayDialogTemplate: boolean = false;
  closeCopyTemplate() {
    this.displayDialogTemplate = false;
  }
  isTemplate: boolean = false;
  createTemplate() {
    if (!this.slickTitleCopy || this.slickTitleCopy.trim() === '') {
      this.hasError1Copy = true;
      return;
    }

    if (!this.publicarAgoraCopy) {
      if (!this.dataSelecionadaCopy) {
        this.hasError2Copy = true;
        return;
      }
    }
    this.isTemplate = true;
    this.liveShopService.detailAsset(this.uidSeletedTemplate).pipe(takeUntil(this.destroy$)).subscribe({
      next: (resp) => {
        console.log(resp);

        ////////////////////////
        this.issueForm = this.fb.group({
          "widget": this.fb.array([
            this.fb.group({
              "assets": this.fb.group({
                "items": this.fb.array([
                  this.fb.group({
                    "schedule": this.fb.group({
                      "date_from": formatDate(this.dataSelecionadaCopy || new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
                      "date_to": null
                    }),
                    "media": this.fb.group({
                      "type": resp.items[0].media.type,
                      "src": resp.items[0].media.src,
                      "duration": resp.items[0].media.duration,
                      "thumbnail": resp.items[0].media.thumbnail,
                      "aspect_ratio": resp.items[0].media.aspect_ratio,
                      "status": this.publicarAgoraCopy ? 1 : 0
                    }),
                    "content": this.fb.group({
                      "title": this.slickTitleCopy,
                      "description": this.slickDescCopy,
                      "cta": this.fb.group({
                        "type": 0,
                        "style": {
                          "size": {
                            "width": null,
                            "height": null
                          },
                          "padding": {
                            "top": null,
                            "right": null,
                            "bottom": null,
                            "left": null
                          },
                          "colors": {
                            "background": null,
                            "font": null
                          },
                          "position": null
                        },
                        "components": {
                          "custom": {
                            "title": "",
                            "redirect_url": ""
                          },
                          "whatsapp": {
                            "phone": ""
                          },
                          "products": {
                            "items": []
                          }
                        }
                      })
                    }),
                    extra: this.fb.group({ // Início do FormGroup 'extra'
                      player: this.fb.group({ // FormGroup 'player' dentro de 'extra'
                        time_to_pitch: [0],
                        time_to_pitch_value: [],
                        player_color: [],
                        progress_bar_color: [],
                        auto_play: [0],
                        fake_bar: [0],
                      }),
                      "tags": resp.items[0].extra.tags
                    })
                  })
                ])
              })
            })
          ])
        });

        this.liveShopService.saveSlick(this.issueForm.value).pipe(takeUntil(this.destroy$)).subscribe({
          next: (res1) => {


            ///////////////////////////////
            this.issueForm = this.fb.group({
              widget: this.fb.array([ // Início do FormArray para 'widget'
                this.fb.group({ // Início do FormGroup dentro do array 'widget'
                  assets: this.fb.group({ // Início do FormGroup para 'assets'
                    items: this.fb.array([ // Início do FormArray para 'items'
                      this.fb.group({ // Início do FormGroup para cada item em 'items'
                        schedule: this.fb.group({
                          date_from: [resp.items[0].schedule.date_from],
                          date_to: [resp.items[0].schedule.date_to]
                        }),
                        media: this.fb.group({
                          type: [resp.items[0].media.type],
                          src: resp.items[0].media.src,
                          time_to_pitch: resp.items[0].media.time_to_pitch,
                          duration: resp.items[0].media.duration,
                          thumbnail: resp.items[0].media.thumbnail,
                          aspect_ratio: resp.items[0].media.aspect_ratio,
                          status: resp.items[0].media.status
                        }),
                        content: this.fb.group({
                          title: [this.slickTitleCopy],
                          uuidCopy: [this.uidSeletedTemplate],
                          description: [this.slickDescCopy],
                          cta: this.fb.group({
                            type: [0],
                            components: this.fb.group({
                              custom: this.fb.array(resp.items[0].content.cta.components.custom), // Se 'this.accordionTabs' já é um array, use diretamente sem colchetes adicionais
                              whatsapp: this.fb.array(resp.items[0].content.cta.components.whatsapp), // Se 'this.whatsappTabs' já é um array, use diretamente sem colchetes adicionais
                              products: this.fb.group({
                                items: this.fb.array(resp.items[0].content.cta.components.products.items) // Se 'this.targetProducts' já é um array, use diretamente sem colchetes adicionais
                              }),
                              promotions: this.fb.group({
                                items: this.fb.array(resp.items[0].content.cta.components.promotions.items) // Se 'this.targetPromotions' já é um array, use diretamente sem colchetes adicionais
                              }),
                              alerts: this.fb.array(resp.items[0].content.cta.components.alerts),
                              links: this.fb.array(resp.items[0].content.cta.components.links)
                            })
                          })
                        }),
                        extra: this.fb.group({ // Início do FormGroup 'extra'
                          player: this.fb.group({ // FormGroup 'player' dentro de 'extra'
                            time_to_pitch: [resp.items[0].extra.player.time_to_pitch ? 1 : 0],
                            time_to_pitch_value: resp.items[0].extra.player.time_to_pitch_value,
                            player_color: resp.items[0].extra.player.player_color,
                            progress_bar_color: [resp.items[0].extra.player.progress_bar_color],
                            auto_play: [resp.items[0].extra.player.auto_play ? 1 : 0],
                            fake_bar: [resp.items[0].extra.player.fake_bar ? 1 : 0],

                          }),
                          "tags": resp.items[0].extra.tags
                        })
                      }) // Fim do FormGroup para cada item em 'items'
                    ]) // Fim do FormArray para 'items'
                  }) // Fim do FormGroup para 'assets'
                }) // Fim do FormGroup dentro do array 'widget'
              ]) // Fim do FormArray para 'widget'
            });
            //////////////////// novo



            this.liveShopService.updateAsset(this.issueForm.value, res1.widget[0].assets.items[0].content.id).pipe(takeUntil(this.destroy$)).subscribe({
              next: (res) => {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Slick Salvo',
                  detail: 'O slick foi salvo com sucesso!'
                });
                this.isTemplate = false;
                this.redirectToSlickDetail(res1.widget[0].assets.items[0].content.uid);

                // Router
              },
              error: (error) => {
                let message = '';
                this.isTemplate = false;
                if (error.status === 400 || error.status === 403 || error.status === 401) {
                  message = error.error.message;
                } else if (error.status === 500) {
                  message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
                }

                if (message) {
                  this.showErrorDialog(message);
                }
              }
            });
            ///////////////////////////////
          },
          error: (err) => {
            this.isTemplate = false;
            // Exiba uma mensagem de erro
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: 'Erro ao criar slick.'
            });

          }
        });
        ////////////////////////

      }
    });
  }
  showErrorDialog(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Erro',
      icon: 'pi pi-times',
      accept: () => {
        // Lógica de aceitação, se necessário
      }
    });
  }

  resetDialogInputs(): void {
    this.slickTitleCopy = '';
    this.slickDescCopy = '';
    this.publicarAgoraCopy = true;
    this.dataSelecionadaCopy = undefined;
    this.hasError1 = false;
    this.hasError1Copy = false;
    this.hasError2Copy = false;
    this.isTemplate = false;
    this.uploadOn = false;
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
