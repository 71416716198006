<div class="page-title">
    <h1>Crie e customize widgets</h1>
</div>
<div class="grid">
    <div class="col-12 md:col-6 xl:col-3 cursor-pointer" (click)="openSidebar()">
        <div class="ss-g-hover-border h-full flex-center">
            <i class="pi pi-plus-circle text-5xl mb-2"></i>
            <span class="text-base font-medium">Adicionar</span>

        </div>

    </div>
    <div class="col-12 md:col-6 xl:col-3">
        <div class="card h-full">
            <ng-container *ngIf="!loading; else loadingTemplate">
                <!-- <ng-container> -->
                <div class="flex justify-content-between align-items-center">
                    <span class="font-semibold text-lg">
                        <i class="bi bi-grid-1x2-fill mr-2"></i> Widgets
                    </span>
                    <div class="ml-auto">
                        <i class="bi bi-info-circle" pTooltip="Quantidade de widgets cadastrados"
                            tooltipPosition="right"></i>
                    </div>
                </div>
                <div class="flex justify-content-between align-items-start mt-3">
                    <div class="w-12">
                        <span class="text-4xl font-bold text-900">{{totalRecords}} </span>
                        <div class="text-green-500">
                            <span class="font-medium">
                                <!-- <span *ngIf="percentageWatched == 0">
                                    -
                                </span> -->
                                <!-- <span *ngIf="percentageWatched > 0">
                                     +{{ percentageWatched | number:'1.0-2' }} <i class="pi pi-arrow-up"></i> 
                                </span> -->

                                <!-- Para porcentagem negativa -->
                                <!-- <span *ngIf="percentageWatched < 0">
                                     {{ percentageWatched | number:'1.0-2' }} <i class="pi pi-arrow-down"></i> 
                                </span> -->
                            </span>
                            <!-- <i *ngIf="(porcentagemAguardando ?? 0) > 0" class="pi pi-arrow-up text-xs ml-2"></i>
                            <i *ngIf="(porcentagemAguardando ?? 0) < 0" class="pi pi-arrow-down text-xs ml-2"></i> -->


                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #loadingTemplate>
                <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
                <p-skeleton width="60%" height="1rem"></p-skeleton>
            </ng-template>
        </div>
    </div>
    <div class="col-12 md:col-6 xl:col-3">
        <div class="card h-full">
            <ng-container *ngIf="!loading; else loadingTemplate">
                <!-- <ng-container> -->
                <div class="flex justify-content-between align-items-center">
                    <span class="font-semibold text-lg">
                        <i class="bi bi-play-circle mr-2"></i> Slicks
                    </span>
                    <div class="ml-auto">
                        <i class="bi bi-info-circle" pTooltip="Quantidade de slicks associados aos widgets"
                            tooltipPosition="right"></i>
                    </div>
                </div>
                <div class="flex justify-content-between align-items-start mt-3">
                    <div class="w-12">
                        <span class="text-4xl font-bold text-900">{{totalWidgets}}</span>

                        <div class="text-pink-500">
                            <span class="font-medium">
                                <!-- <span *ngIf="percentageEspectadores == 0">
                                    -
                                </span> -->
                                <!-- <span *ngIf="percentageEspectadores > 0">
                                     +{{ percentageEspectadores | number:'1.0-2' }} <i class="pi pi-arrow-up"></i> 
                                </span> -->

                                <!-- Para porcentagem negativa -->
                                <!-- <span *ngIf="percentageEspectadores < 0">
                                     {{ percentageEspectadores | number:'1.0-2' }} <i class="pi pi-arrow-down"></i> 
                                </span> -->
                            </span>
                            <!-- <i *ngIf="(porcentagemExpiradas ?? 0) > 0" class="pi pi-arrow-up text-xs ml-2"></i>
                            <i *ngIf="(porcentagemExpiradas ?? 0) < 0" class="pi pi-arrow-down text-xs ml-2"></i> -->

                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #loadingTemplate>
                <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
                <p-skeleton width="60%" height="1rem"></p-skeleton>
            </ng-template>
        </div>
    </div>
    <div class="col-12 md:col-6 xl:col-3">
        <div class="card h-full">
            <ng-container *ngIf="!loading; else loadingTemplate">
                <!-- <ng-container> -->
                <div class="flex justify-content-between align-items-center">
                    <span class="font-semibold text-lg">
                        <i class="bi bi-house-fill mr-2"></i> Visitas
                    </span>
                    <div class="ml-auto">
                        <i class="bi bi-info-circle" pTooltip="Quantidade de usuarios que interagiram com os widgets"
                            tooltipPosition="left"></i>
                    </div>
                </div>
                <div class="flex justify-content-between align-items-start mt-3">
                    <div class="w-12">
                        <span class="text-4xl font-bold text-900"></span>
                        <div class="text-green-500">
                            <span class="font-medium">
                                <!-- <span *ngIf="percentageLikes == 0">
                                    -
                                </span> -->
                                <!-- <span *ngIf="percentageLikes > 0">
                                     +{{ percentageLikes | number:'1.0-2' }} <i class="pi pi-arrow-up"></i> 
                                </span> -->

                                <!-- Para porcentagem negativa -->
                                <!-- <span *ngIf="percentageLikes < 0">
                                    {{ percentageLikes | number:'1.0-2' }} <i class="pi pi-arrow-down"></i> 
                                </span> -->
                            </span>
                            <!-- <i *ngIf="(porcentagemCanceladas ?? 0) > 0" class="pi pi-arrow-up text-xs ml-2"></i>
                            <i *ngIf="(porcentagemCanceladas ?? 0) < 0" class="pi pi-arrow-down text-xs ml-2"></i> -->

                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #loadingTemplate>
                <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
                <p-skeleton width="60%" height="1rem"></p-skeleton>
            </ng-template>
        </div>
    </div>
</div>
<div class="card">
    <p-table [value]="filteredWidgets" dataKey="id" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
        [loading]="loading" [rowsPerPageOptions]="[10, 20, 100]" [sortMode]="'multiple'" [scrollable]="true">

        <ng-template pTemplate="caption">
            <div class="caption-container">
                <div class="left-section">
                </div>
                <div class="ss-g-right-section">
                    <div class="search-button-container" style="display: flex; align-items: center;">
                        <span class="p-input-icon-left mb-2 search-input" style="flex-grow: 1;">
                            <i class="pi pi-search"></i>
                            <input type="search" [(ngModel)]="searchTerm" pInputText
                                placeholder="Buscar por nome ou data" (input)="onFilterSlicks($event)"
                                style="width: 100%;">
                        </span>
                        <button pButton pRipple type="button" icon="pi pi-chevron-down" (click)="menu.toggle($event)"
                            class="p-element p-ripple p-datepicker-trigger p-button-icon-only p-button p-component mb-2 menu-button"
                            style="margin-left: 0.5rem;"></button>
                        <p-menu #menu [model]="itemsButtons" [popup]="true" appendTo="body"></p-menu>
                    </div>
                </div>
            </div>
        </ng-template>


        <ng-template pTemplate="header">
            <tr>

                <th pSortableColumn="title" class="centered-content-sales" style="width: 50%;">
                    Nome
                    <p-sortIcon field="title"></p-sortIcon>
                </th>
                <th pSortableColumn="feed_type" class="centered-content-sales" style="width: 25%;">
                    Tipo de feed
                    <p-sortIcon field="feed_type"></p-sortIcon>
                </th>
                <th pSortableColumn="created_at" class="centered-content-sales" style="width: 20%;">
                    Data
                    <p-sortIcon field="created_at"></p-sortIcon>
                </th>
                <th class="centered-content-sales" style="width: 5%;">Ações</th>
            </tr>
            <tr>

                <th class="centered-content-sales" style="width: 50%;">
                    <p-columnFilter field="title" display="row" type="text" matchMode="contains"></p-columnFilter>
                </th>
                <th class="centered-content-sales" style="width: 25%;">
                    <div style="display: flex; align-items: center;">
                        <p-dropdown [options]="feedTypeOptions" [(ngModel)]="selectedFeedType"
                            (ngModelChange)="applyFeedTypeFilter()" placeholder="Filtrar por tipo">

                            <ng-template let-option pTemplate="selectedItem">
                                <p-tag [severity]="option.value === 'manual' ? 'warning' : 'danger'" [rounded]="true">
                                    {{ option.label }}
                                </p-tag>
                            </ng-template>

                            <ng-template let-option pTemplate="item">
                                <p-tag [severity]="option.value === 'manual' ? 'warning' : 'danger'" [rounded]="true">
                                    {{ option.label }}
                                </p-tag>
                            </ng-template>
                        </p-dropdown>
                        <button pButton pRipple icon="pi pi-filter-slash" class="p-button-text p-button-rounded"
                            [disabled]="!selectedFeedType" (click)="clearFeedTypeFilter()"
                            style="margin-left: 0.5rem;"></button>
                    </div>
                </th>



                <th class="centered-content-sales" style="width: 20%;">
                    <div style="display: flex; align-items: center;">
                        <p-calendar [(ngModel)]="selectedDateRange" (ngModelChange)="onDateFilterChange($event)"
                            selectionMode="range" dateFormat="dd/mm/yy" placeholder="Filtrar por intervalo de datas"
                            appendTo="body">
                        </p-calendar>
                        <button pButton pRipple icon="pi pi-filter-slash" class="p-button-text p-button-rounded"
                            [disabled]="!selectedDateRange" (click)="clearDateFilter()" style="margin-left: 0.5rem;">
                        </button>
                    </div>
                </th>


                <th class="centered-content-sales" style="width: 5%;"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-order>
            <tr *ngIf="!loading; else loadingContent">
                <td class="centered-content-sales">
                    {{ order.title ?? 'Slick' }}
                </td>
                <td class="centered-content-sales text-center">
                    <p-tag [severity]="order.feed_type === 'manual' ? 'warning' : 'danger'" [rounded]="true">
                        {{ order.feed_type }}
                    </p-tag>
                </td>
                <td>
                    {{ order.created_at | date:'dd/MM/yyyy' }}
                </td>
                <td class="centered-content-sales">
                    <button pButton pRipple type="button" icon="pi pi-pencil"
                        (click)="menu.toggle($event); selectedWidget = order;"></button>
                    <p-menu #menu [model]="getActionMenu(selectedWidget)" [popup]="true" appendTo="body"></p-menu>
                </td>
            </tr>
            <ng-template #loadingContent>
                <tr>
                    <td colspan="4">
                        <p-skeleton width="100%" height="1rem"></p-skeleton>
                    </td>
                </tr>
            </ng-template>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4" style="text-align: center; padding: 20px;">
                    <span style="font-style: italic;">Nenhum widget encontrado.</span>
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>
<p-confirmDialog header="Confirmação" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast></p-toast>
<p-dialog header="Aguarde" [(visible)]="displayAguarde" [modal]="true" [closable]="true" [showHeader]="true"
    [dismissableMask]="true">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</p-dialog>