import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-p-direct',
  templateUrl: './p-direct.component.html',
  styleUrls: ['./p-direct.component.scss']
})
export class PDirectComponent implements OnInit {
  @Input() store_name?: string;
  @Input() slick_id?: string;
  @Input() widget_id?: string;
  store_id?:number;

  ngOnInit(): void {
    this.store_id = Number(localStorage.getItem('SelectedStoreId'));
  }
}
