// user-info.service.ts
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  private ipApiUrl = 'https://ipapi.co/json/'; // Você pode usar qualquer serviço de IP de sua escolha

  constructor(private deviceService: DeviceDetectorService, private http: HttpClient) {}

  getLocation(): Observable<any> {
    return this.http.get(this.ipApiUrl);
  }

  getDeviceInfo(): any {
    const deviceInfo = this.deviceService.getDeviceInfo();
    return {
      type: this.deviceService.isMobile() ? 'mobile' : this.deviceService.isTablet() ? 'tablet' : 'desktop',
      name: deviceInfo.device,
      brand: deviceInfo.device,
      is_mobile_device: this.deviceService.isMobile(),
      operation_system: deviceInfo.os,
      browser: { name: deviceInfo.browser, version: deviceInfo.browser_version },
      ipv4: '', // Será preenchido após obter o IP
    };
  }

  getUserInfo(): Observable<any> {
    return forkJoin({
      location: this.getLocation(),
      device: new Observable((observer) => {
        observer.next(this.getDeviceInfo());
        observer.complete();
      })
    }).pipe(
      map((result: any) => {
        const deviceInfo = result.device;
        deviceInfo.ipv4 = result.location.ip; // Adiciona o IP ao deviceInfo
        return {
          location: result.location,
          device: deviceInfo
        };
      })
    );
  }
}
