import { Component } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from 'rxjs';
import { MetaStoreService } from 'src/app/services/meta-store.service';

@Component({
  selector: 'app-p-meta-store',
  templateUrl: './p-meta-store.component.html',
  styleUrls: ['./p-meta-store.component.scss']
})
export class PMetaStoreComponent {

  constructor() { } 

  // Método para adicionar um produto ao carrinho
  addProductToCart(product: any, cart: any[], show: any, products: any[]) {
    const event = new CustomEvent('cartEvent', {
      detail: {
        action: 'add_product',
        item: product,
        cart: cart,
        show: show,
        products: products
      }
    });
    window.dispatchEvent(event);
  }

  // Método para finalizar a compra
  checkoutCart(cart: any[]) {
    const event = new CustomEvent('cartEvent', {
      detail: {
        action: 'checkout_cart',
        cart: cart
      }
    });
    window.dispatchEvent(event);
  }

  // Simulação de disparo de eventos para teste
  testEvents() {
    this.addProductToCart({ id: 1, name: 'Produto 1' }, [], true, []);
    this.checkoutCart([{ id: 1, name: 'Produto 1' }]);
  }

  sendEvents(event:any){
    window.dispatchEvent(event);
  }
}
