import { Component } from '@angular/core';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  constructor(public breadcrumbsService: BreadcrumbsService) {}
}
