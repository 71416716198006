import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ModalstateService {
  private modalOpenedSubject = new Subject<boolean>();
  modalOpened$ = this.modalOpenedSubject.asObservable();

  emitModalOpened(opened: boolean) {
    console.log('emitModalOpened')
    this.modalOpenedSubject.next(opened);
  }
  constructor() { }
}
