import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root', // Disponível em toda a aplicação
})
export class UnregisterSwService {
  async removeServiceWorkerAndCaches(): Promise<void> {
    if ('serviceWorker' in navigator) {
      try {
        // Força a atualização do SW (se houver um novo)
        const registrations = await navigator.serviceWorker.getRegistrations();
        // for (const registration of registrations) {
        //   registration.update();
        // }

        // Desregistra o service worker
        for (const registration of registrations) {
          const success = await registration.unregister();
          if (success) {
            console.log('Service worker unregistered successfully');
          }
        }

        // Limpa os caches
        const cacheNames = await caches.keys();
        for (const name of cacheNames) {
          await caches.delete(name);
        }
        console.log('All caches deleted successfully');

        // Força o Bypass for network, removendo o controller do SW
        if (navigator.serviceWorker.controller) {
          console.log('Service worker still controlling, applying bypass...');
          navigator.serviceWorker.controller.postMessage({ action: 'skipWaiting' });
        }

      } catch (error) {
        console.error('Error while unregistering service worker or clearing caches:', error);
      }
    }
  }

  async registerServiceWorker(): Promise<void> {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register('/ngsw-worker.js'); // Caminho do SW
        console.log('Service worker registered successfully with scope:', registration.scope);
        
        // Se o SW estiver aguardando, faz ele ativar imediatamente
        if (registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
  
        // Verifica se o SW está controlando a página
        if (!navigator.serviceWorker.controller) {
          console.log('Service worker not controlling, applying reload...');
          window.location.reload();
        }
      } catch (error) {
        console.error('Error while registering service worker:', error);
      }
    }
  }
  
}
