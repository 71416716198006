<swiper #swiper slidesPerView="auto" [spaceBetween]="spaceBetween" [navigation]="true" [pagination]="false"
  [loop]="false" (activeIndexChange)="onSwiperActiveIndexChange()">
  <ng-container *ngFor="let video of videoUrls; let idx = index; trackBy: trackByFn">
    <ng-template swiperSlide>
      <div class="carousel-item">
        <div [class]="itemClass" (click)="!isDragging && openModal(video)"  class="ss-l-video-container-widget " *ngIf="!video.isLoading; else loadingTemplate">
          <video [poster]="video.thumbnail" class=" ">
            <source [src]="video.src" type="video/mp4">
            Seu navegador não suporta a tag de vídeo.
          </video>
        </div>
        <ng-template #loadingTemplate>
          <div class="loading-spinner">
            <i class="pi pi-spin pi-spinner text-4xl"></i>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </ng-container>
</swiper>


<!-- Modal Carousel -->
<app-video-modal [videoUrls]="selectedVideoUrls" [widget_id]="widget_id" [show]="isModalOpen" [height]="height"
  [arraySlicksFull]="arraySlicksFull" [selectedVideo]="selectedVideoUrls[0]" [totalAssets]="totalAssets"
  (close)="isModalOpen = false"></app-video-modal>