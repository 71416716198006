import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sanitizeUrl'
})
export class SanitizeUrlPipe implements PipeTransform {
  transform(url: string): string {
    if (!url) return '';
    return url.split('?')[0];
  }
}
