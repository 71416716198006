import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { PedidosService } from 'src/app/services/pedidos.service';
import { ColorScheme, MenuColorScheme, MenuMode } from './service/app.layout.service';
@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent {

    constructor(
        private authService: AuthService, 
        private router: Router, 
        private pedidosService: PedidosService,
        public layoutService: LayoutService
    ) { }
    darkMode:boolean =false;    
    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    get menuTheme(): MenuColorScheme {
        return this.layoutService.config.menuTheme;
    }

    goToProfile(): void {
        this.visible = false;
        this.router.navigate(['/profile']);
    }



    changeColorScheme(colorScheme: ColorScheme): void {
        const themeLink = document.getElementById('theme-link') as HTMLLinkElement;
        if (themeLink) {
            const themeLinkHref = themeLink.getAttribute('href');
            const currentColorScheme = 'theme-' + this.layoutService.config.colorScheme;
            const newColorScheme = 'theme-' + colorScheme;
            const newHref = themeLinkHref!.replace(currentColorScheme, newColorScheme);
            this.replaceThemeLink(newHref, () => {
                this.layoutService.config.colorScheme = colorScheme;
                this.layoutService.onConfigUpdate();
            });
        } else {
            console.error('Theme link element not found!');
        }
    }

    replaceThemeLink(href: string, onComplete: () => void): void {
        const id = 'theme-link';
        const themeLink = document.getElementById(id) as HTMLLinkElement;
        const cloneLinkElement = themeLink.cloneNode(true) as HTMLLinkElement;

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

        cloneLinkElement.onload = () => {
            themeLink.remove();
            cloneLinkElement.setAttribute('id', id);
            onComplete();
        };
    }

    toggleTheme(): void {
        // Determina o novo esquema com base no estado do switch const accessToken = localStorage.getItem('access_token');
        const newScheme = this.darkMode ? 'dark' : 'light';
        localStorage.setItem('viewMode', newScheme);
        this.changeColorScheme(newScheme as ColorScheme);
    }
    user_name:string ="";
    ngOnInit(): void {
        //this.darkMode = this.layoutService.config.colorScheme === 'dark';
        let user_id = localStorage.getItem('user_id');
        if (user_id === null) {
            console.error('User ID is not available in localStorage');
            // Você pode tratar esse caso como achar melhor
        } else {
            let numericUserId = parseInt(user_id, 10);
            this.pedidosService.detailUser(numericUserId).subscribe({
                next: (response:any) => {

                    this.user_name = response.name;

                },
                error: (error:any) => {

                }
            });
        }

        const accessToken = localStorage.getItem('viewMode');
        if(accessToken == 'dark'){
            this.darkMode = true;
        }
        if(accessToken !== null){
            this.changeColorScheme(accessToken as ColorScheme);
        }
        
    }

    set menuTheme(_val: MenuColorScheme) {
        this.layoutService.config.menuTheme = _val;
    }

    resetUserToLogin(): void {
        this.authService.logout()
            .subscribe(res => {
                console.log(res.status)
                localStorage.clear();
                this.router.navigate(['/auth/login']);
            }, err => {
                if (err.status === 401) {
                    localStorage.clear();
                    this.router.navigate(['/auth/login']);
                } else {
                    console.error('resetUserToLogin', err);
                }
            })
    }

}