<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    styleClass="layout-profile-sidebar w-full sm:w-25rem">
    <ng-template pTemplate="header">
        <div style="display: flex; flex-direction: column;">
            <div><b>Bem-vindo(a)</b></div>
            <!-- <span class="text-color-secondary font-medium mb-5">Isabella Andolini</span> -->
            <span class="text-color-secondary font-medium mb-5">{{user_name}}</span>
        </div>
    </ng-template>
    <div class="flex flex-column mx-auto md:mx-0">
        <ul class="list-none m-0 p-0">
            <li>
                <a (click)="goToProfile()"
                    class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-user text-xl text-primary"></i>
                    </span>
                    <div class="ml-3">
                        <span class="mb-2 font-semibold">Perfil</span>
                        <p class="text-color-secondary m-0">Gerencie seus dados pessoais e assinatura</p>
                    </div>
                </a>
            </li>


            <!-- <li>
                <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150" (click)="resetUserToLogin()">
                    <span>
                        <i class="pi pi-power-off text-xl text-primary"></i>
                    </span>
                    <div class="ml-3">
                        <span class="mb-2 font-semibold">Sign Out</span>
                        <p class="text-color-secondary m-0">Sed ut perspiciatis</p>
                    </div>
                </a>
            </li> -->
        </ul>
        <!---->
        <div class="col-12 p-0 grid mx-0 w-full">
            <div class="col-12 mb-2 mt-4 p-0">
                <label>Mode de visualização</label>
                <div class="mt-2"></div>
                <div class="flex align-items-center gap-2">

                    <p-inputSwitch binary="true" class="mr-2 ss-l-switch-position3" [(ngModel)]="darkMode"
                        (onChange)="toggleTheme()" [ngModelOptions]="{standalone: true}"></p-inputSwitch>

                    <label>Modo Dark</label>
                </div>
            </div>
        </div>
        <!---->
    </div>
</p-sidebar>