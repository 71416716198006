import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUserData } from '../@types/UserData';
import { tap, retry, catchError, filter, map } from 'rxjs/operators';
import { firstValueFrom, of  } from 'rxjs';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private path: string = 'auth'
  private httpOptions = {};
  constructor(private http: HttpClient, private router: Router) { 
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
  }

    async login(email: string, pass: string): Promise<any> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });

      const body = {
        email: email,
        password: pass
      }

      if (environment.production) {
        return this.http
        .post(`${environment.serverUrl}/${this.path}/login`, body, { headers: headers })
        .toPromise()
      } else {
          return this.http
          .post(`${environment.paymentGenetatorLinkURL}/${this.path}/login`, body, { headers: headers })
          .toPromise()
      }
  }

  wallet(): Promise<any[]> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const url = environment.production ? environment.serverUrl : environment.paymentGenetatorLinkURL;
    const requestObservable = this.http.get<any[]>(`${url}/wallets`, httpOptions)
      .pipe(
        catchError(() => of([])) // Em caso de erro, retorna um array vazio
      );
  
    return firstValueFrom(requestObservable);
  }
  
  me(accessToken?: string): Observable<IUserData> {
    const token = accessToken ? accessToken : this.getAccessToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });
  
    if (environment.production) {
      return this.http.post<IUserData>( 
        `${environment.serverUrl}/${this.path}/me`, {}, { headers: headers }
      ).pipe();
    } else {
      return this.http.post<IUserData>( // Adicione o tipo de retorno aqui
        `${environment.paymentGenetatorLinkURL}/${this.path}/me`, {}, { headers: headers }
      ).pipe();
    }
  }

  me2(): Observable<IUserData> {
    const token = this.getAccessToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
  
    if (environment.production) {
      return this.http.post<IUserData>( 
        `${environment.serverUrl}/${this.path}/me`, {}, { headers: headers }
      ).pipe();
    } else {
      return this.http.post<IUserData>( // Adicione o tipo de retorno aqui
        `${environment.paymentGenetatorLinkURL}/${this.path}/me`, {}, { headers: headers }
      ).pipe();
    }
  }

  // refresh(): Observable<any> {
  //   const token = this.getAccessToken()
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${token}`
  //   });

  //   return this.http
  //     .post(`${environment.serverUrl}/${this.path}/refresh`, {}, { headers: headers })
  //     //.toPromise()
  // }

  forgot(email: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessToken()}`
    });
    if (environment.production) {
      return this.http.post(`${environment.serverUrl}/${this.path}/forgot`, { email: email }, { headers: headers })
    } else {
      return this.http.post(`${environment.paymentGenetatorLinkURL}/${this.path}/forgot`, { email: email }, { headers: headers })
    }
    
  }

  resetPassword(token: string, email: string, password: string, password_confirmation: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessToken()}`
    });

    const body = {
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      token: token
    }
    if (environment.production) {
      return this.http.post(`${environment.serverUrl}/${this.path}/reset`, body, { headers: headers })
    } else {
      return this.http.post(`${environment.paymentGenetatorLinkURL}/${this.path}/reset`, body, { headers: headers })
    }
    
  }

  getAccessToken(): string {
    return localStorage.getItem("access_token") || '';
  }

  setAccessToken(access_token: string, clear: Boolean = true): void {
    if(clear){
      localStorage.removeItem("access_token");

    }
    localStorage.setItem("access_token", access_token);
  }

  getSaleorToken(): string {
    return localStorage.getItem("saleor_token") || '';
  }

  setSaleorToken(saleor_token: string): void {
    localStorage.setItem("saleor_token", saleor_token);
  }

  getUser(): any {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : null; // ou outro valor padrão
  }

  setUser(user: number): void {
    localStorage.setItem("user_id", JSON.stringify(user));
  }
  setUserName(user: string): void {
    localStorage.setItem("user_name", JSON.stringify(user));
  }
  setUserID(user: number): void {
    localStorage.setItem("user_id", JSON.stringify(user));
  }
  setUserTrial(user: string): void {
    localStorage.setItem("use_trial", JSON.stringify(user));
  }
  setUserAvatar(user: string): void {
    localStorage.setItem("user_avatar", JSON.stringify(user));
  }

  getWorkspace(): string {
    //return localStorage.getItem("workspace");
    return "loja";
  }

  isStoreWorkspace(): Boolean {
    return this.getWorkspace() == 'store';
  }

  isFinanceWorkspace(): Boolean {
    return this.getWorkspace() == 'finance';
  }

  setWorkspaceToFinance(): Promise<any> {
    return new Promise((resolve, reject) => {
      localStorage.setItem("workspace", 'finance')

      setTimeout(() => {
        if (this.getWorkspace() && this.getWorkspace() !== '') {
          resolve(true);
        } else {
          reject('sem storage');
        }
      }, 100);
    })
  }

  setWorkspaceToStore(): Promise<any> {
    return new Promise((resolve, reject) => {
      localStorage.setItem("workspace", 'store')

      setTimeout(() => {
        if (this.getWorkspace() && this.getWorkspace() !== '') {
          resolve(true);
        } else {
          reject('sem storage');
        }
      }, 100);
    })
  }

  logout(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessToken()}`
    });


    if (environment.production) {
      return this.http.post(`${environment.serverUrl}/${this.path}/logout`, {}, { headers: headers })
    } else {
      return this.http.post(`${environment.paymentGenetatorLinkURL}/${this.path}/logout`, {}, { headers: headers })
    }

  }




}
