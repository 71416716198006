import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FocusService {
  private focusElement: HTMLElement | null = null;

  constructor() {}

  setFocus(element: HTMLElement): void {
    this.focusElement = element;
  }

  triggerFocus(): void {
    if (this.focusElement && this.isElementVisible(this.focusElement)) {
      setTimeout(() => {
        this.focusElement!.focus(); 
        this.focusElement = null; 
      }, 0); 
    }
  }
  
  private isElementVisible(element: HTMLElement): boolean {
    return element.offsetWidth > 0 || element.offsetHeight > 0;
  }
}
