import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SMetadataLocalstorageService {

  constructor() { }


  //metadataLocalStorageService

  getStoreId(){
    return localStorage.getItem('SelectedStoreId');
  }
  getStore(){
    return localStorage.getItem('store');
  }
  private localStorageKey = 'customer_crypto';
  private playerDataKey = 'player_data';
  // Método para obter customerCrypto do localStorage
  getCustomerCrypto(): any {
    const customerCrypto = localStorage.getItem(this.localStorageKey);
    return customerCrypto ? JSON.parse(customerCrypto) : null;
  }

  // Método para salvar customerCrypto no localStorage
  setCustomerCrypto(data: any): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(data));
  }

  // Método para atualizar customerCrypto com novas informações
  updateCustomerCrypto(customerCrypto: any, location: any, device: any): any {
    const updatedCustomerCrypto = {
      ...customerCrypto,
      location,
      device
    };
    this.setCustomerCrypto(updatedCustomerCrypto);
    return updatedCustomerCrypto;
  }

  setPlayerData(playerSessionId: string, slicks: any[], virtualIndex: number, loadedSlides: number, page: number, currentVideoIndex: number, totalSwipes: number, lastSlickUid: string | undefined): void {
    // Criação do objeto `pagination`
    const pagination = {
      loaded_slides: loadedSlides,
      page: page
    };

    // Criação do objeto `current_asset`
    const current_asset = {
      id: slicks[virtualIndex].content.id,
      title: slicks[virtualIndex].content.title,
      description: slicks[virtualIndex].content.description,
      url: slicks[virtualIndex].media.src,
      thumbnail: slicks[virtualIndex].media.thumbnail,
      slide_index: currentVideoIndex,
      total_duration: slicks[virtualIndex].media.duration,
      pitch_time: slicks[virtualIndex].extra.player.time_to_pitch_value
    };

    // Criação do objeto `playerData`
    const playerData = {
      current_asset: current_asset,
      player_session_id: playerSessionId,
      total_swipes: totalSwipes,
      pagination: pagination,
      previous_asset_uid: lastSlickUid
    };

    // Salvar no localStorage
    localStorage.setItem(this.playerDataKey, JSON.stringify(playerData));
  }

  // Método para obter o playerData do localStorage (caso necessário)
  getPlayerData(): any {
    const playerData = localStorage.getItem(this.playerDataKey);
    return playerData ? JSON.parse(playerData) : null;
  }

}
