import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideoMuteService {
  private isMutedSubject = new BehaviorSubject<boolean>(true);
  isMuted$ = this.isMutedSubject.asObservable();

  toggleMute() {
    const currentMuteState = this.isMutedSubject.value;
    this.isMutedSubject.next(!currentMuteState);
  }

  setMuteState(isMuted: boolean) {
    this.isMutedSubject.next(isMuted);
  }

  getMuteState() {
    return this.isMutedSubject.value;
  }
}
