// websocket.service.ts
import { Injectable } from '@angular/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Observable, throwError, Subject } from 'rxjs';
//import { io } from 'socket.io-client';
declare const io: any;
declare const Swal: any;
@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnInit, OnDestroy {

  constructor() { }
  socket: any;
  framerate: any;
  audioBitrate: any;
  uniq_id?: string;
  isConnected: boolean = false;
  async connect(streamKey: string, uniq_id: string): Promise<void> {

    this.uniq_id = uniq_id;
    this.framerate = 25;
    this.audioBitrate = 22050;
    const socketOptions = {
      id: uniq_id,
      secure: true,
      timeout: 6000,
      pingTimeout: 6000,
      pingInterval: 45000,
      query: {
        video: 'h264',
        audio: 'aac',
        framespersecond: this.framerate,
        audioBitrate: this.audioBitrate
      }
    };

    this.socket = io('https://krabo.online:1437', socketOptions);
    this.socket.on('connect', () => {
      console.log('Conectado ao servidor www');
      this.isConnected = true;
    });

    // Ouvindo por mensagens do servidor
    this.socket.on('message', (data: any) => {
      console.log('recv server message front', data);
      console.log(data);
    });

    // Escute por eventos aqui
    this.socket.on('ffmpeg_stderr', (error: any) => {
      console.log('ffmpeg123:', error);
      this.isConnected = false;
      this.handleError({ event: 'connect_error', error });
    });

    this.socket.on('ffmpeg_stdout', (data: any) => {
      console.log('ffmpeg stdout:', data);

    });

    let swalShown = false;
    this.socket.on('fatal', (error: any) => {
      //console.error('Erro fatal:', error);
      this.handleError({ event: 'fatal', error });
    });

    this.socket.on('connect_timeout', (timeout: any) => {
      console.log("state on connection timeout= " + timeout);
    });

    this.socket.on('error', (error: any) => {
      console.log("state on connection error= " + error);
      this.handleError({ event: 'fatal', error });
    });

    this.socket.on('connect_error', (error: any) => {
      console.log("state on connection error");
      this.isConnected = false;
      this.handleError({ event: 'connect_error', error });
    });

    this.socket.on('disconnect', (reason: any) => {
      console.log("state disconec= " + reason);
      this.isConnected = false;
      this.handleError({ event: 'disconnect', reason });
    });
    // this.socket.on('camera_change_processed', (reason: any) => {
    //   console.log("camera_change_processed: " + reason);
    //   this.cameraChangeProcessed.next(); // Notifica os assinantes sobre a mudança processada
    // });

  }
  onCameraChangeProcessed(): Observable<void> {
    return this.cameraChangeProcessed.asObservable();
  }
  reconnect(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Verifica se já está conectado para evitar tentativas de reconexão desnecessárias
      if (this.isConnected) {
        console.log('Já conectado');
        resolve();
        return;
      }

      console.log('Tentando reconectar...');
      this.socket.connect(); // Reativa as tentativas de reconexão

      const onSuccess = () => {
        console.log('Reconectado ao servidor com sucesso');
        this.isConnected = true;
        this.socket.off('connect_error', onError); // Remove o ouvinte de erro, pois a conexão foi bem-sucedida
        resolve();
      };

      const onError = (error: any) => {
        //console.error('Falha ao reconectar:', error);
        this.isConnected = false;
        this.socket.off('connect', onSuccess); // Remove o ouvinte de sucesso, pois a conexão falhou
        reject(error);
      };

      // Configura os manipuladores de eventos para a tentativa de reconexão
      this.socket.once('connect', onSuccess);
      this.socket.once('connect_error', onError);

      // Define um tempo limite para a reconexão
      setTimeout(() => {
        if (!this.isConnected) {
          console.error('Tempo limite de reconexão atingido');
          this.socket.off('connect', onSuccess);
          this.socket.off('connect_error', onError);
          reject(new Error('Tempo limite de reconexão atingido'));
        }
      }, this.socket.io.opts.timeout || 6000);
    });
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.io.opts.reconnection = false;  // Desativar reconexão
      this.socket.disconnect();
      this.isConnected = false;
      console.log('Socket disconnected');
    }
  }

  setRTMPDestination(destination: string): void {
    this.socket.emit('config_rtmpDestination', 'rtmp://global-live.mux.com:5222/app/' + destination);
  }

  setVideoCodec(codec: string): void {
    this.socket.emit('config_vcodec', codec);
  }
  setTypeSlick(type: number): void {
    this.socket.emit('config_type_slick', type);
  }

  sendMessage(message: { action: string }): void {
    this.socket.emit(message.action);
  }
  // sendMessage(message: { action: string }): void {
  //   this.socket.emit(message.action);
  // }

  startStream(): void {
    console.log('fazeno start com socket');
    this.socket.emit('start', {});
    //this.socket.emit('start','start');
  }

  sendStreamData(data: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (data) {
        this.socket.emit('binarystream', data, (response: boolean) => {
          if (response) {
            resolve(true); // Resolva como true se o envio for bem-sucedido
            console.log('sendStreamData')

          } else {
            reject(new Error("Falha ao enviar os dados")); // Rejeite como false se o envio falhar
          }
        });
      } else {
        reject(new Error("Falha ao enviar os dados")); // Rejeite como false se os dados forem nulos ou indefinidos
      }
    });
  }


  closeConnection(): void {
    if (this.socket) {
      this.socket.disconnect();
      localStorage.removeItem('pipState');
    }
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    // Desconecte do socket quando o componente for destruído
    this.socket.disconnect();
    this.errorSubject.complete();
  }

  disconnectSocket(): void {
    // Emita um evento "disconnect" para o socket
    this.socket.emit('desconectou');
  }


  isReady(): boolean {
    return this.socket && this.socket.connected;
  }
  private errorSubject = new Subject<any>();
  errorObservable = this.errorSubject.asObservable();

  handleError(errorEvent: any): void {
    this.errorSubject.next(errorEvent);
  }

  emit(event: string, data?: any): void {
    if (this.socket) {
      this.socket.emit('change_camera');
    }
  }

  cameraChangeProcessed = new Subject<void>();
}