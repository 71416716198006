import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, Output, ViewEncapsulation, EventEmitter, AfterViewInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ViewportService } from 'src/app/services/viewport.service';
import { DialogModule } from 'primeng/dialog';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { SkeletonModule } from 'primeng/skeleton';
import { DraggableDirective } from 'src/app/shared/directives/draggable.directive';
import { ViewportRuler } from '@angular/cdk/overlay';
import { Item } from 'src/app/models/liveshop';
export interface VideoItem {
  uid: string;
  id: number;
  src: string;   // URL do vídeo
  poster: string; // Poster do vídeo
  thumbnail?: string;
}
@Component({
  selector: 'app-popup',
  standalone: true,
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  imports: [CommonModule, ConfirmDialogModule, DialogModule, VideoModalComponent, SkeletonModule, DraggableDirective],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PopupComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() arraySlicksFull: Item[] = [];
  @Input() position: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright' = 'center';
  @Input() highlight?: string;
  @Input() shape?: string;
  @Input() height?: number | undefined;
  @Input() backgroundColor?: string;
  @Input() font_color: string = "#000000";
  @Input() widget_id?: number | undefined;
  @Input() templateType: string | undefined;
  @Input() pageToVideos: number = 0;
  visible: boolean = true;
  selectedVideoUrl: VideoItem | undefined;
  @Input() videoUrls: VideoItem[] = [];
  @ViewChild('dialogContainer', { read: ElementRef }) dialogContainer!: ElementRef;
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  @Input() totalAssets: number = 0;
  private isDragging = false;
  private offsetX = 0;
  private offsetY = 0;
  constructor(private viewportService: ViewportService, private viewportRuler: ViewportRuler, private renderer: Renderer2) {

  }
  ngAfterViewInit(): void {
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      this.videoPlayer.nativeElement.muted = true;
    }
    this.viewportService.setTemplateType(this.templateType);
  }
  // Método chamado quando o diálogo é exibido
  onDialogShow(): void {
    // Tenta acessar o `.p-dialog-content` diretamente no documento
    const dialogContentElement = document.querySelector('.p-dialog-content');
    if (dialogContentElement) {
      this.renderer.setStyle(dialogContentElement, 'background-color', 'transparent');
      //  	  2this.renderer.setStyle(dialogContentElement, 'transform', 'translate("100px", 0)');
    } else {
      console.warn('Elemento `.p-dialog-content` não encontrado');
    }
  }
  
  @Output() openSidebar = new EventEmitter<void>();
  @ViewChild('modalContainer', { read: ElementRef }) modalContainer!: ElementRef;

  isModalOpen = false;
  selectedVideoUrls: VideoItem[] = [];

  ngOnInit(): void {
    console.log('Position:', this.position);
  }

  private wasDragged = false;

  onDragEnd() {
    this.wasDragged = true; // Seta a flag de arrasto
  }

  handleClick() {
    console.log('disparou clique')
    if (this.wasDragged) {
      this.wasDragged = false; // Reseta a flag após o clique
      return; // Ignora o clique
    }
    this.openModal();
  }

  openModal() {
    console.log('clicou em abrir modal');
    if (this.videoUrls && this.videoUrls.length > 0) {
      this.selectedVideoUid = this.videoUrls[0].uid;
      console.log('Abrindo modal com videoUrls:', this.videoUrls);
      this.isModalOpen = true;
      this.visible = false;

      this.selectedVideoUrls = this.videoUrls;
    } else {
      console.log('Nenhum vídeo disponível para abrir no modal.');
    }
  }
  resetPosition: boolean = false;
  showHighlight: boolean = false;
  ngOnChanges(changes: SimpleChanges): void {
    this.visible = true;
    if (changes['videoUrls'] && this.videoUrls.length > 0) {
      this.selectedVideoUrl = this.videoUrls[0]; // Pegando apenas o primeiro vídeo
      console.log('Video URL selecionada:', this.selectedVideoUrl);
      console.log('PopupComponent - videoUrls atualizado:', changes['videoUrls'].currentValue);
      // Forçar recarregamento do vídeo
      if (this.videoPlayer) {
        const videoElement = this.videoPlayer.nativeElement;
        videoElement.load(); // Recarrega o vídeo quando a URL é alterada
      }
      
    }
    if (changes['position']) {
      this.resetPosition = true;
      console.log('Position foi atualizada:', changes['position'].currentValue);
      setTimeout(() => {
        this.resetPosition = false;
      }, 0);
    }
    if (changes['height']) {
      console.log('height foi atualizada:', changes['height'].currentValue);
    }
    if (changes['font_color']) {
      console.log('font_color foi atualizada:', changes['font_color'].currentValue);
    }
    if (changes['highlight']) {
      console.log('Highlight foi atualizado:', changes['highlight'].currentValue);
    }
    if (changes['shape']) {
      console.log('Shape foi atualizado:', changes['shape'].currentValue);
    }
    if (changes['backgroundColor']) {
      console.log('Cor de fundo foi atualizada:', changes['backgroundColor'].currentValue);
    }
  }

  getDialogClass(): string {
    switch (this.shape) {
      case 'portrait':
        return 'dialog-portrait';
      case 'landscape':
        return 'dialog-landscape';
      case 'circle':
        return 'dialog-circle';
      default:
        return 'dialog-circle';
    }
  }
  getDialogStyle() {
    return {
      'background-color': this.backgroundColor ? this.backgroundColor : 'inherit'
    };
  }
  loading: boolean = true;
  onVideoLoaded(): void {
    this.loading = false; // Desativar o skeleton quando o vídeo for carregado
    this.showHighlight = true;
    // Garante que o vídeo está mutado quando os metadados carregam
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      this.videoPlayer.nativeElement.muted = true;
    }
  }
  selectedVideoUid?: string;

  closeModal() {
    console.log('Fechando o modal');
    this.isModalOpen = false; // Reseta isModalOpen para permitir reabertura
    this.visible = true;
  }
  onOpenSidebar(): void {
    console.log(' primeiro onOpenSidebar');
    this.openSidebar.emit();
  }
}
