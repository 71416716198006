import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Custompage } from './../models/custompage';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, startWith } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { LocalstorageService } from 'src/app/services/localstorage.service';
import { of } from 'rxjs';
import { Store } from 'src/app/models/store';
interface Config {
  id: number;
  store_id: number;
  category: number;
  key: string;
  value: string;
  created_at: string;
  updated_at: string;
}
@Injectable({
  providedIn: 'root'
})
export class ConfGeraisService {
  // Base url
  baseurl = '';

  constructor(
    private httpClient: HttpClient,
    private http: HttpClient,
    private auth: AuthService,
    private localstorageService: LocalstorageService
  ) {

    this.baseurl = `${environment.serverUrl}/admin`;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
  }
  public getDadosLoja(): Observable<any[]> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    var lojas = this.getUser().stores;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    lojas.forEach((item: any, index: any) => {
      id_loja = item.store_id;
    });

    const body = {};

    if (environment.production) {
      return this.httpClient.post<any[]>(`${environment.serverUrl}/admin/show_stores`, body, this.httpOptions).pipe(
        catchError(this.errorHandl)
      );
    } else {
      return this.httpClient.post<any[]>(`${environment.paymentGenetatorLinkURL}/admin/show_stores`, body, this.httpOptions).pipe(
        catchError(this.errorHandl)
      );
    }
  }

  public getDadosLojaPorId(): Observable<Store[]> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    const body = {
      "store_id": id_loja,
      "domain": null,
      "subdomain": null
    }
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    if (environment.production) {
      return this.httpClient.post<Store[]>(`${environment.serverUrl}/admin/show_stores?store_id=${id_loja}`, this.httpOptions);
    } else {
      return this.httpClient.post<Store[]>(`${environment.paymentGenetatorLinkURL}/admin/show_stores?store_id=${id_loja}`, this.httpOptions);
    }


  }

  verificarDisponibilidadeSubdominio(subdomain: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    if (environment.production) {
      return this.http.get(`${environment.serverUrl}/shop/store/verify?subdomain=${subdomain}`, this.httpOptions);
    } else {
      return this.http.get(`${environment.paymentGenetatorLinkURL}/shop/store/verify?subdomain=${subdomain}`, this.httpOptions);
    }
  }
  verificarDisponibilidadeEmail(email: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    if (environment.production) {
      return this.http.get(`${environment.serverUrl}/users/verify?email=${email}`, this.httpOptions);
    } else {
      return this.http.get(`${environment.paymentGenetatorLinkURL}/users/verify?email=${email}`, this.httpOptions);
    }
  }
  createOnboarding(data: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    if (environment.production) {
      return this.http.post(`${environment.serverUrl}/users/onboarding`, data, this.httpOptions);
    } else {
      return this.http.post(`${environment.paymentGenetatorLinkURL}/users/onboarding`, data, this.httpOptions);
    }
  }

  insertNewEmail(data: any, user_id:number | undefined): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http.patch(`${environment.serverUrl}/users/email_public/${user_id}`, data, this.httpOptions);
    } else {
      return this.http.patch(`${environment.paymentGenetatorLinkURL}/users/email_public/${user_id}`, data, this.httpOptions);
    }

  }


  createDNSRecord(data: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    if (environment.production) {
      return this.http.post('https://api.cloudflare.com/client/v4/zones/{zone_id}/dns_records', data, this.httpOptions);
    } else {
      return this.http.post('https://api.cloudflare.com/client/v4/zones/{zone_id}/dns_records', data, this.httpOptions);
    }
  }

  public getConfigsLojaPorId(): Observable<Config[]> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    const body = {
      "store_id": id_loja,
      "domain": null,
      "subdomain": null
    }
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    if (environment.production) {
      return this.httpClient.get<Config[]>(`${environment.serverUrl}/admin/store/${id_loja}/settings`, this.httpOptions);
    } else {
      return this.httpClient.get<Config[]>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/settings`, this.httpOptions);
    }


  }

  updateDadosLoja(data: any): Observable<Store> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    if (environment.production) {
      return this.httpClient.patch<Store>(`${environment.serverUrl}/admin/store/${id_loja}`, data, this.httpOptions);
    } else {
      return this.httpClient.patch<Store>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}`, data, this.httpOptions);
    }
  }
  updateDadosLojaConfig(data: any): Observable<Store> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    if (environment.production) {
      return this.httpClient.patch<Store>(`${environment.serverUrl}/admin/store/${id_loja}/settings/configs`, data, this.httpOptions);
    } else {
      return this.httpClient.patch<Store>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/settings/configs`, data, this.httpOptions);
    }
  }
  updateUrlLoja(data: any): Observable<Store> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    if (environment.production) {
      return this.httpClient.patch<Store>(`${environment.serverUrl}/admin/store/${id_loja}/subdomain`, data, this.httpOptions);
    } else {
      return this.httpClient.patch<Store>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/subdomain`, data, this.httpOptions);
    }
  }
  detalharInfosLoja(data: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    if (environment.production) {
      return this.httpClient.post<any>(`${environment.serverUrl}/admin/store`, data, this.httpOptions);
    } else {
      return this.httpClient.post<any>(`${environment.paymentGenetatorLinkURL}/admin/store`, data, this.httpOptions);
    }
  }
  checkCEP(cep: any): Observable<any> {

    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    if (environment.production) {
      return this.httpClient.get<any>(`https://ws.hubdodesenvolvedor.com.br/v2/cep3/?cep=${cep}&token=116390195hnbTeuwZkO210138968`, this.httpOptions);
    } else {
      return this.httpClient.get<any>(`https://ws.hubdodesenvolvedor.com.br/v2/cep3/?cep=${cep}&token=116390195hnbTeuwZkO210138968`, this.httpOptions);
    }
  }

  // updateSubdominioLoja(loja: Store, store_id: string): Promise<any> {

  //   return this.http
  //     .patch(`${environment.serverUrlHostSwoole}/admin/subdomain/store/${store_id}`, loja, this.httpOptions)
  //     .toPromise()
  // }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessToken()}`
    }),
  };


  // Error handling
  errorHandl(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  private handleError2<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }


  getAccessToken(): string {
    return localStorage.getItem("access_token") || '';
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem("user") || '');
  }

}
