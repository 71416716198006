import { OnInit } from '@angular/core';
import { Component, ViewChild, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { HomeService } from 'src/app/services/home.service';
import { BillingService } from 'src/app/services/billing.service';
import { switchMap } from 'rxjs/operators';
@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit, OnDestroy {
    @ViewChild('menu') menu!: Menu;
    items!: MenuItem[];
    lojas = [
        { label: 'Loja ', value: 'loja1' },
        { label: 'Loja 2', value: 'loja2' },
    ];
    lojaSelecionada: string | null = null;
    model: any[] = [];

    constructor(private authService: AuthService, private router: Router, private homeService: HomeService, private billingService: BillingService) {
        this.lojaSelecionada = 'loja1';
    }

    mostrarDropdown: boolean = true;

    verExtrato() {
        this.router.navigate(['/extrato']);
    }

    formasPagamento() {
        // Lógica para "Formas de Pagamento"
    }

    taxasTarifas() {
        // Lógica para "Taxas e Tarifas"
    }
    openMenu(event: Event) {
        this.menu.toggle(event);
    }
    mostrarValores: boolean = true;
    carregando: boolean = false;
    atualizarValores() {
        this.carregando = true;
        const wallet = this.billingService.getWalletId();
        //////////////////////////////
        // this.homeService.balance(wallet)
        //     .subscribe(res => {

        //         this.saldoDisponivel = parseFloat(res.balance).toFixed(2);
        //         this.carregando = false;
        //     })

        // this.homeService.releaseBalance(wallet)
        //     .subscribe(res => {
        //         const saldo = parseFloat(res.balance).toFixed(2);
        //         this.saldoLiberar = saldo;
        //         this.carregando = false;
        //     })
    }

    alternarVisibilidade() {
        this.mostrarValores = !this.mostrarValores;
    }
    itemsButtons: MenuItem[] = [];
    itemsButtonsCobrar: MenuItem[] = [];
    itemsButtonsRetirar: MenuItem[] = [];
    saldoDisponivel: string = '0.00';
    saldoLiberar: string = '0.00';
    xT: number = 0;
    ngOnInit() {
        this.itemsButtonsCobrar = [
            { label: 'Links de pagamento', icon: 'pi pi-refresh' },
            { label: 'Links recentes', icon: 'pi pi-times' }
        ];
        this.itemsButtonsRetirar = [
            { label: 'Conta Bancária PIX', icon: 'pi pi-refresh' }
        ];
        this.items = [
            { label: 'Ver extrato', icon: 'pi pi-list', command: () => { this.verExtrato(); } },
            { label: 'Formas de Pagamento', icon: 'pi pi-credit-card', command: () => { this.formasPagamento(); } },
            { label: 'Taxas e Tarifas', icon: 'pi pi-tag', command: () => { this.taxasTarifas(); } }
        ];
        // this.model = [
        //     {
        //         items: [
        //             {
        //                 label: 'Dashbord',
        //                 icon: 'pi pi-fw pi-home',
        //                 routerLink: ['/']
        //             },
        //             {
        //                 label: 'Slicks',
        //                 icon: 'pi pi-fw pi-video',
        //                 routerLink: ['/lives']
        //             },
        //             {
        //                 label: 'Configurações',
        //                 icon: 'pi pi-fw pi-cog',
        //                 routerLink: ['/configurations']
        //             },
        //             // {
        //             //     label: 'Configurações',
        //             //     icon: 'pi pi-fw pi-cog',
        //             //     items: [
        //             //         {
        //             //             label: 'Player',
        //             //             icon: 'pi pi-fw pi-play',
        //             //             routerLink: ['/player']
        //             //         }
        //             //     ]
        //             // },
        //             // {
        //             //     label: 'Integrações',
        //             //     icon: 'pi pi-fw pi-globe',
        //             //     routerLink: ['/integrations']
        //             // },
        //         ]
        //     },
        //     {
        //         bottom: true,
        //         items: [
        //             {
        //                 label: 'Suporte',
        //                 icon: 'pi pi-fw pi-question-circle',
        //                 routerLink: ['/help']
        //             },
        //             // {
        //             //     label: 'Fale Conosco',
        //             //     icon: 'pi pi-fw pi-phone',
        //             //     routerLink: ['/contact']
        //             // },
        //             {
        //                 label: 'Sair',
        //                 icon: 'pi pi-fw pi-power-off',
        //                 command: () => this.resetUserToLogin()
        //             }
        //         ]
        //     }
        // ];

        this.model = [
            {
                items: [
                    {
                        label: 'Analytics',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/']
                    },
                    {
                        label: 'Slicks',
                        icon: 'pi pi-fw pi-video',
                        routerLink: ['/lives']
                    },
                    {
                        label: 'Widgets',
                        icon: 'bi bi-grid-1x2',
                        routerLink: ['/widgets']
                    },
                    {
                        label: 'Configurações',
                        icon: 'pi pi-fw pi-cog',
                        routerLink: ['/configurations']
                    }
                ]
            },
            { // Pseudo-separador, se necessário adicionar uma classe com `flex-grow: 1`
                separator: true
            },
            {
                items: [
                    {
                        label: 'Suporte',
                        icon: 'pi pi-fw pi-question-circle',
                        command: () => window.open('https://slicksell.crisp.chat', '_blank')
                    },
                    {
                        label: 'Sair',
                        icon: 'pi pi-fw pi-power-off',
                        command: () => this.resetUserToLogin()
                    }
                ]
            }
        ];

        if (this.xT == 0) {
            this.xT = 1;
            this.carregando = true;
            this.atualizacaoInterval = setTimeout(() => {
                this.atualizarValores();
            }, 1600);
        } else {
            this.atualizarValores();
        }

        //////////////////////////////
        //this.atualizarValores(); // Chama imediatamente ao iniciar
        this.atualizacaoInterval = setInterval(() => {
            this.atualizarValores();
        }, 120000); // 120000 milissegundos = 2 minutos
        //////////////////////////////
    }
    private atualizacaoInterval: any;
    isSelected(item: any): boolean {
        return this.router.isActive(item.routerLink[0], true);
    }
    goToMyPlayer() {
        const domainStore = localStorage.getItem('domain_store');
        if (domainStore) {
            const url = `https://${domainStore}`;
            window.open(url, '_blank');
        } else {
            console.error('domain_store not found in localStorage');
        }
    }
    ngOnDestroy() {
        if (this.atualizacaoInterval) {
            clearInterval(this.atualizacaoInterval);
        }
    }
    resetUserToLogin(): void {
        this.authService.logout()
            .subscribe(res => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('access_token_ebanking');
                localStorage.removeItem('domain_store');
                localStorage.removeItem('SelectedStoreId');
                localStorage.removeItem('user_name');
                localStorage.removeItem('user_id');
                localStorage.removeItem('use_trial');
                localStorage.removeItem('wallet_id');
                localStorage.removeItem('user_chat');
                localStorage.removeItem('user_ip');
                this.router.navigate(['/auth/login']);
            }, err => {
                if (err.status === 401) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('access_token_ebanking');
                    localStorage.removeItem('domain_store');
                    localStorage.removeItem('SelectedStoreId');

                    this.router.navigate(['/auth/login']);
                } else {
                    console.error('resetUserToLogin', err);
                }
            })
    }


}
