import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ConfGeraisService } from 'src/app/services/conf-gerais.service';
import { Store } from 'src/app/models/store';
import { ChangeDetectorRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { uploadData, getUrl } from 'aws-amplify/storage';
import { Observable, fromEvent } from 'rxjs';
import { from, defer } from 'rxjs';
import { tap, catchError, finalize, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { CountryService } from 'src/app/servicesApollo/country.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  ValidatorFn
} from '@angular/forms';
interface PhoneNumber {
  ddd: string;
  number: string;
}
@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent {
  storeName: string = '';
  storeDescription: string = '';
  urlStore: string = '';
  urlStoreBefore: string = '';
  address: string = '';
  nro: string = '';
  neighborhood: string = '';
  complement: string = '';
  city: string = '';
  country: any;
  state: string = '';
  zipcode: string = '';
  email: string = '';
  phoneDDD: string = '';
  phone: string = '';
  whatsDDD: string = '';
  whatsapp: string = '';
  selectedState: any;
  page: boolean = true;
  btnSave: boolean = true;
  manutantionMode: boolean = false;
  localhost: boolean = false;
  logoPreviewUrl: any = null;
  faviconPreviewUrl: any = null;
  logoSelected: boolean = false;
  saveBtnForm: boolean = false;
  primaryColor: string = '#00FF00';
  secondaryColor: string = '#FF0000';
  pageOptions: any[] = [];
  states: any[] = [];
  valuesDoms: string[] | undefined;
  constructor(
    private confirmationService: ConfirmationService,
    private countryService: CountryService,
    private configService: ConfGeraisService,
    public fb: FormBuilder,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef
  ) {
    this.issueForm = this.fb.group({
      // defina os controles do formulário aqui
      // exemplo: 'name': ['']
    });
  }

  extractPhoneNumber(phoneFull: string): PhoneNumber {
    const cleanedPhone = phoneFull.replace(/\D/g, ''); // Remove todos os caracteres não numéricos, incluindo espaços
    const ddd = cleanedPhone.substring(0, 2); // Extrai os dois primeiros dígitos para o DDD
    const number = cleanedPhone.substring(2); // O restante é o número

    console.log(number);
    console.log(ddd);

    return { ddd, number };
  }
  updateZipCode(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.zipcode = value.replace(/[^0-9]/g, '');
  }
  filterNonNumeric(event: KeyboardEvent) {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }
  filterSpecialChars(event: KeyboardEvent) {
    // Permite apenas letras (maiúsculas e minúsculas), números e espaço
    if (!/^[a-zA-Z0-9 ]$/.test(event.key)) {
      event.preventDefault();
    }
  }
  filterSpecialCharsUrl(event: KeyboardEvent) {
    // Permite apenas letras (maiúsculas e minúsculas), números e traços
    if (!/^[a-zA-Z0-9-]$/.test(event.key)) {
      event.preventDefault();
    }
  }
  cancelButton() {
    this.displaySidebar = false;
    this.configService.getDadosLojaPorId().subscribe({
      next: (res) => {
        console.log(res);
        this.storeName = res[0].store_name || '';
        this.storeDescription = res[0].store_description || '';
        this.urlStore = res[0].store_link_subdomain ? res[0].store_link_subdomain.split(".")[0] : '';
        this.address = res[0].store_street || '';
        this.nro = res[0].store_number || '';
        this.neighborhood = res[0].store_district || '';
        this.complement = res[0].store_complement || '';
        this.city = res[0].store_city || '';
        this.country = this.countries.find(country => country.name === res[0].store_country);
        // this.primaryColor = res[0].color_primary || '#00FF00'; // Valor padrão amarelo
        // this.secondaryColor = res[0].color_secondary || '#FF0000'; // Valor padrão vermelho
        this.email = res[0].support_email || '';
        this.zipcode = res[0].store_zip_code || '';
        this.manutantionMode = (res[0].maintenence_status == null || res[0].maintenence_status == 0) ? false : true;


        // Trata o telefone e o WhatsApp
        const phoneData = this.extractPhoneNumber((res[0].support_phone_number || '').replace(/\D/g, ''));
        this.phoneDDD = phoneData.ddd;
        this.phone = phoneData.number;

        const whatsappData = this.extractPhoneNumber((res[0].support_whatsapp || '').replace(/\D/g, ''));
        this.whatsDDD = whatsappData.ddd;
        this.whatsapp = whatsappData.number;
      },
      error: (err: any) => {
        console.error('Failed to set meta tags', err);
      }
    });


    this.getConfigs();
  }
  issueForm?: FormGroup;
  uploadFile(file: File, storageKey: string, contentTypeSend: string, bar: number): Observable<string> {
    return new Observable<string>(observer => {
      // Inicia o upload
      uploadData({
        key: storageKey,
        data: file,
        options: {
          accessLevel: 'guest',
          contentType: contentTypeSend,
          onProgress: (progressEvent) => {
            const progress = (progressEvent.transferredBytes / (progressEvent.totalBytes ?? 1)) * 100;
          }
        }
      });

      // Aguarda a conclusão do upload antes de emitir a URL rxjs
      defer(() => from(getUrl({ key: storageKey }))).pipe(
        switchMap(response => {
          observer.next(response.url.toString()); // Emite a URL
          observer.complete();
          return [];
        })
      ).pipe().subscribe({
        error: error => {
          observer.error(error); // Emite erro em caso de falha ao obter a URL
        }
      });
    });
  }
  selectedFileLogo: File | null = null;
  selectedFileFavicon: File | null = null;
  urlLogo: string = "";
  saveBtn3() {
    this.issueForm = this.fb.group({
      "store_link_subdomain": this.urlStore + '.slicksell.com.br'
    });



    this.configService.updateUrlLoja(this.issueForm.value).subscribe({
      next: (resp) => {
        this.saveBtnForm = false;
        localStorage.setItem('domain_store', this.urlStore + '.slicksell.com.br');
        localStorage.setItem('store_name', this.urlStore);
        this.btnSave = true;
        this.form3 = false;
        this.displaySidebar = false;
        console.log(resp)
        // Exiba uma mensagem de sucesso
        this.messageService.add({
          severity: 'success',
          summary: 'URL salva',
          detail: 'A URL da loja foi salva com sucesso!'
        });
      },
      error: (error) => {
        this.form3 = false;
        let message = '';
        this.saveBtnForm = false;
        this.btnSave = true;
        console.error('Error response', error);
        if (error.status === 400 || error.status === 403 || error.status === 401) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }
        this.errorURL = true;


      }
    });
  }
  generateUID() {
    return uuidv4();
  }

  logoTmp: string = "";
  faviconTmp: string = "";
  hasFaviconTmp: boolean = false;
  hasLogoTmp: boolean = false;
  saveBtn2() {
    ////////////////////////////////
    if (this.selectedFileLogo) {
      const storageKey = `uploads/images/${this.generateUID()}.jpeg`;
      const contentType = this.selectedFileLogo.type;
      this.uploadFile(this.selectedFileLogo, storageKey, contentType, 1).subscribe({
        next: (url: any) => {
          this.logoTmp = url.split('?')[0];
          this.hasLogoTmp = true;
          console.log('logo abaixo', url);
          if (this.selectedFileFavicon) {
            const storageKey2 = `uploads/images/${this.generateUID()}.jpeg`;
            this.uploadFile(this.selectedFileFavicon, storageKey2, contentType, 1).subscribe({
              next: (url2: any) => {
                //this.faviconPreviewUrl = url2.split('?')[0];
                this.faviconTmp = url2.split('?')[0];
                this.hasFaviconTmp = true;
                console.log('t3');
                // Chamar o método para atualizar as configurações após o sucesso do upload
                this.updateConfigurations();
              },
              error: (error) => {
                console.error('Erro no upload do arquivo:', error);
                // Tratar o erro de upload aqui
              }
            });
          } else {
            console.log('t2');
            this.updateConfigurations();
          }
        },
        error: (error) => {
          console.error('Erro no upload do arquivo:', error);
          // Tratar o erro de upload aqui
        }
      });
    } else {
      console.log('t1');
      if (this.selectedFileFavicon) {
        const contentType = this.selectedFileFavicon.type;
        const storageKey2 = `uploads/images/${this.generateUID()}.jpeg`;
        this.uploadFile(this.selectedFileFavicon, storageKey2, contentType, 1).subscribe({
          next: (url2: any) => {
            //this.faviconPreviewUrl = url2.split('?')[0];
            this.faviconTmp = url2.split('?')[0];
            this.hasFaviconTmp = true;
            console.log('t3');
            // Chamar o método para atualizar as configurações após o sucesso do upload
            this.updateConfigurations();
          },
          error: (error) => {
            console.error('Erro no upload do arquivo:', error);
            // Tratar o erro de upload aqui
          }
        });
      } else {
        console.log('t2');
        this.updateConfigurations();
      }
    }
    ////////////////////////////////

  }
  updateConfigurations() {
    const valoresString = JSON.stringify(this.valuesDoms);
    this.issueForm = this.fb.group({
      "dominios":
      {
        "key": "whitelist_domains",
        "value": valoresString,
      },
      "localhost":
      {
        "key": 'localhost_any',
        "value": this.localhost ? 1 : 0,
      },
      "logo":
      {
        "key": 'logo',
        "value": this.hasLogoTmp ? this.logoTmp : this.logoPreviewUrl,
      }
      ,
      "favicon":
      {
        "key": 'favicon',
        "value": this.hasFaviconTmp ? this.faviconTmp : this.faviconPreviewUrl,
      },
      "color1":
      {
        "key": 'Background Color',
        "value": this.primaryColor,
      },
      "color2":
      {
        "key": 'Background Font Color',
        "value": this.secondaryColor,
      }
    });
    this.configService.updateDadosLojaConfig(this.issueForm.value).subscribe({
      next: (resp) => {
        this.saveBtnForm = false;
        this.btnSave = true;
        this.form2 = false;
        this.hasFaviconTmp = false;
        this.hasLogoTmp = false;
        console.log(resp)
        this.displaySidebar = false;
        // Exiba uma mensagem de sucesso
        this.messageService.add({
          severity: 'success',
          summary: 'Configurações Salvas',
          detail: 'As configurações foram salvas com sucesso!'
        });
      },
      error: (error) => {
        this.form2 = false;
        let message = '';
        this.saveBtnForm = false;
        this.btnSave = true;
        console.error('Error response', error);
        if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 404) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }

        if (message) {
          this.showErrorDialog(message);
        }

      }
    });
  }
  saveBtn() {
    if (this.errorName) {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'O nome da loja contém erros!'
      });
      this.displaySidebar = false;
      return;
    }
    this.saveBtnForm = true;
    if (this.form1) {
      this.issueForm = this.fb.group({
        "store_name": this.storeName,
        "store_description": this.storeDescription,
        "support_email": this.email,
        "support_phone_number": this.phoneDDD + ' ' + this.phone,
        "support_whatsapp": this.whatsDDD + ' ' + this.whatsapp,
        "support_whatsapp_status": 0,
        "metadata_object_id": null,
        "store_zip_code": this.zipcode,
        "zipcode": null,
        "store_country": this.country?.name || '',
        "store_street": this.address,
        "store_number": this.nro,
        "store_district": this.neighborhood,
        "store_city": this.city,
        "store_uf": this.selectedState.value,
        "store_complement": this.complement,
        "show_address": 1,
        "maintenence_status": this.manutantionMode == true ? 1 : 0,
        "status": 1
      });
      console.log(this.country)
      console.log('passou form');
      this.configService.updateDadosLoja(this.issueForm.value).subscribe({
        next: (resp) => {
          this.saveBtnForm = false;
          this.btnSave = true;
          this.form1 = false;
          this.displaySidebar = false;
          console.log(resp)
          // Exiba uma mensagem de sucesso
          this.messageService.add({
            severity: 'success',
            summary: 'Dados Salvos',
            detail: 'Os dados foram salvos com sucesso!'
          });
        },
        error: (error) => {
          this.form1 = false;
          let message = '';
          this.saveBtnForm = false;
          this.btnSave = true;
          console.error('Error response', error);
          if (error.status === 400 || error.status === 403 || error.status === 401) {
            message = error.error.message;
          } else if (error.status === 500) {
            message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
          }

          if (message) {
            this.showErrorDialog(message);
          }

        }
      });
    }
    if (this.form2) {
      this.saveBtn2();
    }
    if (this.form3) {
      this.saveBtn3();
    }
  }
  displaySidebar: boolean = true;
  countries: any[] = [];
  manageOverlay() {
    const overlays = document.querySelectorAll('.p-component-overlay');
    const overlayEnters = document.querySelectorAll('.p-component-overlay-enter');

    overlays.forEach((overlay: Element) => {
      (overlay as HTMLElement).style.display = 'none';
    });

    overlayEnters.forEach((overlayEnter: Element) => {
      (overlayEnter as HTMLElement).style.display = 'none';
    });

    this.cdr.detectChanges();
  }

  toggleSidebar() {
    this.displaySidebar = false;
    setTimeout(() => {
      this.manageOverlay();
    }, 500);
  }
  toggleSidebar2() {
    this.displaySidebar = false;
    setTimeout(() => {
      this.manageOverlay();
    }, 10);
  }


  displayAguarde: boolean = false;
  ngOnInit() {

    this.toggleSidebar();
    this.countryService.getCountries().then(countries => {
      this.countries = countries;
    });
    this.displayAguarde = true;
    this.configService.getDadosLojaPorId().subscribe({
      next: (res) => {
        console.log(res);
        this.storeName = res[0].store_name || '';
        this.storeDescription = res[0].store_description || '';
        this.urlStore = res[0].store_link_subdomain ? res[0].store_link_subdomain.split(".")[0] : '';

        this.urlStoreBefore = this.urlStore;

        this.address = res[0].store_street || '';
        this.nro = res[0].store_number || '';
        console.log('store uf: ' + res[0].store_uf)
        this.selectedState = { label: res[0].store_uf || 'SP', value: res[0].store_uf || 'SP' };;
        this.cdr.detectChanges();
        this.neighborhood = res[0].store_district || '';
        this.complement = res[0].store_complement || '';
        this.city = res[0].store_city || '';
        this.country = this.countries.find(country => country.name === res[0].store_country);
        this.primaryColor = res[0].color_primary || '#00FF00'; // Valor padrão amarelo
        this.secondaryColor = res[0].color_secondary || '#FF0000'; // Valor padrão vermelho
        this.email = res[0].support_email || '';
        this.zipcode = res[0].store_zip_code || '';
        this.manutantionMode = (res[0].maintenence_status == null || res[0].maintenence_status == 0) ? false : true;


        // Trata o telefone e o WhatsApp
        const phoneData = this.extractPhoneNumber((res[0].support_phone_number || '').replace(/\D/g, ''));
        this.phoneDDD = phoneData.ddd;
        this.phone = phoneData.number;

        const whatsappData = this.extractPhoneNumber((res[0].support_whatsapp || '').replace(/\D/g, ''));
        this.whatsDDD = whatsappData.ddd;
        this.whatsapp = whatsappData.number;


        this.displayAguarde = false;
        // setTimeout(() => {
        //   this.displayAguarde = false;
        // }, 10000)
      },
      error: (err: any) => {
        this.displayAguarde = false;
        console.error('Failed to set meta tags', err);
      }
    });


    /// exibir imagem e logo
    this.getConfigs();

    this.pageOptions = [
      { name: 'Geral', value: 1 },
      { name: 'Atendimento', value: 2 },
      { name: 'Integrações', value: 3 },
    ];
    this.pageConfigs = this.pageOptions[0];


    this.states = [
      { label: 'AC', value: 'AC' },
      { label: 'AL', value: 'AL' },
      { label: 'AP', value: 'AP' },
      { label: 'AM', value: 'AM' },
      { label: 'BA', value: 'BA' },
      { label: 'CE', value: 'CE' },
      { label: 'DF', value: 'DF' },
      { label: 'ES', value: 'ES' },
      { label: 'GO', value: 'GO' },
      { label: 'MA', value: 'MA' },
      { label: 'MT', value: 'MT' },
      { label: 'MS', value: 'MS' },
      { label: 'MG', value: 'MG' },
      { label: 'PA', value: 'PA' },
      { label: 'PB', value: 'PB' },
      { label: 'PR', value: 'PR' },
      { label: 'PE', value: 'PE' },
      { label: 'PI', value: 'PI' },
      { label: 'RJ', value: 'RJ' },
      { label: 'RN', value: 'RN' },
      { label: 'RS', value: 'RS' },
      { label: 'RO', value: 'RO' },
      { label: 'RR', value: 'RR' },
      { label: 'SC', value: 'SC' },
      { label: 'SP', value: 'SP' },
      { label: 'SE', value: 'SE' },
      { label: 'TO', value: 'TO' }
    ];

  }


  getConfigs() {
    this.configService.getConfigsLojaPorId().subscribe({
      next: (res) => {
        console.log('configs');
        console.log(res);

        // Inicializa as variáveis com valores padrão ou mantém vazias
        this.primaryColor = '';
        this.secondaryColor = '';
        this.faviconPreviewUrl = '';
        this.logoPreviewUrl = '';

        // Itera sobre o array de configurações para encontrar os valores necessários
        res.forEach((config) => {
          if (config.key === 'Background Color') {
            this.primaryColor = config.value;
          } else if (config.key === 'Background Font Color') {
            this.secondaryColor = config.value;
          } else if (config.key === 'favicon') {
            this.faviconPreviewUrl = config.value;
          } else if (config.key === 'logo') {
            this.logoPreviewUrl = config.value;
          } else if (config.key === 'whitelist_domains') {
            try {
              const parsedValue = JSON.parse(config.value);
              if (Array.isArray(parsedValue)) {
                this.valuesDoms = parsedValue;
                this.showClearDoms = parsedValue.length > 0;
              } else {
                this.valuesDoms = []; // Define como array vazio se não for um array
                //console.error('Expected an array for whitelist_domains, received:', parsedValue);
              }
            } catch (error) {
              console.error('Error parsing whitelist_domains:', error);
              this.valuesDoms = []; // Caso a análise falhe
            }
          } else if (config.key === 'localhost_any') {
            this.localhost = config.value == "1" ? true : false;
          }
        });
      },
      error: (err: any) => {
        console.error('Failed to set meta tags', err);
      }
    });
  }
  pageConfigs?: any;
  activeOption: number = 1;
  onChangeSource: string = '';
  selecionarOpcao(opcao: number): void {
    this.onChangeSource = 'selectButton';
    this.activeOption = opcao;
  }

  changePage() {

  }
  onStateChange() {

  }
  erroTamanhoImagem: boolean = false;
  erroTipoImagem: boolean = false;
  onLogoSelect(event: any): void {
    this.erroTamanhoImagem = false;
    this.erroTipoImagem = false;
    this.form2 = true;
    if (event.files && event.files.length > 0) {
      this.selectedFileLogo = event.files[0]; // Atribuir o arquivo selecionado à variável selectedFile

      // Verificar o tamanho do arquivo (máximo 1MB)
      if (this.selectedFileLogo && this.selectedFileLogo.size > 1048576) {
        this.erroTamanhoImagem = true;
        this.selectedFileLogo = null;
        this.logoPreviewUrl = false;
        return;
      }

      // Verificar o tipo do arquivo (webp, png, jpg, jpeg)
      const fileType = this.selectedFileLogo?.type;
      if (fileType && !['image/webp', 'image/png', 'image/jpg', 'image/jpeg'].includes(fileType)) {
        this.erroTipoImagem = true;
        this.selectedFileLogo = null;
        this.logoPreviewUrl = false;
        return;
      }

      if (this.selectedFileLogo) { // Verifica se realmente existe um arquivo antes de prosseguir
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.logoPreviewUrl = e.target.result; // Atribui a URL da imagem para visualização
          this.checkSaveBtn();
          this.form2 = true;
          this.displaySidebar = true;
        };
        //this.toggleSidebar2();
        reader.readAsDataURL(this.selectedFileLogo);
      }
    } else {
      this.selectedFileLogo = null; // Resetar selectedFile se nenhum arquivo for selecionado
      this.logoPreviewUrl = null;
    }
  }
  erroTamanhoImagem2: boolean = false;
  erroTipoImagem2: boolean = false;
  onFaviconSelect(event: any): void {

    this.form2 = true;
    this.erroTamanhoImagem2 = false;
    this.erroTipoImagem2 = false;
    if (event.files && event.files.length > 0) {
      this.selectedFileFavicon = event.files[0]; // Atribuir o arquivo selecionado à variável selectedFile

      // Verificar o tamanho do arquivo (máximo 1MB)
      if (this.selectedFileFavicon && this.selectedFileFavicon.size > 1048576) {
        this.erroTamanhoImagem2 = true;
        this.selectedFileFavicon = null;
        this.faviconPreviewUrl = false;
        return;
      }

      // Verificar o tipo do arquivo (webp, png, jpg, jpeg)
      const fileType = this.selectedFileFavicon?.type;
      if (fileType && !['image/webp', 'image/png', 'image/jpg', 'image/jpeg'].includes(fileType)) {
        this.erroTipoImagem2 = true;
        this.selectedFileFavicon = null;
        this.faviconPreviewUrl = false;
        return;
      }
      

      if (this.selectedFileFavicon) { // Verifica se realmente existe um arquivo antes de prosseguir
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.faviconPreviewUrl = e.target.result; // Atribui a URL da imagem para visualização
          this.checkSaveBtn();
          this.form2 = true;
          this.displaySidebar = true;
          
        };
        //this.toggleSidebar();
        reader.readAsDataURL(this.selectedFileFavicon);
      }
    } else {
      this.selectedFileFavicon = null; // Resetar selectedFile se nenhum arquivo for selecionado
      this.faviconPreviewUrl = null;
    }
  }

  removelogo(event: Event): void {
    this.displaySidebar = true;
    setTimeout(() => {
      this.manageOverlay();
    }, 1);
    event.stopPropagation();
    this.logoPreviewUrl = null;
    this.selectedFileLogo = null;
    this.form2 = true;
    this.checkSaveBtn();
  }
  removelogoFavicon(event: Event): void {
    this.displaySidebar = true;
    setTimeout(() => {
      this.manageOverlay();
    }, 1);
    event.stopPropagation();
    this.faviconPreviewUrl = null;
    this.selectedFileFavicon = null;
    this.form2 = true;
    this.checkSaveBtn();
  }

  checkSaveBtn() {
    console.log('checando save');
    if (this.errorName == false) {
      this.btnSave = false;
    } else {
      this.form2 = false;
      this.form1 = false;
      this.form3 = false;
    }
  }

  updatePrimaryColor(value: string | object): void {
    const color = typeof value === 'string' ? value : '';
    this.checkSaveBtn();
    this.form2 = true;
  }
  updateSecondaryColor(value: string | object): void {
    const color = typeof value === 'string' ? value : '';
    this.checkSaveBtn();
    this.form2 = true;
  }
  allowOnlyNumbers(event: KeyboardEvent) {
    // Permite apenas dígitos numéricos
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }
  mask: string = '0000-0000';
  mask2: string = '0000-0000||00000-0000';
  updateMask(value: string) {
    if (value && value.length <= 9) { // Adapte este número ao ponto de transição entre as máscaras
      this.mask = '00000-000'; // Máscara para números com 8 dígitos após o DDD
    } else {
      this.mask = '00000-0000'; // Máscara para números com 9 dígitos após o DDD
    }
    //this.form1 = true;
  }
  limitInput(event: any) {
    const value = event.target.value;
    if (value.length > 2) {
      event.target.value = value.slice(0, 2);
      // Atualize o valor no seu modelo, se necessário
      // Isso pode ser necessário porque a atualização direta do valor do input
      // pode não refletir automaticamente no modelo Angular.
    }
  }
  preventE(event: KeyboardEvent) {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  }

  showSlickClearFunction() {
    if (this.valuesDoms && this.valuesDoms?.length > 0) {
      this.showClearDoms = true;
    } else {
      this.showClearDoms = false;
    }
  }
  showClearDoms: boolean = false;
  clearSlickMaps() {
    this.valuesDoms = [];
    this.showClearDoms = false;


  }
  showErrorDialog(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Erro',
      icon: 'pi pi-times',
      accept: () => {
        // Lógica de aceitação, se necessário
      }
    });
  }

  form1: boolean = false;
  form2: boolean = false;
  form3: boolean = false;
  checkInput() {
    this.errorName = this.storeName.trim().length === 0;
    if (this.errorName) {
      this.btnSave = true;
      this.displaySidebar = false;
      this.form1 = false;
    } else {
      this.btnSave = false;
    }
  }
  formToSave1() { // removelogo checkInput
    this.displaySidebar = true;
    setTimeout(() => {
      this.manageOverlay();
    }, 1);
    console.log('this.country');
    console.log('onchange from input');
    this.form1 = true;
    this.btnSave = false;
  }
  formToSave2() {
    this.displaySidebar = true;
    setTimeout(() => {
      this.manageOverlay();
    }, 1);
    this.form2 = true;
    this.btnSave = false;
  }

  checkCEP() {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.configService.checkCEP(this.zipcode).subscribe({
        next: (resp) => {
          console.log('check', resp);
          if (resp.status) {
            this.address = resp.result.logradouro;
            this.neighborhood = resp.result.bairro;
            this.complement = resp.result.complemento;
            this.city = resp.result.localidade;
            this.selectedState = { label: resp.result.uf || 'SP', value: resp.result.uf || 'SP' };;
          }
        },
        error: (error) => {

        }
      });
    }, 750);
  }

  private debounceTimer: any = null;
  formToSave3() {

    this.errorURL = false;
    clearTimeout(this.debounceTimer);
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.configService.verificarDisponibilidadeSubdominio(this.urlStore).subscribe({
        next: (res: any) => {
          if (res.message == "Não existe Loja com o subdomínio informado.") {
            console.log(res);
            this.form3 = true;
            this.btnSave = false;
            this.displaySidebar = true;
            setTimeout(() => {
              this.manageOverlay();
            }, 1);
          }


        },
        error: (error: any) => {
          this.displaySidebar = false;
          this.urlStore = this.urlStoreBefore;
          this.form3 = false;
          this.errorURL = true;
          this.btnSave = true;
          let message = '';
          this.saveBtnForm = false;
          this.btnSave = true;
          console.error('Error response', error);
          if (error.status === 400 || error.status === 403 || error.status === 401) {
            //message = error.error.message;
            this.message = error.error.message;
          } else if (error.status === 500) {
            //message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
            this.message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
          }

          // if (message) {
          //   this.showErrorDialog(message);
          // }
        }
      });
    }, 750);

  }
  message: string = "";
  errorURL: boolean = false;
  errorName: boolean = false;

}
