<div class="flex flex-column ">
    <div class="p-3 md:px-4 lg:px-6 lg:py-4 mt-2 overflow-y-auto" #chatWindow [scrollTop]="chatWindow.scrollHeight"
        style="max-height: 50vh;">
        <div *ngFor="let message of messages">
            <div *ngIf="message.isOwnMessage; else otherUserMessage">
                <div class="grid grid-nogutter mb-4">
                    <div class="col mt-3 text-right">
                        <p class="text-900 text-xs font-semibold mb-3">From: Você</p>
                        <span
                            class="inline-block text-left font-medium border-1 surface-border bg-primary-100 text-primary-900 p-3 white-space-normal border-round text-xs"
                            style="word-break: break-word; max-width:60%;">{{message.text}}</span>
                        <p class="text-700 text-xs mt-3">{{message.time}} <i
                                class="pi pi-check ml-2 text-green-400"></i></p>
                    </div>
                </div>
            </div>
            <ng-template #otherUserMessage>
                <div class="grid grid-nogutter mb-4">
                    <div class="col mt-3 text-left">
                        <p class="text-900 text-xs font-semibold mb-3">From: {{ message.from }}</p>
                        <span
                            class="text-700 text-xs inline-block font-medium border-1 surface-border p-3 white-space-normal border-round"
                            style="word-break: break-word; max-width:60%;">{{ message.text }}</span>
                        <p class="text-700 text-xs mt-3">{{ message.time }}</p>
                    </div>
                </div>
            </ng-template>

        </div>

    </div>
    <div
        class="ss-g-input-group-chat p-1 md:p-1 lg:p-1 flex flex-column sm:flex-row align-items-center mt-auto border-top-1 surface-border gap-1">
        <input id="message" type="text" pInputText placeholder="Type a message222" class="ss-g-input-text-chat"
            [(ngModel)]="textContent" (keydown.enter)="sendMessage()" />
        <div class="ss-g-button-container-chat">
            <div class="justify-content-center text-2xl ss-g-cursor-pointer p-2" (click)="op.toggle($event)">😀</div>
            <div class="justify-content-center text-2xl ss-g-cursor-pointer p-2" (click)="sendMessage()"><i
                    class="pi pi-send"></i></div>
        </div>
    </div>

</div>

<p-overlayPanel #op styleClass="w-full sm:w-30rem ss-g-custom-z-index-emojis">
    <ng-template pTemplate>
        <button *ngFor="let emoji of emojis;" pButton pRipple (click)="op.hide(); onEmojiSelect(emoji)" type="button"
            [label]="emoji" class="p-2 p-button-text text-2xl"></button>
    </ng-template>
</p-overlayPanel>