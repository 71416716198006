import { Injectable } from '@angular/core';
import { IUserData } from '../@types/UserData';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor() { }

  getUser(): IUserData {
    return JSON.parse(localStorage.getItem("user") || '');
  }

  setSelectedItems(selectedItens: any): void {
    localStorage.setItem("selectedItens", JSON.stringify(selectedItens));
  }

  setSelectedItemsFilho(selectedItensFilho: any): void {
    localStorage.setItem("selectedItensFilho", JSON.stringify(selectedItensFilho));
  }

  clearItemsFilho(): void {
    localStorage.setItem("selectedItensFilho", "");
  }


  getSelectedItems(): any {
    return JSON.parse(localStorage.getItem("selectedItens") || '');
  }

  getSelectedItemsFilho(): any {
    return JSON.parse(localStorage.getItem("selectedItensFilho") || '');
  }

  ocultarNavegacao() {
    localStorage.setItem("mostrarNavegacao", "false");
  }
  mostrarNavegacao() {
    localStorage.setItem("mostrarNavegacao", "true");
  }

  getMostrarNavegacao(): any {
    return JSON.parse(localStorage.getItem("mostrarNavegacao") || '');
  }

  setId(valorId: any): void {
    localStorage.setItem("valueId", valorId);
  }
  getId(): any {
    return JSON.parse(localStorage.getItem("valueId") || '');
  }

  setDomain(valorId: any): void {
    console.log('setando subdomain')
    localStorage.setItem("domain_store", valorId);
  }
  getDomain(): any {
    return localStorage.getItem("domain_store");
  }
  setSelectedStoreId(valorId: any): void {
    localStorage.setItem("SelectedStoreId", valorId);
  }
  setSelectedStoreName(name: any): void {
    localStorage.setItem("store_name", name);
  }
  getSelectedStoreId(): any {
    return localStorage.getItem("SelectedStoreId");
  }
  setAllProducts(data: any): void {
    localStorage.setItem("allProducts", JSON.stringify(data));
  }
  getAllProducts(): any {
    return JSON.parse(localStorage.getItem("allProducts") || '');
  }
  setAllProductsFilhos(data: any): void {
    localStorage.setItem("allProductsFilhos", JSON.stringify(data));
  }
  getAllProductsFilhos(): any {
    return JSON.parse(localStorage.getItem("allProductsFilhos") || '');
  }
  getAllProductsPais(): any {
    return JSON.parse(localStorage.getItem("allProductsPais") || '');
  }
  setAllProductsPais(data: any): void {
    localStorage.setItem("allProductsPais", JSON.stringify(data));
  }

  setAllCategorys(data: any): void {
    localStorage.setItem("allCategorys", JSON.stringify(data));
  }
  getAllCategorys(): any {
    return JSON.parse(localStorage.getItem("allCategorys") || '');
  }
  setAllCollections(data: any): void {
    localStorage.setItem("allCollections", JSON.stringify(data));
  }
  getAllCollections(): any {
    return JSON.parse(localStorage.getItem("allCollections") || '');
  }
  setAllOrders(data: any): void {
    localStorage.setItem("allOrders", JSON.stringify(data));
  }
  getAllOrders(): any {
    return JSON.parse(localStorage.getItem("allOrders") || '');
  }
  setAllMenuNavigations(data: any): void {
    localStorage.setItem("allMenuNavigations", JSON.stringify(data));
  }
  getAllMenuNavigations(): any {
    return JSON.parse(localStorage.getItem("allMenuNavigations") || '');
  }
  setAllClients(data: any): void {
    localStorage.setItem("allClients", JSON.stringify(data));
  }
  getAllClients(): any {
    return JSON.parse(localStorage.getItem("allClients") || '');
  }
  setAllPromotions(data: any): void {
    localStorage.setItem("allPromotions", JSON.stringify(data));
  }
  getAllPromotions(): any {
    return JSON.parse(localStorage.getItem("allPromotions") || '');
  }

  getUniqueID(): any {
    return localStorage.getItem("uniqueId");
  }

  set(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  get(key: string): any {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  }
}
