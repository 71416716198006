
<div class="grid">
    <div class="col-12 md:col-6 xl:col-3 cursor-pointer" (click)="openSidebar()">
        <div class="ss-g-hover-border h-full flex-center">
            <i class="pi pi-plus-circle text-5xl mb-2"></i>
            <span class="text-base font-medium">Adicionar</span>

        </div>

    </div>
    <div class="col-12 md:col-6 xl:col-3">
        <div class="card h-full">
            <ng-container *ngIf="!loading; else loadingTemplate">
                <!-- <ng-container> -->
                <div class="flex justify-content-between align-items-center">
                    <span class="font-semibold text-lg">
                        <i class="pi pi-clock mr-2"></i> Tempo Assistido
                    </span>
                    <div class="ml-auto">
                        <i class="bi bi-info-circle"
                            pTooltip="Representa o tempo médio de permanência dos usuários nos slicks (shorts e lives). É considerado um tempo mínimo de 5% de progresso para começar a contabilizar)."
                            tooltipPosition="right"></i>
                    </div>
                </div>
                <div class="flex justify-content-between align-items-start mt-3">
                    <div class="w-12">
                        <span class="text-4xl font-bold text-900">{{ timeWatched.toFixed(2) }}% </span>
                        <div class="text-green-500">
                            <span class="font-medium">
                                <span *ngIf="percentageWatched == 0">
                                    -
                                </span>
                                <span *ngIf="percentageWatched > 0">
                                    +{{ percentageWatched | number:'1.0-2' }} <i class="pi pi-arrow-up"></i>
                                </span>

                                <!-- Para porcentagem negativa -->
                                <span *ngIf="percentageWatched < 0">
                                    {{ percentageWatched | number:'1.0-2' }} <i class="pi pi-arrow-down"></i>
                                </span>
                            </span>
                            <!-- <i *ngIf="(porcentagemAguardando ?? 0) > 0" class="pi pi-arrow-up text-xs ml-2"></i>
                            <i *ngIf="(porcentagemAguardando ?? 0) < 0" class="pi pi-arrow-down text-xs ml-2"></i> -->


                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #loadingTemplate>
                <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
                <p-skeleton width="60%" height="1rem"></p-skeleton>
            </ng-template>
        </div>
    </div>
    <div class="col-12 md:col-6 xl:col-3">
        <div class="card h-full">
            <ng-container *ngIf="!loading; else loadingTemplate">
                <!-- <ng-container> -->
                <div class="flex justify-content-between align-items-center">
                    <span class="font-semibold text-lg">
                        <i class="bi bi-people-fill mr-2"></i> Visitantes
                    </span>
                    <div class="ml-auto">
                        <i class="bi bi-info-circle"
                            pTooltip="Quantidade total de visitantes que visualizaram os slicks de sua loja (shorts e lives). Contabilizamos apenas 1 visitante ao visualizar um ou mais slicks (não será contabilizado novamente caso o usuário atualize a página ou entre no link novamente)."
                            tooltipPosition="right"></i>
                    </div>
                </div>
                <div class="flex justify-content-between align-items-start mt-3">
                    <div class="w-12">
                        <span class="text-4xl font-bold text-900">{{ countEspectadores }}</span>

                        <div class="text-pink-500">
                            <span class="font-medium">
                                <span *ngIf="percentageEspectadores == 0">
                                    -
                                </span>
                                <span *ngIf="percentageEspectadores > 0">
                                    +{{ percentageEspectadores | number:'1.0-2' }} <i class="pi pi-arrow-up"></i>
                                </span>

                                <!-- Para porcentagem negativa -->
                                <span *ngIf="percentageEspectadores < 0">
                                    {{ percentageEspectadores | number:'1.0-2' }} <i class="pi pi-arrow-down"></i>
                                </span>
                            </span>
                            <!-- <i *ngIf="(porcentagemExpiradas ?? 0) > 0" class="pi pi-arrow-up text-xs ml-2"></i>
                            <i *ngIf="(porcentagemExpiradas ?? 0) < 0" class="pi pi-arrow-down text-xs ml-2"></i> -->

                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #loadingTemplate>
                <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
                <p-skeleton width="60%" height="1rem"></p-skeleton>
            </ng-template>
        </div>
    </div>
    <div class="col-12 md:col-6 xl:col-3">
        <div class="card h-full">
            <ng-container *ngIf="!loading; else loadingTemplate">
                <!-- <ng-container> -->
                <div class="flex justify-content-between align-items-center">
                    <span class="font-semibold text-lg">
                        <i class="bi bi-heart-fill mr-2"></i> Likes
                    </span>
                    <div class="ml-auto">
                        <i class="bi bi-info-circle" pTooltip="Quantidade de curtidas de um slick."
                            tooltipPosition="left"></i>
                    </div>
                </div>
                <div class="flex justify-content-between align-items-start mt-3">
                    <div class="w-12">
                        <span class="text-4xl font-bold text-900">{{ countLikes }}</span>
                        <div class="text-green-500">
                            <span class="font-medium">
                                <span *ngIf="percentageLikes == 0">
                                    -
                                </span>
                                <span *ngIf="percentageLikes > 0">
                                    +{{ percentageLikes | number:'1.0-2' }} <i class="pi pi-arrow-up"></i>
                                </span>

                                <!-- Para porcentagem negativa -->
                                <span *ngIf="percentageLikes < 0">
                                    {{ percentageLikes | number:'1.0-2' }} <i class="pi pi-arrow-down"></i>
                                </span>
                            </span>
                            <!-- <i *ngIf="(porcentagemCanceladas ?? 0) > 0" class="pi pi-arrow-up text-xs ml-2"></i>
                            <i *ngIf="(porcentagemCanceladas ?? 0) < 0" class="pi pi-arrow-down text-xs ml-2"></i> -->

                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #loadingTemplate>
                <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
                <p-skeleton width="60%" height="1rem"></p-skeleton>
            </ng-template>
        </div>
    </div>
</div>
<div class="grid">
    <div class="col-12">
        <div class="card">
            <p-dataView #dv [value]="filteredAssets" [paginator]="true" [rows]="12" filterBy="name"
                [rowsPerPageOptions]="[12, 24, 96]" [sortField]="sortField" [sortOrder]="sortOrder" layout="grid">
                <ng-template pTemplate="header">

                    <div class="mb-2" style="display: flex; justify-content: space-between; width: 100%;">
                        <div class="flex align-items-center">
                            <p-dataViewLayoutOptions class="flex mb-2 mr-2"></p-dataViewLayoutOptions>
                        </div>
                        <div class="flex align-items-center">
                            <button pButton pRipple type="button" icon="bi bi-three-dots-vertical" [disabled]="true"
                                style="max-height: 39px;" (click)="menu.toggle($event)"
                                class="p-element p-ripple p-datepicker-trigger p-button-icon-only p-button p-component p-3"></button>
                            <p-menu #menu [popup]="true" [model]="itemsButtons" appendTo="body"></p-menu>
                        </div>
                    </div>
                    <div class="caption-container">
                        <div class="left-section">
                            <p-calendar [(ngModel)]="rangeDates" class=" mb-2 calendar-grid" selectionMode="range"
                                (onSelect)="onDateSelect()" [showIcon]="true"></p-calendar>

                            <div class="flex">
                                <button pButton pRipple label="Filtros" (click)="op2.toggle($event)"
                                    class="flex clear-button mb-2 p-button-outlined mr-2 b1 "></button>
                                <p-overlayPanel #op2 [showCloseIcon]="true">
                                    <ng-template pTemplate>
                                        <!--              -->
                                        <!-- p-dropdown que busca por live ou shorts -->
                                        <div class="mb-2">
                                            <label class="ss-g-globalClassLabel"> Tipo de Slick</label>
                                            <div class=" mb-2"></div>
                                            <p-dropdown [options]="typeOptions" class="mt-2" [(ngModel)]="selectedType"
                                                (onChange)="onTypeSelect()" placeholder="Selecione o tipo">
                                                <ng-template let-option pTemplate="item">
                                                    <p-tag [icon]="getIconType(option.value)" [value]="option.label"
                                                        [severity]="getSeverity(option.value)"></p-tag>
                                                </ng-template>
                                            </p-dropdown>

                                        </div>
                                        <!-- busca por ao vivo -->
                                        <!-- <div class="mb-2">
                                            <label class="ss-g-globalClassLabel"> Stream Status</label>
                                            <div class=" mb-2"></div>
                                            <p-dropdown [options]="streamStatusOptions"
                                                [(ngModel)]="selectedStreamStatus" (onChange)="onStreamStatusSelect()"
                                                class="mb-2" placeholder="Select Stream Status">
                                                <ng-template let-option pTemplate="item">
                                                    <p-tag [value]="option.label"
                                                        [severity]="getSeverityStreamStatusDropdown(option.value)"></p-tag>
                                                </ng-template>
                                            </p-dropdown>
                                        </div> -->
                                        <!-- busca por ao publicado -->
                                        <div class="mb-2">
                                            <label class="ss-g-globalClassLabel"> Status do Slick</label>
                                            <div class=" mb-2"></div>
                                            <p-dropdown [options]="statusOptions" [(ngModel)]="selectedStatus"
                                                [ngClass]="'mb-2'" (onChange)="onStatusSelect()"
                                                placeholder="Select Status">
                                                <ng-template let-option pTemplate="item">
                                                    <p-tag [value]="option.label"
                                                        [severity]="getSeverityStatusDropdown(option.value)"></p-tag>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <!-- <p-tag [value]="getStatusText(item.media.status, item.schedule?.date_from)"
                                        class="tag-item ml-2"
                                        [severity]="getSeverityStatus(item.media.status, item.schedule?.date_from)"
                                        [rounded]="true"></p-tag> -->
                                        <!-- busca por nome -->
                                        <label class="ss-g-globalClassLabel"> Nome</label>
                                        <div class=" mb-2"></div>
                                        <span class="p-input-icon-left mb-2 w-full">
                                            <i class="pi pi-search"></i>
                                            <input type="search" [(ngModel)]="searchTerm" pInputText class="w-full"
                                                placeholder="Buscar por nome" (input)="onFilterSlicks($event)">
                                        </span>
                                        <div class=" mb-2"></div>
                                        <button pButton pRipple icon="pi pi-filter-slash " label="Limpar Filtros"
                                            (click)="clearFilter()"
                                            class="flex clear-button-inner mb-2 p-button-outlined w-full"></button>
                                        <!--              -->
                                    </ng-template>
                                </p-overlayPanel>
                                <button pButton pRipple icon="pi pi-filter-slash " (click)="clearFilter()"
                                    class="mb-2 p-button-outlined p-element p-ripple p-datepicker-trigger p-button-icon-only p-button p-component ss-l-w-3rem"></button>
                            </div>
                        </div>




                    </div>
                </ng-template>

                <ng-template let-asset pTemplate="listItem">
                    <div class="col-12 " *ngFor="let item of asset.items">
                        <div
                            class="flex flex-column md:flex-row justify-content-center md:align-items-center p-3 w-full">
                            <!-- <ng-container
                                *ngIf="item.media.src !== null && item.media.src !== '' && !item.media.thumbnail">
                                <div class="col-12 sm:col-2 md:col-2 xl:col-2">
                                    <div class="video-upload-container ">
                                        <div class="ss-l-video-container-inner flex">

                                            <video #videoPlayer
                                                [src]="replaceDomain(item.media.src.split('?')[0])+'?ngsw-bypass=true'"
                                                controls poster="item.media.thumbnail.split('?')[0]" controls="false"
                                                muted [autoplay]="false" [muted]="true" class="shadow-2 video-preview"
                                                style="min-width: 100%;" (click)="playVideo(videoPlayer)"
                                                (mouseenter)="startVideo(videoPlayer)"
                                                (mouseleave)="resetVideo(videoPlayer)" playsinline></video>
                                        </div>
                                    </div>
                                </div>
                            </ng-container> -->


                            <ng-container
                                *ngIf="item.media.src !== null && item.media.src !== '' && (item.media.thumbnail !== null && item.media.thumbnail !== '')">
                                <div class="col-12 sm:col-2 md:col-2 xl:col-2">
                                    <div class="video-upload-container ">
                                        <div class="ss-l-video-container-inner flex">
                                            <video #videoPlayer
                                                [src]="replaceDomain(item.media.src.split('?')[0])+'?ngsw-bypass=true'"
                                                controls="false" [poster]="item.media.thumbnail.split('?')[0]"
                                                [controls]="false" [muted]="true" muted
                                                class="shadow-2 video-preview 123" style="cursor: pointer;"
                                                (click)="playVideo(videoPlayer)" (mouseenter)="startVideo(videoPlayer)"
                                                (mouseleave)="resetVideo(videoPlayer)" playsinline
                                                webkit-playsinline></video>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>

                            <ng-container
                                *ngIf="item.media.src !== null && item.media.src !== '' && !item.media.thumbnail">
                                <div class="col-12 sm:col-2 md:col-2 xl:col-2">
                                    <div class="video-upload-container ">
                                        <div class="ss-l-video-container-inner flex">
                                            <video #videoPlayer
                                                [src]="replaceDomain(item.media.src.split('?')[0])+'?ngsw-bypass=true'"
                                                controls="false" [controls]="false" [muted]="true" muted
                                                class="shadow-2 video-preview 456" style="cursor: pointer;"
                                                (click)="playVideo(videoPlayer)" (mouseenter)="startVideo(videoPlayer)"
                                                (mouseleave)="resetVideo(videoPlayer)" playsinline
                                                webkit-playsinline></video>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>

















                            <!-- <ng-container
                                *ngIf="(item.media.thumbnail !== null && item.media.thumbnail !== '') && item.media.src === null">
                                <img [src]="item.media.thumbnail.split('?')[0]" class="shadow-2" loading="lazy"
                                    style="object-fit: cover;width:100% !important;height: auto;border-radius: 20px;" />
                            </ng-container>

                            <ng-container *ngIf="item.media.thumbnail === null && item.media.src === null">
                                <img src="assets/img.jpg" class="shadow-2" loading="lazy"
                                    style="object-fit: cover;width:100% !important;height: auto;border-radius: 20px;" />
                            </ng-container> -->







                            <!-- <ng-container *ngIf="item.media.thumbnail !== null && item.media.thumbnail !== ''">
                                <div class="col-12 sm:col-2 md:col-2 xl:col-2">
                                    <div class="video-upload-container">
                                        <div class="ss-l-video-container-inner flex">
                                            <img [src]="item.media.thumbnail.split('?')[0]" class="shadow-2"
                                                loading="lazy"
                                                style="object-fit: cover;max-width: 100%;height: auto;border-radius: 20px;min-width: 100%;" />
                                        </div>
                                    </div>
                                </div>
                            </ng-container> -->




                            <!-- Substitua com campos relevantes do item -->
                            <div class="flex-1 flex flex-column text-left p-0 p-md-4">
                                <div class="font-bold mb-2">
                                    <div class="title-container mr-1 bold text-xl">
                                        {{ item.content.title }}
                                        <i *ngIf="item.media.pin === 1" class="bi bi-pin-angle-fill"></i>
                                    </div>
                                </div>
                                <div class="mb-2  text-lg break-word">{{item.content.description}}</div>
                                <div class="tag-container mb-2">

                                    <p-tag [icon]="getIconDuration(item.media.duration, item.media.stream_status)"
                                        class="tag-item "
                                        [value]="formatDurationPill(item.media.duration, item.media.stream_status, item.media.type)"
                                        styleClass="custom-gray-tag" [rounded]="true"></p-tag>


                                    <p-tag [icon]="getIconType(item.media.type)" [value]="item.media.type || 'Live'"
                                        class="tag-item ml-2" [rounded]="true" severity="info">
                                    </p-tag>


                                    <p-tag [value]="getStatusText(item.media.status, item.schedule?.date_from)"
                                        class="tag-item ml-2"
                                        [severity]="getSeverityStatus(item.media.status, item.schedule?.date_from)"
                                        [rounded]="true"></p-tag>



                                </div>
                                <!-- Mais campos do item conforme necessário -->
                            </div>
                            <div
                                class="d-flex flex-column align-items-center justify-content-center md:flex-row md:justify-content-between md:align-items-end mt-2 md:mt-0 w-100 ct-coins">
                                <span
                                    class="mb-2 d-flex justify-content-center w-100 md:w-auto md:justify-content-start">



                                    <button pButton pRipple type="button" icon="pi pi-pencil"
                                        (click)="menu.toggle($event)"
                                        class="p-element p-ripple p-datepicker-trigger p-button-icon-only w-full p-button p-component mb-2"
                                        style="min-width: 3rem;">
                                        <span class="button-label ml-2">Ações</span>
                                    </button>
                                    <p-menu #menu
                                        [model]="getActionMenu(item.content.id, item.content.uid, item.content.title)"
                                        [popup]="true" appendTo="body"></p-menu>
                                </span>
                            </div>


                        </div>
                    </div>
                </ng-template>


                <ng-template let-asset pTemplate="gridItem">
                    <div *ngIf="isLoadingSkeleton; else contentTemplate">
                        <div class="col-12 sm:col-6 lg:col-12 xl:col-2 p-2 pt-3" *ngFor="let _ of [].constructor(6)">
                            <div class="m-3 border-1 surface-border video-upload-container">
                                <div class="aspect-ratio-container ss-l-video-container-inner">
                                    <p-skeleton width="100%" height="100%" class="video-preview"></p-skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #contentTemplate>
                        <div class="col-12 sm:col-6 lg:col-12 xl:col-2 p-2 pt-3" *ngFor="let item of asset.items">

                            <!-- Adaptação do template de grid para os items do asset -->
                            <div class="border-1 surface-border video-upload-container flex">
                                <!-- Contêiner para imagem e status -->
                                <div class="relative text-center ss-l-video-container-inner"
                                    style="display: flex;border-radius: 20px;">


                                    <ng-container
                                        *ngIf="item.media.src !== null && item.media.src !== '' && (item.media.thumbnail !== null && item.media.thumbnail !== '')">
                                        <video #videoPlayer
                                            [src]="replaceDomain(item.media.src.split('?')[0])+'?ngsw-bypass=true'"
                                            controls="false" [poster]="item.media.thumbnail.split('?')[0]"
                                            [controls]="false" [muted]="true" muted class="shadow-2 video-preview"
                                            style="cursor: pointer;" (click)="playVideo(videoPlayer)"
                                            (mouseenter)="startVideo(videoPlayer)"
                                            (mouseleave)="resetVideo(videoPlayer)" playsinline
                                            webkit-playsinline></video>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="item.media.src !== null && item.media.src !== '' && !item.media.thumbnail">
                                        <video #videoPlayer
                                            [src]="replaceDomain(item.media.src.split('?')[0])+'?ngsw-bypass=true'"
                                            controls="false" [controls]="false" [muted]="true" muted
                                            class="shadow-2 video-preview" style="cursor: pointer;"
                                            (click)="playVideo(videoPlayer)" (mouseenter)="startVideo(videoPlayer)"
                                            (mouseleave)="resetVideo(videoPlayer)" playsinline
                                            webkit-playsinline></video>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="(item.media.thumbnail !== null && item.media.thumbnail !== '') && item.media.src === null">
                                        <img [src]="item.media.thumbnail.split('?')[0]" class="shadow-2" loading="lazy"
                                            style="object-fit: cover;width:100% !important;height: auto;border-radius: 20px;" />
                                    </ng-container>

                                    <ng-container *ngIf="item.media.thumbnail === null && item.media.src === null">
                                        <img src="assets/img.jpg" class="shadow-2" loading="lazy"
                                            style="object-fit: cover;width:100% !important;height: auto;border-radius: 20px;" />
                                    </ng-container>


                                    <div *ngIf="item.media.pin === 1" class="absolute mt-3 ml-3 mr-3 ss-l-bottom-5rem"
                                        style="top: 8px !important;right: 40px !important;"><i
                                            class="bi bi-pin-angle-fill"></i></div>

                                    <!-- Status sobreposto à imagem na parte inferior direita -->
                                    <div class="absolute left-0 mt-3 ml-3 mr-3 ss-l-bottom-5rem">
                                        <div class="title-container">
                                            <p-tag [value]="item.content.title" class="mr-1 custom-white-tag bold ">
                                            </p-tag>
                                        </div>
                                    </div>
                                    <div class="absolute top-0 right-0 mt-3 mr-3 grid">


                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-pencil"
                                                (click)="menu.toggle($event);" class="border-none button-tag"></button>
                                            <p-menu #menu
                                                [model]="getActionMenu(item.content.id, item.content.uid, item.content.title)"
                                                [popup]="true" appendTo="body"></p-menu>
                                        </span>
                                    </div>

                                    <div class="absolute top-0 left-0 mt-3 ml-3  ">
                                        <p-tag [icon]="getIconDuration(item.media.duration, item.media.stream_status)"
                                            [value]="formatDurationPill(item.media.duration, item.media.stream_status, item.media.type)"
                                            styleClass="custom-gray-tag" [rounded]="true"></p-tag>
                                    </div>
                                    <div class="absolute bottom-0 left-0 mb-3 ml-3 text-2xl font-bold">
                                        <p-tag [icon]="getIconType(item.media.type)" [value]="item.media.type || 'Live'"
                                            severity="info" class="mr-1 " [rounded]="true">
                                        </p-tag>
                                    </div>
                                    <div class="absolute bottom-0 right-0 mb-3 mr-3 text-2xl font-bold">
                                        <p-tag [value]="getStatusText(item.media.status, item.schedule?.date_from)"
                                            [severity]="getSeverityStatus(item.media.status, item.schedule?.date_from)"
                                            [rounded]="true"></p-tag>
                                    </div>
                                    <!-- Nova p-tag no canto superior direito -->

                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    Nenhum resultado encontrado
                </ng-template>

            </p-dataView>

        </div>
    </div>
</div>


<p-sidebar [(visible)]="displaySidebar" (onHide)="closeSidebar()" class="ss-g-custom-sidebar-width" position="right">
    <ng-template pTemplate="header">
        <h3 style="margin-bottom: 0;">Slicks</h3>
    </ng-template>
    <div class="sidebar-content">
        <p>Você pode carregar seus vídeos e criar vídeos interativos - SlickShorts - ou transmitir um evento ao vivo -
            SlickLives</p>

        <div class="">
            <div class="card shadow-none">
                <div class="flex flex-column mb-2">
                    <label class="w-full ss-g-globalClassLabel">
                        Título do Slick
                        <div class="p-inputgroup mt-2">
                            <input type="text" id="title" pInputText class="w-full" (ngModelChange)="onInputChange()"
                                [(ngModel)]="slickTitle" [ngClass]="{'ng-invalid': hasError1, 'ng-dirty': hasError1}">
                        </div>
                    </label>
                    <div class="flex align-items-center justify-content-between">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="hasError1" class="p-error">
                            Obrigatório
                        </small>

                    </div>
                </div>
                <div class="flex flex-column mb-2">
                    <label class="w-full ss-g-globalClassLabel">
                        Descrição
                    </label>
                    <div class="mb-2">
                        <textarea rows="3" cols="30" class="w-full p-inputtextarea p-inputtext p-component p-element"
                            placeholder="Descrição do Slick" [(ngModel)]="slickDesc" pInputTextarea></textarea>
                    </div>

                </div>
                <div class="flex flex-column mb-2">
                    <label class="w-full ss-g-globalClassLabel">Tipo de Slick
                        <p-dropdown [options]="slickTypes" [(ngModel)]="selectedSlickType"
                            (onChange)="onTypeChange($event)"
                            [ngClass]="{'ng-invalid': hasError2, 'ng-dirty': hasError2}" id="slickTypeDropdown"
                            placeholder="Selecione um tipo" optionLabel="label" class="w-full ss-g-sidebarVendas mt-2">
                        </p-dropdown>
                    </label>
                    <div class="flex align-items-center justify-content-between">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="hasError2" class="p-error">
                            Obrigatório
                        </small>

                    </div>
                </div>
                <div class="p-fluid">
                    <div class="">
                        <label class="w-full ss-g-globalClassLabel">Modo de visualização
                            <div class="mt-2">
                                <p-selectButton class=" mt-5" [options]="landscapeOptions"
                                    (onChange)="changeLandscape()" [(ngModel)]="valSelect1Video" optionLabel="name">
                                    <ng-template let-option pTemplate="item">
                                        <div class="flex align-items-center justify-content-center w-full">
                                            <i [ngClass]="option.icon" class="mr-2"></i>
                                            <span class="font-bold">{{ option.name }}</span>
                                        </div>
                                    </ng-template>
                                </p-selectButton>
                            </div>
                        </label>
                    </div>
                </div>

                <!-- <div class="col-12 mt-3 mb-2 flex flex-column" style="padding: 0;" *ngIf="typeLive"> -->
                <div class="mt-3 mb-2 flex flex-column" *ngIf="typeLive">
                    <span class="ss-g-globalClassLabel">Carregue seu vídeo aqui</span>
                    <p-fileUpload #fileUpload name="demo[]" accept="video/*" [multiple]="false" *ngIf="!videoPreviewUrl"
                        [ngClass]="{'ng-invalid': hasError3, 'ng-dirty': hasError3, 'file-upload-error': hasError3}"
                        (onSelect)="onFileSelect($event)" [customUpload]="true" [showCancelButton]="false"
                        (uploadHandler)="myUploadMethod($event)"
                        styleClass="border-1 surface-border surface-card border-round">
                        <ng-template pTemplate="content">
                            <div [ngClass]="{'video-upload-container': valSelect1Video.value === 1, 'video-upload-container-landscape': valSelect1Video.value === 2}"
                                (click)="fileUpload.choose()">
                                <div class="content">
                                    <!-- Exibir apenas se nenhum arquivo for selecionado -->
                                    <div *ngIf="!invoiceSelected"
                                        class="flex flex-column justify-content-center align-items-center">
                                        <i class="pi pi-file text-4xl text-primary"></i>
                                        <span class="block font-semibold text-900 text-lg mt-3">Arraste um arquivo ou
                                            selecione</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-fileUpload>

                    <div *ngIf="videoPreviewUrl"
                        [ngClass]="{'video-upload-container': valSelect1Video.value === 1, 'video-upload-container-landscape': valSelect1Video.value === 2}">
                        <div
                            [ngClass]="{'ss-l-video-container-inner': valSelect1Video.value === 1, 'ss-g-video-container-inner-landscape': valSelect1Video.value === 2}">

                            <video #videoPlayer [src]="replaceDomain(videoPreviewUrl)" controls [controls]="true" muted
                                [muted]="true" autoplay [autoplay]="true" loop [loop]="true"
                                (mouseenter)="startVideo(videoPlayer)" (mouseleave)="resetVideo(videoPlayer)"
                                [ngClass]="{'video-preview': valSelect1Video.value === 1, 'video-preview-landscape': valSelect1Video.value === 2}"
                                (ended)="videoPlayer.play()" (canplaythrough)="handleCanPlayThrough()" playsinline
                                webkit-playsinline></video>

                            <!-- Botão de fechar -->
                            <button *ngIf="videoPreviewUrl" pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center cursor-pointer w-2rem h-2rem"
                                style="top: -10px; right: -10px; cursor: pointer;"
                                (click)="removeVideo($event)"></button>
                        </div>
                    </div>

                    <div class="mt-2" *ngIf="uploadProgressVideo > 0" style="position: relative;">
                        <p-progressBar [value]="uploadProgressVideo" class="w-full"></p-progressBar>
                        <!-- <div class="progress-value"
                            style="position: absolute; width: 100%; top: 0; text-align: center; color: #FFFFFF;">
                            {{ uploadProgressVideo.toFixed(0) }}%
                        </div> -->
                    </div>

                    <div class="flex align-items-center justify-content-between mt-2">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="hasError3" class="p-error">
                            Obrigatório
                        </small>

                    </div>
                    <div class="flex align-items-center justify-content-between mt-2">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="hasSizeVideoError" class="p-error">
                            O vídeo deve ter no máximo 400 MB.
                        </small>
                    </div>
                    <div class="flex align-items-center justify-content-between mt-2">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="hasTypeVideoError" class="p-error">
                            ipos de vídeo permitidos: mp4, mov, mp3, wav, webm, avi, wmv, mkv, flv, mpeg, mpg, 3gp, m4v.
                        </small>
                    </div>
                </div>

                <div class="col-12 mt-3 mb-2 flex flex-column" style="padding: 0;">
                    <span class="ss-g-globalClassLabel">Escolha uma imagem de capa</span>
                    <p-fileUpload #imageUpload name="image[]" accept="image/*" [multiple]="false" *ngIf="!imageSelected"
                        (onSelect)="onImageSelect($event)" [customUpload]="true" [showCancelButton]="false"
                        (uploadHandler)="myImageUploadMethod($event)"
                        styleClass="border-1 surface-border surface-card border-round">
                        <ng-template pTemplate="content">
                            <div [ngClass]="{'custom-upload-area': valSelect1Video.value === 1, 'custom-upload-area-landscape': valSelect1Video.value === 2}"
                                (click)="imageUpload.choose()">
                                <!-- Exibir apenas se nenhum arquivo de imagem for selecionado image-preview -->
                                <div *ngIf="!imageSelected"
                                    class="flex flex-column justify-content-center align-items-center">
                                    <i class="pi pi-file text-4xl text-primary"></i>
                                    <span class="block font-semibold text-900 text-center text-lg mt-3">Arraste uma
                                        imagem ou
                                        selecione</span>
                                </div>


                            </div>
                        </ng-template>
                    </p-fileUpload>
                    <!-- Exibir após selecionar um arquivo de imagem -->
                    <div *ngIf="imageSelected" class="flex flex-wrap gap-3 border-round"
                        [ngClass]="{'video-upload-container': valSelect1Video.value === 1, 'video-upload-container-landscape': valSelect1Video.value === 2}">
                        <div class="border-3 border-transparent border-round position-relative"
                            (mouseenter)="showImageDeleteButton = true"
                            [ngClass]="{'ss-l-video-container-inner': valSelect1Video.value === 1, 'ss-g-video-container-inner-landscape': valSelect1Video.value === 2}"
                            (mouseleave)="showImageDeleteButton = false">
                            <img [src]="imagePreviewUrl"
                                [ngClass]="{'video-preview': valSelect1Video.value === 1, 'video-preview-landscape': valSelect1Video.value === 2}"
                                class="image-preview shadow-2 border-round">
                            <!-- Botão de excluir para imagem -->
                            <button *ngIf="showImageDeleteButton" pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center cursor-pointer w-2rem h-2rem"
                                style="top: -10px; right: -10px;" (click)="removeImage($event)"></button>
                        </div>
                    </div>
                    <div class="flex align-items-center justify-content-between mt-2" *ngIf="uploadProgress > 0">
                        <p-progressBar [value]="uploadProgress" class="w-full"></p-progressBar>
                        <!-- <div class="progress-value"
                            style="position: absolute; width: 100%; top: 0; text-align: center; color: #FFFFFF;">
                            {{ uploadProgress.toFixed(0) }}%
                        </div> -->
                    </div>
                    <div class="flex align-items-center justify-content-between">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="erroTamanhoImagem" class="p-error">
                            Tamanho da imagem deve ser de no máximo 1 mega
                        </small>
                    </div>
                    <div class="flex align-items-center justify-content-between">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="erroTipoImagem" class="p-error">
                            Tipo da imagem deve ser .png, .webp, .jpg ou jpeg
                        </small>
                    </div>
                </div>



                <div class="flex flex-column mb-4 mt-5 w-full">
                    <label class="w-full ss-g-globalClassLabel">Tags</label>
                        <p-chips [(ngModel)]="selectedTags" class="w-full" (ngModelChange)="updateTagsString()" separator="," placeholder="Digite tags" styleClass="w-full"></p-chips>
                    
                </div>
                <!-- Contêiner principal para alinhar tudo verticalmente -->
                <div class="flex flex-column">
                    <!-- Primeira label sozinha -->
                    <div class="align-items-center mb-2">
                        <label>mais opções</label>
                    </div>

                    <!-- Segundo contêiner para os switches e labels -->
                    <div class="flex align-items-center mb-2">

                        <p-inputSwitch [(ngModel)]="publicarAgora" class="mr-2" (onChange)="simular()"></p-inputSwitch>
                        <label>Publicar Agora</label>
                    </div>

                    <!-- Terceiro contêiner para o último switch e label -->
                    <div class="flex align-items-center" *ngIf="!typeLive">

                        <p-inputSwitch [(ngModel)]="armazenarEvento" class="mr-2"
                            (onChange)="simular()"></p-inputSwitch>
                        <label>Armazenar evento e re-play</label>
                    </div>
                </div>


                <div class="flex align-items-center mt-5 mb-2 w-full" *ngIf="!publicarAgora">
                    <label class="ss-g-globalClassLabel">Data e hora</label>
                </div>
                <div class="flex align-items-center w-full" *ngIf="!publicarAgora">
                    <p-calendar [(ngModel)]="dataSelecionada" (ngModelChange)="onDateChange($event)" [showTime]="true"
                        class="mb-2 calendar-grid " [showIcon]="true" [disabledDates]="disabledDates"
                        [readonlyInput]="true"></p-calendar>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div style="text-align: right;">
            <button pButton pRipple label="Fechar" (click)="closeSidebar()" class="p-button-outlined "></button>
            <button pButton pRipple label="Upload" [loading]="loadingBtn" (click)="uploadSave()" [disabled]="uploadOn"
                class="ml-2 mr-2"></button>

        </div>
    </ng-template>
</p-sidebar>

<p-confirmDialog></p-confirmDialog>
<p-dialog header="Aguardando" [(visible)]="displayDialogSidebar" [modal]="true" [closable]="false">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</p-dialog>
<p-toast></p-toast>

<p-dialog [baseZIndex]="100" class="ss-l-sizeDialogs" header="Usar Como Template" [(visible)]="displayDialogTemplate"
    class="ss-l-sizeDialogs" [modal]="true" id="dialog1" [dismissableMask]="true" (onHide)="resetDialogInputs()">
    <div class="flex flex-column mb-2">
        <label class="w-full ss-g-globalClassLabel">
            Título do Slick
            <div class="p-inputgroup mt-2">
                <input type="text" id="title" pInputText class="w-full" (ngModelChange)="onInputChange()"
                    [(ngModel)]="slickTitleCopy" [ngClass]="{'ng-invalid': hasError1, 'ng-dirty': hasError1}">
            </div>
        </label>
        <div class="flex align-items-center justify-content-between">
            <!-- Mensagem de Erro à esquerda -->
            <small *ngIf="hasError1Copy" class="p-error">
                Obrigatório
            </small>

        </div>
    </div>
    <div class="flex flex-column mb-2">
        <label class="w-full ss-g-globalClassLabel">
            Descrição
        </label>
        <div class="mb-2">
            <textarea rows="3" cols="30" class="w-full p-inputtextarea p-inputtext p-component p-element"
                placeholder="Descrição do Slick" [(ngModel)]="slickDescCopy" pInputTextarea></textarea>
        </div>

    </div>
    <div class="flex flex-column">
        <!-- Primeira label sozinha -->
        <div class="align-items-center mb-2">
            <label>mais opções</label>
        </div>

        <!-- Segundo contêiner para os switches e labels -->
        <div class="flex align-items-center mb-2">

            <p-inputSwitch [(ngModel)]="publicarAgoraCopy" class="mr-2" (onChange)="simular()"></p-inputSwitch>
            <label>Publicar Agora</label>
        </div>

    </div>
    <div class="flex align-items-center mt-2 mb-2 w-full" *ngIf="!publicarAgoraCopy">
        <label class="ss-g-globalClassLabel">Data e hora</label>
    </div>
    <div class="flex align-items-center w-full" *ngIf="!publicarAgoraCopy">
        <p-calendar [(ngModel)]="dataSelecionadaCopy" (ngModelChange)="onDateChangeCopy($event)" [showTime]="true"
            class="mb-2 calendar-grid " [showIcon]="true" [disabledDates]="disabledDates"
            [readonlyInput]="true"></p-calendar>
    </div>
    <div class="flex align-items-center justify-content-between">
        <!-- Mensagem de Erro à esquerda -->
        <small *ngIf="hasError2Copy" class="p-error">
            Obrigatório
        </small>

    </div>
    <ng-template pTemplate="footer">
        <div style="text-align: right;">
            <button pButton pRipple label="Fechar" (click)="closeCopyTemplate()" class="p-button-outlined "></button>
            <button pButton pRipple label="Criar" [loading]="isTemplate" (click)="createTemplate()"
                [disabled]="uploadOn" class="ml-2 mr-2"></button>

        </div>
    </ng-template>
</p-dialog>
<p-dialog header="Uploading" [(visible)]="uploadProgressBar" [modal]="true" [closable]="false">
    <p-progressBar [value]="uploadProgress"></p-progressBar>
</p-dialog>
<p-dialog header="Confirmação" [(visible)]="displayConfirmDialog" [modal]="true" [closable]="false" [resizable]="false">
    <p>Marcando esta opção, este slick se tornará o primeiro vídeo do seu Link na Bio. Deseja Continuar?</p>
    <p-footer>
        <button type="button" pButton pRipple label="Sim" (click)="confirmFixarVideo()"></button>
        <button type="button" pButton pRipple label="Não" (click)="displayConfirmDialog = false"></button>
    </p-footer>
</p-dialog>
<p-dialog header="Aguardando" [(visible)]="displayLoading" [modal]="true" [closable]="false" [resizable]="false">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</p-dialog>
<p-dialog header="Limite de Slicks Atingido" [(visible)]="displayLimitDialog" [modal]="true" [closable]="false">
    <p>Você atingiu o limite de 10 Slicks do seu plano. Remova um Slick ou atualize seu plano.</p>
    <p-footer>
        <button type="button" pButton label="Upgrade" icon="pi pi-check" (click)="upgradePlan()"></button>
        <button type="button" pButton label="Ok" icon="pi pi-times" (click)="closeLimitDialog()"></button>
    </p-footer>
</p-dialog>
<router-outlet></router-outlet>