// log.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private logs: string[] = [];

  constructor() {
   // this.overrideConsoleMethods();
  }

  private overrideConsoleMethods() {
    const originalConsoleError = console.error;
    const originalConsoleWarn = console.warn;

    console.error = (...args: any[]) => {
      this.addLog('ERROR', ...args);
      originalConsoleError.apply(console, args);
    };
  }

  private addLog(level: string, ...args: any[]) {
    const message = `[${new Date().toISOString()}] [${level}] ${args.join(' ')}`;
    this.logs.push(message);
    this.saveLogsToLocalStorage();
  }

  private saveLogsToLocalStorage() {
    localStorage.setItem('log_level', JSON.stringify(this.logs));
  }

  getLogs(): string[] {
    return this.logs;
  }
}
