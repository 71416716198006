import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewContainerRef, ComponentRef, Output, EventEmitter, ViewEncapsulation, ElementRef, AfterViewInit, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewportService } from 'src/app/services/viewport.service'; // Importa o serviço

import { PPlayerComponent } from '../p-player/p-player.component';
import { SkeletonModule } from 'primeng/skeleton';
import { PVideoPlayerComponent } from '../p-video-player/p-video-player.component';
@Component({
  selector: 'app-inline',
  standalone: true,
  templateUrl: './inline.component.html',
  styleUrls: ['./inline.component.scss'],
  imports: [CommonModule, PPlayerComponent, SkeletonModule, PVideoPlayerComponent],
  encapsulation: ViewEncapsulation.Emulated,
})
export class InlineComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() height?: number | undefined;
  // 
  @ViewChild('videoPlayerContainer', { read: ViewContainerRef, static: true }) videoPlayerContainer!: ViewContainerRef;
  private videoPlayerRef?: ComponentRef<PVideoPlayerComponent> | null;

  @Input() widget_id?: number | undefined;
  @Input() store_id?: number | undefined = 163;
  @Input() template_id?: number | undefined;
  @Input() aspectRatio?: any;
  @Input() templateType: string | undefined;
  @Input() isCol4: boolean = false;
  @Output() openSidebar = new EventEmitter<void>();
  private resizeObserver?: ResizeObserver;
  loading: boolean = true;
  constructor(private viewportService: ViewportService, private renderer: Renderer2) { }

  onVideoLoaded(): void {
    this.loading = false;
  }
  ngOnInit(): void {
    this.createVideoPlayerComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['height']) {
      console.log('height foi atualizada:', changes['height'].currentValue);
      this.calculateLayout();
    }
    if (changes['aspectRatio']) {
      console.log('aspectRatio foi atualizada:', changes['aspectRatio'].currentValue);
      this.calculateLayout();
    }
    if (changes['store_id']) {
      console.log('store_id foi atualizada:', changes['store_id'].currentValue);
    }
    if (changes['template_id']) {
      console.log('template_id foi atualizada:', changes['template_id'].currentValue);
    }
    if (changes['isCol4']) {
      console.log('isCol4 foi atualizada inline:', changes['isCol4'].currentValue);
    }
  }

  private createVideoPlayerComponent() {
    // Limpa o container antes de criar um novo componente
    this.videoPlayerContainer.clear();

    // Cria o componente dinamicamente
    this.videoPlayerRef = this.videoPlayerContainer.createComponent(PVideoPlayerComponent);

    // Configura os inputs do componente de vídeo dinâmico
    if (this.videoPlayerRef.instance) {
      this.videoPlayerRef.instance.store_id = this.store_id;
      this.videoPlayerRef.instance.height = this.height;
      this.videoPlayerRef.instance.widget_id = this.widget_id;

      // // Listen for loaded event
      // this.videoPlayerRef.instance.loaded.subscribe(() => {
      //   this.loading = false;
      // });
      this.videoPlayerRef.instance.initializeAfterView();
    }
  }
  // Função para emitir o evento quando o botão for clicado
  onOpenSidebar(): void {
    console.log(' primeiro onOpenSidebar');
    this.openSidebar.emit();
  }
  // Referência para a div de classe `s-s-l-inlineDiv`
  @ViewChild('inlineDiv', { static: true }) inlineDiv!: ElementRef;
  ngAfterViewInit(): void {
    // Observador para monitorar mudanças no tamanho da div
    this.resizeObserver = new ResizeObserver(() => {
      this.calculateLayout();
    });
    this.resizeObserver.observe(this.inlineDiv.nativeElement);
  }
  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

  }
  private calculateLayout(): void {
    let width = this.inlineDiv.nativeElement.offsetWidth;
    this.viewportService.setDivWidth(width); // Atualiza o Signal com a nova largura
    this.viewportService.setTemplateType(this.templateType); // Atualiza o Signal com a nova largura
    console.log('aspect ratio em inline:  ' + this.aspectRatio)
    // Calcular a altura com base na largura e aspectRatio
    // Avalia o aspectRatio se for uma string (exemplo: "540 / 540")
    let numericAspectRatio: number;
    if (typeof this.aspectRatio === 'string') {
      try {
        numericAspectRatio = eval(this.aspectRatio); // Converte "540 / 540" para 1
      } catch (e) {
        console.error('Erro ao avaliar aspectRatio:', e);
        numericAspectRatio = 1; // Valor padrão caso haja erro
      }
    } else {
      numericAspectRatio = this.aspectRatio;
    }
    const maxHeight = 30 * 16;
    // Calcular a altura com base na largura e numericAspectRatio
    if (numericAspectRatio && numericAspectRatio > 0) {
      let height = width / numericAspectRatio; // Cálculo da altura
      if (height > maxHeight) {
        height = this.inlineDiv.nativeElement.offsetHeight;

        // width = height*numericAspectRatio;
        // this.viewportService.setDivWidth(width);
      }

      console.log('Altura calculada: ' + height)

      this.viewportService.setDivHeight(height); // Atualiza altura no serviço
    }
  }

}
