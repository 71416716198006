import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { retry, catchError } from 'rxjs/operators';
import { Asset } from 'src/app/models/liveshop';
import { ResponseData } from 'src/app/models/liveshop';
import { formatDate } from '@angular/common';
import { setting } from 'src/app/models/listSettings';
import { environment } from 'src/environments/environment';
interface slicksStats {
  likeCount: number;
  espectadores: number;
  watched: number;
  content: number;
}
export interface Session {
  session_id: string;
}
interface AssetsResponse {
  assets: Asset[];   // Define a propriedade 'assets' como um array de 'Asset'
  totalAssets: number; // Define 'totalAssets' como um número
}
export interface GranularData {
  date: string;
  views: number;
  viewers: {
    mobile: number;
    desktop: number;
  };
  avg_views_per_viewer: number;
  avg_slicks_per_viewer: number;
  avg_watched_time: number;
  likes: number;
  clicks: number;
}
export interface Viewers {
  mobile: number;
  desktop: number;
}
export interface OverviewData {
  views: number;
  viewers: Viewers; // Alterado para refletir o novo formato
  avg_views_per_viewer: number;
  avg_slicks_per_viewer: number;
  avg_watched_time: number;
  likes: number;
  clicks: number;
}

export interface PlayerAnalytics {
  overview_data: OverviewData;
  granular_data: GranularData[];
}

export interface AnalyticsResponse {
  player_analytics: PlayerAnalytics;
}
interface Produto {
  id: number;
  item_group_id: number;
  name: string;
  stock_qty: number;
  track_inventory: number;
  status: number;
  min_qty: number;
  inputQty: number;
  price: string;
  onsale_price: string;
  price_exchange: string;
  thumbnail: string;
  qtd: number;
  isSelected?: boolean;
  highlight?: boolean;
  define_interval?: boolean;
  showSlider?: boolean;
  comprarFontColor?: string;
  comprarBgColor?: string;
  adicionarFontColor?: string;
  adicionarBgColor?: string;
  interval_time?: { start: string; end: string };

}
interface SlickMaps {
  id: number;
  uniq_id: string;
  asset_uniq_id: string;
  url: string;
  status: number;
  created_at: string;
  updated_at: string;
  type: number;
}
export interface SlickAnalytics {
  asset_id: string;
  name: string;
  video_url: string;
  views: number;
  viewers: number;
  avg_views_per_viewer: number;
  avg_slicks_per_viewer: number;
  avg_watched_time: number;
  likes: number;
  clicks: number;
}

export interface TopSlicks {
  top_slicks: SlickAnalytics[];
}
export interface NewSlicks {
  assets: AssetList[];
  totalAssets: number;
}
export interface AssetList {
  items: Item[];
}
export interface Item {
  schedule: Schedule;
  media: Media;
  content: Content;
}
export interface Schedule {
  date_from: string;
  date_to: string | null;
}

export interface Media {
  type: string;
  src: string;
  thumbnail: string | null;
  duration: number;
  stream_status: string;
  aspect_ratio: string;
  pin: number;
  status: number;
}

export interface Content {
  id: number;
  uid: string;
  title: string;
  description: string;
  cta: any[];
}
@Injectable({
  providedIn: 'root'
})
export class LiveShopService {

  dadosUsuario: any
  loja_id: any
  lojasUsuario: any
  baseurl = 'https://app.krabo.io/api'; // Base_url
  baseurl2 = 'https://app.krabo.io/'; // Base_url

  constructor(private httpClient: HttpClient, private http: HttpClient, private auth: AuthService) {

    // this.dadosUsuarioLogado();
    if (environment.production) {
      this.baseurl = environment.serverUrl;
    } else {
      this.baseurl = environment.paymentGenetatorLinkURL;
    }
  }


  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessToken()}`
    }),
  };

  // Error handling


  getAccessToken(): string {
    return localStorage.getItem("access_token") || '';
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem("user") || '');
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // Ocorreu um erro do lado do cliente ou da rede.
      console.error('Um erro ocorreu:', error.error.message);
    } else {
      // Ocorreu um erro do lado do servidor.
      console.error(
        `Código do erro ${error.status}, ` +
        `Erro: ${error.error}`);
    }
    // Retornar uma mensagem de erro para o usuário.
    return throwError(
      'Alguma coisa deu errado; tente novamente mais tarde.');
  };



  // createLiveShop(data: any): Observable<any> {

  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })

  //   console.log('service: ' + this.loja_id)

  //   if (environment.production) {
  //     return this.http.post<any>(
  //       `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping`,
  //       JSON.stringify(data),
  //       this.httpOptions
  //     );


  //   } else {
  //     return this.http.post<any>(
  //       `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping`,
  //       JSON.stringify(data),
  //       this.httpOptions
  //     );
  //   }

  // }

  getLiveViewers(liveShopId: string): Observable<any> {
    const url = `https://krabo.online:1438/get-user-count/${liveShopId}`;

    if (environment.production) {
      return this.http.get<any>(url, this.httpOptions);
    } else {
      return this.http.get<any>(url, this.httpOptions);
    }
  }
  // enableLive(liveShopId: string): Observable<any> {
  //   var id_loja: number;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   })
  //   if (environment.production) {
  //     return this.http.get<any>(
  //       `${environment.serverUrl}/admin/store/${id_loja}/liveshopping/enableLive/${liveShopId}`,
  //       this.httpOptions
  //     );


  //   } else {
  //     return this.http.get<any>(
  //       `${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/liveshopping/enableLive/${liveShopId}`,
  //       this.httpOptions
  //     );
  //   }

  // }



  // getLives(liveShopId: string): Observable<any> {
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })
  //   const url = `${this.baseurl}/admin/store/${this.loja_id}/liveshopping/detail/${liveShopId}`;

  //   if (environment.production) {
  //     return this.http.get<any>(url, this.httpOptions);
  //   } else {
  //     return this.http.get<any>(url, this.httpOptions);
  //   }
  // }
  // getLiveDetails(liveShopId: string): Observable<any> {
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })
  //   const url = `${this.baseurl}/admin/store/${this.loja_id}/liveshopping/details_live_mux/${liveShopId}`;

  //   if (environment.production) {
  //     return this.http.get<any>(url, this.httpOptions);
  //   } else {
  //     return this.http.get<any>(url, this.httpOptions);
  //   }
  // }
  payLive(liveShopId: string): Observable<any> {
    const url = `${this.baseurl2}/liveshopping/pay/${liveShopId}`;

    if (environment.production) {
      return this.http.patch<any>(url, null, this.httpOptions);
    } else {
      return this.http.patch<any>(url, null, this.httpOptions);
    }
  }


  private isRequestMade = false;
  // getAllLivesX(date_from: string, date_to: string): Observable<any> {
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })

  //   console.log('no service de lives')

  //   if (date_from == null) {
  //     if (environment.production) {
  //       return this.http.get<any>(
  //         `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping`,
  //         this.httpOptions
  //       );
  //     } else {
  //       return this.http.get<any>(
  //         `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping`,
  //         this.httpOptions
  //       );
  //     }
  //   }

  //   if (environment.production) {
  //     return this.http.get<any>(
  //       `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping?date_from=${date_from}&date_to=${date_to}`,
  //       this.httpOptions
  //     );
  //   } else {
  //     return this.http.get<any>(
  //       `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping?date_from=${date_from}&date_to=${date_to}`,
  //       this.httpOptions
  //     );
  //   }



  // }

  // getAllLives(): Observable<any> {
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })

  //   console.log('no service de lives')

  //   if (environment.production) {
  //     return this.http.get<any>(
  //       `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping`,
  //       this.httpOptions
  //     );
  //   } else {
  //     return this.http.get<any>(
  //       `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping`,
  //       this.httpOptions
  //     );
  //   }



  // }



  getStorneInfos(subdomain: string): Observable<any> {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    var body = {
      subdomain: subdomain
    }
    console.log(body);
    if (environment.production) {
      const url = `${environment.serverUrl}/admin/store`;
      return this.http.post<any>(url, body, this.httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        );
    } else {
      const url = `${environment.paymentGenetatorLinkURL}/admin/store`;
      return this.http.post<any>(url, body, this.httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        );
    }
  }

  getChatMessages(liveShopId: string): Observable<any> {

    const url = `https://krabo.online:1438/all_messages/${liveShopId}`;
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // getLiveShop(liveShopId: string): Observable<any> {
  //   var id_loja: number;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   })
  //   console.log('get live shop');
  //   var id_loja: number;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   })
  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/detail2/${liveShopId}`;
  //     return this.http.get<any>(url, this.httpOptions);
  //   } else {
  //     const url = `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/detail2/${liveShopId}`;
  //     return this.http.get<any>(url, this.httpOptions);
  //   }
  // }


  // updateLiveShop(id: string, liveShopForm: any): Observable<any> {
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })

  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/uniq_id/${id}`;
  //     return this.http.patch<any>(url, liveShopForm, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   } else {
  //     const url = `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/uniq_id/${id}`;
  //     return this.http.patch<any>(url, liveShopForm, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   }
  // }

  // updateProductStatus(uniq_id: string, id: number, status: number, tipo: string): Observable<any> {
  //   const body = {
  //     status: status,
  //     tipo: tipo,
  //     uniq_id: uniq_id
  //   };
  //   var id_loja: number;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   })
  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/item_status/${id}`;
  //     return this.http.patch<any>(url, body, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   } else {
  //     console.log('chamou aqui ' + environment.paymentGenetatorLinkURL)
  //     const url = `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/item_status/${id}`;
  //     return this.http.patch<any>(url, body, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   }
  // }

  // updateProductStatusCheck(uniq_id: string, id: number, status: number, tipo: string): Observable<any> {
  //   const body = {
  //     status: status,
  //     tipo: tipo,
  //     uniq_id: uniq_id
  //   };
  //   var id_loja: number;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   })
  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/item_status_check/${id}?tipo=${tipo}&status=${status}&uniq_id=${uniq_id}`;
  //     return this.http.get<any>(url, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   } else {
  //     console.log('chamou aqui ' + environment.paymentGenetatorLinkURL)
  //     const url = `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/item_status_check/${id}?tipo=${tipo}&status=${status}&uniq_id=${uniq_id}`;
  //     return this.http.get<any>(url, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   }
  // }


  // getAllProducts(): Observable<any> {
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })




  //   // return this.http.get<any>(url, this.httpOptions)
  //   //   .pipe(
  //   //     retry(1),
  //   //     catchError(this.handleError)
  //   // );
  //   // if (environment.production) {
  //   //   return this.http.get<any>(url, this.httpOptions);
  //   // } else {
  //   //   return this.http.post<any>(url, this.httpOptions);
  //   // }
  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/shop/store/${this.loja_id}/product?parent_sku=1`;
  //     return this.http.get<any>(url, this.httpOptions);
  //   } else {
  //     const url = `${environment.paymentGenetatorLinkURL}/shop/store/${this.loja_id}/product?parent_sku=1`;
  //     return this.http.get<any>(url, this.httpOptions);
  //   }
  // }
  // getAllPromotions(): Observable<any> {
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })

  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/promotions?promotion_type=2`;  // /admin/store/${liveId}/promotions`;

  //     return this.http.get<any>(url, this.httpOptions);
  //   } else {
  //     const url = `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/promotions?promotion_type=2`;  // /admin/store/${liveId}/promotions`;

  //     return this.http.get<any>(url, this.httpOptions);
  //   }
  // }

  // addProductsLive(liveId: string, products: any[]): Observable<any> {
  //   const body = { products };
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })
  //   if (environment.production) {
  //     return this.http.post<any>(
  //       `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/product/${liveId}`,
  //       body,
  //       this.httpOptions
  //     );
  //   } else {
  //     return this.http.post<any>(
  //       `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/product/${liveId}`,
  //       body,
  //       this.httpOptions
  //     );
  //   }

  // }
  // addCuponsLive(liveId: string, products: any[]): Observable<any> {
  //   const body = { products };
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })
  //   if (environment.production) {
  //     return this.http.post<any>(
  //       `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/cupom/${liveId}`,
  //       body,
  //       this.httpOptions
  //     );
  //   } else {
  //     return this.http.post<any>(
  //       `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/cupom/${liveId}`,
  //       body,
  //       this.httpOptions
  //     );
  //   }
  // }
  // addAlertsLive(liveId: string, text: string): Observable<any> {
  //   const body = { text: text };
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })

  //   if (environment.production) {
  //     return this.http.post<any>(
  //       `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/alert/${liveId}`,
  //       body,
  //       this.httpOptions
  //     );
  //   } else {
  //     return this.http.post<any>(
  //       `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/alert/${liveId}`,
  //       body,
  //       this.httpOptions
  //     );
  //   }
  // }

  // removeProduct(id: number): Observable<any> {
  //   var id_loja: number;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   })
  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/remove_product/${id}`;
  //     return this.http.post<any>(url, null, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   } else {
  //     const url = `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/remove_product/${id}`;
  //     return this.http.post<any>(url, null, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   }




  // }
  // removeCupom(id: number): Observable<any> {
  //   var id_loja: number;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   })
  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/remove_cupom/${id}`;
  //     return this.http.post<any>(url, null, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   } else {
  //     const url = `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/remove_cupom/${id}`;
  //     return this.http.post<any>(url, null, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   }


  // }
  // removeAlert(id: number): Observable<any> {
  //   var id_loja: number;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   })
  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/remove_alert/${id}`;
  //     return this.http.post<any>(url, null, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   } else {
  //     const url = `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/remove_alert/${id}`;
  //     return this.http.post<any>(url, null, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   }


  // }

  // removeLive(uniq_id: string): Observable<any> {
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })
  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/remove/${uniq_id}`;
  //     return this.http.delete<any>(url, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   } else {
  //     const url = `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/remove/${uniq_id}`;
  //     return this.http.delete<any>(url, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   }

  // }

  // updateAlertsLive(alertId: number, alertData: AnimatedText): Observable<any> {

  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     this.loja_id = item.store_id;
  //   })
  //   const body = { text: alertData.text };

  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/alerts/update/${alertId}`;

  //     return this.http.patch<any>(url, body, this.httpOptions);
  //   } else {
  //     const url = `${environment.paymentGenetatorLinkURL}/admin/store/${this.loja_id}/liveshopping/alerts/update/${alertId}`;
  //     return this.http.patch<any>(url, body, this.httpOptions);
  //   }
  // }



  // liveSimulator(duration_time: number, bitrate_fps: number, viewers: string, average_view_time: string): Observable<any> {
  //   var body = {
  //     duration_time: duration_time,
  //     bitrate_fps: bitrate_fps,
  //     viewers: viewers,
  //     average_view_time: average_view_time
  //   }
  //   var id_loja: number;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   })
  //   console.log(body);

  //   if (environment.production) {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/simulate/live`;
  //     return this.http.post<any>(url, body, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   } else {
  //     const url = `${environment.serverUrl}/admin/store/${this.loja_id}/liveshopping/simulate/live`;
  //     return this.http.post<any>(url, body, this.httpOptions)
  //       .pipe(
  //         retry(1),
  //         catchError(this.handleError)
  //       );
  //   }
  // }
  getAssets(): Observable<AssetsResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .get<AssetsResponse>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/asset`, httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<AssetsResponse>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/asset`, httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }
  getAnalytics(dateFrom: string, dateTo: string, slick_id?: string): Observable<AnalyticsResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    let url: string;

    // Verificar se é produção ou desenvolvimento
    if (environment.production) {
      // Para ambiente de produção
      url = `${environment.serverUrl}/store/${id_loja}/analytics/overview?date_from=${dateFrom}&date_to=${dateTo}`;
    } else {
      // Para ambiente de desenvolvimento
      url = `${environment.paymentGenetatorLinkURL}/store/${id_loja}/analytics/overview?date_from=${dateFrom}&date_to=${dateTo}`;
    }

    // Adicionar slick_id na URL, se estiver disponível
    if (slick_id) {
      url += `&slick_id=${slick_id}`;
    }

    console.log("URL chamada para getAnalytics:", url); // Verificar se o slick_id está sendo anexado corretamente

    // Retornar a chamada HTTP para produção ou desenvolvimento
    return this.http.get<AnalyticsResponse>(url, httpOptions).pipe(
      catchError(error => throwError(error))
    );
  }


  getTopSlicks(dateFrom: string, dateTo: string): Observable<TopSlicks> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    const url = `${environment.serverUrl}/store/${id_loja}/analytics/top_slicks?score=views&limit=6&page=0&date_from=${dateFrom}&date_to=${dateTo}`;

    if (environment.production) {
      return this.http.get<TopSlicks>(url, httpOptions).pipe(catchError(error => throwError(error)));
    } else {
      return this.http.get<TopSlicks>(`${environment.paymentGenetatorLinkURL}/store/${id_loja}/analytics/top_slicks?score=views&limit=6&page=0&date_from=${dateFrom}&date_to=${dateTo}`, httpOptions).pipe(catchError(error => throwError(error)));
    }
  }


  getNewSlicks(dateFrom: string, dateTo: string): Observable<NewSlicks> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    // Converta as datas para o formato dd/MM/yyyy
    const formattedDateFrom = formatDate(dateFrom, 'dd/MM/yyyy', 'en');
    const formattedDateTo = formatDate(dateTo, 'dd/MM/yyyy', 'en');

    if (environment.production) {
      return this.http
        .get<NewSlicks>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/asset?date_from=${formattedDateFrom}&date_to=${formattedDateTo}`, httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<NewSlicks>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/asset?date_from=${formattedDateFrom}&date_to=${formattedDateTo}`, httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }

  getNewSlicks1(): Observable<NewSlicks> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .get<NewSlicks>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/asset`, httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<NewSlicks>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/asset`, httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }

  getAnalyticsMobileDesktop(isMobile: boolean): Observable<AnalyticsResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .get<AnalyticsResponse>(`${environment.serverUrl}/store/${id_loja}/analytics/overview?is_mobile=${isMobile}`, httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<AnalyticsResponse>(`${environment.paymentGenetatorLinkURL}/store/${id_loja}/analytics/overview?is_mobile=${isMobile}`, httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }

  getAssetsPublic(limit: number | null, type: string, page: number, slickId?: string | null): Observable<AssetsResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || 163;
    let appendLink = '';
    if (slickId) {
      appendLink = '&slick_id=' + slickId;
      if (slickId == '1') {
        appendLink = '&l=1&slick_id=' + slickId;
      }
    }
    let limitAssets = '';
    if (limit != null) {
      limitAssets = `&limit=${limit}`;
    }

    if (environment.production) {
      return this.http
        .get<AssetsResponse>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/asset_public?type=${type}${limitAssets}&page=${page}${appendLink}`, httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<AssetsResponse>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/asset_public?type=${type}${limitAssets}&page=${page}${appendLink}`, httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }
  getAssetsFromWidgetsPublic(
    widget_id: number,
    limit: number | null,
    type: string,
    page: number,
    slickId?: string | null
  ): Observable<AssetsResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`,
        'X-Full-Referer': window.location.href
      }),
    };

    const id_loja = localStorage.getItem("SelectedStoreId") || 163;
    let appendLink = '';
    if (slickId) {
      appendLink = '&slick_id=' + slickId;
      if (slickId == '1') {
        appendLink = '&l=1&slick_id=' + slickId;
      }
    }
    let limitAssets = '';
    if (limit != null) {
      limitAssets = `&limit=${limit}`;
    }

    const baseUrl = environment.production
      ? `${environment.serverUrl}/admin/store/${id_loja}/videoshopping/widget/${widget_id}/assets?type=${type}${limitAssets}&page=${page}${appendLink}`
      : `${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/widget/${widget_id}/assets?type=${type}${limitAssets}&page=${page}${appendLink}`;

    return this.http
      .get<AssetsResponse>(baseUrl, httpOptions)
      .pipe(catchError(error => throwError(error)));
  }
  createSessionId(data: any): Observable<Session> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || '163';

    if (environment.production) {
      return this.http
        .post<Session>(`${environment.serverUrl}/admin/store/${id_loja}/liveshopping/create_session_id`, data, httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<Session>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/liveshopping/create_session_id`, data, httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }

  gerarSessaoVisitante(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || '163';

    if (environment.production) {
      return this.http
        .post<any>(`${environment.serverUrl}/auth/store/${id_loja}/guest_auth`,
          JSON.stringify(data),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<any>(`${environment.paymentGenetatorLinkURL}/auth/store/${id_loja}/guest_auth`,
          JSON.stringify(data),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }


  pinarSlick(data: any, uid: string): Observable<ResponseData> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .post<ResponseData>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/${uid}`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<ResponseData>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/${uid}`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }

  sendUids(uids: string[], n: number, date_from?: string, date_to?: string): Observable<slicksStats> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || '';
    let appendDates: string = "";
    if (date_to && date_from) {
      appendDates = `&date_from=${date_from}&date_to=${date_to}`;
    }


    if (environment.production) {
      return this.http
        .post<slicksStats>(`${environment.serverUrl}/admin/store/${id_loja}/slicksStats?type=${n}${appendDates}`,
          JSON.stringify(uids),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<slicksStats>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/slicksStats?type=${n}${appendDates}`,
          JSON.stringify(uids),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }
  }

  getFilteredAssetsByDate(date_from: string, date_to: string): Observable<Asset[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .get<Asset[]>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/asset?date_from=${date_from}&date_to=${date_to}`,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<Asset[]>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/asset?date_from=${date_from}&date_to=${date_to}`,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }
  }
  DeleteSlick(id: any) {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .delete<Asset>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/asset/${id}`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .delete<Asset>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/asset/${id}`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }

  postToExternalEndpoint(folder: string, objectName: string): Observable<any> {
    const url = 'https://hkdk.events/zbx8fq9566t9rd';
    const body = { folder: folder, object_name: objectName };

    return this.http.post(url, body, this.httpOptions)
      .pipe(catchError(error => throwError(error)));
  }
  saveSlick(data: any): Observable<ResponseData> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .post<ResponseData>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/asset`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<ResponseData>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/asset`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }
  detailSettings(): Observable<setting[]> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .get<setting[]>(`${environment.serverUrl}/admin/store/${id_loja}/settings`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<setting[]>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/settings`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }

  detailAsset(uid: string): Observable<Asset> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .get<Asset>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/asset/${uid}`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<Asset>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/asset/${uid}`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }

  updateAsset(data: any, asset_id: number): Observable<Produto> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .patch<Produto>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/asset/${asset_id}`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .patch<Produto>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/asset/${asset_id}`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }

  createSlickMaps(data: any): Observable<Produto> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .post<Produto>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/maps`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<Produto>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/maps`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }
  clearSlickMaps(uniq_id: string): Observable<SlickMaps> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .delete<SlickMaps>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/asset/${uniq_id}/maps`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .delete<SlickMaps>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/asset/${uniq_id}/maps`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }
  listSlickMaps(uniq_id: string): Observable<SlickMaps[]> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    const url = environment.production ? `${environment.serverUrl}` : `${environment.paymentGenetatorLinkURL}`;
    return this.http
      .get<SlickMaps[]>(`${url}/admin/store/${id_loja}/videoshopping/asset/${uniq_id}/maps`, this.httpOptions)
      .pipe(catchError(error => throwError(error)));
  }

  create_pixel(data: any, uuid: string): Observable<Produto> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .post<Produto>(`${environment.serverUrl}/admin/store/${id_loja}/asset/${uuid}/pixel`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<Produto>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/asset/${uuid}/pixel`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }

  update_pixel(data: any, pixel_id: number): Observable<Produto> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .patch<Produto>(`${environment.serverUrl}/admin/store/${id_loja}/pixel/${pixel_id}`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .patch<Produto>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/pixel/${pixel_id}`, data, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }
  listPixelLazy(uuid: string, page: number, size: number, searchTerm = ''): Observable<any> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    let params = new HttpParams()
      .set('page', page == 0 ? 1 : page ?? 1)
      .set('limit', size ?? 10);

    return this.http.get<any>(`${environment.serverUrl}/admin/store/${id_loja}/asset/${uuid}/pixel`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
      params
    }).pipe(
      catchError(error => throwError(error))
    );
  }

  list_pixel(): Observable<any> {
    console.log('list_pixel')
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .get<any>(`${environment.serverUrl}/admin/store/${id_loja}/pixel_public`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<any>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/pixel_public`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }

  detail_pixel(pixel_id: string): Observable<Produto> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .get<Produto>(`${environment.serverUrl}/admin/store/${id_loja}/pixel/${pixel_id}`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<Produto>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/pixel/${pixel_id}`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }
  deletar_pixel(pixel_id: string): Observable<Produto> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .delete<Produto>(`${environment.serverUrl}/admin/store/${id_loja}/pixel/${pixel_id}`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .delete<Produto>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/pixel/${pixel_id}`, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }
  sendHook(uid: string,): Observable<any> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    console.log('chamou disable live')
    var body = {
      uid: uid,
    }
    if (environment.production) {
      return this.http
        .post<any>(`${environment.serverUrl}/admin/store/${id_loja}/disableLive`, body, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<any>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/disableLive`, body, this.httpOptions)
        .pipe(catchError(error => throwError(error)));
    }
  }
  enableLive(liveShopId: string): Observable<any> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    if (environment.production) {
      return this.http.get<any>(
        `${environment.serverUrl}/admin/store/${id_loja}/liveshopping/enableLive/${liveShopId}`,
        this.httpOptions
      );


    } else {
      return this.http.get<any>(
        `${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/liveshopping/enableLive/${liveShopId}`,
        this.httpOptions
      );
    }

  }
  getLive(liveShopId: string): Observable<any> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    const url = `${this.baseurl}/admin/store/${id_loja}/liveshopping/details_live/${liveShopId}`;
    if (environment.production) {
      return this.http.get<any>(url, this.httpOptions);
    } else {
      return this.http.get<any>(url, this.httpOptions);
    }
  }
  getLiveData(liveShopId: string): Observable<any> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    const url = `${this.baseurl}/admin/store/${id_loja}/liveshopping/detail/${liveShopId}`;
    console.log('Chamou detalhar dados de transmissão');
    console.log(url);
    if (environment.production) {
      return this.http.get<any>(url, this.httpOptions);
    } else {
      return this.http.get<any>(url, this.httpOptions);
    }
  }
  getLiveDetails(liveShopId: string): Observable<any> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    const url = `${this.baseurl}/admin/store/${id_loja}/liveshopping/details_live_mux/${liveShopId}`;

    if (environment.production) {
      return this.http.get<any>(url, this.httpOptions);
    } else {
      return this.http.get<any>(url, this.httpOptions);
    }
  }
  getLiveTert(liveShopId: string): Observable<any> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    const url = `${this.baseurl}/admin/store/${id_loja}/liveshopping/tert/${liveShopId}`;

    if (environment.production) {
      return this.http.get<any>(url, this.httpOptions);
    } else {
      return this.http.get<any>(url, this.httpOptions);
    }
  }

  generatePix(): Observable<any> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    let user_id = localStorage.getItem("user_id") || '';
    if (environment.production) {
      const url = `${environment.serverUrl}/admin/store/${id_loja}/gerarPix/${user_id}`;
      return this.http.post<any>(url, null, this.httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        );
    } else {
      const url = `${environment.serverUrl}/admin/store/${id_loja}/gerarPix/${user_id}`;
      return this.http.post<any>(url, null, this.httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        );
    }
  }
  liveSimulator(duration_time: number, bitrate_fps: number, viewers: string, average_view_time: number): Observable<any> {
    var body = {
      duration_time: duration_time,
      bitrate_fps: bitrate_fps,
      viewers: viewers,
      average_view_time: average_view_time
    }
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    console.log(body);

    if (environment.production) {
      const url = `${environment.serverUrl}/admin/store/${id_loja}/liveshopping/simulate/live`;
      return this.http.post<any>(url, body, this.httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        );
    } else {
      const url = `${environment.serverUrl}/admin/store/${id_loja}/liveshopping/simulate/live`;
      return this.http.post<any>(url, body, this.httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        );
    }
  }


}
