// viewport.service.ts
import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ViewportService {
  private divWidthSignal: WritableSignal<number> = signal(0);
  private temlateType: WritableSignal<string | undefined> = signal('');
  private divHeightSignal: WritableSignal<number> = signal(0); // Novo sinal para altura

  // Getters para acessar os valores atuais dos Signals
  getDivWidthSignal() {
    return this.divWidthSignal.asReadonly();
  }

  getDivHeightSignal() {
    return this.divHeightSignal.asReadonly(); // Getter para altura
  }

  // Setters para atualizar os Signals
  setDivWidth(width: number) {
    this.divWidthSignal.set(width);
  }


  setTemplateType(template: string | undefined) {
    this.temlateType.set(template);
  }
  getTemplateType() {
    return this.temlateType.asReadonly(); // Getter para altura
  }

  setDivHeight(height: number) {
    this.divHeightSignal.set(height); // Atualiza o Signal de altura
  }

  // Inicializa o signal com um valor inicial de `undefined`.
  private reloadSignal = signal(false);

  // Método para emitir o sinal
  triggerReload() {
    this.reloadSignal.set(true);
  }

  // Método para acessar o sinal de recarga
  getReloadSignal() {
    return this.reloadSignal.asReadonly();
  }
}
