import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type MenuMode = 'static' | 'overlay' | 'horizontal' | 'slim' | 'slim-plus' | 'reveal' | 'drawer';

export type ColorScheme = 'light' | 'dark' | 'dim';

export type MenuColorScheme = 'colorScheme' | 'primaryColor' | 'transparent';

export interface AppConfig {
    inputStyle: string;
    colorScheme: ColorScheme;
    theme: string;
    ripple: boolean;
    menuMode: MenuMode;
    scale: number;
    menuTheme: MenuColorScheme;
}

interface LayoutState {
    staticMenuDesktopInactive: boolean;
    overlayMenuActive: boolean;
    profileSidebarVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
    sidebarActive: boolean;
    anchored: boolean,
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService {

    constructor(){
        console.log('LayoutService');
        //this.checkTheme();
    }

    config: AppConfig = {
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'drawer',
        colorScheme: 'light',
        theme: 'purple',
        scale: 14,
        menuTheme: 'colorScheme'
    };

    state: LayoutState = {
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
        sidebarActive: false,
        anchored: false
    };

    private configUpdate = new Subject<AppConfig>();

    private overlayOpen = new Subject<any>();

    configUpdate$ = this.configUpdate.asObservable();

    overlayOpen$ = this.overlayOpen.asObservable();

    onMenuToggle() {
        if (this.isOverlay()) {
            this.state.overlayMenuActive = !this.state.overlayMenuActive;

            if (this.state.overlayMenuActive) {
                this.overlayOpen.next(null);
            }
        }

        if (this.isDesktop()) {
            this.state.staticMenuDesktopInactive = !this.state.staticMenuDesktopInactive;
        }
        else {
            this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

            if (this.state.staticMenuMobileActive) {
                this.overlayOpen.next(null);
            }
        }
    }

    onOverlaySubmenuOpen() {
        this.overlayOpen.next(null);
    }

    showProfileSidebar() {
        this.state.profileSidebarVisible = true;
    }

    showConfigSidebar() {
        this.state.configSidebarVisible = true;
    }

    isOverlay() {
        return this.config.menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isSlim() {
        return this.config.menuMode === 'slim';
    }

    isSlimPlus() {
        return this.config.menuMode === 'slim-plus';
    }

    isHorizontal() {
        return this.config.menuMode === 'horizontal';
    }

    isMobile() {
        return !this.isDesktop();
    }

    onConfigUpdate() {
        this.configUpdate.next(this.config);
    }


    checkTheme(): void {
        try {
            const viewMode = localStorage.getItem('viewMode');
            const validSchemes: ColorScheme[] = ['light', 'dark']; // Adicione todos os esquemas de cores válidos aqui
    
            if (viewMode && validSchemes.includes(viewMode as ColorScheme)) {
                this.changeColorScheme(viewMode as ColorScheme);
                //console.log("Tema encontrado e aplicado:", viewMode);
            } else {
                this.changeColorScheme('light'); // Define 'light' como padrão caso o tema salvo não seja válido
                //console.log("Tema não encontrado ou inválido, aplicando tema padrão: light");
            }
        } catch (error) {
            //console.error("Erro ao aplicar o tema:", error);
            this.changeColorScheme('light'); // Em caso de erro, também aplicar o tema padrão
        }
    }
    


    changeColorScheme(colorScheme: ColorScheme): void {
        const themeLink = document.getElementById('theme-link') as HTMLLinkElement;
        if (themeLink) {
            const themeLinkHref = themeLink.getAttribute('href');
            const currentColorScheme = 'theme-' + this.config.colorScheme;
            const newColorScheme = 'theme-' + colorScheme;
            const newHref = themeLinkHref!.replace(currentColorScheme, newColorScheme);
            this.replaceThemeLink(newHref, () => {
                this.config.colorScheme = colorScheme;
                this.onConfigUpdate();
            });
        } else {
            console.error('Theme link element not found!');
        }
    }
    replaceThemeLink(href: string, onComplete: () => void): void {
        const id = 'theme-link';
        const themeLink = document.getElementById(id) as HTMLLinkElement;
        const cloneLinkElement = themeLink.cloneNode(true) as HTMLLinkElement;

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

        cloneLinkElement.onload = () => {
            themeLink.remove();
            cloneLinkElement.setAttribute('id', id);
            onComplete();
        };
    }
}
