import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  private avatarSource = new BehaviorSubject<string | null>(null);
  avatar$ = this.avatarSource.asObservable();

  updateAvatar(newAvatar: string | null) {
    this.avatarSource.next(newAvatar);
  }
}
