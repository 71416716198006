import { Injectable } from '@angular/core';
import { Router, UrlTree, ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { UrlSegment } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class SubdomainService {

  constructor(
    private route: ActivatedRoute
  ) { }
  getSubdomainFromSegments(urlSegments: UrlSegment[]): string {
    if (urlSegments.length > 1 && urlSegments[1].path === 'p-video-player') {
      const subdomain = urlSegments[0].path; // O segmento antes de '/p-video-player'
      return `${subdomain}.slicksell.com.br`;
    }
    return ''; // Retorna vazio ou algum valor padrão se a condição não for cumprida
  }
}
