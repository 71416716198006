<div class="layout-container" [ngClass]="containerClass">
    <app-sidebar *ngIf="containerClassManage"></app-sidebar>
    <div class="layout-content-wrapper" [ngClass]="{'layout-content-wrapper': containerClassManage}">

        <app-topbar *ngIf="containerClassManage"></app-topbar>

        <app-breadcrumb class="content-breadcrumb" *ngIf="containerClassManage"></app-breadcrumb>
        <div class="layout-content" [ngClass]="{'layout-content': containerClassManage}">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-profilemenu></app-profilemenu>
    <app-config></app-config>
    <div class="layout-mask"></div>
</div>