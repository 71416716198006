import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Message } from 'src/app/api/message';
import { User } from 'src/app/api/user';
import { ChatWebSocketService } from 'src/app/services/chat-web-socket.service';
import { Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'app-chat-box',
    templateUrl: './chat-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatBoxComponent implements OnInit, AfterViewInit {
    @Input() showTopChat: boolean = true;
    @Input() chatId: string = '';
    defaultUserId: number = 123;
    @ViewChild('chatWindow', { static: false }) private chatContainer?: ElementRef<HTMLDivElement>;

    message!: Message;
    textContent: string = '';
    messages: any[] = [];
    uploadedFiles: any[] = [];

    emojis = [
        '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😇', '😉', '😊', '🙂', '🙃', '😋', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '🤪', '😜', '😝', '😛',
        '🤑', '😎', '🤓', '🧐', '🤠', '🥳', '🤗', '🤡', '😏', '😶', '😐', '😑', '😒', '🙄', '🤨', '🤔', '🤫', '🤭', '🤥', '😳', '😞', '😟', '😠', '😡', '🤬', '😔',
        '😟', '😠', '😡', '🤬', '😔', '😕', '🙁', '😬', '🥺', '😣', '😖', '😫', '😩', '🥱', '😤', '😮', '😱', '😨', '😰', '😯', '😦', '😧', '😢', '😥', '😪', '🤤'
    ];

    @Input() user!: User;

    constructor(private chatWebSocketService: ChatWebSocketService, private cdr: ChangeDetectorRef) {
        this.scrollToBottom();
    }

    ngAfterViewInit() {
        this.scrollToBottom();
    }

    scrollToBottom(): void {
        setTimeout(() => {
            if (this.chatContainer) {
                this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
            }
        }, 100);
    }

    setMessage() {
        if (this.user) {
            let filteredMessages = this.user.messages.filter(m => m.ownerId !== this.defaultUserId);
            this.message = filteredMessages[filteredMessages.length - 1];
        }
    }

    ngOnInit(): void {
        this.setMessage();
        this.receiveMessages();
        this.loadMessages(); // Chame loadMessages uma vez no ngOnInit
    }
    @Output() isLoadingChange = new EventEmitter<boolean>();
    loadMessages() {
        this.isLoadingChange.emit(true);
        this.chatWebSocketService.getInitialMessages(this.chatId).subscribe(messages => {
            const localUserIp = localStorage.getItem('user_chat');
            this.messages = messages.map(message => {
                const isOwnMessage = message.ip === localUserIp;
                console.log('Message IP:', message.ip, 'Is own message:', isOwnMessage);
                return {
                    ...message,
                    isOwnMessage: isOwnMessage
                };
            });
            this.isLoadingChange.emit(false);
            this.cdr.markForCheck();
            this.scrollToBottom();
        });
    }

    fetchInitialMessages() {
        this.chatWebSocketService.getInitialMessages(this.chatId).subscribe(messages => {
            console.log('Initial messages received:', messages);
            const localUserIp = localStorage.getItem('user_ip');
            console.log('Local stored user IP:', localUserIp);

            this.messages = messages.map(message => {
                const isOwnMessage = message.ip === localUserIp;
                console.log('Message IP:', message.ip, 'Is own message:', isOwnMessage);
                return {
                    ...message,
                    isOwnMessage: isOwnMessage
                };
            });

            this.cdr.markForCheck();  // Necessário para atualizar a view com ChangeDetectionStrategy.OnPush
            this.scrollToBottom();
        });
    }


    receiveMessages() {
        console.log('chegou em receiveMessages do componente de chat')
        this.chatWebSocketService.receiveMessages().subscribe(message => {
            //if (message.chatId === this.chatId) {
            console.log('em chat box');
            console.log(message);
            const formattedMessage = {
                from: message.username,
                text: message.text,
                time: message.time,
                isOwnMessage: message.ip === localStorage.getItem('user_chat')
            };
            this.messages = [...this.messages, formattedMessage];
            this.cdr.markForCheck();
            this.scrollToBottom();
            //}
        });
    }

    sendMessage() {
        if (this.textContent.trim()) {
            const user_chat = localStorage.getItem('user_chat') ?? undefined;
            const newMessage = {
                from: 'Você',
                text: this.textContent.trim(),
                time: new Date().toLocaleTimeString(),
                isOwnMessage: true
            };
            //this.messages = [...this.messages, newMessage];
            this.chatWebSocketService.sendMessage(this.chatId, this.textContent.trim(), user_chat);
            this.textContent = '';
            this.scrollToBottom();
            this.cdr.markForCheck();
        }
    }

    onEmojiSelect(emoji: string) {
        this.textContent += emoji;
    }

    parseDate(timestamp: number) {
        return new Date(timestamp).toTimeString().split(':').slice(0, 2).join(':');
    }
}
