import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
//import {map, startWith} from 'rxjs/operators';
//import 'rxjs/add/operator/map' 
import { map } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Pedidos } from './../models/pedidos';
import { of } from 'rxjs';
import { IAuthToken } from '../@types/AuthToken';
import { ITransaction } from '../@types/Transaction';
import { IPaymentLinkOrder } from '../@types/PaymentLinkOrder';
import { IUserBilling } from '../@types/Billing';
export interface IOrder { 
    store_id: string;
}
@Injectable({
  providedIn: 'root'
})
export class BillingService {

  private path: string = 'billings';

  constructor(private httpClient: HttpClient, private http: HttpClient) { }

   // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/xml',
      'Authorization': `Bearer ${this.getAccessToken()}`
    })
  };


  login(email: string, pass: string): Observable<IAuthToken> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });


    const body = {
      email: email,
      password: pass
    }


      if (environment.production) {
        return this.http
      .post<IAuthToken>(`${environment.serverUrl}/${this.path}/login`, body, { headers: headers })
      } else {
        return this.http
      .post<IAuthToken>(`${environment.paymentGenetatorLinkURL}/${this.path}/login`, body, { headers: headers })

          // return this.http
          // .post(`${environment.paymentGenetatorLinkURL}/${this.path}/login`, body, { headers: headers })
          // .toPromise()
      }
  }

  gerarTokenPublic(email: string): Observable<any> {
    console.log(email);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (environment.production) {
      return this.http.post(`${environment.serverUrl}/${this.path}/login/token/`+email, { headers: headers })
    } else {
      return this.http.post(`${environment.paymentGenetatorLinkURL}/${this.path}/login/token/`+email, { headers: headers })
    }
    
  }

  consultarTransacao(id: any): Observable<ITransaction> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/xml',
        'Authorization': `Bearer ${this.getAccessToken()}`
      })
    };

    
      if (environment.production) {
        return this.http
      .get<ITransaction>(`${environment.serverUrl}/${this.path}/activities_show/`+id,this.httpOptions)
      } else {
        return this.http
      .get<ITransaction>(`${environment.paymentGenetatorLinkURL}/${this.path}/activities_show/`+id,this.httpOptions)

      }
  }

  // BUSCAR Links de Pagamentos
  // listarLinksPagamentos(): Promise<any> {


  //   return this.http
  //     .get(`${environment.serverUrl}/${this.path}/billing_details/?wallet_id=2&date_from=2021-01-01&date_to=2023-02-09&max_results=10`,this.httpOptions)
  //     .toPromise()
  // }


   // Gerar link pagamento
   gerarlinkPagamento(data: any): Observable<IPaymentLinkOrder> {

     const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessTokenEbanking()}`
    });

    if (environment.production) {
      return this.http.post<IPaymentLinkOrder>(`${environment.serverUrl}/${this.path}/billing_link`, data, { headers: headers })
    } else {
      return this.http.post<IPaymentLinkOrder>(`${environment.paymentGenetatorLinkURL}/${this.path}/billing_link`, data, { headers: headers })
    }
    
  }

  // Cancelar link pagamento
  cancelarTransacao(data: any): Observable<any> {
    console.log(data);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessTokenEbanking()}`
    });

    if (environment.production) {
      return this.http.patch(`${environment.serverUrl}/payments/cancel_payment_tid/${data}`, data, { headers: headers })
    } else {
      return this.http.patch(`${environment.paymentGenetatorLinkURL}/payments/cancel_payment_tid/${data}`, data, { headers: headers })
    }
   
 }

  detalharlinkPagamento(id: any): Promise<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/xml',
        'Authorization': `Bearer ${this.getAccessToken()}`
      })
    };
    if (environment.production) {
      return this.http
      .get(`${environment.serverUrl}/${this.path}/billing_details/`+id,this.httpOptions)
      .toPromise()
    } else {
      return this.http
      .get(`${environment.paymentGenetatorLinkURL}/${this.path}/billing_details/`+id,this.httpOptions)
      .toPromise()
    }

    
  }

  GetIssuePedidoPorTID(tid: String): Observable<Pedidos> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/xml',
        'Authorization': `Bearer ${this.getAccessToken()}`
      })
    };
    var id_loja;
    var lojas = this.getUser().stores;

    lojas.forEach((item: IOrder, index: number) => {
      id_loja = item.store_id;
    })
    if (environment.production) {
      return this.http
      .get<Pedidos>(`${environment.serverUrl}/shop/store/${id_loja}/order/tid/${tid}`, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
    } else {
      return this.http
      .get<Pedidos>(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/order/tid/${tid}`, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
    }
    
  }

  // Error handling
 errorHandl(error: any) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(() => {
    return errorMessage;
  });
}

  getAccessToken(): string {
    return localStorage.getItem("access_token") || '';
  }

  getAccessTokenPublic(): string {
    return localStorage.getItem("access_token_public") || '';
  }

  setAccessTokenPublic(access_token: string, clear: Boolean = true): void {
    localStorage.setItem("access_token_public", access_token);
  }

  setAccessTokenEbanking(access_token: string, clear: Boolean = true): void { 
    localStorage.setItem("access_token_ebanking", access_token);
  }

  setWalletId(wallet_id: string, clear: Boolean = true): void { 
    localStorage.setItem("wallet_id", wallet_id);
  }

  getWalletId(): string {
    return localStorage.getItem("wallet_id") || '';
  }
  
  getAccessTokenEbanking(): string {
    return localStorage.getItem("access_token_ebanking") || '';
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem("user") || '');
  }

}
