import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PickListObserverService {
  private pickListChange = new Subject<void>();

  pickListChange$ = this.pickListChange.asObservable();
  shouldEmit = false;
  notifyChange() {
    if (this.shouldEmit) {
      this.pickListChange.next();
    }
    this.shouldEmit = true;
  }
}
