import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pedidos } from './../models/pedidos';
import { PedidoSelected } from './../models/pedido';
import { BillingDetails } from './../models/billingDetails';
import { UserStore } from './../models/userStore';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
//import 'rxjs/add/operator/map';
import { tap, retry, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { IOrderListForPeriod, IRecentOrders } from '../@types/Pedidos';
import { of } from 'rxjs';
interface Usuario {
  user_id: number;
  user_name: string;
  user_surname: string;
  wallet_id: number;
}
interface UsuarioLP {
  name: string;
  surname: string;
  birth_date: string;
  nickname: string;
  username: string;
  phone_number: string;
  email: string;
  photo: string;
  cpf: string;
  ssn: string;
  company_name: string;
  cnpj: string;
}
interface InstPrice {
  id: number;
  name: string;
  key: string;
  cost: string;
  price: string;
  status: number;
}
interface Address {
  id: number;
  nickname: string;
  country: string;
  state: string;
  city: string;
  postal_code: string;
  address_district: string;
  address_street: string;
  address_number: string;
  address_complement: string;
}
interface Carrinho {
  shopping_cart_id: number;
  soma_items: string;
  valor_frete: string;
  total: string;
  cart_type: string;
  valor_desc_voucher_frete: string;
  valor_desc_voucher_cart: string;
  valor_desc_promo_cart: string;
  products: Produto[];
}
interface Billing {
  billing_uniq_id: string;
  billing_link: string;
  billing_id: number;
  installments_plan: Installment[];
}
interface Installment {
  n: number;
  tid: string;
  expiration_date: string;
  status: number;
  payment_link: string;
  amount: string;
}
interface Pedido {
  imagePath?: string | null;
  order: Order;
}
interface Order {
  id: number;
  store_id: number;
  shopping_cart_id: number;
  tid: string;
}
interface Produto {
  id: number;
  item_group_id: number;
  position: number;
  name: string;
  stock_qty: number;
  track_inventory: number;
  status: number;
  min_qty: number;
  inputQty: number;
  price: string;
  onsale_price: string;
  price_exchange: string;
  thumbnail: string;
  qtd: number;
}
interface Promotion {
  id: number;
  store_id: number;
  name: string;
  code: string;
  promotion_type: number;
  promotion_value_type: number;
  shopping_cart_discount: number;
  value: number;
  usage_limit: number;
  usage_qty: number;
  status: number;
}
interface IconResponse {
  data: Icon[];
  meta: Meta;
}
interface Gif {
  type: string;
  id: string;
  url: string;
  images: {
    downsized: {
      url: string;
    };
    // Inclua outras versões conforme necessário
  };
  title: string;
}

interface GifResponse {
  data: Gif[];
  meta: {

  };
  pagination: {
    total_count: number;
    last_page: number;
    per_page: number;
    current_page: number;
  };
}
interface Meta {
  pagination: Pagination;
}
interface Pagination {
  "total_count": number;
  "count": number;
  "offset": number;
}
interface Pagination {
  total: number;
  last_page: number;
  per_page: number;
  current_page: number;
}

interface Icon {
  id: number;
  name: string;
  created: string;
  slug: string;
  style: { id: number, name: string };
  family: { id: number, name: string, total: number };
  free_svg: boolean;
  author: { id: number, name: string, slug: string, avatar: string, assets: number };
  thumbnails: { width: number, height: number, url: string }[];
}

interface DeliveryMethods {
  id: number;
  method: string;
  Valor: string;
  Prazo: string;
}
interface SimulationResponseItem {
  pix?: PixData[];
  slip?: SlipData[];
  credit_card?: CreditCardData[];
}

interface PixData {
  n: number;
  charged_amount: string;
  received_amount: string;
}

interface SlipData {
  n: number;
  charged_amount: string;
  received_amount: string;
}

interface CreditCardData {
  n: number;
  charged_amount: string;
  received_amount: string;
}
//import 'rxjs/add/operator/map';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PedidosService {
  user: any;
  dadosUsuario: any;
  lojasUsuario: any;
  userData: any;

  id_metadata: any = 10;

  numero_loja: any = '';
  lojas: any;

  // Base url
  baseurl = `${environment.serverUrl}/admin`;
  baseurl_list = `${environment.serverUrl}`;

  constructor(
    private httpClient: HttpClient,
    private http: HttpClient,
    private auth: AuthService,
    private router: Router
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
  }

  // dadosUsuarioLogado(): Observable<any> {
  //   return new Observable((observer) => {
  //     this.auth.me().subscribe(
  //       (res) => {
  //         this.dadosUsuario = res;
  //         this.lojasUsuario = this.dadosUsuario.stores;
  //         console.log(res);
  //         console.log('dentro do service 1')
  //         this.lojasUsuario.forEach((item, index) => {
  //           console.log(item);
  //         });

  //         observer.next(this.dadosUsuario); // Emitir os dados do usuário
  //         observer.complete(); // Completar o observable
  //       },
  //       (err) => {
  //         console.log('dentro do service 2')
  //         console.log('no erro')
  //         // console.error(err);
  //         // console.log(err.status);
  //         if (err.status === 401) {
  //           console.log('Usuário não autenticado');
  //           this.router.navigate(['/login']);
  //         }

  //         observer.error(err); // Emitir o erro, se houver
  //       }
  //     );
  //   });
  // }
  setAccessToken(access_token: string, clear: Boolean = true): void {
    if (clear) {
      localStorage.clear();
    }
    localStorage.setItem("access_token", access_token);
  }
  // dadosUsuarioLogado(): Observable<any> {
  //   console.log('dentro do service 0');
  //   return this.auth.me(localStorage.getItem('access_token')).pipe();
  // }

  public getDadosLoja() {
    var id_loja;
    var lojas = this.getUser().stores;

    // lojas.forEach((item, index) => {
    //   id_loja = item.store_id;
    // }).pipe(
    // );


    if (environment.production) {
      return this.httpClient.get(`${environment.serverUrl}/admin/show_stores/${id_loja}`,
        this.httpOptions
      );
    } else {
      return this.httpClient.get(`${environment.paymentGenetatorLinkURL}/admin/show_stores/${id_loja}`,
        this.httpOptions
      ).pipe();

    }

    //baseurl = `${environment.serverUrl}/admin`;

  }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getAccessToken()}`,
    }),
  };

  detailPedido(id: any): Promise<any> {
    if (environment.production) {
      return this.http
        .get(`${environment.serverUrl}/admin/show_stores/${id}`, this.httpOptions)
        .toPromise();
    } else {
      return this.http
        .get(`${environment.paymentGenetatorLinkURL}/admin/show_stores/${id}`, this.httpOptions)
        .toPromise();
    }

  }

  // GET PEDIDO
  // GetIssuePedido(id): Observable<Pedidos> {
  //   var id_loja;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   }).pipe(
  //   );
  //   if (environment.production) {
  //     return this.http
  //       .get<Pedidos>(`${environment.serverUrl}/shop/store/${id_loja}/order/${id}`)
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   } else {
  //     return this.http
  //       .get<Pedidos>(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/order/${id}`)
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   }

  //   //baseurl = `${environment.serverUrl}/admin`;
  //   // return this.http
  //   //   .pipe(retry(1), catchError(this.errorHandl));
  // }

  // GetIssuePedidoPorTID(tid): Observable<Pedidos> {
  //   var id_loja;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   }).pipe(
  //   );
  //   if (environment.production) {
  //     return this.http
  //       .get<Pedidos>(`${environment.serverUrl}/shop/store/${id_loja}/order/tid/${tid}`)
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   } else {
  //     return this.http
  //       .get<Pedidos>(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/order/tid/${tid}`)
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   }
  //   // return this.http
  //   //   .pipe(retry(1), catchError(this.errorHandl));

  // }

  // GET PEDIDOS/VENDAS
  listarPedidosVendasStore(
    payment_status: number,
    data1?: any,
    data2?: any,
    user_id?: number,
    match_mode_user_id?: string,
    user_name?: string,
    match_mode_user_name?: string,
    user_cpf?: string,
    match_mode_user_cpf?: string,
    forma_pagamento?: number,
    forma_entrega?: number
  ): Observable<IOrderListForPeriod> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    // Inicializando queryParam com o parâmetro obrigatório
    let queryParam = '?payment_status=' + payment_status;

    const isValidDate = (date: any) => {
      return date instanceof Date && !isNaN(date.valueOf());
    }
    // Adicionando condicionalmente os outros parâmetros
    console.log('data1' + data1)
    if (isValidDate(new Date(data1)) && data1 != null) {
      queryParam += '&date_from=' + new Date(data1).toISOString().split('T')[0];
    }
    if (isValidDate(new Date(data2))) {
      queryParam += '&date_to=' + new Date(data2).toISOString().split('T')[0];
    }
    // Continuando com os outros parâmetros
    if (user_id) {
      queryParam += '&user_id=' + user_id + '&match_mode_user_id=' + match_mode_user_id;
    }
    if (user_name) {
      queryParam += '&user_name=' + encodeURIComponent(user_name) + '&match_mode_user_name=' + match_mode_user_name;  // Encode para URL
    }
    if (user_cpf) {
      queryParam += '&user_cpf=' + user_cpf + '&match_mode_user_cpf=' + match_mode_user_cpf;
    }
    if (forma_pagamento) {
      queryParam += '&forma_pagamento=' + forma_pagamento;
    }
    if (forma_entrega) {
      queryParam += '&forma_entrega=' + forma_entrega;
    }

    queryParam += '&data=asc';

    if (environment.production) {
      return this.http
        .get<IOrderListForPeriod>(
          `${environment.serverUrl}/admin/store/${id_loja}/order/list` + queryParam,
          this.httpOptions
        )
        .pipe(retry(1));


    } else {
      return this.http
        .get<IOrderListForPeriod>(
          `${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/order/list` + queryParam,
          this.httpOptions
        )
        .pipe(retry(1));
    }



  }

  // POST
  // CreatePedido(data): Observable<Pedidos> {
  //   if (environment.production) {
  //     return this.http
  //       .post<Pedidos>(`${environment.serverUrl}/admin/order/create`,
  //         JSON.stringify(data),
  //         this.httpOptions
  //       )
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   } else {
  //     return this.http
  //       .post<Pedidos>(`${environment.paymentGenetatorLinkURL}/admin/order/create`,
  //         JSON.stringify(data),
  //         this.httpOptions
  //       )
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   }

  //   //baseurl = `${environment.serverUrl}/admin`;
  //   // return this.http
  //   //   .post<Pedidos>(
  //   //     this.baseurl + '/order/create',
  //   //     JSON.stringify(data),
  //   //     this.httpOptions
  //   //   )
  //   //   .pipe(retry(1), catchError(this.errorHandl));

  // }

  // GET LOJA
  // GetIssueLoja(id): Observable<Pedidos> {
  //   var id_loja;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   });

  //   if (environment.production) {
  //     return this.http
  //       .get<Pedidos>(`${environment.serverUrl}/admin/show_stores/${id_loja}`, this.httpOptions)
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   } else {
  //     return this.http
  //       .get<Pedidos>(`${environment.paymentGenetatorLinkURL}/admin/show_stores/${id_loja}`, this.httpOptions)
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   }

  //   //baseurl = `${environment.serverUrl}/admin`;
  //   // return this.http
  //   //   .get<Pedidos>(this.baseurl + '/show_stores/' + id_loja, this.httpOptions)
  //   //   .pipe(retry(1), catchError(this.errorHandl));

  // }

  // BUSCAR Pedidos
  public GetPedidosByUser(user_id: number) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getAccessToken()}`,
      }),
    };
    var id_loja;
    var lojas = this.getUser().stores;

    // lojas.forEach((item, index) => {
    //   id_loja = item.store_id;
    // });

    let url;
    if (environment.production) {
      url = `${environment.serverUrl}/shop/store/${id_loja}/order/user/${user_id}`;
    } else {
      url = `${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/order/user/${user_id}`;
    }

    return this.httpClient.get(url, this.httpOptions).pipe();
  }

  // public GetPedidosPorParmetersByUser(user_id, date_from: string, date_to: string, fullfilmentStatus: number | null = null) {
  //   this.httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${this.getAccessToken()}`,
  //     }),
  //   };
  //   var id_loja;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   });

  //   let url;




  //   if (fullfilmentStatus !== null) {
  //     if (date_from == null) {
  //       url = `${environment.serverUrl}/shop/store/${id_loja}/order/user/${user_id}?fullfilment_status=${fullfilmentStatus}`;
  //     } else {
  //       url = `${environment.serverUrl}/shop/store/${id_loja}/order/user/${user_id}?date_from=${date_from}&date_to=${date_to}&fullfilment_status=${fullfilmentStatus}`;
  //     }

  //   } else {
  //     if (date_from == null) {
  //       url = `${environment.serverUrl}/shop/store/${id_loja}/order/user/${user_id}`;
  //     } else {
  //       url = `${environment.serverUrl}/shop/store/${id_loja}/order/user/${user_id}?date_from=${date_from}&date_to=${date_to}`;
  //     }

  //   }

  //   if (environment.production) {
  //     return this.httpClient.get(url, this.httpOptions).pipe();
  //   } else {
  //     return this.httpClient.get(url, this.httpOptions).pipe();
  //   }
  // }
  // BUSCAR Pedidos
  public GetPedidosPorParmeters(page: number, rows: number, filters: any = {}) {
    // Configurações iniciais de cabeçalhos HTTP
    // É uma boa prática criar uma função separada para obter os cabeçalhos HTTP,
    // especialmente se você os usa em vários métodos.
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`,  // Assegure-se de que getAccessToken está retornando o token esperado.
      }),
    };

    // Obter o ID da loja de armazenamento local ou definir como vazio se não encontrado
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    // Monta a URL com os parâmetros de paginação
    let url = `${environment.serverUrl}/shop/store/${id_loja}/order?page=${page}&limit=${rows}`;

    // Monta os parâmetros de filtro
    let filterParams = new HttpParams();
    for (let key in filters) {
      // Verifique se o filtro existe e tem um valor.
      if (filters[key] && filters[key].value) {
        // Inclui o valor do filtro
        filterParams = filterParams.append(`filters[${key}][value]`, filters[key].value);

        // Inclui o matchMode do filtro
        if (filters[key].matchMode) {
          filterParams = filterParams.append(`filters[${key}][matchMode]`, filters[key].matchMode);
        }
      }
    }

    // Faz a requisição HTTP GET com os cabeçalhos e parâmetros
    return this.httpClient.get(url, {
      headers: httpOptions.headers,
      params: filterParams // Inclui os parâmetros de filtro na requisição
    }).pipe(
      // Adicione operadores RxJS conforme necessário, por exemplo, catchError para tratar erros.
    );
  }


  // BUSCAR Pedidos
  public GetPedidos() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getAccessToken()}`,
      }),
    };
    var id_loja;
    var lojas = this.getUser().stores;

    if (environment.production) {
      return this.httpClient.get(
        `${environment.serverUrl}/shop/store/${id_loja}/orders/list?date=desc`,
        this.httpOptions
      );



    } else {
      return this.httpClient.get(
        `${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/order?date=desc`,
        this.httpOptions
      );


    }
  }

  // BUSCAR Pedidos Receitas
  public GetPedidosReceitas() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getAccessToken()}`,
      }),
    };
    var id_loja;
    var lojas = this.getUser().stores;

    // lojas.forEach((item, index) => {
    //   id_loja = item.store_id;
    // });

    if (environment.production) {
      return this.httpClient.get(`${environment.serverUrl}/shop/store/${id_loja}/order/store_customers?payment_status=2&status=1`,
        this.httpOptions
      ).pipe(

      );
    } else {
      return this.httpClient.get(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/order/store_customers?payment_status=2&status=1`,
        this.httpOptions
      ).pipe();
    }
  }

  // BUSCAR Últimos Pedidos
  public GetUltimosPedidos(): Observable<IRecentOrders> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getAccessToken()}`,
      }),
    };
    var id_loja;
    var lojas = this.getUser().stores;

    // lojas.forEach((item, index) => {
    //   id_loja = item.store_id;
    // });


    //baseurl_list = `${environment.serverUrl}`;
    if (environment.production) {
      return this.httpClient.get<IRecentOrders>(
        `${environment.serverUrl}/shop/store/${id_loja}/order?limit=10&payment_status=2&status=1&date=desc`,
        this.httpOptions
      ).pipe();

    } else {
      return this.httpClient.get<IRecentOrders>(
        `${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/order?limit=10&payment_status=2&status=1&date=desc`,
        this.httpOptions
      ).pipe();
    }


  }

  // Buscar Pedidos por Nome
  // public getPedidosPorNome(valor) {
  //   this.httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${this.getAccessToken()}`,
  //     }),
  //   };
  //   var id_loja;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   });

  //   //baseurl_list = `${environment.serverUrl}`;
  //   if (environment.production) {
  //     return this.httpClient.get(
  //       `${environment.serverUrl}/shop/store/${id_loja}/order?name=${valor}`,
  //       this.httpOptions
  //     ).pipe(

  //     );

  //   } else {
  //     return this.httpClient.get(
  //       `${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/order?name=${valor}`,
  //       this.httpOptions
  //     ).pipe(
  //     );
  //   }


  // }

  // PUT PEDIDO
  // UpdatePedido(id, data): Observable<Pedidos> {
  //   var id_loja;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   });
  //   console.log('********* Valores da data do Pedido 123');
  //   console.log(data);
  //   console.log(id);

  //   if (environment.production) {
  //     return this.http
  //       .patch<Pedidos>(`${environment.serverUrl}/admin/store/${id_loja}/order/${id}`,
  //         JSON.stringify(data),
  //         this.httpOptions
  //       )
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   } else {
  //     return this.http
  //       .patch<Pedidos>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/order/${id}`,
  //         JSON.stringify(data),
  //         this.httpOptions
  //       )
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   }

  //   //baseurl = `${environment.serverUrl}/admin`;
  //   // return this.http
  //   //   .patch<Pedidos>(
  //   //     this.baseurl + '/order/' + id,
  //   //     JSON.stringify(data),
  //   //     this.httpOptions
  //   //   )
  //   //   .pipe(retry(1), catchError(this.errorHandl));

  // }

  // // PUT COD RASTREIO
  // UpdateCodigoRastreio(id, data): Observable<Pedidos> {
  //   var id_loja;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   });
  //   console.log('********* Valores da data do Pedido');
  //   console.log(data);
  //   console.log(id);

  //   if (environment.production) {
  //     return this.http
  //       .patch<Pedidos>(`${environment.serverUrl}/admin/store/${id_loja}/order_tracker/${id}`,
  //         JSON.stringify(data),
  //         this.httpOptions
  //       )
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   } else {
  //     return this.http
  //       .patch<Pedidos>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/order_tracker/${id}`,
  //         JSON.stringify(data),
  //         this.httpOptions
  //       )
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   }
  // }

  // PUT NOTA FISCAL
  // UpdateNotaFiscal(id, data): Observable<Pedidos> {
  //   var id_loja;
  //   var lojas = this.getUser().stores;

  //   lojas.forEach((item, index) => {
  //     id_loja = item.store_id;
  //   });
  //   console.log('********* Valores da data do Pedido');
  //   console.log(data);
  //   console.log(id);

  //   if (environment.production) {
  //     return this.http
  //       .patch<Pedidos>(`${environment.serverUrl}/admin/store/${id_loja}/order_invoice/${id}`,
  //         JSON.stringify(data),
  //         this.httpOptions
  //       )
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   } else {
  //     return this.http
  //       .patch<Pedidos>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/order_invoice/${id}/store/${id_loja}`,
  //         JSON.stringify(data),
  //         this.httpOptions
  //       )
  //       .pipe(retry(1), catchError(this.errorHandl));
  //   }

  //   //baseurl = `${environment.serverUrl}/admin`;

  // } zipcode
  calcCartId(id_carrinho: number): Observable<Carrinho> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .patch<Carrinho>(`${environment.serverUrl}/shop/store/${id_loja}/shoppingcart/${id_carrinho}/calc`,
          null,
          this.httpOptions
        )
        .pipe(catchError(error => {
          return of(error);
        }));
    } else {
      return this.http
        .patch<Carrinho>(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/shoppingcart/${id_carrinho}/calc`,
          null,
          this.httpOptions
        )
        .pipe(catchError(error => {
          return of(error);
        }));
    }
  }
  deatilCartId(id_carrinho: number): Observable<Carrinho> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .get<Carrinho>(`${environment.serverUrl}/shop/shoppingcart?shopping_cart_id=${id_carrinho}`,
          this.httpOptions
        )
        .pipe(catchError(error => {
          return of(error);
        }));
    } else {
      return this.http
        .get<Carrinho>(`${environment.paymentGenetatorLinkURL}/shop/shoppingcart?shopping_cart_id=${id_carrinho}`,
          this.httpOptions
        )
        .pipe(catchError(error => {
          return of(error);
        }));
    }
  }
  /// 
  createOrder(data: any): Observable<Pedido> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .post<Pedido>(`${environment.serverUrl}/admin/store/${id_loja}/order/create`,
          JSON.stringify(data),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<Pedido>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/order/create`,
          JSON.stringify(data),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }
  updateOrder(data: any, order_id: number | null): Observable<Pedido> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .patch<Pedido>(`${environment.serverUrl}/admin/store/${id_loja}/order/${order_id}`,
          JSON.stringify(data),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .patch<Pedido>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/order/${order_id}`,
          JSON.stringify(data),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }
  updateLinkRas(data: any, order_id: number | null): Observable<Pedido> {  ///billings/{billing}/address/{walletAddress}
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .patch<Pedido>(`${environment.serverUrl}/admin/store/${id_loja}/order_tracker/${order_id}`,
          JSON.stringify(data),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .patch<Pedido>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/order_tracker/${order_id}`,
          JSON.stringify(data),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }
  updateAddress(billing_id: number, wallet_address_id: number): Observable<Pedido> {  ///billings/{billing}/address/{walletAddress}
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .patch<Pedido>(`${environment.serverUrl}/billings/${billing_id}/address/${wallet_address_id}`,
          null,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .patch<Pedido>(`${environment.paymentGenetatorLinkURL}/billings/${billing_id}/address/${wallet_address_id}`,
          null,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }
  updateOrderInvoice(data: any, order_id: number | null): Observable<Pedido> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .patch<Pedido>(`${environment.serverUrl}/admin/store/${id_loja}/order_invoice/${order_id}`,
          JSON.stringify(data),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .patch<Pedido>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/order_invoice/${order_id}`,
          JSON.stringify(data),
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }

  AdicionarItem(data: any, id_carrinho: number, id_produto: number): Observable<Carrinho> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .post<Carrinho>(`${environment.serverUrl}/shop/store/${id_loja}/shoppingcart/` + id_carrinho + '/add_product/' + id_produto,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe(catchError(error => {
          const mensagemErro = error.error.message || 'Erro desconhecido';
          return throwError(() => new Error(mensagemErro));
        }));
    } else {
      return this.http
        .post<Carrinho>(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/shoppingcart/` + id_carrinho + '/add_product/' + id_produto,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe(catchError(error => {
          const mensagemErro = error.error.message || 'Erro desconhecido';
          return throwError(() => new Error(mensagemErro));
        }));
    }

  }

  detalharLP(billing_uniq_id: string): Observable<Billing> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .get<Billing>(`${environment.serverUrl}/billings/billing_group/${billing_uniq_id}`,
          this.httpOptions
        )
        .pipe(catchError(error => {
          const mensagemErro = error.error.message || 'Erro desconhecido';
          return throwError(() => new Error(mensagemErro));
        }));
    } else {
      return this.http
        .get<Billing>(`${environment.paymentGenetatorLinkURL}/billings/billing_group/${billing_uniq_id}`,
          this.httpOptions
        )
        .pipe(catchError(error => {
          const mensagemErro = error.error.message || 'Erro desconhecido';
          return throwError(() => new Error(mensagemErro));
        }));
    }

  }
  login(email: string, pass: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  
    const body = { email, password: pass };
  
    const url = environment.production ? `${environment.serverUrl}/auth/login` : `${environment.paymentGenetatorLinkURL}/auth/login`;
  
    return this.http.post(url, body, { headers: headers }).pipe(
      catchError(error => throwError(() => new Error(error)))
    );
  }
  changePassword(data: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let user_id = localStorage.getItem("user_id") || '';
    if (environment.production) {
      return this.http
        .post<any>(`${environment.serverUrl}/users/${user_id}/changepass`, data,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<any>(`${environment.paymentGenetatorLinkURL}/users/${user_id}/changepass`, data,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }
  countSlicks(): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    if (environment.production) {
      return this.http
        .get<any>(`${environment.serverUrl}/admin/store/${id_loja}/countslicks`,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<any>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/countslicks`,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }

  detailUser(user_id: number): Observable<UsuarioLP> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .get<UsuarioLP>(`${environment.serverUrl}/users/${user_id}`,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .get<UsuarioLP>(`${environment.paymentGenetatorLinkURL}/users/${user_id}`,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }
  saveUser(user_id: number, data: any): Observable<UsuarioLP> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .patch<UsuarioLP>(`${environment.serverUrl}/users/${user_id}`, data,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .patch<UsuarioLP>(`${environment.paymentGenetatorLinkURL}/users/${user_id}`, data,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }

  forgot(email: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessToken()}`
    });
    if (environment.production) {
      return this.http.post(`${environment.serverUrl}/auth/forgot`, { email: email }, { headers: headers })
    } else {
      return this.http.post(`${environment.paymentGenetatorLinkURL}/auth/forgot`, { email: email }, { headers: headers })
    }
    
  }
  changeEmail(data: any, user_id: number): Observable<UsuarioLP> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .post<UsuarioLP>(`${environment.serverUrl}/users/change_email/${user_id}`, data,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<UsuarioLP>(`${environment.paymentGenetatorLinkURL}/users/change_email/${user_id}`, data,
          httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }
  createLP(data: any): Observable<Billing> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .post<Billing>(`${environment.serverUrl}/billings/billing_group_link`,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    } else {
      return this.http
        .post<Billing>(`${environment.paymentGenetatorLinkURL}/billings/billing_group_link`,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe(catchError(error => throwError(error)));
    }

  }
  detailLP(billing_uniq_id: string): Observable<Billing> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .get<Billing>(`${environment.serverUrl}/billings/billing_group/${billing_uniq_id}`,
          httpOptions
        )
        .pipe(catchError(error => {
          // Aqui você pode tratar o erro ou apenas reemitir
          return of(error); // Reemite o erro como um valor observável
        }));
    } else {
      return this.http
        .get<Billing>(`${environment.paymentGenetatorLinkURL}/billings/billing_group/${billing_uniq_id}`,
          httpOptions
        )
        .pipe(catchError(error => {
          // Aqui você pode tratar o erro ou apenas reemitir
          return of(error); // Reemite o erro como um valor observável
        }));
    }

  }
  // REMOVE ITEM
  removerItem(data: any, id_carrinho: number, id_produto: number): Observable<Carrinho> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .patch<Carrinho>(`${environment.serverUrl}/shop/store/${id_loja}/shoppingcart/` + id_carrinho + '/remove_product/' + id_produto,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe(catchError(error => {
          // Aqui você pode tratar o erro ou apenas reemitir
          return of(error); // Reemite o erro como um valor observável
        }));
    } else {
      return this.http
        .patch<Carrinho>(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/shoppingcart/` + id_carrinho + '/remove_product/' + id_produto,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe(catchError(error => {
          // Aqui você pode tratar o erro ou apenas reemitir
          return of(error); // Reemite o erro como um valor observável
        }));
    }

  }
  // valor_desc_voucher_frete + valor_desc_voucher_cart
  // valor_desc_promo_cart
  aplicarCupom(data: string, id_carrinho: number): Observable<Carrinho> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .patch<Carrinho>(`${environment.serverUrl}/shop/store/${id_loja}/shoppingcart/` + id_carrinho + '/voucher?name=' + data,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe(catchError(error => {
          const mensagemErro = error.error.message || 'Erro desconhecido';
          return throwError(() => new Error(mensagemErro));
        }));
    } else {
      return this.http
        .patch<Carrinho>(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/shoppingcart/` + id_carrinho + '/voucher?name=' + data,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe(catchError(error => {
          const mensagemErro = error.error.message || 'Erro desconhecido';
          return throwError(() => new Error(mensagemErro));
        }));
    }

  }
  removerCupom(id_carrinho: number): Observable<Carrinho> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .patch<Carrinho>(`${environment.serverUrl}/shop/store/${id_loja}/shoppingcart/` + id_carrinho + '/remove_voucher',
          null,
          this.httpOptions
        )
        .pipe(catchError(error => {
          return throwError(() => new Error(error));
        }));
    } else {
      return this.http
        .patch<Carrinho>(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/shoppingcart/` + id_carrinho + '/remove_voucher',
          null,
          this.httpOptions
        )
        .pipe(catchError(error => {
          return throwError(() => new Error(error));
        }));
    }

  }

  getTodosProdutos(): Observable<Produto[]> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';


    return this.httpClient.get<Produto[]>(this.baseurl_list + '/shop/store/' + id_loja + '/product_boosted?parent_sku=1', httpOptions);
  }
  GetPromotions(): Observable<Promotion[]> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.http
        .get<Promotion>(`${environment.serverUrl}/admin/store/${id_loja}/promotions`,
          httpOptions
        )
        .pipe(catchError(error => {
          // Aqui você pode tratar o erro ou apenas reemitir
          return of(error); // Reemite o erro como um valor observável
        }));
    } else {
      return this.http
        .get<Promotion>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/promotions`,
          httpOptions
        )
        .pipe(catchError(error => {
          // Aqui você pode tratar o erro ou apenas reemitir
          return of(error); // Reemite o erro como um valor observável
        }));
    }

  }
  addShippingMethodToCart(data: any, shopping_cart_id: number): Observable<Carrinho> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .patch<Carrinho>(`${environment.serverUrl}/shop/store/${id_loja}/shoppingcart/${shopping_cart_id}/shipping_method`,
          data,
          this.httpOptions
        )
        .pipe(catchError(error => {
          // Aqui você pode tratar o erro ou apenas reemitir
          return of(error); // Reemite o erro como um valor observável
        }));
    } else {
      return this.http
        .patch<Carrinho>(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/shoppingcart/${shopping_cart_id}/shipping_method`,
          data,
          this.httpOptions
        )
        .pipe(catchError(error => {
          // Aqui você pode tratar o erro ou apenas reemitir
          return of(error); // Reemite o erro como um valor observável
        }));
    }

  }
  simularVenda(data: any): Observable<SimulationResponseItem[]> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http.post<SimulationResponseItem[]>(`${environment.serverUrl}/billings/billing_group_simulate`, JSON.stringify(data), this.httpOptions)
        .pipe(catchError(error => of(error)));
    } else {
      return this.http.post<SimulationResponseItem[]>(`${environment.paymentGenetatorLinkURL}/billings/billing_group_simulate`, JSON.stringify(data), this.httpOptions)
        .pipe(catchError(error => of(error)));
    }

  }
  addZipCodeToCart(data: any, shopping_cart_id: number): Observable<Carrinho> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .patch<Carrinho>(`${environment.serverUrl}/shop/store/${id_loja}/shoppingcart/${shopping_cart_id}/zipcode`,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe(catchError(error => {
          // Aqui você pode tratar o erro ou apenas reemitir
          return of(error); // Reemite o erro como um valor observável
        }));
    } else {
      return this.http
        .patch<Carrinho>(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/shoppingcart/${shopping_cart_id}/zipcode`,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe(catchError(error => {
          // Aqui você pode tratar o erro ou apenas reemitir
          return of(error); // Reemite o erro como um valor observável
        }));
    }

  }
  listDeliveryAddress(data: any, shopping_cart_id: number): Observable<DeliveryMethods[]> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    const url = environment.production
      ? `${environment.serverUrl}/shop/store/${id_loja}/shipping_methods`
      : `${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/shipping_methods`;

    return this.http
      .post<DeliveryMethods | DeliveryMethods[]>(url, data, this.httpOptions)
      .pipe(
        map(response => Array.isArray(response) ? response : [response]), // Assegura que o retorno seja sempre um array
        catchError(error => {
          let errorMessage = error.error?.message || 'Erro desconhecido';
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  gerarSessaoVisitante(data: any): Observable<Carrinho> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .post<Carrinho>(`${environment.serverUrl}/auth/store/${id_loja}/guest_auth`,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe();
    } else {
      return this.http
        .post<Carrinho>(`${environment.paymentGenetatorLinkURL}/auth/store/${id_loja}/guest_auth`,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe();
    }

  }
  gerarSessionID(data: any, uid:string): Observable<Carrinho> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .post<Carrinho>(`${environment.serverUrl}/admin/store/${id_loja}/liveshopping/create_session_id/${uid}`,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe();
    } else {
      return this.http
        .post<Carrinho>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/liveshopping/create_session_id/${uid}`,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe();
    }

  }
  installmentInterest(data: any): Observable<InstPrice[]> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .post<InstPrice[]>(`${environment.serverUrl}/pricing/price`,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe();
    } else {
      return this.http
        .post<InstPrice[]>(`${environment.paymentGenetatorLinkURL}/pricing/price`,
          JSON.stringify(data),
          this.httpOptions
        )
        .pipe();
    }

  }
  // Listar users vinculados 
  GetUsuariosVinculadosLoja(): Observable<Usuario[]> {


    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.httpClient.get<Usuario[]>(`${environment.serverUrl}/auth/store/${id_loja}/store_customer`, this.httpOptions);
    } else {
      return this.httpClient.get<Usuario[]>(`${environment.paymentGenetatorLinkURL}/auth/store/${id_loja}/store_customer`, this.httpOptions);
    }
  }
  getUserAddress(wallet_id: number): Observable<Address[]> {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.httpClient.get<Address[]>(`${environment.serverUrl}/wallets/${wallet_id}/addresses`, this.httpOptions);
    } else {
      return this.httpClient.get<Address[]>(`${environment.paymentGenetatorLinkURL}/wallets/${wallet_id}/addresses`, this.httpOptions);
    }
  }

  detailOrder(id: number): Observable<PedidoSelected> { // sss PedidoSelected
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .get<PedidoSelected>(`${environment.serverUrl}/shop/store/${id_loja}/order/${id}`, this.httpOptions)
        .pipe(retry(1));
    } else {
      return this.http
        .get<PedidoSelected>(`${environment.paymentGenetatorLinkURL}/shop/store/${id_loja}/order/${id}`, this.httpOptions)
        .pipe(retry(1));
    }
  }
  detailBilling(tid: string): Observable<BillingDetails> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .get<BillingDetails>(`${environment.serverUrl}/billings/billing_details/${tid}`, this.httpOptions)
        .pipe(retry(1));
    } else {
      return this.http
        .get<BillingDetails>(`${environment.paymentGenetatorLinkURL}/billings/billing_details/${tid}`, this.httpOptions)
        .pipe(retry(1));
    }
  }


  detailUserFromStore(id: number): Observable<UserStore[]> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .get<UserStore[]>(`${environment.serverUrl}/auth/store/${id_loja}/store_user/user/${id}`, this.httpOptions)
        .pipe(retry(1));
    } else {
      return this.http
        .get<UserStore[]>(`${environment.paymentGenetatorLinkURL}/auth/store/${id_loja}/store_user/user/${id}`, this.httpOptions)
        .pipe(retry(1));
    }
  }
  // DELETE
  DeletePedido(id: any) {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    if (environment.production) {
      return this.http
        .delete<Pedidos>(`${environment.serverUrl}/admin/store/${id_loja}/order/${id}`, this.httpOptions)
        .pipe(retry(1));
    } else {
      return this.http
        .delete<Pedidos>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/order/${id}`, this.httpOptions)
        .pipe(retry(1));
    }
  }



  searchIcons(query: string, first: number = 0, rows: number = 96, color: string | null = '', shape: string = ''): Observable<IconResponse> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    const url = environment.production ?
      `${environment.serverUrl}/admin/store/${id_loja}/search-icons?query=${query}&first=${first}&limit=${rows}&color=${color}&shape=${shape}` :
      `${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/search-icons?query=${query}&first=${first}&limit=${rows}&color=${color}&shape=${shape}`;

    return this.http.get<IconResponse>(url, this.httpOptions);
  }
  searchGifs(query: string, first: number = 0, rows: number = 20): Observable<GifResponse> {
    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };

    const url = environment.production ?
      `${environment.serverUrl}/admin/store/${id_loja}/search-gifs?query=${query}&first=${first}&limit=${rows}` :
      `${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/search-gifs?query=${query}&first=${first}&limit=${rows}`;

    return this.http.get<GifResponse>(url, this.httpOptions);
  }

  // public getDadosLojaPorId(id) {
  //   const headers = new HttpHeaders({
  //     'Cache-Control': 'no-cache',
  //     'Content-Type': 'application/json;',
  //     Authorization: 'No Auth',
  //     Accept: '*/*',
  //   });
  //   if (environment.production) {
  //     return this.httpClient.post(`${environment.serverUrl}/admin/show_stores?store_id=${id}`, {
  //       headers: headers,
  //     }).pipe();
  //   } else {
  //     return this.httpClient.post(`${environment.paymentGenetatorLinkURL}/admin/show_stores?store_id=${id}`, {
  //       headers: headers,
  //     }).pipe();
  //   }

  // }

  // Error handling
  // errorHandl(error) {
  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     // Get client-side error
  //     errorMessage = error.error.message;
  //   } else {
  //     // Get server-side error
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   console.log(errorMessage);
  //   // window.location.reload();
  //   return throwError(() => {
  //     return errorMessage;
  //   });
  // }

  getAccessToken(): string {
    return localStorage.getItem('access_token') || '';
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem('user') || '');
  }
}
