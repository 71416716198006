<div class="card shadow-none col-12" style="padding: 1rem;">
    <!-- Placeholder para o conteúdo principal -->
    <div class="skeleton-content" style="margin-top: 1rem;">
        <!-- Placeholder para imagem -->
        <p-skeleton shape="rectangle" width="30%" height="150px" [ngStyle]="{ 'margin-bottom': '1rem' }"></p-skeleton>

        <!-- Placeholder para duas colunas menores à direita -->
        <div style="display: flex; gap: 1rem;">
            <p-skeleton shape="rectangle" width="20%" height="50px"></p-skeleton>
            <p-skeleton shape="rectangle" width="50%" height="50px"></p-skeleton>
        </div>

        <!-- Placeholder para uma série de elementos de texto -->
        <div style="margin-top: 1rem;">
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="70%" height="20px"></p-skeleton>
            </div>
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="60%" height="20px"></p-skeleton>
            </div>
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="40%" height="20px"></p-skeleton>
            </div>
        </div>

        <!-- Vídeo abaixo do cabeçalho -->
        <div class="ss-l-video-container-widget s-s-l-inlineDiv p-0 min-h-30rem" [class.col-12]="!isCol4"
            [class.md:col-6]="!isCol4" [class.lg:col-4]="!isCol4" [style.width]="isCol4 ? '100%' : null" #inlineDiv
            [style.aspect-ratio]="aspectRatio">
            <!-- <p-skeleton *ngIf="loading" height="600px" width="100%"></p-skeleton> -->

            <!-- <app-p-player [hidden]="loading" [store_id]="store_id" [height]="height" [widget_id]="widget_id"
                (loaded)="onVideoLoaded()">
            </app-p-player> -->
            <!-- Container para o componente dinâmico de vídeo -->
            <ng-template #videoPlayerContainer></ng-template>
        </div>

        <!-- Placeholder para botões ou ações, como "ver mais" -->
        <div style="margin-top: 1rem; display: flex; gap: 0.5rem;">
            <p-skeleton shape="circle" size="40px"></p-skeleton>
            <p-skeleton shape="circle" size="40px"></p-skeleton>
            <p-skeleton shape="circle" size="40px"></p-skeleton>
        </div>

        <!-- Placeholder para o rodapé -->
        <div style="margin-top: 1.5rem;">
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="90%" height="20px" [ngStyle]="{ 'margin-bottom': '0.5rem' }"></p-skeleton>
            </div>
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="80%" height="20px" [ngStyle]="{ 'margin-bottom': '0.5rem' }"></p-skeleton>
            </div>
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="40%" height="20px"></p-skeleton>
            </div>
        </div>
    </div>
</div>