import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICharging } from '../@types/Transaction';
import { IBalance } from '../@types/Wallet';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  path: string = 'homescreen';
  pathWallet: string = 'wallets';

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.getAccessToken()}`
    })
  };

  releaseBalance(userId: any, initialDate?: string, finalDate?: string): Observable<IBalance> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      })
    };
    console.log('o')
    let queryParam = '';//

    if(finalDate && initialDate){
      queryParam = `?initial_date=${initialDate}&final_date=${finalDate}`
    }

    
      if (environment.production) {
        return this.http
      .get<IBalance>(`${environment.serverUrl}/${this.path}/${userId}/release_balance`,this.httpOptions)
      //.toPromise()
      } else {
        return this.http
      .get<IBalance>(`${environment.paymentGenetatorLinkURL}/${this.path}/${userId}/release_balance`,this.httpOptions)
      }
  }

  balance(walletId: any): Observable<IBalance> {
    console.log('toooooooooken')
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      })
    };
    if (environment.production) {
      return this.http
      .get<IBalance>(`${environment.serverUrl}/${this.pathWallet}/${walletId}/balance`,this.httpOptions)
    } else {
      return this.http
      .get<IBalance>(`${environment.paymentGenetatorLinkURL}/${this.pathWallet}/${walletId}/balance`,this.httpOptions)
    }
    
  }

  cobrancasAprovadas(id: any, initialDate?: string, finalDate?: string): Observable<ICharging> {
    

    let queryParam = '';

    if(finalDate && initialDate){
      queryParam = `?initial_date=${initialDate}&final_date=${finalDate}`
    }

    
      if (environment.production) {
        return this.http
      .get<ICharging>(`${environment.serverUrl}/${this.path}/${id}/approved_charges2${queryParam}`,this.httpOptions)
      //.toPromise()
      } else {
      return this.http
      .get<ICharging>(`${environment.paymentGenetatorLinkURL}/${this.path}/${id}/approved_charges2${queryParam}`,this.httpOptions)
      }
  }

  cobrancasAprovadasSaldo(id: any, initialDate?: string, finalDate?: string): Observable<ICharging> {
    

    let queryParam = '';

    if(finalDate && initialDate){
      queryParam = `?initial_date=${initialDate}&final_date=${finalDate}`
    }

    
      if (environment.production) {
        return this.http
      .get<ICharging>(`${environment.serverUrl}/${this.path}/${id}/approved_charges${queryParam}`,this.httpOptions)
      //.toPromise()
      } else {
      return this.http
      .get<ICharging>(`${environment.paymentGenetatorLinkURL}/${this.path}/${id}/approved_charges${queryParam}`,this.httpOptions)
      }
  }

  cobrancasAtrasadas(id: any, initialDate?: string, finalDate?: string): Observable<ICharging> {
    

    let queryParam = '';

    if(finalDate && initialDate){
      queryParam = `?initial_date=${initialDate}&final_date=${finalDate}`
    }

    
      if (environment.production) {
        return this.http
      .get<ICharging>(`${environment.serverUrl}/${this.path}/${id}/late_charges2${queryParam}`,this.httpOptions)
      //.toPromise()
      } else {
      return this.http
      .get<ICharging>(`${environment.paymentGenetatorLinkURL}/${this.path}/${id}/late_charges2${queryParam}`,this.httpOptions)
      //.toPromise()
      }
  }

  cobrancasAtrasadasSaldo(id: any, initialDate?: string, finalDate?: string): Observable<ICharging> {
    

    let queryParam = '';

    if(finalDate && initialDate){
      queryParam = `?initial_date=${initialDate}&final_date=${finalDate}`
    }

    
      if (environment.production) {
        return this.http
      .get<ICharging>(`${environment.serverUrl}/${this.path}/${id}/late_charges${queryParam}`,this.httpOptions)
      //.toPromise()
      } else {
      return this.http
      .get<ICharging>(`${environment.paymentGenetatorLinkURL}/${this.path}/${id}/late_charges${queryParam}`,this.httpOptions)
      //.toPromise()
      }
  }

  cobrancasAguardando(id: any, initialDate?: string, finalDate?: string): Observable<ICharging> {
    

    let queryParam = '';

    if(finalDate && initialDate){
      queryParam = `?initial_date=${initialDate}&final_date=${finalDate}`
    }

    

      if (environment.production) {
        return this.http
        .get<ICharging>(`${environment.serverUrl}/${this.path}/${id}/awaiting_charges2${queryParam}`,this.httpOptions)
        //.toPromise()
      } else {
      return this.http
      .get<ICharging>(`${environment.paymentGenetatorLinkURL}/${this.path}/${id}/awaiting_charges2${queryParam}`,this.httpOptions)
      //.toPromise()
      }
  }

  cobrancasAguardandoSaldo(id: any, initialDate?: string, finalDate?: string): Observable<ICharging> {
    

    let queryParam = '';

    if(finalDate && initialDate){
      queryParam = `?initial_date=${initialDate}&final_date=${finalDate}`
    }

    

      if (environment.production) {
        return this.http
        .get<ICharging>(`${environment.serverUrl}/${this.path}/${id}/awaiting_charges${queryParam}`,this.httpOptions)
        //.toPromise()
      } else {
      return this.http
      .get<ICharging>(`${environment.paymentGenetatorLinkURL}/${this.path}/${id}/awaiting_charges${queryParam}`,this.httpOptions)
      //.toPromise()
      }
  }

  getAccessToken(): string {
    return localStorage.getItem("access_token") || '';
  }
}
