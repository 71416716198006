import { Component, Input, OnInit, ChangeDetectionStrategy, OnChanges, SimpleChanges, ViewEncapsulation, ChangeDetectorRef, Output, EventEmitter, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { CarouselComponent } from '../carousel/carousel.component';
import { GridComponent } from '../grid/grid.component';
import { MessageService } from 'primeng/api';
import { PopupComponent } from '../popup/popup.component';
import { InlineComponent } from '../inline/inline.component';
import { StoreService } from 'src/app/services/store.service';
import { ButtonModule } from 'primeng/button';
import { Item } from 'src/app/models/liveshop';
import { SkeletonModule } from 'primeng/skeleton';
interface VideoItem {
  uid: string;
  id: number;
  src: string;   // URL do vídeo
  poster: string; // Poster do vídeo
  thumbnail?: string;
}
@Component({
  selector: 'app-widget-template',
  standalone: true,
  templateUrl: './widget-template.component.html',
  styleUrls: ['./widget-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CarouselComponent,
    GridComponent,
    SkeletonModule,
    PopupComponent,
    ButtonModule,
    InlineComponent,
    ToastModule
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class WidgetTemplateComponent implements OnInit, OnChanges {

  @Input() containerDiv?: ElementRef<HTMLDivElement>;
  @Input() templateType?: string = '';
  @Input() popupPosition?: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
  @Input() highlight?: string;
  @Input() selectedShape?: string;
  @Input() bg_color?: string;
  @Input() font_color: string = '#000000';
  @Input() height?: number | undefined;
  @Input() aspectRatio?: any;
  @Input() widget_id?: number | undefined;
  @Input() store_id?: number | undefined;
  @Input() template_id?: number | undefined;
  @Input() limit: number | null = null;
  @Input() reloadRequest?: EventEmitter<void>;
  @Input() isCol4: boolean = false;
  totalAssets: number = 0;
  @Output() openSidebarEvent = new EventEmitter<void>();

  pageToVideos: number = 0;

  loading: boolean = false;
  videoUrls: VideoItem[] = [];
  posterUrls: string[] = [];
  private currentPage = 1;
  hasMoreVideos: boolean = true; // Flag para indicar se há mais vídeos disponíveis
  @Output() newVideos = new EventEmitter<VideoItem[]>();
  @Input() refreshTrigger: boolean = false;
  constructor(
    private storeService: StoreService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef) {


  }

  ngOnInit(): void {
    console.log('ngOnInit');
    if (this.store_id) {
      this.storeService.detailStoreInfosByID(this.store_id).subscribe(
        (response: any) => {
          const storeInfo = {
            SelectedStoreId: response.id,
            store_logo: response.store_logo
          };
          const storeInfoJSON = JSON.stringify(storeInfo);
          localStorage.setItem('store', storeInfoJSON);

        },
        (error: any) => {
        }
      );
    }
  }
  onOpenSidebar(): void {
    console.log('onOpenSidebar repassando evento');
    this.openSidebarEvent.emit();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['widget_id'] && this.widget_id) {
      this.currentPage = 1; // Resetar para a primeira página sempre que o widget_id mudar
      this.hasMoreVideos = true; // Sempre reiniciar com "mais vídeos disponíveis" ao mudar de widget
    }
    if (changes['height']) {
      console.log('height foi atualizada:', changes['height'].currentValue);
      this.cdr.detectChanges();
    }
    if (changes['refreshTrigger']) {
      this.videoUrls = [];
      this.arraySlicksFull = [];
      this.currentPage = 1;
      this.hasMoreVideos = true;
      this.loadVideos();
      console.log('refreshTrigger foi atualizada widget:', changes['refreshTrigger'].currentValue);
    }
    if (changes['widget_id']) {
      console.log('widget_id foi atualizada:', changes['widget_id'].currentValue);
      this.arraySlicksFull = [];
      //this.cdr.detectChanges();
    }
    if (changes['limit']) {
      console.log('limit foi atualizada:', changes['limit'].currentValue);
      this.cdr.detectChanges();
    }
    if (changes['containerDiv']) {
      console.log('containerDiv foi atualizada widget:', changes['containerDiv'].currentValue);
      this.cdr.detectChanges();
    }
    if (changes['isCol4']) {
      console.log('isCol4 foi atualizada widget:', changes['isCol4'].currentValue);
      this.cdr.detectChanges();
    }
    if (changes['font_color']) {
      console.log('font_color foi atualizada widget:', changes['font_color'].currentValue);
    }
  }
  capitalizeFirstLetter(text: string | undefined): string {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  arraySlicksFull: Item[] = [];
  @Output() loadingComplete = new EventEmitter<void>();
  loadVideos(): void {
    console.log('loadVideos')
    if (!this.hasMoreVideos) {
      this.loadingComplete.emit();
    }
    if (!this.widget_id || !this.hasMoreVideos) return;

    this.loading = true;
    this.storeService.listarAssetsWidget2(this.widget_id, this.currentPage, this.limit).subscribe(
      (response: any) => {
        console.log('WidgetTemplateComponent: ', response);
        this.loading = false;

        // Atualize arraySlicksFull com todos os itens recebidos
        const newItems = response.assets.flatMap((asset: any) => asset.items);
        this.arraySlicksFull = [...this.arraySlicksFull, ...newItems];
        //this.arraySlicksFull = newItems.filter((item: Item) => Object.keys(item).length > 0);

        const totalNewItems = newItems.length;
        this.pageToVideos = Math.ceil(totalNewItems / 3);
        console.log('pageToVideos calculado:', this.pageToVideos);

        const newVideoItems = response.assets.flatMap((asset: any) =>
          asset.items.map((item: any) => ({
            src: item.media.src,
            thumbnail: item.media.thumbnail ?? 'assets/imgTmp.webp',
            uid: item.content.uid,
            id: item.content.id
          }))
        );

        this.totalAssets = response.totalAssets;
        // Se a resposta não trouxer novos vídeos, significa que não há mais vídeos para carregar
        if (newVideoItems.length === 0) {
          this.hasMoreVideos = false;
        } else {
          if (this.currentPage === 1) {
            this.videoUrls = [...newVideoItems];
          } else {
            this.videoUrls = [...this.videoUrls, ...newVideoItems];
          }

          // Incrementar a página
          this.currentPage++;

        }

        this.highlight 

        this.newVideos.emit(this.videoUrls);
        this.loadingComplete.emit();
        console.log('loadingComplete')
        // Forçar detecção de mudanças para que o DataView seja atualizado imediatamente
        this.cdr.detectChanges();
      },
      (error: any) => {
        this.loading = false;
        this.handleError(error);
      }
    );
  }

  loadMoreVideosForGrid(): void {
    if (this.templateType === 'grid' && !this.loading) {
      this.loadVideos();
    }
  }

  loadMoreVideosForCarousel(): void {
    if (this.templateType === 'carousel' && !this.loading) {
      this.loadVideos();
    }
  }

  handleError(error: any): void {
    let message = '';
    if ([400, 403, 401, 404, 429].includes(error.status)) {
      message = error.error.message;
    } else if (error.status === 500) {
      message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
    }
    if (message) {
      this.showErrorDialog(message);
    }
    console.error('erro:', error);
  }

  showErrorDialog(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Erro', detail: message, icon: 'pi pi-times' });
  }
}
