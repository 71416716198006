import { Component, OnInit } from '@angular/core';
import { SubdomainService } from '../../services/subdomain.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-p-access',
  templateUrl: './p-access.component.html',
  styleUrls: ['./p-access.component.scss']
})
export class PAccessComponent implements OnInit {
  subdomain?: string;
  slick_id?: string;
  widget_id?: string;

  constructor(
    private subdomainService: SubdomainService,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.url.subscribe(urlSegments => {
      // Usando o serviço para obter o subdomínio correto baseado na URL
      this.subdomain = this.subdomainService.getSubdomainFromSegments(urlSegments);

      // Assinando queryParams para os outros parâmetros
      this.route.queryParams.subscribe(params => {
        this.slick_id = params['slick'];
        this.widget_id = params['widget'];
        console.log('Subdomínio:', this.subdomain);
        console.log('Slick ID:', this.slick_id);
        console.log('Widget ID:', this.widget_id);

        // Chamando serviço de detalhes com subdomínio
        this.storeService.detailStoreInfos(this.subdomain).subscribe(
          (response: any) => {
            console.log(response);
            if (response.id) {
              localStorage.setItem("SelectedStoreId", response.id);
              this.redirectToVideoPlayer(); // Ajuste conforme necessário
            }
          },
          (error: any) => {
            console.error('Erro ao atualizar Alerta1:', error);
            // Lidar com erro se necessário
          }
        );
      });
    });
  }

  private redirectToVideoPlayer(): void {
    this.router.navigate(['/p-video-player'], {
      queryParams: {
        slick: this.slick_id,
        widget: this.widget_id
      }
    });
  }
}
