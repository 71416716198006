<div class="flex flex-nowrap justify-content-between align-items-center border-1 surface-border border-round p-3 cursor-pointer select-none
    hover:surface-hover transition-colors transition-duration-150" 
    (keydown.enter)="changeView(user)" (click)="changeView(user)" tabindex="0">
    <div class="flex align-items-center">
        <div class="relative md:mr-3">
            <img src="assets/demo/images/avatar/{{user.image}}" alt="user" class="w-3rem h-3rem border-circle shadow-4">
            <span class="w-1rem h-1rem border-circle border-2 surface-border absolute" [ngClass]="{'bg-green-400': user.status === 'active', 'bg-red-400': user.status === 'busy', 'bg-yellow-400': 'away'}" style="bottom: 2px; right: 2px"></span>
        </div>
        <div class="flex-column hidden md:flex">
            <span class="text-900 font-semibold block">{{user.name}}</span>
            <span class="block text-600 text-overflow-ellipsis overflow-hidden white-space-nowrap w-10rem text-sm">{{lastMessage.text}}</span>
        </div>
    </div>
    <span class="text-700 font-semibold ml-auto hidden md:inline">{{user.lastSeen}}</span>
</div>