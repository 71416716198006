import { Injectable, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { WidgetsService } from 'src/app/services/widgets.service'; // Supondo que este já exista
import { WidgetDetailsResponse } from 'src/app/components/edit-widget/models';
import { MessageService } from 'primeng/api';
interface ResolutionOption {
  label: string;
  value: { width: number; height: number };
  selected?: boolean; // Opcional, pois nem todos os itens terão `selected`
}
@Injectable({
  providedIn: 'root'
})
export class WidgetHelperService {
  getPopupPositions() {
    return [
      { label: 'Superior à direita', value: 'topright' },
      { label: 'Superior à esquerda', value: 'topleft' },
      { label: 'Inferior à direita', value: 'bottomright' },
      { label: 'Inferior à esquerda', value: 'bottomleft' }
    ];
  }
  getResolutions(): ResolutionOption[] {
    return [
      { label: '9:16 (1080x1920)', value: { width: 540, height: 960 } },
      { label: '16:9 (1920x1080)', value: { width: 960, height: 540 } },
      { label: '1:1 (1080x1080)', value: { width: 540, height: 540 } }
    ];
  }
  mapPositionValue(position: string): 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright' {
    switch (position) {
      case 'top-left': return 'topleft';
      case 'top-right': return 'topright';
      case 'bottom-left': return 'bottomleft';
      case 'bottom-right': return 'bottomright';
      default: return position as 'center' | 'top' | 'bottom' | 'left' | 'right';
    }
  }
  getCtaOptions(component: any, isCreateMode: boolean) {
    return [
      {
        label: 'Customização',
        icon: 'algum-icon',
        command: () => component.selecionarOpcao(0),
        disabled: false // Sempre habilitado
      },
      {
        label: 'Aparência',
        icon: 'algum-icon',
        command: () => component.selecionarOpcao(1),
        disabled: isCreateMode // Habilitar apenas se não estiver no modo de criação
      },
      {
        label: '<> Embbeding',
        icon: 'algum-icon',
        command: () => component.selecionarOpcao(2),
        disabled: isCreateMode // Habilitar apenas se não estiver no modo de criação
      }
    ];
  }

  getWidgetTypes() {
    return [
      { label: 'Manual', value: 'manual' },
      { label: 'Tags', value: 'tags' },
      { label: 'Social (em breve)', value: 'social', disabled: true }
    ];
  }
  getSlickTypes() {
    return [
      { label: 'Todos', value: 'all' },
      { label: 'Shorts', value: 'shorts' },
      { label: 'Live', value: 'live' },
      { label: 'Spots', value: 'spots' }
    ];
  }
  constructor(private widgetService: WidgetsService, private messageService: MessageService) { }

  // Método para buscar os detalhes do widget
  getWidgetDetails(uid: string): Observable<WidgetDetailsResponse> {
    return this.widgetService.detailWidget(uid);
  }
  // Métodos de Limpeza
  showClearFunction(tags: string[]): boolean {
    return tags.length > 0;
  }
  clearTags(tags: string[]): string[] {
    return [];
  }
  // Lógica de Alteração do Layout do Vídeo
  changeLandscapeLayout(valSelect1Video: any, containerDiv: any, renderer: Renderer2) {
    if (valSelect1Video.value === 2) {
      renderer.removeClass(containerDiv.nativeElement, 'col-12');
      renderer.addClass(containerDiv.nativeElement, 'col-4');
    } else {
      renderer.removeClass(containerDiv.nativeElement, 'col-4');
      renderer.addClass(containerDiv.nativeElement, 'col-12');
    }
  }
  // Função para Cópia para Clipboard
  copiarTextoParaClipboard(texto: string) {
    navigator.clipboard.writeText(texto).then(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Texto copiado',
        detail: 'Texto copiado com sucesso!'
      });
    }).catch(err => {
      console.error('Erro ao copiar o texto:', err);
    });
  }
  // Verificação de UUID
  isUuid(value: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(value);
  }

  // Função de Filtragem
  filterItems(items: any[], query: string): any[] {
    return items.filter(item =>
      item.label.toLowerCase().includes(query.toLowerCase())
    );
  }
  // Função para listar assets e transformar os dados
  transformAssets(res: any): any[] {
    const assets = res.assets || [];
    return assets.flatMap((asset: { items: any[] }) =>
      asset.items.map((item: any) => ({
        id: item.content.id,
        title: item.content.title ?? "Título",
        type: item.media.type ?? "Slick",
        thumbnail: item.media.thumbnail ?? 'assets/imgTmp.webp',
        src: item.media.src,
        description: item.content.description,
        duration: item.media.duration
      }))
    );
  }
  // Função de Criação e Edição de Widget
  processWidgetForm(form: any, selectedTemplate: string, options: any) {
    const style: any = {};

    if (selectedTemplate === 'popup') {
      style['popup'] = {
        position: options.selectedPopupPosition || 'top-right',
        highlight: options.highlight,
        shape: options.selectedShape,
        background_color: options.bg_color
      };
    } else {
      style[selectedTemplate] = {
        background_color: options.bg_color
      };
    }

    form.patchValue({
      title: options.widgetTitle,
      feed_type: options.selectedWidgetType,
      slick_filter: options.selectedSlickType,
      max_limit: options.manualValue,
      tags: options.valuesSlicksTags ? options.valuesSlicksTags.join('|') : '',
      assets: options.targetProducts.map((product: any) => product.id),
      style: style,
      widget_rule: {
        device_display: {
          mobile: options.showSmartphones,
          desktop: options.showDesktops
        },
        url_display: {
          contain_rule: options.valuesContainTags.join(','),
          equal_rule: options.valuesEqualTags.join(',')
        }
      }
    });
  }
}
