import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError  } from 'rxjs';
import { catchError   } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IWallet } from '../@types/Wallet';
import { IUserData } from '../@types/UserData';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class WalletService {

  path: string = 'wallets';

  constructor(private http: HttpClient, private router: Router) { 
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
  }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessToken()}`
    }),
  };

  getAllWallets(): Observable<IWallet[]> {
    if (environment.production) {
      return this.http.get<IWallet[]>(`${environment.serverUrl}/${this.path}`, this.httpOptions)
        .pipe();
    } else {
      return this.http.get<IWallet[]>(`${environment.paymentGenetatorLinkURL}/${this.path}`, this.httpOptions)
        .pipe();
    }
  }

  getAllWalletsById(id:any): Promise<any> {
    if (environment.production) {
      return this.http
      .get(`${environment.serverUrl}/${this.path}/`+id, this.httpOptions)
      .toPromise()
    } else {
      return this.http
      .get(`${environment.paymentGenetatorLinkURL}/${this.path}/`+id, this.httpOptions)
      .toPromise()
    }


  }

  getWalletSetting(walletId: any): Promise<any> {
    if (environment.production) {
      return this.http.get(`${environment.serverUrl}/${this.path}/${walletId}/settings`, this.httpOptions)
        .toPromise()
        .catch( );
    } else {
      return this.http.get(`${environment.paymentGenetatorLinkURL}/${this.path}/${walletId}/settings`, this.httpOptions)
        .toPromise()
        .catch((error) => {
          
        });
    }
  }  

  getWalletyByUser(): Promise<any> {
    if (environment.production) {
      return this.http
      .get(`${environment.serverUrl}/${this.path}/${this.getUser().id}/company-accesses`, this.httpOptions)
      .toPromise()
    } else {
      return this.http
      .get(`${environment.paymentGenetatorLinkURL}/${this.path}/${this.getUser().id}/company-accesses`, this.httpOptions)
      .toPromise()
    }


  }

  changeWalletSettings(walletId: any, settingsId: string, value: string): Promise<any> {


    const body = {
      value: value
    }

    ​if (environment.production) {
      return this.http
      .patch(`${environment.serverUrl}/${this.path}/${walletId}/settings/${settingsId}`, body, this.httpOptions)
      .toPromise()
    } else {
      return this.http
      .patch(`${environment.paymentGenetatorLinkURL}/${this.path}/${walletId}/settings/${settingsId}`, body, this.httpOptions)
      .toPromise()     
      
    }

  }

  getAccessToken(): string {
    return localStorage.getItem("access_token") || '';
  }

  getUser(): IUserData {
    return JSON.parse(localStorage.getItem("user") || '');
  }

  // TODO
  // utilities/bank-institutions
}
