<div class="page-title">
    <h1>Gerencie suas vendas</h1>
</div>
<div class="grid">
    <div class="col-12 md:col-6 xl:col-3">
        <div class="card h-full">
            <ng-container *ngIf="!loading; else loadingTemplate">
                <span class="font-semibold text-lg"><i class="pi pi-check mr-2"></i> Aprovadas
                    ({{countAprovadas}})</span>
                <div class="flex justify-content-between align-items-start mt-3">
                    <div class="w-12">
                        <span class="text-4xl font-bold text-900">{{ formatarComoReais(totalAprovadas) }}</span>
                        <div class="text-green-500">
                            <span class="font-medium">
                                {{ porcentagemAprovadas !== null && porcentagemAprovadas !== 0
                                ? (porcentagemAprovadas | number:'1.0-2') + '%'
                                : porcentagemAprovadas === 0 ? '-' : '—' }}
                            </span>
                            <!-- Exibe a seta para cima se o número for maior que zero -->
                            <i *ngIf="(porcentagemAprovadas ?? 0) > 0" class="pi pi-arrow-up text-xs ml-2"></i>
                            <i *ngIf="(porcentagemAprovadas ?? 0) < 0" class="pi pi-arrow-down text-xs ml-2"></i>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #loadingTemplate>
                <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
                <p-skeleton width="60%" height="1rem"></p-skeleton>
            </ng-template>
        </div>
    </div>
    <div class="col-12 md:col-6 xl:col-3">
        <div class="card h-full">
            <ng-container *ngIf="!loading; else loadingTemplate">
                <span class="font-semibold text-lg"><i class="pi pi-clock mr-2"></i> Aguardando
                    ({{countAguardando}})</span>
                <div class="flex justify-content-between align-items-start mt-3">
                    <div class="w-12">
                        <span class="text-4xl font-bold text-900">{{ formatarComoReais(totalAguardando) }}</span>
                        <div class="text-green-500">
                            <span class="font-medium">
                                {{ porcentagemAguardando !== null && porcentagemAguardando !== 0
                                ? (porcentagemAguardando | number:'1.0-2') + '%'
                                : porcentagemAguardando === 0 ? '-' : '—' }}
                            </span>
                            <!-- Exibe a seta para cima se o número for maior que zero -->
                            <i *ngIf="(porcentagemAguardando ?? 0) > 0" class="pi pi-arrow-up text-xs ml-2"></i>
                            <i *ngIf="(porcentagemAguardando ?? 0) < 0" class="pi pi-arrow-down text-xs ml-2"></i>


                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #loadingTemplate>
                <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
                <p-skeleton width="60%" height="1rem"></p-skeleton>
            </ng-template>
        </div>
    </div>
    <div class="col-12 md:col-6 xl:col-3">
        <div class="card h-full">
            <ng-container *ngIf="!loading; else loadingTemplate">
                <span class="font-semibold text-lg"><i class="pi pi-clock mr-2"></i> Expiradas
                    ({{countExpiradas}})</span>
                <div class="flex justify-content-between align-items-start mt-3">
                    <div class="w-12">
                        <span class="text-4xl font-bold text-900">{{ formatarComoReais(totalExpiradas) }}</span>

                        <div class="text-pink-500">
                            <span class="font-medium">

                                {{ porcentagemExpiradas !== null && porcentagemExpiradas !== 0
                                ? (porcentagemExpiradas | number:'1.0-2') + '%'
                                : porcentagemExpiradas === 0 ? '-' : '—' }}
                            </span>
                            <!-- Exibe a seta para cima se o número for maior que zero -->
                            <i *ngIf="(porcentagemExpiradas ?? 0) > 0" class="pi pi-arrow-up text-xs ml-2"></i>
                            <i *ngIf="(porcentagemExpiradas ?? 0) < 0" class="pi pi-arrow-down text-xs ml-2"></i>

                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #loadingTemplate>
                <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
                <p-skeleton width="60%" height="1rem"></p-skeleton>
            </ng-template>
        </div>
    </div>
    <div class="col-12 md:col-6 xl:col-3">
        <div class="card h-full">
            <ng-container *ngIf="!loading; else loadingTemplate">
                <span class="font-semibold text-lg"><i class="pi pi-times mr-2"></i> Canceladas
                    ({{countCanceladas}})</span>
                <div class="flex justify-content-between align-items-start mt-3">
                    <div class="w-12">
                        <span class="text-4xl font-bold text-900">{{ formatarComoReais(totalCanceladas) }}</span>
                        <div class="text-green-500">
                            <span class="font-medium">
                                {{ porcentagemCanceladas !== null && porcentagemCanceladas !== 0
                                ? (porcentagemCanceladas | number:'1.0-2') + '%'
                                : porcentagemCanceladas === 0 ? '-' : '—' }}
                            </span>
                            <i *ngIf="(porcentagemCanceladas ?? 0) > 0" class="pi pi-arrow-up text-xs ml-2"></i>
                            <i *ngIf="(porcentagemCanceladas ?? 0) < 0" class="pi pi-arrow-down text-xs ml-2"></i>

                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #loadingTemplate>
                <p-skeleton width="100%" height="1.5rem"></p-skeleton>
                <p-skeleton width="100%" height="1rem"></p-skeleton>
                <p-skeleton width="60%" height="1rem"></p-skeleton>
            </ng-template>
        </div>
    </div>
</div>
<div class="card">
    <p-table [lazy]="true" [value]="orders" (onLazyLoad)="loadOrders($event)" dataKey="id" [paginator]="true"
        [rows]="10" [totalRecords]="totalRecords" [loading]="loading" [rowsPerPageOptions]="[10, 20, 100]">
        <ng-template pTemplate="caption">
            <div class="caption-container">
                <div class="left-section">
                    <p-calendar [(ngModel)]="rangeDates" selectionMode="range" (onSelect)="onDateSelect()"
                        [showIcon]="true" class=" mb-2 calendar-grid "></p-calendar>

                    <button pButton pRipple label="Limpar" (click)="clearFilter()"
                        [disabled]="!rangeDates || rangeDates.length === 0" icon="pi pi-filter-slash 2"
                        class="flex clear-button mb-2 p-button-outlined "></button>
                </div>

                <div class="ss-g-right-section">
                    <button pButton pRipple label="Gerar Link " (click)="openSidebar()"
                        class="p-button-primary-500 b1 mb-2 mr-1"></button>
                    <button pButton pRipple type="button" icon="pi pi-chevron-down" (click)="menu.toggle($event)"
                        class="p-element p-ripple p-datepicker-trigger p-button-icon-only p-button p-component mb-2"></button>
                    <p-menu #menu [model]="itemsButtons" [popup]="true" appendTo="body"></p-menu>
                </div>


            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <!-- Definição dos cabeçalhos de coluna -->
                <th class="centered-content-sales">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="id" class="centered-content-sales" style="min-width: 140px;">ID</th>
                <th pSortableColumn="customer_name" class="centered-content-sales" style="min-width: 275px;">
                    Cliente</th>
                <th pSortableColumn="cpf" class="centered-content-sales" style="min-width: 225px;">CPF</th>
                <th pSortableColumn="payment_method" class="centered-content-sales">Forma de
                    Pagamento</th>
                <th pSortableColumn="total_amount" class="centered-content-sales" style="min-width: 225px;">Valor Total
                </th>
                <th pSortableColumn="created_at" class="centered-content-sales">Data</th>
                <th pSortableColumn="payment_status" class="centered-content-sales">Pagamento</th>
                <th pSortableColumn="fullfilment_status" class="centered-content-sales">Entrega</th>
                <th class="centered-content-sales">Ações</th>
                <!-- A coluna de ações normalmente não é ordenável -->

            </tr>
            <tr>
                <!-- Linha de Filtro -->
                <th class="centered-content-sales">
                    <!-- Espaço para o checkbox, sem filtro -->
                </th>
                <th class="centered-content-sales">
                    <!-- Filtro para a coluna 'ID', se necessário -->
                    <p-columnFilter type="text" field="id"></p-columnFilter>
                </th>
                <th class="centered-content-sales">
                    <!-- Filtro para a coluna 'Cliente' -->
                    <p-columnFilter type="text" field="customer_name"></p-columnFilter>
                </th>
                <th class="centered-content-sales">
                    <!-- Filtro para CPF -->
                    <p-columnFilter placeholder="999.999.999-99" type="text" field="cpf"></p-columnFilter>
                </th>
                <th class="centered-content-sales">
                    <!-- Filtro para 'Forma de Pagamento', se necessário -->
                </th>
                <th class="centered-content-sales">
                    <!-- total_amount -->
                </th>
                <th class="centered-content-sales">
                    <!-- created_at -->
                </th>
                <th class="centered-content-sales">
                    <!-- payment_status -->
                    <p-columnFilter field="payment_status" matchMode="equals" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)"
                                placeholder="Pagamento" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    <p-tag [value]="option.label" [severity]="getSeverity(option.label)"></p-tag>
                                </ng-template>
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th class="centered-content-sales">
                    <!-- fullfilment_status -->
                    <p-columnFilter field="fullfilment_status" matchMode="equals" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" [options]="statuses2" (onChange)="filter($event.value)"
                                placeholder="Entrega" name="fullfilment_status" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    <p-tag [value]="option.label" [severity]="getSeverity2(option.label)"></p-tag>
                                </ng-template>
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th class="centered-content-sales">
                    <!-- Ações -->
                </th>
                <!-- Repita para outras colunas, conforme necessário -->
            </tr>
        </ng-template>


        <ng-template pTemplate="body" let-order>
            <tr *ngIf="!loading; else loadingContent">
                <td class="centered-content-sales">
                    <p-tableCheckbox [value]="order"></p-tableCheckbox>
                </td>
                <td class="centered-content-sales">{{ order.id }}</td>
                <td class="centered-content-sales ">
                    <i class="pi pi-user mr-2"></i>
                    {{ order.customer_name }} {{ order.customer_surname }}
                </td>
                <td class="centered-content-sales">{{ formatCpf(order.customer_cpf) }} </td>
                <td class="centered-content-sales">
                    <div *ngFor="let method of getPaymentMethods(order.payment_method)">
                        <i [ngClass]="'pi ' + method.icon"></i> {{ method.method }}
                    </div>
                </td>

                <td class="centered-content-sales">R$ {{ order.total_amount }}</td>
                <td class="centered-content-sales">{{ formatDate(order.created_at) }}</td>
                <td class="centered-content-sales">
 


                    <p-tag *ngIf="order.payment_status!== null && order.payment_status!== undefined && +order.payment_status !== 6; else dash"
                        [severity]="getPaymentValue(order.payment_status).severity"
                        [value]="getPaymentValue(order.payment_status).text" [rounded]="true">
                    </p-tag>
                    <ng-template #dash>-</ng-template>
                </td>

                <td class="centered-content-sales">

                    <p-tag *ngIf="order.fullfilment_status!== null && order.fullfilment_status!== undefined && +order.fullfilment_status !== 6; else dash"
                        [severity]="getFullfilment(order.fullfilment_status).severity"
                        [value]="getFullfilment(order.fullfilment_status).text" [rounded]="true">
                    </p-tag>
                    <ng-template #dash>-</ng-template>
                </td>


                <td class="centered-content-sales">
                    <button pButton pRipple type="button" icon="pi pi-pencil"
                        (click)="menu.toggle($event); selectedOrder = order;"></button>
                    <p-menu #menu [model]="getActionMenu(selectedOrder)" [popup]="true" appendTo="body"></p-menu>
                </td>
            </tr>
            <ng-template #loadingContent>
                <tr>
                    <td colspan="10">
                        <p-skeleton width="100%" height="1rem"></p-skeleton>
                    </td>
                </tr>
            </ng-template>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="10" style="text-align: center; padding: 20px;">
                    <span style="font-style: italic;">Nenhum pedido encontrado.</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p-confirmDialog></p-confirmDialog>

<p-dialog header="Simulador de vendas" [(visible)]="display" [modal]="true" style="z-index: 1102;"
    [dismissableMask]="true">
    <div class="card shadow-none widSimulador" style="max-width: 55vh !important;">
        <!-- -->
        <label for="autocomplete-cliente" class="w-full">Quanto você quer
            <span *ngIf="cobrarTaxas">receber</span>
            <span *ngIf="!cobrarTaxas">cobrar</span>?
        </label>
        <p-inputNumber class="w-full" id="autocomplete-cliente" [(ngModel)]="valorTotal" [readonly]="true"
            mode="currency" currency="BRL" locale="pt-BR">
        </p-inputNumber>
        <!-- -->

        <div class="flex align-items-center mt-2">
            <p-inputSwitch [(ngModel)]="cobrarTaxas" (onChange)="simular()"></p-inputSwitch>
            <div class="ss-g-labels-container ml-2 ">
                <label>Cobrar Taxas do Pagador</label>
                <small>Receba o valor total de sua cobrança</small>
            </div>
        </div>
        <div class="flex align-items-center mt-2">
            <p-inputSwitch [(ngModel)]="parcelarJuros" (onChange)="simular()"></p-inputSwitch>
            <div class="ss-g-labels-container ml-2 mb-2">
                <label>Parcelamento s/ juros</label>
                <small>Ofereça parcelamento sem juros no cartão</small>
            </div>
        </div>
        <div class="flex align-items-center mb-2" *ngIf="parcelarJuros">
            <div class="col-12" style="padding: 0;">
                <p-inputNumber class="w-full" [(ngModel)]="valSlider2" [readonly]="true"
                    suffix=" parcelas sem juros"></p-inputNumber>
                <p-slider [(ngModel)]="valSlider2" (onSlideEnd)="onSliderChange2()" [step]="1" [max]="12"
                    [min]="2"></p-slider>
            </div>
        </div>
        <div class="flex flex-column align-items-start mt-2 mb-2 card "
            style="background-color: var(--surface-ground);">
            <div>Você
                <span *ngIf="!cobrarTaxas"> cobra </span>
                <span *ngIf="cobrarTaxas"> recebe </span>
                <b>{{ valorTotal | currency:'BRL':'symbol-narrow' }}</b>
            </div>
        </div>
        <div class="flex flex-column align-items-start mt-2 mb-2 ">
            <div class="text-xs font-light">O valor
                <span *ngIf="!cobrarTaxas"> cobrado </span>
                <span *ngIf="cobrarTaxas"> recebido </span>
                pode variar conforma as condições de pagamento escolhidas - <b>Formas de pagamento</b> (cartão, boleto
                ou pix) e <b>Prazo</b> (à vista ou parcelado). Confira nossas taxas <b>aqui</b>.
            </div>
        </div>


        <div class="flex align-items-center mt-2 w-full">
            <p-tabMenu [model]="itemsSimulador" [scrollable]="false" [activeItem]="activeItemSimulador"
                [ngStyle]="{'width': '100%'}"></p-tabMenu>
        </div>
        <div class="flex align-items-center mt-2">
            <p-table [value]="currentTableData" class="w-full">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Cobrado</th>
                        <th>Recebido</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                    <tr>
                        <td>{{ i+1 }} x {{ rowData.charged_amount | currency:'BRL':'symbol' }} ({{
                            (rowData.charged_amount * (i + 1)) | currency:'BRL':'symbol' }})</td>
                        <td>{{ rowData.received_amount | currency:'BRL':'symbol' }}</td>
                    </tr>
                </ng-template>
            </p-table>

        </div>

    </div>
    <!-- mode="currency" currency="BRL" -->
    <p-footer>
        <button pRipple type="button" pButton label="Fechar" icon="pi pi-times" (click)="display=false"></button>
    </p-footer>
</p-dialog>
<p-dialog header="Processando" [(visible)]="displayDialog" [modal]="true" [closable]="false" [resizable]="false">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</p-dialog>
<p-dialog header="Aguardando" [(visible)]="displayDialogSidebar" [modal]="true" [closable]="false" [resizable]="false">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</p-dialog>



<!-- dialog de editar pedido -->
<p-dialog [(visible)]="showDialogEditOrder" (onHide)="clearEditForm()"
    [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [contentStyle]="{'z-index': '10001'}" class="z-5"
    [style]="{width: '36rem', zIndex: 2200}" [modal]="true" [closable]="true">
    <ng-template pTemplate="header">
        <span class="text-900 font-semibold text-xl">Editar Pedido</span>
    </ng-template>
    <ng-template pTemplate="content">
        <div>
            <div class="mb-2 w-full">
                <span class="text-900 font-semibold block ss-g-globalClassLabel">Status de Pagamento</span>
                <p-dropdown [autofocus]="addClassToDropdown" #paymentDropdown [appendTo]="'body'" [options]="statuses"
                    [(ngModel)]="selectedPaymentStatusDialog" (onChange)="opc(1)" placeholder="Status de Pagamento"
                    [showClear]="true" [ngClass]="{'p-inputwrapper-focus': addClassToDropdown}">
                    <ng-template let-option pTemplate="item">
                        <p-tag [value]="option.label" [severity]="getSeverity(option.label)"></p-tag>
                    </ng-template>
                </p-dropdown>
            </div>

            <div class="mb-2 w-full" *ngIf="shippingContent">
                <span class="text-900 font-semibold block ss-g-globalClassLabel">Status de Entrega</span>
                <p-dropdown [autofocus]="addClassToDropdownShippingStatus" [appendTo]="'body'" #shippingStatus
                    [options]="statuses2" [(ngModel)]="selectedShippingStatusDialog" (onChange)="opc(2)"
                    placeholder="Status de Entrega" [showClear]="true">
                    <ng-template let-option pTemplate="item">
                        <p-tag [value]="option.label" [severity]="getSeverity2(option.label)"></p-tag>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="mb-2 w-full" *ngIf="shippingContent">
                <span class="text-900 font-semibold block ss-g-globalClassLabel">Link de Rastreio</span>
                <input #linkRastreio [autofocus]="addClassLinkRastreio" id="title" type="text" class="w-full" pInputText
                    placeholder="Title" (change)="opc(3)" [(ngModel)]="trackingLink" />
            </div>
            <div class="mb-2 w-full" *ngIf="shippingContent">
                <label class="w-full ss-g-globalClassLabel">Selecione o endereço do
                    cliente
                    <p-autoComplete #address [autofocus]="addClassAddress" [appendTo]="'body'"
                        [(ngModel)]="selectedAddressEdit" id="autocomplete-cliente-addrress" (onChange)="opc(4)"
                        field="nickname" class="w-full ss-g-sidebarVendas" [showClear]="true"
                        [suggestions]="filteredResultsAddressEdit" (completeMethod)="searchAddressEdit($event)"
                        (onSelect)="onSelectAddressEdit($event)" (onClear)="onClearAddress()" [dropdown]="true"
                        [ngClass]="{'ng-invalid': hasError3, 'ng-dirty': hasError3}">
                        <ng-template let-address pTemplate="item">
                            {{ address.nickname }}({{ address.address_street }} - {{ address.address_number }} - {{
                            address.address_district }} - {{ address.address_complement }} - {{ address.city }}, {{
                            address.state }} - {{ address.country }} - {{ address.postal_code }})
                        </ng-template>
                    </p-autoComplete>
                </label>
            </div>


        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple (click)="clearEditForm()" label="Fechar" *ngIf="activeIndex === 0"
            class="p-button-outlined"></button>
        <button pButton pRipple label="Salvar" class="ml-2 mr-2" (click)="saveOrder()" [disabled]="!changeOrder"
            [loading]="loadingFinishSaveOrder"></button>
    </ng-template>
</p-dialog>
<!-- dialog de editar pedido -->

<p-toast></p-toast>
















<p-sidebar [(visible)]="displaySidebarEdit" (onHide)="closeSidebarDetail()" class="ss-g-custom-sidebar-width z-1"
    position="right">
    <ng-template pTemplate="header">
        <div style="display: flex; flex-direction: column;">
            <h3 style="margin-bottom: 0;">Detalhes da venda > {{ order_id_current }}</h3>
        </div>
    </ng-template>
    <div class="sidebar-content">
        <p>Veja e gerencie os dados de suas vendas</p>

        <!---->
        <div class="card shadow-none mt-5">
            <div class="flex flex-column sm:flex-row grid sm:align-items-center sm:justify-content-between">
                <div class="mb-2 sm:mb-0 col-12">
                    <span class="font-medium text-xl text-900 mr-2">Data:</span>
                    <span class="font-medium text-xl text-blue-500">{{ date_current }}</span>
                    <br />
                    <span class="font-medium text-xl text-900 mr-2">ID de venda:</span>
                    <span class="font-medium text-xl text-blue-500">{{ order_id_current }}</span>
                </div>
                <div class="col-12">
                    <button pButton pRipple label="Details" icon="pi pi-list"
                        class="p-button-outlined p-button-primary mr-2"></button>
                    <button pButton pRipple label="Print" icon="pi pi-print"
                        class="p-button-outlined p-button-primary"></button>
                </div>
            </div>
            <div class="surface-border border-0" [ngClass]="'border-top-1'">
                <div class="surface-border mt-3">
                    <span class="font-medium text-xl">Resumo do Carrinho</span>
                </div>
                <ul class="list-none p-0 m-0">
                    <li *ngFor="let product of products; let i = index;"
                        class="p-3 surface-border flex align-items-start sm:align-items-center"
                        [ngClass]="{'border-bottom-1' : i !== products.length-1}">
                        <img [src]="product.thumbnail" class="w-3rem sm:w-8rem flex-shrink-0 mr-3 shadow-2" />
                        <div class="flex flex-column">
                            <span class="text-900 font-semibold text-xl mb-2">{{product.name}}</span>
                            <span class="text-900 font-medium">Quantity: {{product.item_qty}}</span>
                        </div>
                        <span class="text-900 font-medium text-lg ml-auto">{{ product.item_price |
                            currency:'BRL':'symbol'}}</span>
                    </li>
                </ul>
            </div>
            <div class="mt-3 surface-border border-bottom-3" [ngClass]="'border-top-1'">
                <div class="flex justify-content-between align-items-center mb-2 mt-2">
                    <span class="text-900 font-medium">Subtotal</span>
                    <span class="text-900">{{ subtotalSelected | currency:'BRL':'symbol-narrow' }}</span>
                </div>
                <div class="flex justify-content-between align-items-center mb-2 ">
                    <span class="text-900 font-medium">Entrega</span>
                    <span class="text-900 font-bold">{{ valorFreteSelected | currency:'BRL':'symbol-narrow'
                        }}</span>
                </div>
                <div class="flex justify-content-between align-items-center mb-2">
                    <span class="text-900 font-medium">Promoções </span>
                    <span class="text-900">-{{ valorPromoSelected | currency:'BRL':'symbol-narrow' }}</span>
                </div>
                <div class="flex justify-content-between align-items-center mb-2">
                    <span class="text-900 font-medium">Cupom</span>
                    <span class="text-900">-{{ valorCupomSelected | currency:'BRL':'symbol-narrow' }}</span>
                </div>
                <div class="flex justify-content-between align-items-center mb-2">
                    <span class="text-900 font-bold">Total</span>
                    <span class="text-900 font-medium text-xl">{{ valorTotalSelected |
                        currency:'BRL':'symbol-narrow'
                        }}</span>
                </div>
            </div>
            <div class="mt-5" *ngIf="shippingContent">
                <div class="delivery-container mb-2">
                    Entrega
                    <div>
                        <p-tag
                            *ngIf="productShippingStatusSelected !== null && productShippingStatusSelected !== undefined && +productShippingStatusSelected !== 6; else dash"
                            [severity]="getSeverity2(getLabelForProductShippingStatus(productShippingStatusSelected))"
                            [value]="getLabelForProductShippingStatus(productShippingStatusSelected)" [rounded]="true">
                        </p-tag>
                        <ng-template #dash>-</ng-template>




                    </div>
                </div>
                <div class="mt-2 surface-border border-bottom-3">
                    <div class="bg-gray-100 py-2 px-3 flex align-items-center border-round">
                        <span class="text-black-alpha-90 font-bold mr-3"><i class="pi pi-inbox mr-2"></i></span>
                        <span class="text-black-alpha-60 font-semibold">{{shippingMethodSelected}} -
                            <b>{{valorFreteSelected
                                | currency:'BRL':'symbol-narrow' }}</b></span>
                        <button pButton pRipple type="button" icon="pi pi-fw pi-pencil" (click)="editarOrder(1)"
                            class="p-button-rounded p-button-text text-black-alpha-60 ml-auto"></button>
                    </div>
                    <div class="mt-3">
                        <span class="ss-g-globalClassLabel">Link de Rastreio</span>
                        <div class="bg-gray-100 py-2 px-3 flex align-items-center border-round mt-2">
                            <span
                                class="text-black-alpha-60 font-semibold break-word">{{shipping_tracker_linkSelected}}</span>
                            <button pButton pRipple type="button" icon="pi pi-fw pi-pencil" (click)="editarOrder(2)"
                                class="p-button-rounded p-button-text text-black-alpha-60 ml-auto"></button>
                        </div>
                    </div>
                    <div class="mt-3 mb-5">
                        <div class="flex-container mb-2">
                            <span><i class="pi pi-truck mr-2"></i> Endereço de entrega</span>
                            <i class="pi pi-copy mr-2" style="cursor: pointer;" (click)="copyAddress()"></i>
                        </div>
                        <div class="bg-gray-100 py-2 px-3 flex align-items-center border-round">
                            <span class="text-black-alpha-60 font-semibold">{{addressSelected}}</span>
                            <button pButton pRipple type="button" icon="pi pi-fw pi-pencil" (click)="editarOrder(4)"
                                class="p-button-rounded p-button-text text-black-alpha-60 ml-auto"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 surface-border border-bottom-3">
                <div class="delivery-container mb-2">
                    Pagamento
                    <div>
                        <p-tag [value]="getLabelForPaymentStatus(paymentStatusStatusSelected)" class="pointer-cursor"
                            (click)="editarOrder(3)"
                            [severity]="getSeverity(getLabelForPaymentStatus(paymentStatusStatusSelected))"></p-tag>
                    </div>
                </div>
                <div class=" ">
                    <div class="mb-2">
                        <ng-template #defaultContent>
                            <div class="bg-gray-100 py-2 px-3 flex align-items-center border-round">
                                -
                                <button pButton pRipple type="button" icon="pi pi-fw pi-pencil" (click)="editarOrder(3)"
                                    class="p-button-rounded p-button-text text-black-alpha-60 ml-auto"></button>
                            </div>
                        </ng-template>

                        <div *ngIf="paymentStatusStatusSelected != '2'; else paymentContent">
                            <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
                        </div>


                        <ng-template #paymentContent>
                            <div *ngIf="tipoPagamento === 'cartao'; else otherPayments">
                                <div class="bg-gray-100 py-2 px-3 flex align-items-center border-round">
                                    <span class="text-black-alpha-90 font-bold mr-3">
                                        <i class="pi pi-credit-card mr-2"></i>
                                    </span>
                                    <span class="text-black-alpha-60 font-semibold">
                                        {{ (dadosCartao?.card_network && dadosCartao?.final_card) ?
                                        (dadosCartao?.card_network + ' final ' + dadosCartao?.final_card) : 'Cartão de
                                        Crédito' }}
                                    </span>


                                    <button pButton pRipple type="button" icon="pi pi-fw pi-pencil"
                                        (click)="editarOrder(3)"
                                        class="p-button-rounded p-button-text text-black-alpha-60 ml-auto"></button>
                                </div>
                            </div>

                            <ng-template #otherPayments>
                                <div *ngIf="tipoPagamento === 'pix'">
                                    <div class="bg-gray-100 py-2 px-3 flex align-items-center border-round">
                                        <!-- Exibir informações do PIX -->
                                        <i class="pi pi-bolt"></i> Pix

                                        <button pButton pRipple type="button" icon="pi pi-fw pi-pencil"
                                            (click)="editarOrder(3)"
                                            class="p-button-rounded p-button-text text-black-alpha-60 ml-auto"></button>
                                    </div>
                                </div>
                                <div *ngIf="tipoPagamento === 'boleto'">
                                    <!-- Exibir informações do boleto -->
                                    <div class="bg-gray-100 py-2 px-3 flex align-items-center border-round">
                                        <i class="pi pi-file"></i> Boleto

                                        <button pButton pRipple type="button" icon="pi pi-fw pi-pencil"
                                            (click)="editarOrder(3)"
                                            class="p-button-rounded p-button-text text-black-alpha-60 ml-auto"></button>
                                    </div>
                                </div>
                                <div *ngIf="!tipoPagamento">
                                    <div class="bg-gray-100 py-2 px-3 flex align-items-center border-round">
                                        -
                                        <button pButton pRipple type="button" icon="pi pi-fw pi-pencil"
                                            (click)="editarOrder(3)"
                                            class="p-button-rounded p-button-text text-black-alpha-60 ml-auto"></button>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-template>

                    </div>
                </div>
                <div class="col-12 mt-3 mb-2 flex flex-column" style="padding: 0;">
                    <span class="ss-g-globalClassLabel">Faça o upload de sua nota fiscal (até <b>5mb</b>)</span>
                    <p-fileUpload #fileUpload name="demo[]" accept="image/*,application/pdf" [multiple]="false"
                        (onSelect)="onFileSelect($event)" [customUpload]="true" [showCancelButton]="false"
                        (uploadHandler)="myUploadMethod($event)"
                        styleClass="border-1 surface-border surface-card border-round">
                        <ng-template pTemplate="content">
                            <div class="h-15rem" style="cursor: pointer" (click)="fileUpload.choose()">
                                <!-- Exibir apenas se nenhum arquivo for selecionado -->
                                <div *ngIf="!invoiceSelected"
                                    class="flex flex-column w-full h-full justify-content-center align-items-center">
                                    <i class="pi pi-file text-4xl text-primary"></i>
                                    <span class="block font-semibold text-900 text-lg mt-3">Arraste um arquivo ou
                                        selecione</span>
                                </div>

                                <!-- Exibir após selecionar um arquivo -->
                                <div *ngIf="invoiceSelected" class="flex flex-wrap gap-3 border-round">
                                    <div class="h-full w-7rem h-7rem border-3 border-transparent border-round position-relative"
                                        (mouseenter)="showDeleteButton = true" (mouseleave)="showDeleteButton = false">
                                        <img *ngIf="isImage(invoiceSelected)" [src]="invoiceSelected"
                                            class="w-full h-full  shadow-2"
                                            [ngClass]="{'border-round': showDeleteButton}">
                                        <embed *ngIf="!isImage(invoiceSelected)" [src]="invoiceSelected"
                                            class="w-full h-full border-round shadow-2">
                                        <!-- Botão de excluir -->
                                        <button *ngIf="showDeleteButton" pButton pRipple type="button"
                                            icon="pi pi-times"
                                            class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center cursor-pointer w-2rem h-2rem"
                                            style="top: -10px; right: -10px;" (click)="removeInvoice($event)"></button>
                                    </div>
                                </div>

                            </div>
                        </ng-template>
                    </p-fileUpload>



                    <button pButton pRipple label="Enviar" [loading]="loadingFinishUpload" class="w-full mb-2 mt-2"
                        (click)="enviarArquivo()"></button>


                </div>





            </div>
            <div class="mt-3 surface-border border-bottom-3">
                <div class="delivery-container mb-2 ss-g-globalClassLabel">
                    Cliente
                </div>
                <ul class="list-none p-0 m-0 mb-2">
                    <li class="flex align-items-center">
                        <i class="pi pi-user mr-3 text-6xl"></i>
                        <div class="flex flex-column">
                            <span class="text-xl font-medium text-900 mb-1"> {{ customerNameSelected }} {{
                                customerSurnameSelected }}</span>
                            <a class="font-medium no-underline text-blue-500 cursor-pointer">Ver cliente</a>
                        </div>
                        <!-- <span class="text-xl text-900 ml-auto font-semibold">$250.00</span> -->
                    </li>
                </ul>

            </div>
            <div class="flex flex-wrap mt-5 pb-3">
                <button pButton pRipple type="button" [disabled]="cancelOrderDisabled" label="Cancelar Pedido" class="p-button-danger w-full mb-2"
                    (click)="cancelOrder()"></button>


            </div>

        </div>
        <!---->
    </div>
    <ng-template pTemplate="footer">
        <div style="text-align: right;">
            <button pButton pRipple (click)="closeSidebarDetail()" label="Fechar" class=""></button>



        </div>
    </ng-template>
</p-sidebar>




<p-sidebar [(visible)]="displaySidebar" (onHide)="closeSidebar()" class="ss-g-custom-sidebar-width" position="right">
    <ng-template pTemplate="header">
        <h3 style="margin-bottom: 0;">Gerar Link de Venda</h3>
    </ng-template>
    <div class="sidebar-content">

        <p>Reserve Itens e personalize as condições de pagamento para cada cliente</p>
        <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="true"></p-steps>

        <!-- Adicione conteúdo adicional baseado na etapa ativa -->
        <div *ngIf="activeIndex === 0" class="grid formgrid mt-5">
            <div class="col-12 md:col-12">
                <div class="card shadow-none">
                    <div class="flex flex-column">
                        <label for="name" class="w-full ss-g-globalClassLabel">Selecione um cliente
                            <p-autoComplete [(ngModel)]="selectedClient" id="name" field="name"
                                class="w-full ss-g-sidebarVendas" [suggestions]="filteredResults"
                                [ngClass]="{'ng-invalid': hasError1, 'ng-dirty': hasError1}"
                                (completeMethod)="search($event)" (onSelect)="onSelect($event)" [dropdown]="true">
                                <ng-template let-user pTemplate="item">
                                    {{ user.name }} {{ user.surname }}
                                </ng-template>
                            </p-autoComplete>
                        </label>
                        <div class="flex align-items-center justify-content-between">
                            <!-- Mensagem de Erro à esquerda -->
                            <small *ngIf="erro1" class="p-error mb-2">
                                Obrigatório
                            </small>

                            <!-- Link "+ Cadastrar" à direita -->
                            <a class="font-medium no-underline ml-auto text-blue-500 cursor-pointer">+ Cadastrar</a>
                        </div>
                    </div>
                    <div class="flex flex-column mb-5 surface-border border-0 border-bottom-1">
                        <label for="multiselect-itens" class="w-full ss-g-globalClassLabel">Selecione os itens
                            <p-multiSelect [options]="itemsProdutos" #multiselect id="multiselect-itens"
                                placeholder="Selecione os itens" [group]="true" optionLabel="label"
                                class="w-full ss-g-sidebarVendas multiselect-custom" [(ngModel)]="selectedItems"
                                (onChange)="processSelectionChange()" optionGroupLabel="label"
                                [ngClass]="{'ng-invalid': hasError2, 'ng-dirty': hasError2}" scrollHeight="250px"
                                display="chip" optionGroupChildren="items">
                                <ng-template let-item pTemplate="item2">
                                    <div class="custom-chip" [ngClass]="{'disabled-item': item.isDisabled}">
                                        {{ item.label }}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </label>
                        <div class="flex align-items-center justify-content-between  mb-5">
                            <!-- Mensagem de Erro à esquerda -->
                            <small *ngIf="erro2" class="p-error mb-2">
                                Obrigatório
                            </small>

                            <!-- Subtotal à direita -->
                            <p class="text-right flex-grow-1">Subtotal: <b>{{ subtotal | currency:'BRL':'symbol-narrow'
                                    }}</b>
                            </p>
                        </div>
                    </div>
                    <div class="flex flex-column mb-2 mt-2"
                        *ngIf="cart_type != '' && cart_type != 'digital' && selectedClient">
                        <label for="autocomplete-cliente-addrress" class="w-full ss-g-globalClassLabel">Selecione o endereço
                            do
                            cliente</label>
                        <p-autoComplete [(ngModel)]="selectedAddress" id="autocomplete-cliente-addrress"
                            field="nickname" class="w-full ss-g-sidebarVendas" [showClear]="true"
                            [suggestions]="filteredResultsAddress" (completeMethod)="searchAddress($event)"
                            (onSelect)="onSelectAddress($event)" (onClear)="onClearAddress()" [dropdown]="true"
                            [ngClass]="{'ng-invalid': hasError3, 'ng-dirty': hasError3}">
                            <ng-template let-address pTemplate="item">
                                {{ address.nickname }}({{ address.address_street }} - {{ address.address_number }} - {{
                                address.address_district }} - {{ address.address_complement }} - {{ address.city }}, {{
                                address.state }} - {{ address.country }} - {{ address.postal_code }})
                            </ng-template>
                        </p-autoComplete>
                        <div class="flex align-items-center justify-content-between mt-2">
                            <!-- Mensagem de Erro à esquerda -->
                            <small *ngIf="erro3 && (cart_type != 'digital')" class="p-error mb-2">
                                Obrigatório
                            </small>

                            <!-- Link "+ Cadastrar" à direita -->
                            <a class="font-medium no-underline ml-auto text-blue-500 cursor-pointer">+ Cadastrar</a>
                        </div>


                    </div>
                    <!-- Forma de entrega -->
                    <div class="radio-button-group flex flex-wrap gap-3"
                        *ngIf="cart_type != '' && cart_type != 'digital' && selectedAddress">
                        <label class="w-full ss-g-globalClassLabel">Selecione a forma de entrega</label>
                        <div *ngIf="loadingDeliveryOptions" style="width: 100%; text-align: center;">
                            <p-skeleton width="100%" height="1.5rem" class="mb-2"></p-skeleton>
                        </div>
                        <div *ngIf="loadingDeliveryOptions" style="width: 100%; text-align: center;">
                            <p-skeleton width="100%" height="1.5rem" class="mb-2"></p-skeleton>
                        </div>
                        <div *ngIf="loadingDeliveryOptions" style="width: 100%; text-align: center;">
                            <p-skeleton width="100%" height="1.5rem" class="mb-2"></p-skeleton>
                        </div>
                        <ng-container *ngIf="!loadingDeliveryOptions">
                            <p-radioButton *ngFor="let option of deliveryOptions" name="deliveryMethod"
                                [value]="option.value" class="mt-2" [(ngModel)]="selectedDeliveryMethod"
                                label="{{option.label}}" (ngModelChange)="onDeliveryMethodChange()"
                                [ngClass]="{'ng-invalid': hasError4, 'ng-dirty': hasError4}">
                            </p-radioButton>
                        </ng-container>


                    </div>
                    <div class="flex align-items-center justify-content-between mt-2">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="erro4 && cart_type != 'digital' && selectedAddress" class="p-error">
                            Obrigatório
                        </small>
                    </div>

                    <div *ngIf="selectedDeliveryMethod?.id === 7 && selectedAddress && cart_type != '' && cart_type != 'digital'"
                        class="mt-2">
                        <div class="input-wrapper-vencimento w-full">
                            <p-inputNumber type="text" mode="currency" currency="BRL" [(ngModel)]="manualValue"
                                class="w-full ss-g-inputM" (ngModelChange)="ValorManual()" locale="pt-BR"
                                placeholder="R$ 0,00"></p-inputNumber>
                        </div>
                    </div>
                    <div *ngIf="selectedDeliveryMethod?.id === 7 && selectedAddress && cart_type != '' && cart_type != 'digital'"
                        class="mt-2">
                        <label class="ml-2 w-full">Insira um valor manualmente</label>
                    </div>

                    <!-- Forma de entrega -->
                    <div class="mt-5 grid grid-nogutter">
                        <!-- -->
                        <div class="col-12">
                            <div class="pb-3 surface-border">
                                <span class="font-medium text-xl">Resumo do Carrinho</span>
                            </div>
                            <div class="grid lg:align-items-center py-2 mt-3" *ngFor="let product of selectedItems">
                                <div class="col-4 ss-g-imgCenter">
                                    <img [src]="product.thumbnail" class="w-8rem h-8rem  mb-2" alt="product" />
                                </div>
                                <div class="col-8">
                                    <div class="flex align-items-center justify-content-between mb-2">
                                        <span class="text-900 font-bold ss-g-product-label">{{ product.label.length > 60 ?
                                            (product.label | slice:0:60) + '...' : product.label }}</span>
                                        <span class="text-900 font-bold">{{ product.price |
                                            currency:'BRL':'symbol-narrow'
                                            }}</span>
                                    </div>
                                    <!-- <div class="text-600 text-sm mb-2">Black | Large</div> -->
                                    <div class="flex flex-auto justify-content-between align-items-center">
                                        <p-inputNumber [showButtons]="true" buttonLayout="horizontal"
                                            spinnerMode="horizontal" (ngModelChange)="onQuantityChange($event, product)"
                                            [min]="product.min_qty || 1" [(ngModel)]="product.inputQty"
                                            inputStyleClass="w-2rem text-center py-2 px-1 border-transparent outline-none shadow-none"
                                            class="border-1 surface-border border-round"
                                            decrementButtonClass="p-button-text text-600 hover:text-primary py-1 px-1"
                                            incrementButtonClass="p-button-text text-600 hover:text-primary py-1 px-1"
                                            incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus"></p-inputNumber>
                                        <button pButton pRipple icon="pi pi-trash"
                                            class="p-button-text p-button-rounded"
                                            (click)="removeItemFromCart(product)"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="py-2 mt-2 surface-border">
                                <label class="w-full mb-2">Possui cupom de desconto</label>
                                <div class="p-inputgroup mb-2 mt-2">
                                    <input type="text" pInputText class="w-full" [(ngModel)]="cupom">
                                    <button type="button" pButton pRipple label="Aplicar" (click)="aplicarCupom()"
                                        [loading]="loadingCupom" [disabled]="cupomAplicado"></button>
                                </div>
                                <div class="flex-container">
                                    <a *ngIf="cupomAplicado; else placeholder" class="ss-g-remove-coupon"
                                        style="cursor: pointer;" (click)="removerCupom()">
                                        <i class="pi pi-times"></i> Remover Cupom
                                    </a>
                                    <ng-template #placeholder><span class="placeholder"></span></ng-template>
                                    <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">+
                                        Cadastrar</a>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="flex justify-content-between align-items-center mb-2">
                                    <span class="text-900 font-medium">Subtotal</span>
                                    <span class="text-900">{{ subtotal | currency:'BRL':'symbol-narrow' }}</span>
                                </div>
                                <div class="flex justify-content-between align-items-center mb-2 ">
                                    <span class="text-900 font-medium">Entrega</span>
                                    <span class="text-900 font-bold">{{ valorFrete | currency:'BRL':'symbol-narrow'
                                        }}</span>
                                </div>
                                <div class="flex justify-content-between align-items-center mb-2">
                                    <span class="text-900 font-medium">Promoções </span>
                                    <span class="text-900">-{{ valorPromo | currency:'BRL':'symbol-narrow' }}</span>
                                </div>
                                <div class="flex justify-content-between align-items-center mb-2">
                                    <span class="text-900 font-medium">Cupom</span>
                                    <span class="text-900">-{{ valorCupom | currency:'BRL':'symbol-narrow' }}</span>
                                </div>
                                <div class="flex justify-content-between align-items-center">
                                    <span class="text-900 font-bold">Total</span>
                                    <span class="text-900 font-medium text-xl">{{ valorTotal |
                                        currency:'BRL':'symbol-narrow'
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div *ngIf="activeIndex === 1" class="grid formgrid mt-5">
            <div class="col-12 md:col-12">
                <div class="card shadow-none">
                    <div class="flex flex-column mb-2">
                        <label for="parcelamento" class="w-full ss-g-globalClassLabel">Quantidade de cobranças
                            <p-dropdown [options]="opcoesParcelamento" id="parcelamento" class="w-full"
                                (onChange)="quantidadeCobs()" [(ngModel)]="selecaoParcelamento"
                                [ngClass]="{'ng-invalid': hasError7, 'ng-dirty': hasError7}"
                                [ngClass]="{'ng-invalid': hasError4, 'ng-dirty': hasError4}"
                                placeholder="Selecione uma opção" autoWidth="false" [showClear]="true" [filter]="false">
                                <ng-template let-item pTemplate="item">
                                    <div>
                                        <span style="font-size: smaller;">{{ item.cobrancas }} </span>
                                        <span style="font-size: larger; font-weight: bold;">{{ item.valorFormatado
                                            }}</span>
                                    </div>
                                </ng-template>
                                <ng-template let-selItem pTemplate="selectedItem">
                                    <div>
                                        <span style="font-size: smaller;">{{ selItem.cobrancas }} </span>
                                        <span style="font-size: larger; font-weight: bold;">{{ selItem.valorFormatado
                                            }}</span>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </label>
                        <div class="flex align-items-center justify-content-between">
                            <!-- Mensagem de Erro à esquerda -->
                            <small *ngIf="erro7" class="p-error">
                                Obrigatório
                            </small>

                            <!-- Link "+ Cadastrar" à direita -->
                            <a class="font-medium no-underline ml-auto text-blue-500 cursor-pointer"
                                (click)="openSimulador()">Simular
                                Venda</a>
                        </div>

                    </div>

                    <div class="flex align-items-center mt-2 mb-5">
                        <p-inputSwitch [(ngModel)]="cobrarTaxas" (onChange)="simular()"></p-inputSwitch>
                        <label class="ml-2">Cobrar Taxas do Pagador</label>
                    </div>
                    <div class="mt-2 surface-border border-0"
                        [ngClass]="{'border-bottom-1': !hasSelectedCreditCard}">
                        <div [ngClass]="{'mb-5': !hasSelectedCreditCard}">
                            <label class="ss-g-globalClassLabel w-full">Selecione as formas de pagamento</label>
                            <p-multiSelect [options]="paymentOptionsCob" [(ngModel)]="selectedPaymentMethodsCob"
                                display="chip"
                                [ngClass]="{'ng-invalid': hasError5, 'ng-dirty': hasError5, 'mb-2': parcelamentoJuros}"
                                class=" mt-2 w-full ss-g-inputM" optionLabel="label" (onChange)="onMethodsChange()">
                                <ng-template let-option pTemplate="item">
                                    <div class="flex align-items-center">
                                        <i [ngClass]="option.icon" class="mr-2"></i>
                                        <span class="label-spacing">{{ option.label }} </span>
                                        <div class="flex-column">
                                            <span class="small-text"><b> {{ option.additionalText }} </b></span>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                            <div class="flex align-items-center justify-content-between mt-2">
                                <!-- Mensagem de Erro à esquerda -->
                                <small *ngIf="erro5" class="p-error">
                                    Obrigatório
                                </small>

                            </div>
                        </div>
                    </div>


                    <div class="flex align-items-center mt-2 mb-2 surface-border border-0"
                        [ngClass]="{'border-bottom-1': !parcelamentoJuros, 'mb-5':!parcelamentoJuros}"
                        *ngIf="hasSelectedCreditCard">
                        <p-inputSwitch [(ngModel)]="parcelamentoJuros"
                            [ngClass]="{'mb-5':!parcelamentoJuros}"></p-inputSwitch>
                        <label class="ml-2" [ngClass]="{'mb-5':!parcelamentoJuros}">Oferecer parcelamento s/
                            juros</label>
                    </div>
                    <div class="flex align-items-center mt-2 surface-border border-0"
                        [ngClass]="{'border-bottom-1': parcelamentoJuros}"
                        *ngIf="parcelamentoJuros && hasSelectedCreditCard">
                        <div class="col-12" style="padding: 0;" [ngClass]="{'mb-5': parcelamentoJuros}">
                            <input type="text" pInputText [(ngModel)]="valSlider" readonly class="w-full" />
                            <p-slider [(ngModel)]="valSlider" (onChange)="onSliderChange()" class="mb-1" [step]="1"
                                [max]="12" [min]="2"></p-slider>
                            <div class="mb-1" style="width: 100%;"></div>
                            <small>Você paga até <b>{{ valorTotalSlider | currency:'BRL':'symbol-narrow' }}</b> ({{
                                valSliderJuros }}%) em taxas adicionais</small>
                        </div>
                    </div>
                    <div class="flex align-items-center mt-5 mb-2 w-full">
                        <label class="ss-g-globalClassLabel">Data de vencimento</label>
                    </div>
                    <div class="flex align-items-center w-full">
                        <p-calendar [(ngModel)]="dataSelecionada" (ngModelChange)="onDateChange($event)"
                            [showTime]="true" [ngClass]="{'ng-invalid': hasError6, 'ng-dirty': hasError6}"
                            class="mb-2 calendar-grid " [minDate]="minDate" [showIcon]="true"
                            [disabledDates]="disabledDates" [readonlyInput]="true"></p-calendar>
                    </div>
                    <small *ngIf="erro6" class="p-error mt-2">
                        Obrigatório
                    </small>

                    <div class="flex align-items-center mt-2 surface-border border-0"
                        [ngClass]="{'border-bottom-1': hasSelectedBoletoPix && !pagarVencimento}">
                        <p-inputSwitch [(ngModel)]="pagarVencimento"
                            [ngClass]="{'mb-5': hasSelectedBoletoPix && !pagarVencimento}"></p-inputSwitch>
                        <label class="ml-2" [ngClass]="{'mb-5': hasSelectedBoletoPix && !pagarVencimento}">Aceitar
                            pagamento após o vencimento</label>
                    </div>
                    <div class="flex align-items-center mt-2 mb-2 w-full" *ngIf="pagarVencimento">
                        <div class="input-wrapper-vencimento w-full">
                            <p-inputNumber [(ngModel)]="payment_limit" class="calendar-grid w-full" [min]="0"
                                [max]="100" [step]="1" suffix=" dia(s)"></p-inputNumber>
                        </div>
                    </div>
                    <div class="flex align-items-center mt-2 mb-2 w-full surface-border border-0"
                        *ngIf="pagarVencimento" [ngClass]="{'border-bottom-1': hasSelectedBoletoPix}">
                        <label class="ml-2 w-full" [ngClass]="{'mb-5': pagarVencimento}">ex.: Receber pagamento até 3
                            dias após o vencimento</label>
                    </div>


                    <div class="flex align-items-center mt-5 mb-5" *ngIf="hasSelectedBoletoPix">
                        <p-inputSwitch [(ngModel)]="multasJurosDescontos"></p-inputSwitch>
                        <label class="ml-2">Aplicar multas, juros e descontos (Pix, Boleto)</label>
                    </div>
                    <div id="collapse-aplicar-juros" *ngIf="multasJurosDescontos && hasSelectedBoletoPix">
                        <div class="grid formgrid mb-2 mt-5 mb-5">
                            <div class="w-full mb-2  flex flex-column">
                                <label for="juros" class="ss-g-globalClassLabel w-full"><strong>Juros</strong> (ao
                                    mês)</label>
                                <p-dropdown [options]="jurosOptions" id="juros" [(ngModel)]="interestHandler"
                                    class="p-dropdown-item" [disabled]="true"></p-dropdown>
                            </div>

                            <!-- Valor dos Juros -->
                            <div class="w-full mb-2  flex flex-column">
                                <label for="valorJuros" class="ss-g-globalClassLabel w-full"><strong>Valor</strong>
                                    (%)</label>
                                <p-dropdown [options]="valorJurosOptions" id="valorJuros" class="p-dropdown-item"
                                    [(ngModel)]="late_fee_daily"></p-dropdown>
                            </div>
                        </div>

                        <div class="grid formgrid mb-5">
                            <!-- Seleção de Multa -->
                            <div class="col-12 mb-2 flex flex-column">
                                <label for="multa" class="ss-g-globalClassLabel w-full">Multa (ao mês)</label>
                                <p-dropdown [options]="multaOptions" id="multa" [(ngModel)]="fineHandler"
                                    class="p-dropdown-item w-full" [disabled]="true"></p-dropdown>
                            </div>

                            <!-- Valor dos Juros -->
                            <div class="col-12 mb-2 flex flex-column">
                                <label for="valorJuros123s" class="ss-g-globalClassLabel w-full">Valor (%)</label>
                                <p-dropdown [options]="multaOptionsValor" id="valorJuros123s" class="p-dropdown-item"
                                    [(ngModel)]="late_fee"></p-dropdown>
                            </div>
                        </div>

                        <div class="grid formgrid">
                            <!-- Seleção de Multa -->
                            <div class="col-12 mb-2 flex flex-column">
                                <label for="juros123" class="ss-g-globalClassLabel w-full">Desconto</label>
                                <p-dropdown [options]="descontosOptions" id="juros123" [(ngModel)]="fineHandler2"
                                    class="p-dropdown-item" (onChange)="onFirstDropdownChange()"></p-dropdown>
                            </div>

                            <!-- Valor dos Juros -->
                            <div class="col-12 mb-2 flex flex-column">
                                <label for="valorJurosD" class="ss-g-globalClassLabel w-full">Valor (%)</label>
                                <p-dropdown [options]="descontosOptionsValor" id="valorJurosD" class=" p-dropdown-item"
                                    [(ngModel)]="discount.discount_percentage"
                                    [disabled]="isSecondDropdownDisabled"></p-dropdown>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="activeIndex === 2" class="grid formgrid mt-5">
            <div class="col-12 md:col-12">
                <div class="card shadow-none">
                    <div class="ss-g-container-qr-code mb-2" style="text-align: center;">
                        <i class="pi pi-link text-xl"></i>
                        <div class="text-2xl mb-5">Seu link está pronto!</div>
                        <div *ngIf="qrCodeUrl" class="mb-5">
                            <img [src]="qrCodeUrl" alt="QR Code" style="width: 200px; height: 200px;">
                        </div>
                        <div class="p-inputgroup ss-g-custom-inputgroup">
                            <input type="text" pInputText [(ngModel)]="url_share" readonly class="custom-input">
                            <button pButton type="button" icon="pi pi-copy" (click)="copiarLink()"
                                class="custom-button"></button>
                        </div>


                        <div class="ss-g-button-container-qr-code mt-2">
                            <button pButton type="button" style="padding: 0;" (click)="compartilharLink()"
                                icon="pi pi-share-alt" class="p-button-link left-button" label="Compartilhar"></button>
                            <a [href]="url_share" target="_blank" class="right-button"><i class="pi pi-eye"></i> Ver
                                Link</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>







    </div>
    <ng-template pTemplate="footer">
        <div style="text-align: right;">
            <button pButton pRipple label="Fechar" (click)="closeSidebar()" *ngIf="activeIndex === 0"
                class="p-button-outlined "></button>
            <button pButton pRipple (click)="proximoStep()" label="Próximo" *ngIf="activeIndex === 0"
                class="ml-2 mr-2"></button>


            <button pButton pRipple label="Voltar" (click)="previousStep()" *ngIf="activeIndex === 1"
                class="p-button-outlined "></button>
            <button pButton pRipple label="Finalizar" (click)="finishProcess()" *ngIf="activeIndex === 1"
                [loading]="loadingFinish" class="ml-2 mr-2"></button>

            <button pButton pRipple (click)="closeSidebar()" *ngIf="activeIndex === 2">Fechar</button>
        </div>
    </ng-template>
</p-sidebar>