<div class="ss-g-menuP layout-menuitem ">
    <div class="cardSaldoMenu h-full relative" style="overflow: visible;border-radius: 12px;">

        <svg id="visual" viewBox="0 0 900 600" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" preserveAspectRatio="none"
            class="absolute left-0 top-0 h-full w-full z-1" style="border-radius: 12px;">
            <rect x="0" y="0" width="900" height="600" fill="var(--primary-600)"></rect>
            <path
                d="M0 400L30 386.5C60 373 120 346 180 334.8C240 323.7 300 328.3 360 345.2C420 362 480 391 540 392C600 393 660 366 720 355.2C780 344.3 840 349.7 870 352.3L900 355L900 601L870 601C840 601 780 601 720 601C660 601 600 601 540 601C480 601 420 601 360 601C300 601 240 601 180 601C120 601 60 601 30 601L0 601Z"
                fill="var(--primary-500)" stroke-linecap="round" stroke-linejoin="miter"></path>
        </svg>

        <!-- <div style="position: relative;border-radius: 12px;z-index: 100;" class="ss-g-backColorMenuWallet">
            <p-tabView>
                <p-tabPanel header="Disponível" class="ss-g-panelMenu text-white">
                    <div class="ss-g-valor-e-botoes">
                        <span class="ss-g-valor-container">
                            <ng-container *ngIf="!carregando; else loading">
                                <span *ngIf="mostrarValores" style="font-size: 18px;"><b>{{saldoDisponivel | currency:
                                        'BRL'}}</b></span>
                                <span *ngIf="!mostrarValores" style="font-size: 18px;"><b>*****</b></span>
                            </ng-container>
                            <ng-template #loading>
                                <p-progressSpinner class="ss-g-progressSpinnerMenu"></p-progressSpinner>
                            </ng-template>
                        </span>
                        <button pButton type="button" (click)="alternarVisibilidade()"
                            [icon]="mostrarValores ? 'pi pi-eye' : 'pi pi-eye-slash'"
                            class="p-button-rounded p-button-text ss-g-buttonMenu text-white"></button>
                        <button pButton type="button" (click)="atualizarValores()" icon="pi pi-refresh"
                            class="p-button-rounded p-button-text ss-g-buttonMenu text-white"></button>

                    </div>
                </p-tabPanel>
                <p-tabPanel header="A Liberar" class="ss-g-panelMenu text-white">
                    <div class="ss-g-valor-e-botoes">
                        <span class="ss-g-valor-container">
                            <ng-container *ngIf="!carregando; else loading">
                                <span *ngIf="mostrarValores" style="font-size: 18px;" class="text-white"><b>{{saldoLiberar | currency
                                        :'BRL'}}</b></span>
                                <span *ngIf="!mostrarValores" style="font-size: 18px;" class="text-white"><b>*****</b></span>
                            </ng-container>
                            <ng-template #loading>
                                <p-progressSpinner class="ss-g-progressSpinnerMenu"></p-progressSpinner>
                            </ng-template>
                        </span>
                        <button pButton type="button" (click)="alternarVisibilidade()"
                            [icon]="mostrarValores ? 'pi pi-eye' : 'pi pi-eye-slash'"
                            class="p-button-rounded p-button-text ss-g-buttonMenu text-white"></button>
                        <button pButton type="button" (click)="atualizarValores()" icon="pi pi-refresh"
                            class="p-button-rounded p-button-text ss-g-buttonMenu text-white"></button>

                    </div>
                </p-tabPanel>
                <div class="split-buttons-container">
                    <p-splitButton label="Cobrar" [model]="itemsButtonsCobrar"
                        class="p-button-info ss-g-split-button-custom text-white mr-1"></p-splitButton>
                    <p-splitButton label="Retirar" [model]="itemsButtonsRetirar"
                        class="p-button-success ss-g-split-button-custom text-white"
                        appendTo="body"></p-splitButton>
                </div>
            </p-tabView>
            <button pButton type="button" (click)="openMenu($event)"
                class="p-button-rounded p-button-text p-button-plain ss-g-buttonExtratoMenu text-white"
                style="position: absolute; top: 10px; right: 10px;">
                <i class="pi pi-ellipsis-v"></i>
            </button>
            <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>

        </div> -->


    </div>
</div>
<style>
    .layout-menu {
    width: 100%; /* Assegura que o menu ocupe a largura total do contêiner */
}

.layout-menu li {
    width: 100%; /* Assegura que cada item do menu ocupe a largura total */
    text-align: left; /* Alinha o texto à esquerda */
}

.menu-separator {
    width: 100%; /* Garante que os separadores ocupem a largura total */
}

.divMenu {
    width: 100%; /* Garante que os divisores dentro dos separadores ocupem a largura total */
}
    .flex-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        /* Altura total do contêiner do menu */
    }

    .flex-spacer {
        flex-grow: 1;
    }

    .sidebar-header .player-button {
        width: 100%; /* Assegura que cada item do menu ocupe a largura total */
    text-align: left;
    }
</style>

<ul class="layout-menu flex-container">
    <ng-container *ngFor="let item of model; let i = index;">
        <li app-menuitem *ngIf="!item.separator && !item.custom" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator divMenu">
            <div class="divMenu p-button-label"></div>
        </li>
    </ng-container>
</ul>