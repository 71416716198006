import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDuration'
})
export class FormatDurationPipe implements PipeTransform {

  transform(value: string | null): string {
    if (!value) return '';
    const parts = value.split(':').map(part => parseInt(part, 10));
    const [hours, minutes, seconds] = parts;
  
    let formatted = '';
    if (hours > 0) {
      formatted += `${hours}h`;
    }
    if (minutes > 0) {
      formatted += `${minutes < 10 ? '0' : ''}${minutes}m`;
    }
    if (seconds >= 0) {
      formatted += `${seconds < 10 ? '0' : ''}${seconds}s`;
    }
  
    return formatted;
  }
  
}
