import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LiveStatusService {
  // Inicializa o BehaviorSubject com o estado inicial 'offline'
  private state = new BehaviorSubject<'idle' | 'offline' | 'online'>('offline');

  constructor() {}

  // Permite a inscrição ao estado atual e quaisquer mudanças futuras
  subscribe(observer: (value: 'idle' | 'offline' | 'online') => void) {
    return this.state.asObservable().subscribe(observer);
  }

  // Atualiza o estado e notifica todos os observadores
  setState(newState: 'idle' | 'offline' | 'online') {
    this.state.next(newState);
  }

  // Retorna o último valor do estado sem necessidade de subscrição
  getState(): 'idle' | 'offline' | 'online' {
    return this.state.getValue();
  }

  // Exemplo de manipulação de erro, atualiza o estado para 'offline'
  handleSocketError(event: string, error: any) {
    console.log(`Erro no socket: ${event}`, error);
    this.setState('offline');
  }

  // Exemplo de manipulação de conexão bem-sucedida
  handleSocketConnect() {
    console.log('Conectado ao WebSocket');
    this.setState('online'); // Ajuste conforme a lógica específica da sua aplicação
  }
}
