import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PMetaStoreService {

  constructor() { }

  // Método para adicionar um produto ao carrinho
  addProductToCart(product: any, cart: any[], show: any, products: any[]) {
    const event = new CustomEvent('cartEvent', {
      detail: {
        action: 'add_product',
        item: product,
        cart: cart,
        show: show,
        products: products
      }
    });
    window.dispatchEvent(event);
  }

  // Método para finalizar a compra
  checkoutCart(cart: any[]) {
    const event = new CustomEvent('cartEvent', {
      detail: {
        action: 'checkout_cart',
        cart: cart
      }
    });
    window.dispatchEvent(event);
  }

  // Simulação de disparo de eventos para teste
  testEvents() {
    this.addProductToCart({ id: 1, name: 'Produto 1' }, [], true, []);
    this.checkoutCart([{ id: 1, name: 'Produto 1' }]);
  }

  sendEvents(event: any) {
    window.dispatchEvent(event);
  }

  // Novo método para enviar evento de progresso do player
  sendPlayerProgressEvent(
    currentProgress: number,
    watchedTime: number,
    buffered: number,
    pauses: number,
    forwards: number,
    rewards: number,
    assetUid: string,
    pitchTime: string
  ) {
    let event = new CustomEvent('playerEvents', {
      detail: {
        event: 'progress',
        resume: 'resume',
        progress: currentProgress.toFixed(2),
        watchedTime: watchedTime,
        buffered: buffered,
        pauses: pauses,
        forwards: forwards,
        rewards: rewards,
        asset_uid: assetUid,
        pitch_time: pitchTime
      }
    });

    // Enviar evento
    this.sendEvents(event);
  }
  // Novo método: Enviar evento de primeiro play
  sendFirstPlayEvent(activeIndex: number, slicks: any[], playerSessionId: string) {
    const currentSlick = slicks[activeIndex];

    if (currentSlick && currentSlick.content) {
      const currentUid = currentSlick.content.uid;

      let event = new CustomEvent('playerEvents', {
        detail: {
          event: 'play',
          asset_uid: currentUid,
          playerSessionId: playerSessionId,
        }
      });

      this.sendEvents(event);
    }
  }

  // Novo método: Enviar evento de pitch time
  sendPitchTimeEvent(activeIndex: number, slicks: any[]) {
    const currentSlick = slicks[activeIndex];

    if (currentSlick && currentSlick.content) {
      const currentUid = currentSlick.content.uid;

      // Recuperar os dados do player do localStorage
      const playerDataJSON = localStorage.getItem('player_data');
      if (playerDataJSON) {
        const playerData = JSON.parse(playerDataJSON);
        const pitchTime = playerData.current_asset.pitch_time;

        let event = new CustomEvent('playerEvents', {
          detail: {
            event: 'pitch',
            asset_uid: currentUid,
            pitch_time: pitchTime // Usar o pitch_time recuperado
          }
        });

        this.sendEvents(event);
      }
    }
  }
  // Método para enviar eventos customizados com base no tipo
  sendCustomEvents(
    type: string,
    details: string,
    id_cta: number,
    slicks: any[],
    activeIndex: number,
    tempUrl: string
  ): void {
    const currentSlick = slicks[activeIndex];
    if (!currentSlick || !currentSlick.content) {
      return;
    }

    const currentUid = currentSlick.content.uid;
    let event: CustomEvent;

    switch (type) {
      case 'like':
        event = new CustomEvent('customEvents', { detail: { event: 'like', asset_uid: currentUid } });
        break;
      case 'cta_events':
        event = new CustomEvent('customEvents', { detail: { event: 'cta_click', type: details, title: 'Tree', id_cta: id_cta, asset_uid: currentUid } });
        break;
      case 'cta_redirect':
        event = new CustomEvent('customEvents', { detail: { event: 'cta_redirect', type: details, title: 'Tree', id_cta: id_cta, url: tempUrl, asset_uid: currentUid } });
        break;
      case 'share':
        event = new CustomEvent('customEvents', { detail: { event: 'share', asset_uid: currentUid } });
        break;
      case 'slideMove':
        event = new CustomEvent('customEvents', { detail: { event: 'swipe', asset_uid: currentUid } });
        break;
      case 'pip':
        event = new CustomEvent('customEvents', { detail: { event: 'pip', asset_uid: currentUid } });
        break;
      case 'mute':
        event = new CustomEvent('customEvents', { detail: { event: 'mute', asset_uid: currentUid } });
        break;
      case 'unmute':
        event = new CustomEvent('customEvents', { detail: { event: 'unmute', asset_uid: currentUid } });
        break;
      case 'openChat':
        event = new CustomEvent('customEvents', { detail: { event: 'open_chat', asset_uid: currentUid } });
        break;
      case 'maximize':
        event = new CustomEvent('customEvents', { detail: { event: 'maximize', asset_uid: currentUid } });
        break;
      case 'minimize':
        event = new CustomEvent('customEvents', { detail: { event: 'minimize', asset_uid: currentUid } });
        break;
      case 'session_id':
        event = new CustomEvent('customEvents', { detail: { event: 'session_id', session_id: details } });
        break;
      case 'analytics':
        event = new CustomEvent('customEvents', { detail: { event: 'analytics', session_id: details } });
        break;
      default:
        event = new CustomEvent(type, { detail: { details } });
    }

    this.sendEvents(event);
  }
}
